/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import {
  all,
  call,
  takeLatest,
  take,
  cancel,
  Effect
} from "redux-saga/effects";
import { Task } from "redux-saga";
import { delay } from "redux-saga";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import resetPassword from "src/ipm-platform/pages/ResetPassword/sagas";

import { toast } from "react-toastify";

// Shared
import accounts from "src/ipm-shared/store/model/AccountProfile/sagas";
import account_rates from "src/ipm-shared/store/model/AccountRates/sagas";
import auth from "src/ipm-shared/store/model/Auth/sagas";
import banks from "src/ipm-shared/store/model/Bank/sagas";
import cards from "src/ipm-shared/store/model/Card/sagas";
import cardbrands from "src/ipm-shared/store/model/CardBrand/sagas";
import company from "src/ipm-shared/store/model/Company/sagas";
import countries from "src/ipm-shared/store/model/Country/sagas";
import currencies from "src/ipm-shared/store/model/Currency/sagas";
import promoCode from "src/ipm-shared/store/model/PromoCode/sagas";
import dateconditions from "src/ipm-shared/store/model/DateCondition/sagas";
import fullscreen from "src/ipm-shared/store/model/FullScreen/sagas";
import legacyForm from "src/ipm-shared/store/model/LegacyForms/sagas";
import notifications from "src/ipm-shared/store/model/Notification/sagas";
import payee from "src/ipm-shared/store/model/Payee/sagas";
import payments from "src/ipm-shared/store/model/Payment/sagas";
import paymentRequests from "src/ipm-shared/store/model/PaymentRequest/sagas";
import paymentCollections from "src/ipm-shared/store/model/PaymentCollections/sagas";
import purpose from "src/ipm-shared/store/model/Purpose/sagas";
import user from "src/ipm-shared/store/model/Users/sagas";
import accountsManager from "src/ipm-shared/store/model/AccountsManager/sagas";
import customers from "src/ipm-shared/store/model/Customer/sagas";
import businessTypes from "src/ipm-shared/components/SelectBusiness/sagas";
import collectedAccount from "src/ipm-shared/store/model/CollectedAccount/sagas";
import paymentCollectionRequest from "src/ipm-shared/store/model/PaymentCollectionRequest/sagas";
import onBoarding from "src/ipm-shared/store/model/OnBoarding/sagas";
import uen from "src/ipm-shared/store/model/Uen/sagas";
import reconcile from "src/ipm-shared/store/model/Reconcile/sagas";
import form from "src/ipm-shared/components/Form/sagas";
import screening from "src/ipm-shared/store/model/Screening/sagas";
import escalations from "src/ipm-shared/store/model/Escalation/sagas";
import referral from "src/ipm-shared/store/model/Referral/sagas";

function* toastie(action: ActionType<typeof actions.toast>) {
  yield delay(100);
  yield call(toast, action.payload);
}

export default function rootSaga(
  watchedSagas: Effect[] = [],
  preWatchedSagas: Effect[] = [],
  redirection: string = "/login"
) {
  return function*() {
    yield all([
      ...countries,
      ...currencies,
      ...form,
      ...auth,
      ...resetPassword,
      ...paymentCollectionRequest,
      ...preWatchedSagas,
      takeLatest(getType(actions.toast), toastie)
    ]);

    // @ts-ignore
    while (yield take(getType(actions.start))) {
      // @ts-ignore
      const allTasks = yield all([
        ...accounts,
        ...account_rates,
        ...banks,
        ...cards,
        ...cardbrands,
        ...company,
        ...promoCode,
        ...dateconditions,
        ...fullscreen,
        ...legacyForm,
        ...notifications,
        ...payee,
        ...payments,
        ...paymentRequests,
        ...purpose,
        ...user,
        ...accountsManager,
        ...paymentCollections,
        ...customers,
        ...businessTypes,
        ...collectedAccount,
        ...onBoarding,
        ...uen,
        ...reconcile,
        ...screening,
        ...escalations,
        ...referral,
        ...watchedSagas
      ]);

      yield take(getType(actions.stop));
      yield all(allTasks.map((task: Task) => cancel(task)));

      window.location.href = redirection;
    }
  };
}
