/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import paymentrequests from "./reducers";
import _get from "lodash-es/get";
ReducerFactory.registerReducer({ paymentrequests });

export const getFees = (state: RootState) => ({
  amountOff: state.paymentrequests.amountOff,
  couponUsed: state.paymentrequests.couponUsed,
  exchangeRate: state.paymentrequests.exchangeRate,
  licenseFee: state.paymentrequests.licenseFee,
  paymentAmount: state.paymentrequests.paymentAmount,
  rateBeforeCoupon: state.paymentrequests.rateBeforeCoupon / 100,
  rateUsed: state.paymentrequests.rateUsed / 100,
  savings: state.paymentrequests.savings,
  total: state.paymentrequests.total,
  payeeQuoteToken: state.paymentrequests.payeeQuoteToken
});

export const getCurrentPaymentRequest = (state: RootState) => ({
  ...state.paymentrequests,
  payees: state.paymentrequests.payees.map(payee => ({
    ...payee,
    bankName: _get(state.banks.byId, `${payee.bankId}.name`, "")
  })),
  subtotal: state.paymentrequests.subtotal
});

export const getCurrentPurpose = (state: RootState) =>
  state.paymentrequests.currentPurpose;

export const getCurrentPaidCard = (state: RootState) =>
  state.paymentrequests.paidCard;

export const getCouponUsed = (state: RootState) =>
  state.paymentrequests.couponUsed;
