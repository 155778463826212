import * as React from "react";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { ControlErrorType, ControlValueType } from "../Form/types";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "../Form/actions";
import * as formSelectors from "../Form/selectors";
import * as countrySelectors from "src/ipm-shared/store/model/Country/selectors";
import * as accountProfileSelector from "src/ipm-shared/store/model/AccountProfile/selectors";
import {
  default as BaseControl,
  IBaseProps,
  IBaseState
} from "../Form/controls/lib/Base";
import { connect } from "react-redux";
import _isEmpty from "lodash-es/isEmpty";
import withPropsChecker from "src/ipm-shared/components/Form/controls/lib/withPropsChecker";
import _intersection from "lodash-es/intersection";
import UtilCurrency from "src/ipm-shared/Utils/Currency";
import * as classNames from "classnames";
import { CountryType } from "src/ipm-shared/store/model/Country/reducers";
import _takeWhile from "lodash-es/takeWhile";
import _dropWhile from "lodash-es/dropWhile";
import T from "src/ipm-shared/Utils/Intl";

type CountryPickerProps = IBaseProps;

const selectors = {
  ...formSelectors,
  ...countrySelectors,
  ...accountProfileSelector
};

const mapStateToProps = (state: RootState, props: CountryPickerProps) => ({
  accountCountry: selectors.getAccountCountryCode(state),
  control: selectors.getControl(state, props.name),
  currentCountry: selectors.getCurrentCountry(state),
  getAllCountries: selectors.getAllCountries(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

type IProps = IBaseProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    allowCountries?: string[];
    isHiddenMobileCountryNumber?: boolean;
    isDisplayCurrencyCode?: boolean;
    isHiddenLabel?: boolean;
    isHiddenLabelDropdown?: boolean;
    allowAllCountries?: boolean;
    labelOnlyIsDefaultValue?: boolean;
    exceptCountry?: string[];
    isValidateComponent?: boolean;
  };

interface IState extends IBaseState {
  open: boolean;
  selected: number;
}

class CountryPicker extends BaseControl<IProps, IState> {
  public static options = [
    { name: "Australia", code: "AU", currency: "AUD", codeNumber: 61, id: 16 },
    { name: "Hong Kong", code: "HK", currency: "HKD", codeNumber: 852, id: 3 },
    { name: "Malaysia", code: "MY", currency: "MYR", codeNumber: 60, id: 2 },
    { name: "Singapore", code: "SG", currency: "SGD", codeNumber: 65, id: 1 }
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      selected: 1
    };
  }

  public componentDidMount() {
    const {
      name,
      defaultValue,
      form,
      isValidateComponent,
      displayError,
      placeholder
    } = this.props;

    let options = CountryPicker.options;

    if (this.props.exceptCountry) {
      options = options.filter(
        o => (this.props.exceptCountry as string[]).indexOf(o.code) < 0
      );
    }

    this.props.setControl({
      form,
      name,
      value: defaultValue || (placeholder ? undefined : options[0].code) // If have placeholder that mean no have value.
    });

    if (isValidateComponent) {
      this.doValidate(defaultValue, name, displayError);
    }
  }

  public render() {
    let listCountries = CountryPicker.options;
    if (this.props.allowAllCountries) {
      listCountries = this.props.getAllCountries;
    }

    const { control } = this.props;
    let options = [];

    if (_isEmpty(control.value) && !this.props.placeholder) {
      return T.transl("LOADING_STATUS");
    }

    if (this.props.allowCountries) {
      const code = this.props.getAllCountries.map(c => c.code);
      const finallyCountries = _intersection(code, this.props.allowCountries);

      options = this.props.getAllCountries.filter(
        c => finallyCountries.indexOf(c.code) > -1
      );
    } else {
      options = listCountries;
    }

    if (this.props.exceptCountry) {
      options = options.filter(
        o => (this.props.exceptCountry as string[]).indexOf(o.code) < 0
      );
    }

    const selected = this.getSelected(
      this.props.control.value as string,
      options
    );

    return (
      <>
        <Dropdown
          className={classNames(
            `countrypicker ${this.props.className ? this.props.className : ""}`,
            {
              "hide-label": this.props.isHiddenLabel
            }
          )}
          isOpen={this.state.open}
          toggle={this.handleToggle}
        >
          <DropdownToggle color="white" caret={true}>
            <div>
              {selected ? (
                <>
                  <span
                    className={`flag-icon flag-icon-${selected.code.toLowerCase()}`}
                  />
                  <span className="ml-2">
                    {!this.props.isHiddenLabel && (
                      <span className="label mb-0">
                        {T.transl(`COUNTRY_${selected.id}`)}
                      </span>
                    )}{" "}
                    {!this.props.isHiddenMobileCountryNumber && (
                      <span className="prefix">+{selected.codeNumber}</span>
                    )}
                  </span>
                  {this.props.isDisplayCurrencyCode && (
                    <span className={"currency"}>
                      ({UtilCurrency.convertFromId(selected.id).$})
                    </span>
                  )}
                </>
              ) : (
                this.props.placeholder || ""
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu
            className={classNames({
              "have-label": !this.props.isHiddenLabelDropdown
            })}
            tag="ul"
          >
            {Object.keys(options).map(this.renderDropdownItemByKey)}
          </DropdownMenu>
        </Dropdown>
        {this.renderErrorMessage(control)}
      </>
    );
  }

  private getSelected(value: string, countries: CountryType[]) {
    return countries.filter(o => o.code === value)[0];
  }

  private renderDropdownItemByKey = (key: string) => {
    // const currentCountry = this.props.currentCountry;

    let listCountries = CountryPicker.options;
    if (this.props.allowAllCountries) {
      listCountries = this.props.getAllCountries;
    }
    if (this.props.allowCountries) {
      const code = this.props.getAllCountries.map(c => c.code);
      const finallyCountries = _intersection(code, this.props.allowCountries);
      listCountries = this.props.getAllCountries.filter(
        c => finallyCountries.indexOf(c.code) > -1
      );
    }

    const sgObject = _takeWhile(listCountries, c => c.code === "SG");
    listCountries = _dropWhile(listCountries, c => c.code === "SG");

    // listCountries = listCountries
    // .sort((a, b) => (a.name > b.name ? 1 : -1))
    // .sort((a, b) =>
    //   a.code === currentCountry ? -1 : b.code === currentCountry ? 1 : 0
    // ); // Push current country account to as first of list

    listCountries = sgObject.concat(listCountries); // Push SG always to at the top

    if (this.props.exceptCountry) {
      listCountries = listCountries.filter(
        o => (this.props.exceptCountry as string[]).indexOf(o.code) < 0
      );
    }

    const option = listCountries[key];

    return (
      <DropdownItem
        tag="li"
        key={key}
        onClick={this.selectHandler.bind(this, option.code)}
        className={classNames({
          active: option.code === this.props.control.value
        })}
      >
        <span className={`flag-icon flag-icon-${option.code.toLowerCase()}`} />
        <span className="ml-2">
          {!this.props.isHiddenLabelDropdown && (
            <span className="label">{T.transl(`COUNTRY_${option.id}`)}</span>
          )}{" "}
          {!this.props.isHiddenMobileCountryNumber ? (
            <span className="prefix">+{option.codeNumber}</span>
          ) : (
            ""
          )}
        </span>
      </DropdownItem>
    );
  };

  private handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  private selectHandler = (value: string) => {
    this.props.setControl({
      errors: [],
      name: this.props.name,
      value
    });

    this.doValidate(value, this.props.name);
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true
  ) => {
    let it: any;
    let result: any;
    const errors: ControlErrorType[] = [];

    it = CountryPicker.baseValidate(this.props, value);
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (this.props.onChangeCustom) {
        this.props.onChangeCustom(value);
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(CountryPicker)
);
