/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType, ControlType } from "./types";
import _get from "lodash-es/get";

export const getControl = <T>(
  state: RootState,
  name: string,
  form: string = "default",
  defaultValue?: T
) => {
  return (state.form.controls[name] || {
    errors: [],
    form,
    name,
    notFound: true,
    value: defaultValue
  }) as ControlType & {
    value: T;
    notFound?: boolean;
  };
};

export const getControlsAsObject = (
  state: RootState,
  formName: string
): object => {
  const controls: object = {};
  Object.keys(state.form.controls).map((controlName: string) => {
    const control: ControlType = state.form.controls[
      controlName
    ] as ControlType;
    if (control.form === formName) {
      controls[control.name] = control.value;
    }
  });

  return controls;
};

export const getControls = (state: RootState, formName: string): object =>
  state.form.controls;

export const getFormErrors = (
  state: RootState,
  formName: string
): ControlErrorType[] => _get(state.form.forms[formName], "errors", []);

export const hasError = (
  state: RootState,
  formName: string,
  withServerError?: boolean
): boolean => {
  // No need to check Form Error here

  // If at least 1 control has error => has error
  for (const name of Object.keys(state.form.controls)) {
    if (_get(state.form.controls[name], "form", "default") !== formName) {
      continue;
    }
    const controlErrors = _get(state.form.controls[name], "errors", []);
    const controlIgnoredErrors = _get(
      state.form.controls[name],
      "ignoredErrors",
      []
    );

    for (const e of controlErrors) {
      if (!controlIgnoredErrors.includes(e.code)) {
        // not in ignored error list
        if (!e.fromServer) {
          // its not server error -> its definitely error
          return true;
        } else {
          if (withServerError) {
            // its server error and include server error
            return true;
          }
        }
      }
    }
  }

  return false;
};

export const hasConfirmed = (
  state: RootState,
  formName: string,
  controlName?: string
): boolean => {
  for (const name of Object.keys(state.form.controls)) {
    if (controlName !== undefined && controlName !== name) {
      continue;
    }

    const control = state.form.controls[name] as ControlType;
    if (control.form !== formName) {
      continue;
    }

    if (control.requiredConfirmation && !control.confirmed) {
      return false;
    }
  }

  return true;
};

export const hasFormError = (
  state: RootState,
  formName: string,
  withServerError?: boolean
): boolean => {
  // No need to check Form Error here

  // If at least 1 control has error => has error
  for (const name of Object.keys(state.form.forms)) {
    // if (_get(state.form.forms[name], "form", "default") !== formName) {
    //   continue;
    // }
    const formsErrors = _get(state.form.forms[name], "errors", []);
    const formsIgnoredErrors = _get(
      state.form.forms[name],
      "ignoredErrors",
      []
    );

    for (const e of formsErrors) {
      if (!formsIgnoredErrors.includes(e.code)) {
        // not in ignored error list
        if (!e.fromServer) {
          // its not server error -> its definitely error
          return true;
        } else {
          if (withServerError) {
            // its server error and include server error
            return true;
          }
        }
      }
    }
  }

  return false;
};

export const retrieveFormError = (state: RootState, formName: string) => {
  // No need to check Form Error here

  const errorCodes: string[] = [];
  // If at least 1 control has error => has error
  for (const name of Object.keys(state.form.forms)) {
    if (name === formName) {
      const formsErrors = _get(state.form.forms[name], "errors", []);
      const formsIgnoredErrors = _get(
        state.form.forms[name],
        "ignoredErrors",
        []
      );

      for (const e of formsErrors) {
        if (!formsIgnoredErrors.includes(e.code)) {
          const value = e.code;
          errorCodes.push(value);
        }
      }
    }
  }

  return errorCodes;
};

export const getSubmitButton = (state: RootState) => {
  return state.form.submitButton;
};

export const getControlsPattern = (state: RootState, pattern: RegExp) =>
  Object.keys(state.form.controls)
    .filter(name => pattern.test(name))
    .map(name => state.form.controls[name] as ControlType);
