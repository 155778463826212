/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import _get from "lodash-es/get";
import { ActionType, getType } from "typesafe-actions";
import * as referralActions from "./actions";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { RootState } from "../reducers";
import { SEARCH_FORM } from "./const";
import RestClient from "src/ipm-shared/services/Rest";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import _isEmpty from "lodash-es/isEmpty";

const actions = {
  ...referralActions,
  ...formActions
};
const watchedSagas = [
  reTryTakeLatest(actions.searchReferralList, handleSearchReferral),
  takeLatest(getType(actions.getReferralDetail), handleGetReferralDetail)
];
export default watchedSagas;

export function* handleSearchReferral(
  action: ActionType<typeof actions.searchReferralList>
) {
  const state: RootState = yield select();
  yield put(
    actions.setReferralList({
      isFetching: true,
      total: 0,
      referralList: []
    })
  );
  const { offset, pageCount } = action.payload;
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);
  query.country_id = countryId;
  query.offset = offset;
  query.page_count = pageCount;
  const res: Response = yield call(RestClient.send, {
    query,
    service: "admin_search_referral"
  });

  if (!res) {
    yield put(
      actions.setReferralList({
        isFetching: false,
        total: 0,
        referralList: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);
    const total: number = _get(res, "total");

    yield put(
      actions.setReferralList({
        isFetching: false,
        total,
        referralList: data.map(referral => ({
          id: referral.id,
          referralEmail: referral.referral_email,
          noOfSuccessfulReferrals: referral.no_of_successful_referrals,
          noOfActivePromoCodes: referral.no_of_active_promo_codes,
          latestExpiryDate: referral.latest_expiry_date
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleSearchReferral: ", e.message);
    return;
  }
}

export function* handleGetReferralDetail(
  action: ActionType<typeof actions.getReferralDetail>
) {
  yield put(
    actions.setReferralDetail({
      isFetchingReferralDetail: true,
      referralDetail: []
    })
  );

  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.referralId
    },
    service: "admin_get_referral_detail"
  });

  if (!res) {
    yield put(
      actions.setReferralDetail({
        isFetchingReferralDetail: false,
        referralDetail: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);

    yield put(
      actions.setReferralDetail({
        isFetchingReferralDetail: false,
        referralDetail: data.map(referee => ({
          refereeEmail: referee.referee_email,
          couponExpiryDate: referee.coupon_expiry_date
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleGetReferralDetail: ", e.message);
    return;
  }
}
