import { ActionType, getType } from "typesafe-actions";
import { call, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as screenfull from "screenfull";

const watchedSagas = [
  takeLatest(getType(actions.requestFullScreen), handleRequestFullscreen)
];
export default watchedSagas;
export function* handleRequestFullscreen(
  action: ActionType<typeof actions.requestFullScreen>
) {
  if (!screenfull) {
    return;
  }

  if (screenfull.isFullscreen) {
    yield call([screenfull, "exit"]);
  } else {
    yield call([screenfull, "request"]);
  }
}
