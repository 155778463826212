/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { ControlType, ControlErrorType, ControlValueType } from "./types";
import * as React from "react";
import ToolTip from "src/ipm-shared/components/Tooltip";
import T from "src/ipm-shared/Utils/Intl";

export * from "src/ipm-shared/store/model/actions";

/**
 * Set control properties (init control on the fly if control have not initialized yet)
 * @type {(control: Partial<ControlType>, form?: string) => PayloadMetaAction<string, {control: Partial<ControlType>; form: string}, undefined>}
 */
export const setControl = createAction(
  "form/SET_CONTROL",
  resolve => (control: Partial<ControlType>) => resolve({ control })
);

export const setSubmitButtonState = createAction(
  "form/SET_SUBMIT_BUTTON",
  resolve => (isSubmitting: boolean) => resolve({ isSubmitting })
);

export const setControlPattern = createAction(
  "form/SET_CONTROL_PATTERN",
  resolve => (
    control: Partial<{
      form: string;
      name: RegExp;
      value: ControlValueType;
      errors: ControlErrorType[];
      extraInfo?: any;
      ignoredErrors: string[];
      displayError?: boolean;
      forceRevalidate?: string;
    }> & {
      name: RegExp;
    }
  ) => resolve({ control })
);

export const ignoreError = createAction(
  "form/IGNORE_CONTROL_ERROR",
  resolve => (control: { name: string; errorCode: string }) =>
    resolve({ control })
);

export const removeIgnoredError = createAction(
  "form/REMOVE_IGNORED_CONTROL_ERROR",
  resolve => (control: { name: string; errorCode: string }) =>
    resolve({ control })
);

export const appendControlValue = createAction(
  "form/APPEND_CONTROL_VALUE",
  resolve => (
    name: string,
    value: string, // Only accept string value
    cb?: (values: string[]) => any
  ) => resolve({ name, value, cb })
);

export const spliceControlValue = createAction(
  "form/SPLICE_CONTROL_VALUE",
  resolve => (
    name: string,
    value: string, // Only accept string value
    cb?: (values: string[]) => any
  ) => resolve({ name, value, cb })
);

export const removeControl = createAction(
  "form/REMOVE_CONTROL",
  resolve => (name: string) => resolve(name)
);

export const removeControlPattern = createAction(
  "form/REMOVE_CONTROL_PATTERN",
  resolve => (name: RegExp) => resolve(name)
);

export const removeForm = createAction(
  "form/REMOVE_FORM",
  resolve => (form: string) => resolve(form)
);

export const removeGroup = createAction(
  "form/REMOVE_GROUP",
  resolve => (group: string) => resolve(group)
);

export const parseServerErrors = createAction(
  "form/PARSE_ERRORS",
  resolve => (
    errors: {
      fields: {
        [name: string]: string[];
      };
      form: string[];
    },
    form: string,
    fieldMap?: {
      [name: string]: string;
    }
  ) => {
    const controls: Array<{
      name: string;
      errors: ControlErrorType[];
    }> = [];

    const formErrors: ControlErrorType[] = [];

    if (errors.fields) {
      Object.keys(errors.fields).map(name => {
        const errList: ControlErrorType[] = [];
        errors.fields[name].map(code =>
          errList.push({
            code,
            fromServer: true,
            message: composeMessage(code)
          })
        );
        controls.push({
          errors: errList,
          name: fieldMap && fieldMap[name] ? fieldMap[name] : name
        });
      });
    }

    if (errors.form) {
      errors.form.map(code => {
        formErrors.push({
          code,
          fromServer: true,
          message: composeMessage(code)
        });
      });
    }

    return resolve({ controls, form, formErrors });
  }
);

export const setErrors = createAction(
  "form/SET_ERRORS",
  resolve => (errors: string[], name: string) => {
    const controlErr: ControlErrorType[] = [];
    for (const err of errors) {
      controlErr.push({
        code: err,
        fromServer: true,
        message: T.transl(err)
      });
    }
    return resolve({ errors: controlErr, name });
  }
);

export const resetErrors = createAction(
  "form/RESET_ERRORS",
  resolve => (form: string) => {
    return resolve({ form });
  }
);

export const resetControlErrors = createAction(
  "form/RESET_CONTROL_ERRORS",
  resolve => (name: string) => {
    return resolve({ name });
  }
);

export const resetFormErrors = createAction(
  "form/RESET_FORM_ERRORS",
  resolve => (form: string) => {
    return resolve({ form });
  }
);

export const displayControlErrors = createAction(
  "form/DISPLAY_CONTROL_ERRORS",
  resolve => (form: string) => {
    return resolve({ form });
  }
);

export const uploadFile = createAction(
  "form/FILE_UPLOAD",
  resolve => (args: {
    callbackSuccess?: (key: string, fileRef: string, extraInfo: any) => any;
    name: string;
    form?: string;
    value: File;
    purpose: string;
    showGlobalLoader?: boolean;
    fileName?: string;
    retry?: boolean; // For debugging
    storeFileDisabled?: boolean;
    isAdmin?: boolean;
    accountId?: number;
    isRequireAccountId?: boolean;
    isSettlementFile?: boolean;
  }) => resolve(args)
);

const extraMessage = {
  ADMIN_EDIT_PAYEE_ERROR: (
    <p>There was a problem with editing. Please try again.</p>
  ),
  PAYEE_ALREADY_EXISTS: (
    <ToolTip
      errorType={true}
      displayIcon={true}
      target={"tt_payee_already_exists"}
      className={"mb-2 general-tooltip"}
      label={T.transl("PAYEE_ALREADY_EXISTS")}
      description={
        "You can make multiple payments to the same supplier in the following step."
      }
    />
  )
};

const composeMessage = (
  code: string
): string | React.Component | JSX.Element => {
  let html;
  if (extraMessage[code]) {
    html = extraMessage[code];
  } else {
    html = T.transl(code);
  }
  return html;
};
