import * as React from "react";
import CreateInvoiceIcon from "src/ipm-shared/images/create-invoice-icon.svg";
import UploadInvoiceIcon from "src/ipm-shared/images/upload-invoice-icon.svg";
import SmsRequestIcon from "src/ipm-shared/images/sms-request-icon.svg";
import { IProps } from "./index";
import Slider, { CustomArrowProps } from "react-slick";
import ArrowLeftIcon from "src/ipm-shared/images/arr-left-icon.svg";
import ArrowRightIcon from "src/ipm-shared/images/arr-right-icon.svg";

interface IState {
  isHiddenIRASTooltip: boolean;
}

const NextArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props}>
    <img src={ArrowRightIcon} alt="Right icon" />
  </div>
);

const PrevArrow = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => (
  <div {...props}>
    <img src={ArrowLeftIcon} alt="Left icon" />
  </div>
);

class BePaidMakePayment extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isHiddenIRASTooltip: localStorage.getItem("iras-tooltip-closed") === "yes"
    };
  }

  public render() {
    const settings = {
      dots: false,
      infinite: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      swipeToSlide: true
    };

    return (
      <div className={"be-paid-make-payment-carousel"}>
        <Slider className={"desktop-slider d-none d-sm-block"} {...settings}>
          <div className="d-inline-flex justify-content-center">
            <div className="col-item">
              <div
                className="item"
                onClick={this.goTo.bind(this, "/sms-request")}
              >
                <div className="item-content">
                  <img src={SmsRequestIcon} alt={"SMS icon"} />
                </div>
                <span className={"btn btn-round btn-info"}>
                  Instant Request
                </span>
              </div>
            </div>
          </div>

          <div className="d-inline-flex justify-content-around">
            <div className="col-item">
              <div
                className="item"
                onClick={this.goTo.bind(this, "/invoice-creation")}
              >
                <div className="item-content">
                  <img src={CreateInvoiceIcon} alt={"Create Invoice Icon"} />
                </div>
                <span className={"btn btn-round btn-info"}>
                  Create Order Invoice
                </span>
              </div>
            </div>

            <div className="col-item">
              <div
                className="item"
                onClick={this.goTo.bind(
                  this,
                  "/upload-invoice/select-customer"
                )}
              >
                <div className="item-content">
                  <img src={UploadInvoiceIcon} alt={"Upload Invoice Icon"} />
                </div>
                <span className={"btn btn-round btn-info"}>
                  Upload Order Invoice
                </span>
              </div>
            </div>
          </div>
        </Slider>

        <Slider className="d-sm-none" {...settings}>
          <div className="col-item">
            <div
              className="item"
              onClick={this.goTo.bind(this, "/sms-request")}
            >
              <div className="item-content">
                <img src={SmsRequestIcon} alt={"SMS icon"} />
              </div>
              <span className={"btn btn-round btn-info"}>Instant Request</span>
            </div>
          </div>

          <div className="col-item">
            <div
              className="item"
              onClick={this.goTo.bind(this, "/invoice-creation")}
            >
              <div className="item-content">
                <img src={CreateInvoiceIcon} alt={"Create Invoice Icon"} />
              </div>
              <span className={"btn btn-round btn-info"}>
                Create Order Invoice
              </span>
            </div>
          </div>

          <div className="col-item">
            <div
              className="item"
              onClick={this.goTo.bind(this, "/upload-invoice/select-customer")}
            >
              <div className="item-content">
                <img src={UploadInvoiceIcon} alt={"Upload Invoice Icon"} />
              </div>
              <span className={"btn btn-round btn-info"}>
                Upload Order Invoice
              </span>
            </div>
          </div>
        </Slider>
      </div>
    );
  }

  private goTo = (path: string) => {
    window.location.href = path;
  };
}

export default BePaidMakePayment;
