import RestClient from "../services/Rest";
import * as selectors from "src/ipm-shared/store/model/Auth/selectors";

class Logger {
  public log() {
    console.log.apply(console, arguments);
  }

  public warn() {
    console.warn.apply(console, arguments);
  }

  public error() {
    console.error.apply(console, arguments);
    const args = Array.prototype.slice.call(arguments);

    if (selectors.hasToken()) {
      RestClient.send({
        body: {
          text: args.toString(),
          stack: new Error().stack
        },
        service: "notify_user_error",
        showGlobalLoader: false,
        timeout: -1
      });
    } else {
      console.warn("Cannot call window.Logger.error if user did not login");
    }
  }

  public guestError() {
    const args = Array.prototype.slice.call(arguments);

    RestClient.send({
      body: {
        text: args.toString(),
        stack: new Error().stack
      },
      service: "notify_general_error",
      showGlobalLoader: false
    });
  }

  public info() {
    console.info.apply(console, arguments);
  }
}

export default new Logger();
