import * as React from "react";
import BaseControl, { IBaseProps, IBaseState } from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType, ControlValueType } from "../types";
import * as classNames from "classnames";
import withPropsChecker from "./lib/withPropsChecker";
import T from "src/ipm-shared/Utils/Intl";
import utils from "src/ipm-shared/Utils/Number";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import _get from "lodash-es/get";

export type IInputTextProps = IBaseProps & {
  type?: string;
  hideValue?: boolean;
  maxLength?: number;
  minLength?: number;
  maxValue?: number;
  prefixZero?: boolean;
  isEditAble?: boolean;
  zeroPostfix?: boolean;

  /** This is to prevent from user typing wrong format */
  pattern?:
    | "__CARD__"
    | "__CARD_EXPIRY_DATE__"
    | "__CARD_CVV__"
    | "__NUMBER__"
    | "__MONEY__"
    | "__BANK_ACCOUNT_NUMBER__"
    | "__STATEMENT__"
    | "__SMS_NAME__"
    | "__ONLY_ALPHABETS__"
    | "__PERCENTAGE__"
    | "__RECIPIENT_NAME__"
    | "__GLOBAL_BANK_ACCOUNT_NUMBER__"
    | "__EMAIL__"
    | RegExp;
  divideBySpace?: number;
  autoUppercase?: boolean;
  autoComplete?: "off" | "on" | "nope" | "new-password"; // https://stackoverflow.com/questions/12374442/
  description?: string | JSX.Element;
  /**
   * Because browser has autoComplete feature, so defaultValue was set manually will come first and browser do override
   * that value by autoComplete value. This flag will tell component to wait for a certain time before set defaultValue
   */
  deboundBrowerAutocomplete?: boolean;
  staticText?: string | JSX.Element;
  positionStaticText?: "start" | "end";
  confirmButton?: string;
};

const mapStateToProps = (
  state: RootState,
  props: IInputTextProps
): {
  control: ReturnType<typeof selectors.getControl>;
  confirmButtonConfirmed?: boolean;
} => ({
  confirmButtonConfirmed: props.confirmButton
    ? selectors.getControl(state, props.confirmButton).confirmed
    : undefined,
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IInputTextProps;

type IState = IBaseState & {
  originValue?: string;
};

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl
 */
class InputText extends BaseControl<IProps, IState> {
  public static defaultProps = { ...BaseControl.defaultProps };
  private timer: number | undefined;

  public componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (
      nextProps.control.forceRevalidate !== this.props.control.forceRevalidate
    ) {
      this.doValidate(nextProps.control.value, nextProps.control.name, true);
    }
  }

  public componentDidMount() {
    let { defaultValue = "" } = this.props;
    const {
      name,
      form,
      deboundBrowerAutocomplete,
      displayError,
      control
    } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      if (control.errors.length === 0) {
        this.throwValue(control.value);
      }
      return;
    }

    if (defaultValue !== undefined && defaultValue !== null) {
      defaultValue = defaultValue.toString();
    }

    // Init control
    if (deboundBrowerAutocomplete) {
      setTimeout(() => {
        if (this.props.pattern === "__MONEY__" && this.props.zeroPostfix) {
          if (
            typeof defaultValue === "string" &&
            defaultValue.length > 0 &&
            defaultValue.slice(-1) !== "." &&
            defaultValue.indexOf(".") < 0
          ) {
            defaultValue = defaultValue + ".00";
          }
        }

        this.setControl(form, name, defaultValue, displayError);
      }, 500);
    } else {
      if (this.props.pattern === "__MONEY__" && this.props.zeroPostfix) {
        if (
          typeof defaultValue === "string" &&
          defaultValue.length > 0 &&
          defaultValue.slice(-1) !== "." &&
          defaultValue.indexOf(".") < 0
        ) {
          defaultValue = defaultValue + ".00";
        }
      }
      this.setControl(form, name, defaultValue, displayError);
    }
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      className,
      control,
      errorStyle,
      label,
      placeholder,
      labelOnly,
      displayInline,
      disabled,
      maxLength,
      staticText,
      positionStaticText
    } = this.props;

    if (control.notFound) {
      return null;
    }

    if (labelOnly) {
      return this.renderLabelOnly(control, this.props.id, errorStyle);
    }

    if (control.value) {
      control.value = this.prettier(control.value as string);
    }

    let readOnly: boolean;
    const editable = _get(control, "extraInfo.editable", undefined);
    if (editable) {
      readOnly = false;
    } else {
      readOnly = this.props.isEditAble || !!this.props.readOnly;
    }

    if (this.props.confirmButton) {
      readOnly = this.props.confirmButtonConfirmed === true;
    }

    const dataAttributes = {};
    for (const key in this.props.dataAttributes || {}) {
      if (
        this.props.dataAttributes &&
        this.props.dataAttributes.hasOwnProperty(key)
      ) {
        dataAttributes["data-" + key] = this.props.dataAttributes[key];
      }
    }

    return (
      <div
        className={classNames("form-group", {
          [className as string]: !!className,
          "d-inline-block": displayInline,
          "has-error": control.errors.length > 0 && control.displayError,
          "has-static-text": !!staticText,
          "input-enable-edit": !!this.props.isEditAble,
          "required-input": this.props.required
        })}
      >
        {label && <span className="label">{label}</span>}
        <div className={"d-flex align-items-center form-field"}>
          {this.renderStaticTextComponent(!control.value)}
          <input
            tabIndex={this.props.tabIndex}
            role="tab"
            ref={(ref: HTMLInputElement) => (this.ref = ref)}
            type={this.props.hideValue ? "password" : this.props.type || "text"}
            className={classNames("form-control", {
              "end-position": !!staticText && positionStaticText === "start",
              "is-invalid text-danger":
                control.errors.length > 0 && control.displayError,
              "start-position": !!staticText && positionStaticText === "end"
            })}
            placeholder={placeholder}
            onChange={this.onChange}
            value={control.value || ""}
            readOnly={readOnly}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete={this.props.autoComplete}
            onKeyPress={this.onKeyPress}
            id={this.props.id}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            {...dataAttributes}
          />
        </div>
        {this.props.description}
        {this.renderErrorMessage(control)}
      </div>
    );
  }

  private renderStaticTextComponent = (
    showAsPlaceholder?: boolean
  ): React.ReactElement<any> => {
    const { staticText, positionStaticText, pattern } = this.props;

    // only apply for HKD-USD currency
    if (pattern !== "__MONEY__" || !staticText) {
      return <></>;
    }

    return (
      <span
        className={classNames("static-text", {
          "as-placeholder": showAsPlaceholder as boolean,
          "end-position": positionStaticText === "end",
          "start-position": positionStaticText === "start"
        })}
      >
        {staticText}
      </span>
    );
  };

  private onKeyPress = (e: React.KeyboardEvent<any>): void => {
    if (e.key === "Enter") {
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }
  };

  private setControl = (
    form: string | undefined,
    name: string,
    value: string,
    displayError: boolean = true
  ): void => {
    this.props.setControl({
      displayError: true,
      errors: [],
      form,
      name,
      value
    });
    clearTimeout(this.timer);
    this.timer = window.setTimeout(
      this.doValidate.bind(this, value, name, displayError),
      500
    );
  };

  private onChange = (e: React.ChangeEvent<any>): void => {
    const { name, form, pattern } = this.props;
    let value = e.target.value;
    const regExp = this.getPattern();

    const condition01 = name === "email"; // by pass "email" field => will be checked on "*validate" yield function
    const condition02 = !regExp || regExp.test(value);
    const condition03 =
      !!this.props.control.value &&
      value.length > 1 &&
      this.props.control.value.toString().length > value.length;

    if (pattern === "__CARD_EXPIRY_DATE__") {
      value = value.replace("/", "");
      if (value.length >= 2) {
        value = value.slice(0, 2) + "/" + value.slice(2);
      }
    }
    if (condition01 || condition02 || condition03) {
      this.setControl(form, name, value);
    }

    // by pass "statement" and "sms_name" for server validation (cantonese character)
    if (pattern === "__STATEMENT__" || pattern === "__SMS_NAME__") {
      this.setControl(form, name, value);
    }
  };

  private onFocus = (): void => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }

    if (this.props.confirmButton) {
      this.props.setControl({
        confirmed: false,
        displayError: false,
        name: this.props.confirmButton
      });
    }
  };

  private onBlur = (): void => {
    const { form, name } = this.props.control;
    let { value } = this.props.control;
    if (this.props.pattern === "__MONEY__" && this.props.zeroPostfix) {
      if (
        typeof value === "string" &&
        value.length > 0 &&
        value.slice(-1) !== "." &&
        value.indexOf(".") < 0
      ) {
        value = value + ".00";
        this.props.setControl({
          form,
          name,
          value
        });
      }
    }

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true
  ): void => {
    const it = this.validate(value);
    let result: any;
    const errors: ControlErrorType[] = [];
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    const it2 = InputText.baseValidate(this.props, value);
    while (!(result = it2.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      this.throwValue(value);
    }
    this.props.setControl({
      displayError,
      errors,
      name
    });

    this.onAfterValidate(value);
  };

  private getPattern(): RegExp | undefined {
    const { pattern } = this.props;
    switch (pattern) {
      case "__NUMBER__":
        return /^[0-9]*$/;
      case "__CARD__":
        return /^[0-9 ]*$/;
      case "__CARD_EXPIRY_DATE__":
        return /^\d{1,2}\/?(\d{1,4})?$/;
      case "__CARD_CVC__":
        return /^\d{1,4}$/;
      case "__MONEY__":
        // I would like a solution where I can validate "after" user has finished typing.
        // e.g. "100." is incomplete and invalid, but I need to type this to get to "100.00"
        return /^([0-9]+\.?([0-9]{1,2})?)?$/;
      case "__BANK_ACCOUNT_NUMBER__":
        return /^[0-9-]*$/;
      case "__GLOBAL_BANK_ACCOUNT_NUMBER__":
        return /^[A-Za-z0-9]*$/;
      case "__STATEMENT__":
        return /^[A-Za-z0-9- ]*$/;
      case "__SMS_NAME__":
        return /^[A-Za-z0-9 ]*$/;
      case "__ONLY_ALPHABETS__":
        return /^[A-Za-z ]*$/;
      case "__PERCENTAGE__":
        return /^([0-9]{1,2}\.?([0-9]{1,2})?)?$/;
      case "__RECIPIENT_NAME__":
        return /^[a-zA-Z0-9()+\-\?'/:,. ]*$/;
      case "__EMAIL__":
        return /(^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))+@ipaymy.com$)|(^(([^+<>()\[\]\\.,;:\s@"]+(\.[^+<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

      default:
        return undefined;
    }
  }

  private *validate(value: ControlValueType): any {
    const { maxLength, minLength, pattern } = this.props;
    if (value) {
      if (maxLength) {
        if (value.toString().length > maxLength) {
          yield {
            code: "MAX_LENGTH",
            message: T.transl("ERROR_MAX_LENGTH", {
              max_length: maxLength.toString()
            })
          } as ControlErrorType;
        }
      }

      if (minLength) {
        if (value.toString().length < minLength) {
          if (pattern === "__STATEMENT__") {
            yield {
              code: "MIN_LENGTH",
              message: T.transl("ERROR_STATEMENT_MIN_LENGTH", {
                min_length: minLength.toString()
              })
            } as ControlErrorType;
          } else {
            yield {
              code: "MIN_LENGTH",
              message: T.transl("ERROR_MIN_LENGTH", {
                min_length: minLength.toString()
              })
            } as ControlErrorType;
          }
        }
      }

      switch (pattern) {
        case "__MONEY__": {
          if (!utils.isValidAmount(value.toString())) {
            yield {
              code: "INVALID_AMOUNT",
              message: T.transl("INVALID_AMOUNT")
            };
          }

          try {
            utils.amountStringToInt(value.toString());
          } catch (e) {
            yield {
              code: "INVALID_AMOUNT_CONVERSION",
              message: T.transl("INVALID_AMOUNT_CONVERSION")
            };
          }

          if (this.props.maxValue) {
            if (parseFloat(value as string) * 100 > this.props.maxValue) {
              yield {
                code: "INVALID_MAXIMUM_VALUE",
                message: T.transl("INVALID_MAXIMUM_VALUE", {
                  value: (
                    <b>
                      <ShortCurrency value={this.props.maxValue} />
                    </b>
                  )
                })
              };
            }
          }
          break;
        }

        case "__PERCENTAGE__": {
          if (!utils.isValidAmount(value.toString())) {
            yield {
              code: "INVALID_RATE_NUMBER",
              message: T.transl("INVALID_RATE_NUMBER")
            };
          }
          if (this.props.maxValue) {
            if (parseFloat(value as string) > this.props.maxValue) {
              yield {
                code: "INVALID_MAXIMUM_VALUE",
                message: T.transl("INVALID_MAXIMUM_VALUE", {
                  value: <b>{this.props.maxValue}%</b>
                })
              };
            }
          }
          if (value > 100) {
            yield {
              code: "INVALID_RATE_NUMBER",
              message: T.transl("INVALID_RATE_NUMBER")
            };
          }
          break;
        }

        case "__EMAIL__": {
          const emailPattern: RegExp | undefined = this.getPattern();
          if (
            emailPattern &&
            typeof emailPattern === "object" &&
            !emailPattern.test(value as string)
          ) {
            yield {
              code: "EMAIL_ERROR",
              message: T.transl("EMAIL_ERROR")
            };
          }
          break;
        }

        default:
          return pattern;
      }
    }

    return;
  }

  private prettier(value: string) {
    const { divideBySpace } = this.props;
    if (divideBySpace) {
      if (value.length > 0) {
        const formatted: string[] = [];
        let charCount = 0;
        for (const char of value.split("")) {
          if (char === " ") {
            continue;
          }
          if (charCount % divideBySpace === 0 && charCount > 0) {
            formatted.push(" ");
          }
          formatted.push(char);
          charCount++;
        }

        value = formatted.join("");
        if (value.endsWith(" ")) {
          value = value.substr(0, value.length - 2);
        }
      }
    }

    return value;
  }

  private throwValue = (value: ControlValueType): void => {
    if (this.props.onChangeCustom && this.alive) {
      if (this.props.pattern === "__MONEY__") {
        try {
          this.props.onChangeCustom((value as string).replace(/,/g, ""));
        } catch (e) {
          window.Logger.guestError("throwValue error ", e);
          this.props.onChangeCustom(value);
        }
      } else {
        this.props.onChangeCustom(value);
      }
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(InputText)
);
