export const RequestResetPassword = () =>
  import(
    "src/ipm-platform/pages/ResetPassword/RequestResetPassword"
    /* webpackChunkName: "Page_RequestResetPassword" */
  );
export const SetNewPassword = () =>
  import(
    "src/ipm-platform/pages/ResetPassword/SetNewPassword"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_SetNewPassword" */
  );
export const Instructions = () =>
  import(
    "src/ipm-platform/pages/ResetPassword/Instruction"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Instruction" */
  );
export const PostRegisterPage = () =>
  import(
    "src/ipm-platform/pages/PostRegister"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PostRegister" */
  );
export const RegisterPage = () =>
  import(
    "src/ipm-platform/pages/Register"
    /* webpackChunkName: "Page_Register" */
  );
export const ChangePasswordPage = () =>
  import(
    "src/ipm-platform/pages/ChangePassword"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_ChangePassword" */
  );
export const MobileVerification = () =>
  import(
    "src/ipm-platform/pages/MobileVerification"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_MobileVerification" */
  );

export const CompaniesPage = () =>
  import(
    "src/ipm-platform/pages/Companies"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Companies" */
  );
export const PropertiesPage = () =>
  import(
    "src/ipm-platform/pages/Properties"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Properties" */
  );
export const InvoicesPage = () =>
  import(
    "src/ipm-platform/pages/Invoices"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Invoices" */
  );
export const EmployeesPage = () =>
  import(
    "src/ipm-platform/pages/Employees"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Employees" */
  );
export const MyWalletPage = () =>
  import(
    "src/ipm-platform/pages/MyWallet"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_MyWallet" */
  );
export const MyPayments = () =>
  import(
    "src/ipm-platform/pages/MyPayments"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_MyPayments" */
  );
export const MySchedules = () =>
  import(
    "src/ipm-platform/pages/MySchedules"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_MySchedules" */
  );
export { default as DashboardPage } from "src/ipm-platform/pages/Dashboard/index";

export const CheckoutPage = () =>
  import(
    "src/ipm-platform/pages/Checkout"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Checkout" */
  );
export const NotFound = () =>
  import(
    "src/ipm-platform/pages/NotFound"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_NotFound" */
  );

export const AdminUser = () =>
  import(
    "src/ipm-platform/pages/AdminUsers"
    /* webpackChunkName: "Page_AdminUsers" */
  );
// export const AdminCoupon = () => import("src/ipm-platform/pages/AdminCoupons");
export const AdminAccountRates = () =>
  import(
    "src/ipm-platform/pages/AdminAccountRates"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminAccountRates" */
  );
export const AdminPayment = () =>
  import(
    "src/ipm-platform/pages/AdminPayment" /* webpackChunkName: "Page_AdminPayment" */
  );
export const AdminOperations = () =>
  import(
    "src/ipm-platform/pages/AdminOperations"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminOperations" */
  );
export const AdminPromoCode = () =>
  import(
    "src/ipm-platform/pages/AdminPromoCode"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminPromoCode" */
  );
export const AdminCheckPayment = () =>
  import(
    "src/ipm-platform/pages/AdminPaymentCheck"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminPaymentCheck" */
  );
export const AdminCollectedAccount = () =>
  import(
    "src/ipm-platform/pages/AdminCollectedAccount"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminCollectedAccount" */
  );
export const AdminInternational = () =>
  import(
    "src/ipm-platform/pages/AdminInternational"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminInternational" */
  );

export const WizardPayeeAdd = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeAdd"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WizardPayeeAdd" */
  );
export const WizardSelect = () =>
  import(
    "src/ipm-platform/pages/WizardPayee"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WizardPayee" */
  );
export const WizardSchedule = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeSchedule"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WizardPayeeSchedule" */
  );
export const WizardSummary = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeSummary"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WizardPayeeSummary" */
  );
export const WizardVerification = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeVerification"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WizardPayeeVerification" */
  );

export const PaymentCollections = () =>
  import(
    "src/ipm-platform/pages/PaymentCollections"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PaymentCollections" */
  );
export const Customers = () =>
  import(
    "src/ipm-platform/pages/Customers"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Customers" */
  );
export const CustomerAdd = () =>
  import(
    "src/ipm-platform/pages/Customers/AddCustomer"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AddCustomer" */
  );
export const CollectedAccountSettings = () =>
  import(
    "src/ipm-platform/pages/CollectedAccount/Settings"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Settings" */
  );
export const CreateCollectedAccount = () =>
  import(
    "src/ipm-platform/pages/CollectedAccount/Create"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_Create" */
  );
export const CustomerInvoiceDetails = () =>
  import(
    "src/ipm-platform/pages/CustomerInvoiceDetails"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_CustomerInvoiceDetails" */
  );
export const PaymentCollectionCheckout = () =>
  import(
    "src/ipm-platform/pages/PaymentCollectionCheckout"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PaymentCollectionCheckout" */
  );
export const PaymentCollectionConfirm = () =>
  import(
    "src/ipm-platform/pages/PaymentCollectionCheckout/PaymentConfirm"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PaymentConfirm" */
  );
export const PaymentCollectionMakeAlready = () =>
  import(
    "src/ipm-platform/pages/PaymentCollectionCheckout/PaymentMakeAlready"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PaymentMakeAlready" */
  );
export const PaymentCancelled = () =>
  import(
    "src/ipm-platform/pages/PaymentCollectionCheckout/PaymentCancelled"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_PaymentCancelled" */
  );

export const OnBoarding = () =>
  import(
    "src/ipm-platform/pages/OnBoarding"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_OnBoarding" */
  );

export const OnBoardingStep1 = () =>
  import(
    "src/ipm-platform/pages/OnBoarding/Business/Step1"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_OnBoardingStep1" */
  );

export const OnBoardingStep2 = () =>
  import(
    "src/ipm-platform/pages/OnBoarding/Business/Step2"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_OnBoardingStep2" */
  );

export const OnBoardingStep3 = () =>
  import(
    "src/ipm-platform/pages/OnBoarding/Business/Step3"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_OnBoardingStep3" */
  );

export const BePaidCreateInvoice = () =>
  import(
    "src/ipm-platform/pages/BePaid/InvoiceCreation/CreateInvoice"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_BePaidCreateInvoice" */
  );

export const BePaidPreviewInvoice = () =>
  import(
    "src/ipm-platform/pages/BePaid/InvoiceCreation/PreviewInvoice"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_BePaidPreviewInvoice" */
  );

export const BePaidInvoiceCreation = () =>
  import(
    "src/ipm-platform/pages/BePaid/InvoiceCreation"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_BePaidInvoiceCreation" */
  );

export const BePaidUploadInvoice = () =>
  import(
    "src/ipm-platform/pages/BePaid/UploadInvoice"
    /* webpackPrefetch: true */
    /* webpackChunkName: "BePaidUploadInvoice" */
  );

export const BePaidSmsRequest = () =>
  import(
    "src/ipm-platform/pages/BePaid/Sms"
    /* webpackPrefetch: true */
    /* webpackChunkName: "BePaidSms" */
  );

export const ReferralProgram = () =>
  import(
    "src/ipm-platform/pages/ReferralProgram"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_ReferralProgram" */
  );

export const AdminUEN = () =>
  import(
    "src/ipm-platform/pages/AdminUEN"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_AdminUEN" */
  );

export const WorldpayTest = () =>
  import(
    "src/ipm-platform/pages/WorldpayTest"
    /* webpackPrefetch: true */
    /* webpackChunkName: "Page_WorldpayTest" */
  );

export const AdminReconciliations = () =>
  import(
    "src/ipm-platform/pages/AdminReconciliations" /* webpackChunkName: "Page_AdminReconciliations" */
  );

export const AdminScreening = () =>
  import(
    "src/ipm-platform/pages/AdminScreening" /* webpackChunkName: "Page_AAdminScreening" */
  );
export const AdminConfirmPayout = () =>
  import(
    "src/ipm-platform/pages/AdminConfirmPayout" /* webpackChunkName: "Page_AdminConfirmPayout" */
  );
export const AdminEscalation = () =>
  import(
    "src/ipm-platform/pages/AdminEscalation" /* webpackChunkName: "Page_AdminEscalation" */
  );
export { default as LoginPage } from "src/ipm-platform/pages/Login";
export const AdminReferral = () =>
  import(
    "src/ipm-platform/pages/AdminReferral" /* webpackChunkName: "Page_AdminReferral" */
  );
