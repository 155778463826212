import * as React from "react";

export const IconIRASTooltip = () => (
  <span
    className="icon-iras-tooltip"
    dangerouslySetInnerHTML={{
      __html: `<svg width="45" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 42.3 50" style="enable-background:new 0 0 42.3 50;" xml:space="preserve">
<style type="text/css">
\t.icon-iras-tooltip .st0{enable-background:new;}
\t.icon-iras-tooltip .st1{fill:#9AA0A5;}
</style>
<g>
\t<g id="Dashboard">
\t\t<g class="st0">
\t\t\t<path class="st1" d="M10.4,14.2H8.3V8.1H6.4V6.4h5.9v1.7h-1.9V14.2z"/>
\t\t</g>
\t\t<g class="st0">
\t\t\t<path class="st1" d="M18,14.2l-0.4-1.5h-2.5l-0.4,1.5h-2.3l2.6-7.9h2.8l2.6,7.9H18z M17.2,11l-0.3-1.3c-0.1-0.3-0.2-0.7-0.3-1.1
\t\t\t\tc-0.1-0.5-0.2-0.8-0.2-1c0,0.2-0.1,0.5-0.2,0.9C16,9,15.8,9.8,15.5,11H17.2z"/>
\t\t</g>
\t\t<g class="st0">
\t\t\t<path class="st1" d="M28.6,14.2h-2.4l-1.5-2.4l-1.5,2.4h-2.4l2.6-4l-2.4-3.8h2.3l1.4,2.4L26,6.4h2.4l-2.5,4L28.6,14.2z"/>
\t\t</g>
\t\t<g>
\t\t\t<path class="st1" d="M2,45.1c-0.5,0-1-0.2-1.3-0.5C0.2,44.2,0,43.7,0,43.2L0,2c0-0.5,0.2-1,0.5-1.4S1.4,0,1.9,0h30.9
\t\t\t\tc0.8,0,1.3,0.2,1.7,0.5c0.2,0.2,0.3,0.5,0.3,0.8l0,30.5c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8V1.5c-0.1,0-0.2,0-0.5,0H2
\t\t\t\tc-0.2,0-0.3,0.1-0.4,0.1C1.5,1.7,1.5,1.9,1.5,2v41.1c0,0.1,0.1,0.3,0.1,0.3s0.2,0.1,0.3,0.1h22.2c0.4,0,0.8,0.3,0.8,0.8
\t\t\t\ts-0.3,0.8-0.8,0.8L2,45.1C2,45.1,2,45.1,2,45.1z M33.3,1.3C33.3,1.3,33.3,1.3,33.3,1.3L33.3,1.3z"/>
\t\t</g>
\t\t<g>
\t\t\t<path class="st1" d="M34.2,45.9l-0.9-0.9c0,0-0.1,0-0.1-0.1l-3.4-3.5c-0.5-0.5-0.5-1.3,0-1.8l0.1-0.1c0.5-0.5,1.3-0.5,1.8,0
\t\t\t\tc0,0,0,0,0,0l2.4,2.5l5.9-6c-3.2-3.2-8.4-3.3-11.6-0.1c-3.2,3.2-3.3,8.4-0.1,11.6s8.4,3.3,11.6,0.1c2.5-2.4,3.1-6.2,1.7-9.3
\t\t\t\tL34.2,45.9z"/>
\t\t</g>
\t\t<g>
\t\t\t<rect x="5" y="18.6" class="st1" width="24.8" height="2.7"/>
\t\t</g>
\t\t<g>
\t\t\t<rect x="5" y="24.1" class="st1" width="24.8" height="2.7"/>
\t\t</g>
\t\t<g>
\t\t\t<rect x="5" y="29.7" class="st1" width="24.8" height="2.7"/>
\t\t</g>
\t\t<g>
\t\t\t<path class="st1" d="M26.2,36.8c0.4-0.5,0.8-1,1.3-1.4L5,35.3V38l20.6,0.1C25.7,37.6,26,37.2,26.2,36.8z"/>
\t\t</g>
\t</g>
</g>
</svg>`
    }}
  />
);
