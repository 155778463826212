import _get from "lodash-es/get";
import * as React from "react";
import { IProps } from "./index";
import { format } from "date-fns";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import FileUtils from "src/ipm-shared/Utils/Files";
import * as classNames from "classnames";
import { FEE_PAYER } from "src/ipm-shared/store/model/CollectedAccount/const";
import CircleRefundIcon from "src/ipm-shared/images/circle-refund-icon.svg";
import ToolTip from "src/ipm-shared/components/Tooltip";
import { isSameDay } from "date-fns";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import T from "src/ipm-shared/Utils/Intl";

class PaymentTable extends React.Component<IProps> {
  public render() {
    const { nullText, getCurrenciesById } = this.props;
    const type = this.props.isBusiness ? "business" : "personal";
    const isFnbTheme = this.props.dashboardThemeName === "fnb";
    return (
      <div className="table-responsive">
        {this.props.payments.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th />
                <th style={{ minWidth: 115, width: 115, paddingLeft: 0 }}>
                  {isFnbTheme
                    ? T.transl("TABLE_ORDER_NUMBER")
                    : T.transl("TABLE_INVOICE_NUMBER")}
                </th>
                <th style={{ minWidth: 130, width: 130 }}>
                  {T.transl("TABLE_CUSTOMER")}
                </th>
                <th style={{ minWidth: 120, width: 120 }}>
                  {T.transl("PAYMENT_CONFIRMATION_RECEIPT")}
                </th>
                <th style={{ width: 140 }} className={"text-right "}>
                  {T.transl("AMOUNT_LABEL")}
                </th>
                <th
                  style={{ width: 85, minWidth: 85 }}
                  className={"text-right"}
                >
                  {T.transl("LABEL_PAYMENT_DETAIL_FEE")}
                </th>
                <th
                  style={{ width: 165, minWidth: 165 }}
                  className={"text-right pr-5"}
                >
                  {T.transl("NET_AMOUNT_LABEL")}
                </th>

                <th style={{ width: 120, minWidth: 120 }}>
                  {this.props.status !== "received" ? "Date Paid" : ""}
                </th>
                <th>{T.transl("TABLE_PAYOUT_DATE")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.payments.map(payment => {
                let fee = _get(payment, "feeStructure.requesterFee", 0);
                if (payment.feePayer === FEE_PAYER.COMPANY_PAY) {
                  fee = payment.fee;
                } else if (payment.feePayer === FEE_PAYER.CUSTOMER_PAY) {
                  fee = 0;
                }

                let invoiceAmount = payment.grossAmount;
                if (payment.feePayer === FEE_PAYER.CUSTOMER_PAY) {
                  invoiceAmount = payment.net;
                }

                const currencyId = payment.currencyId;
                const currencySymbol = getCurrenciesById[currencyId].symbol;

                const newDate = new Date().toISOString();
                const isDisplayRefundAction = isSameDay(
                  newDate,
                  _get(payment, "datePaid", "")
                );

                return (
                  <tr key={payment.id}>
                    <td
                      style={{ minWidth: "89px", width: "89px" }}
                      className="text-center"
                    >
                      {this.props.status !== "received" ? (
                        isDisplayRefundAction ? (
                          <>
                            <span
                              onClick={this.handleRefund.bind(
                                this,
                                payment.id,
                                payment.grossAmount,
                                payment.feeStructure.customerFee
                              )}
                              className={"cursor-pointer"}
                            >
                              <ToolTip
                                className="dashboard-icon-tooltip general-tooltip"
                                description={"Refund"}
                                target={`icon-refund-${payment.id}`}
                              />
                              <img
                                id={`icon-refund-${payment.id}`}
                                width={22}
                                src={CircleRefundIcon}
                                alt="Bell icon"
                              />
                            </span>
                          </>
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="pl-0">
                      <span
                        onClick={this.openDocumentFile.bind(
                          this,
                          _get(payment, "supportingDocument[0].url", "")
                        )}
                        className={classNames("text-link", {
                          "have-document":
                            _get(payment, "supportingDocument[0].url", "") !==
                            ""
                        })}
                      >
                        {payment.invoiceNo}
                      </span>
                    </td>

                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {payment.customer.name}
                    </td>
                    <td>{payment.receiptNumber || "-"}</td>
                    <td className={`text-right ${type}-color `}>
                      {currencySymbol}
                      <ShortCurrency value={invoiceAmount} />
                    </td>
                    {fee > 0 ? (
                      <td className={`text-right ${type}-color`}>
                        ({currencySymbol}
                        <ShortCurrency value={fee} />)
                      </td>
                    ) : (
                      <td className={`text-right ${type}-color `}>
                        {currencySymbol}
                        <ShortCurrency value={fee} />
                      </td>
                    )}
                    <td className={`text-right ${type}-color pr-5`}>
                      {currencySymbol}
                      <ShortCurrency value={payment.net} />
                    </td>
                    <td>
                      {this.props.status !== "received"
                        ? format(
                            new Date(_get(payment, "datePaid", "")),
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </td>
                    <td>
                      {format(new Date(payment.payoutDate), "DD/MM/YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          nullText
        )}
      </div>
    );
  }

  private handleRefund = (
    id: number,
    grossAmount: number,
    customerFee: number
  ) => {
    this.props.toggleModal(ModalID.BE_PAID_REFUND, {
      customerFee,
      grossAmount,
      id
    });
  };

  private openDocumentFile = (url: string) => {
    if (url !== "") {
      FileUtils.getFileUrl(url);
    } else {
      return;
    }
  };
}

export default PaymentTable;
