import * as React from "react";

export const IconClose = () => (
  <div
    className="icon-close"
    dangerouslySetInnerHTML={{
      __html: `<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.01 17.87"><defs><style>.cls-1{fill:none;stroke:#88919b;stroke-miterlimit:10;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Popup"><line class="cls-1" x1="0.35" y1="0.35" x2="17.65" y2="17.52"/><line class="cls-1" x1="0.35" y1="17.52" x2="17.65" y2="0.35"/></g></g></svg>`
    }}
  />
);
