import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "./selectors";
import * as actions from "./actions";

const mapStateToProps = (state: RootState) => ({
  dashboardTheme: selectors.getDashboardTheme(state)
});

const mapDispatchToProps = {
  closeModal: actions.closeModal.bind(
    actions,
    actions.ModalID.BE_PAID_MAKE_PAYMENT
  ),
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
