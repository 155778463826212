import * as React from "react";
import ImageUtil from "src/ipm-shared/Utils/Images";

type IProps = {
  bankId: number;
  nullText?: JSX.Element;
};

const CardBankIcon: React.SFC<IProps> = props => {
  const cardBankIcon = ImageUtil.getCardBankIcon(props.bankId);
  return <img className="card-brand" src={cardBankIcon} alt="Card Bank" />;
};

export default CardBankIcon;
