/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { CustomerFee, PaymentCollectionRequestType } from "./reducers";
import { CardInfo } from "../Card/types";

export * from "src/ipm-shared/store/model/actions";
export {
  setControl,
  parseServerErrors
} from "src/ipm-shared/components/Form/actions";

export const fetchPaymentCollectionRequest = createAction(
  "paymentCollectionRequest/FETCH",
  resolve => (token: string) => resolve({ token })
);

export const setPaymentCollectionRequest = createAction(
  "paymentCollectionRequest/SET_REQUEST",
  resolve => (args: {
    isFetching: boolean;
    payment: PaymentCollectionRequestType;
    acquirerId: number;
  }) => resolve(args)
);

export const fetchPaymentCollectionFees = createAction(
  "paymentCollectionRequest/GET_FEES",
  resolve => (cardId: number) => resolve({ cardId })
);

export const setPaymentCollectionFees = createAction(
  "paymentCollectionRequest/SET_FEES",
  resolve => (args: { customerFee: CustomerFee[] }) => resolve(args)
);

export const chargePaymentCollection = createAction(
  "paymentCollectionRequest/CHARGE",
  resolve => (args: { cardInfo?: CardInfo; cardholderName?: string }) =>
    resolve(args)
);

export const setCardCode = createAction(
  "paymentCollectionRequest/SET_CARD_CODE",
  resolve => (cardCode: string) => resolve({ cardCode })
);

export const setCardInfo = createAction(
  "paymentCollectionRequest/SET_CARD_COUNTRY_CODE",
  resolve => (info: CardInfo) => resolve({ info })
);

export const resendPaymentRequest = createAction(
  "paymentCollectionRequest/RESEND",
  resolve => (id: number, cb: () => void) => resolve({ id, cb })
);

export const remindPaymentRequest = createAction(
  "paymentCollectionRequest/REMIND",
  resolve => (id: number, cb: () => void) => resolve({ id, cb })
);

export const cancelPaymentRequest = createAction(
  "paymentCollections/CANCEL_PAYMENT_REQUEST",
  resolve => (paymentId: number) => resolve({ paymentId })
);

export const sendPaymentRequest = createAction(
  "paymentCollections/SEND_PAYMENT_REQUEST",
  resolve => (isValidate?: boolean) => resolve({ isValidate })
);

// REQUEST BY INVOICE CREATION
export const submitCollectionInvoice = createAction(
  "paymentCollections/SUBMIT_INVOICE_VALIDATE",
  resolve => (isValidate: boolean = false, cb?: () => void) =>
    resolve({ isValidate, cb })
);

// SMS REQUEST
export const submitSmsRequest = createAction(
  "paymentCollections/SUBMIT_SMS_REQUEST"
);

export const getEstimateRate = createAction(
  "paymentCollection/GET_ESTIMATE_FEE",
  resolve => (token: string) => resolve({ token })
);

export const createPublicStripeCardIntent = createAction(
  "cards/PUBLIC_STRIPE_CARD_INTENT_CREATE",
  resolve => (paymentToken: string, cb: (clientSecret: string) => void) =>
    resolve({ cb, paymentToken })
);
