/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { PaymentCollectionsState, PaymentCollectionsType } from "./reducers";

export const fetchPaymentCollections = createAction(
  "paymentCollections/FETCH",
  resolve => (offset: number = 0) => resolve({ offset })
);

export const setPaymentCollections = createAction(
  "paymentCollections/SET",
  resolve => (args: {
    isFetching: boolean;
    payments?: PaymentCollectionsType[];
    activity?: PaymentCollectionsState["activity"];
    total?: number;
  }) => resolve(args)
);

export const bePaidSetSumPaymentInThisMonth = createAction(
  "payment/BE_PAID_SET_AMOUNT_THIS_MONTH",
  resolve => (
    sumPaymentInThisMonth: PaymentCollectionsState["sumPaymentInThisMonth"]
  ) => resolve({ sumPaymentInThisMonth })
);

export const appendPaymentCollections = createAction(
  "paymentCollections/APPEND",
  resolve => (args: {
    isFetching: boolean;
    payments: PaymentCollectionsType[];
  }) => resolve(args)
);

export const fetchPaymentCollectionsActivityStatus = createAction(
  "paymentCollections/FETCH_ACTIVITY_STATUS",
  resolve => () => resolve()
);

export const markPaymentAsPaid = createAction(
  "paymentCollections/MARK_PAYMENT_AS_PAID",
  resolve => (id: number) => resolve({ id })
);

export const refundCollectionPayment = createAction(
  "paymentCollections/REFUND_COLLECTION_PAYMENT",
  resolve => (paymentId: number) => resolve({ paymentId })
);

export const fetchPaymentCollectionsHistoryList = createAction(
  "paymentCollections/FETCH_PAYMENT_COLLECTIONS_HISTORY_LIST",
  resolve => (offset: number = 0) => resolve({ offset })
);

export const fetchPaymentCollectionsHistoryExport = createAction(
  "paymentCollections/FETCH_PAYMENT_COLLECTIONS_HISTORY_EXPORT"
);

export const fetchDashboardCollectedPayments = createAction(
  "collected_payments/FETCH_DASHBOARD",
  resolve => () => resolve()
);
