import { ActionType, getType } from "typesafe-actions";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga";
import * as cardActions from "./actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as commonActions from "../actions";
import * as accProfileActions from "src/ipm-shared/store/model/AccountProfile/actions";
import * as cardSelectors from "./selectors";
import * as accProfileSelectors from "../AccountProfile/selectors";
import * as commonSelectors from "../selectors";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as paymentRequestSelectors from "src/ipm-shared/store/model/PaymentRequest/selectors";
import * as accountSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import { RootState } from "../reducers";
import _get from "lodash-es/get";
import _maxBy from "lodash-es/maxBy";
import _isEmpty from "lodash-es/isEmpty";
import { ADD_CARD_FORM } from "./const";
import RestClient from "src/ipm-shared/services/Rest";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { format, parse } from "date-fns";

const actions = {
  ...cardActions,
  ...commonActions,
  ...accProfileActions
};

const selectors = {
  ...accProfileSelectors,
  ...cardSelectors,
  ...commonSelectors,
  ...formSelectors,
  ...paymentRequestSelectors,
  ...accountSelectors
};
const watchedSagas = [
  reTryTakeLatest(actions.fetchCards, handleFetchCards),
  // takeLatest(getType(actions.submitAdyenAddCardForm), handleAddAdyenCard),
  // takeLatest(getType(actions.submitAdyenEditCardForm), handleEditAdyenCard),
  // takeLatest(getType(actions.encryptCard), handleAdyenEncryptCardDetails),
  takeLatest(getType(actions.submitAddCardForm), handleAddCard),
  takeLatest(
    getType(actions.createUserStripeCardIntent),
    handleUserCreateStripeCardIntent
  ),
  takeLatest(getType(actions.deleteCard), handleDeleteCard)
];
export default watchedSagas;

// export function* handleAdyenAddAdyenCard(
//   action: ActionType<typeof actions.submitAdyenAddCardForm>
// ) {
//   yield put(actions.showGlobalLoader());
//   const state: RootState = yield select();
//   const formState = selectors.getControls(state, ADD_FORM);
//   const currentCurrency = selectors.getCurrentCurrency(state);
//
//   let year = _get(formState, "year.value", "").toString();
//   if (year.length === 2) {
//     year = "20" + year;
//   }
//
//   const adyenToken = yield handleEncryptAdyenCardDetails(
//     actions.adyenEncryptCard({
//       cvc: _get(formState, "cvc.value", 737).toString(),
//       expiryMonth: _get(formState, "month.value", "").toString(),
//       expiryYear: year,
//       holderName: _get(formState, "name.value", "").toString(),
//       number: _get(formState, "number.value", "").toString()
//     })
//   );
//
//   if (_get(adyenToken, "length", 0) === 0) {
//     yield put(actions.hideGlobalLoader());
//     yield put(
//       formActions.parseServerErrors(
//         { fields: {}, form: ["ERROR_INVALID_CARD"] },
//         ADD_FORM
//       )
//     );
//     return;
//   }
//
//   const saveCard = _get(formState, "save_card_checkbox.value", "") === "saved";
//   const paymentToken = _get(formState, "payment_token.value", "");
//   const res = yield call(RestClient.send, {
//     body: {
//       currency_code: currentCurrency,
//       payment_token: saveCard ? undefined : paymentToken,
//       token: adyenToken
//     },
//     service: "add_card",
//     timeout: 20000
//   });
//
//   yield put(actions.hideGlobalLoader());
//
//   if (!res) {
//     return;
//   }
//
//   if (!_isEmpty(res.errors)) {
//     yield put(formActions.parseServerErrors(res.errors, ADD_FORM));
//     return;
//   }
//
//   try {
//     yield put(actions.fetchCards(paymentToken, "last"));
//     yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));
//     return;
//   } catch (e) {
//     window.Logger.error("handleAddAdyenCard: ", e.message);
//     return;
//   }
// }

// export function* handleAdyenEditAdyenCard(
//   action: ActionType<typeof actions.submitAdyenEditCardForm>
// ) {
//   yield put(actions.showGlobalLoader());
//   const state: RootState = yield select();
//   const formState = selectors.getControls(state, ADD_FORM);
//
//   let year = _get(formState, "year.value", "").toString();
//   if (year.length === 2) {
//     year = "20" + year;
//   }
//
//   const adyenToken = yield handleAdyenEncryptCardDetails(
//     actions.AdyenEncryptCard({
//       cvc: _get(formState, "cvc.value", "").toString(),
//       expiryMonth: _get(formState, "month.value", "").toString(),
//       expiryYear: year
//     })
//   );
//
//   if (_get(adyenToken, "length", 0) === 0) {
//     yield put(actions.hideGlobalLoader());
//     yield put(
//       formActions.parseServerErrors(
//         { fields: {}, form: ["ERROR_INVALID_CARD"] },
//         ADD_FORM
//       )
//     );
//     return;
//   }
//
//   const res = yield call(RestClient.send, {
//     body: {
//       token: adyenToken
//     },
//     params: {
//       id: _get(formState, "card_id.value", "").toString()
//     },
//     service: "edit_card"
//   });
//
//   yield put(actions.hideGlobalLoader());
//
//   if (!res) {
//     return;
//   }
//
//   if (_get(res.errors, "form.0", "")) {
//     yield put(formActions.parseServerErrors(res.errors, ADD_FORM));
//     return;
//   }
//
//   try {
//     yield put(actions.fetchCards());
//     yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));
//     // yield put(
//     //   actions.toast(getIntl().formatMessage({ id: "SUCCESS_CARD_EDITED" }))
//     // );
//     return;
//   } catch (e) {
//     window.Logger.error("handleEditAdyenCard: ", e.message);
//     return;
//   }
// }

// const options = {};
// export function* handleAdyenEncryptCardDetails(
//   action: ActionType<typeof actions.adyenEncryptCard>
// ) {
//   const acquirerId = selectors.getAcquirerId();
//   const publicKey = CardUtils.getAdyenPublicKey(acquirerId);
//
//   const cseInstance = yield adyen.createEncryption(publicKey, options);
//   let token = "";
//   try {
//     token = yield cseInstance.encrypt({
//       ...action.payload.card,
//       generationtime: new Date().toISOString()
//     });
//   } catch (e) {
//     // Maybe get "Malformed public key"
//     window.Logger.log(e);
//   }
//
//   if (token) {
//     yield put(actions.adyenStoreToken(token));
//     return token;
//   } else {
//     yield put(actions.adyenStoreToken(""));
//   }
// }

export function* handleFetchCards(
  action: ActionType<typeof actions.fetchCards>
) {
  const { paymentToken, defaultSelectedCard } = action.payload;

  const res: {
    data: any;
  } = yield call(RestClient.send, {
    query: {
      payment_token: paymentToken
    },
    service: "get_cards",
    nocache: action.payload.nocache
  });

  if (!res) {
    yield put(
      actions.setCards({
        cards: [],
        isFetching: true,
        selectedId: 0
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    let selectedCardId;
    const state: RootState = yield select();
    const cards = res.data
      .filter((card: any) => card.is_wallet_card === true)
      .filter(
        (card: any) => card.acquirer_id === selectors.getAcquirerId(state)
      );
    const lastUsedCard = _maxBy(cards, card => {
      const lastUsedAt = _get(card, "last_used_at");
      if (lastUsedAt) {
        const times = format(parse(lastUsedAt), "X"); // return to "Seconds timestamp" format
        return Number(times);
      }
      return null;
    });
    switch (defaultSelectedCard) {
      case "last":
        selectedCardId = _get(cards[cards.length - 1], "id", 0);
        break;
      case 0:
        // selectedCardId = _get(cards[0], "id", 0);
        selectedCardId = _get(lastUsedCard || cards[0], "id", 0); // last card used (if existed) OR first card by default
        break;
      default:
        selectedCardId = defaultSelectedCard;
    }
    yield delay(0);
    yield put(
      actions.setCards({
        cards: res.data.map((card: any) => ({
          acquirerId: card.acquirer_id,
          bankId: 1,
          brandId: card.card_brand,
          expiryMonth: card.expiry_month,
          expiryYear: card.expiry_year,
          id: card.id,
          isWallet: card.is_wallet_card,
          last4: card.last_4,
          name: card.name
        })),
        isFetching: false,
        selectedId: selectedCardId
      })
    );

    return;
  } catch (e) {
    window.Logger.error("handleFetchCards: ", e.message);
    return;
  }
}

export function* handleDeleteCard(
  action: ActionType<typeof actions.deleteCard>
) {
  yield put(actions.showGlobalLoader());

  const res: {} = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    service: "delete_card"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }
  yield put(actions.fetchCards());
}

export function* handleAddCard(
  action: ActionType<typeof actions.submitAddCardForm>
) {
  const state: RootState = yield select();
  const token = selectors.getToken(state);
  if (!token) {
    return;
  }

  const formState = selectors.getControls(state, ADD_CARD_FORM);

  yield put(actions.showGlobalLoader());

  const saveCard = _get(formState, "save_card_checkbox.value", "") === "saved";

  const paymentToken = _get(formState, "payment_token.value", "");
  const acquirerId = _get(
    formState,
    "acquirer_id.value",
    selectors.getAcquirerId(state)
  );
  const res: { data: any } = yield call(RestClient.send, {
    body: {
      cardholder_name: action.payload.cardholderName,
      payment_token: saveCard ? undefined : paymentToken,
      redirect_url: window.location.href,
      token: action.payload.cardInfo.cardToken,
      token_type: action.payload.cardInfo.cardTokenType,
      card_info: {
        card_bin: action.payload.cardInfo.cardBin,
        card_brand: action.payload.cardInfo.cardBrand,
        card_expiry_month: action.payload.cardInfo.cardExpiryMonth,
        card_expiry_year: action.payload.cardInfo.cardExpiryYear,
        card_issuer_country: action.payload.cardInfo.cardIssuerCountry,
        card_last4: action.payload.cardInfo.cardLast4,
        card_token: action.payload.cardInfo.cardToken,
        card_token_type: action.payload.cardInfo.cardTokenType,
        card_type: action.payload.cardInfo.cardType
      },
      acquirer_id: Number(acquirerId)
    },
    service: "add_card",
    timeout: 20000
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_CARD_FORM));
    return;
  }

  if (action.payload.cb && res.data.code) {
    action.payload.cb(res.data.id);
  }

  yield put(actions.fetchCards(paymentToken, res.data.id, undefined, true));
  yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));
}

export function* handleUserCreateStripeCardIntent(
  action: ActionType<typeof actions.createUserStripeCardIntent>
) {
  const state: RootState = yield select();
  const acquirerId = selectors.getAcquirerId(state);

  const res: { data: any } = yield call(RestClient.send, {
    query: {
      acquirer_id: acquirerId
    },
    service: "generate_user_stripe_card_intent",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    console.error(errors);
    return;
  }

  if (action.payload.cb) {
    action.payload.cb(res.data.client_secret);
  }
}
