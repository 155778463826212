import { ActionType, getType } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
const watchedSagas = [
  takeLatest(getType(actions.fetchPurposes), handleFetchPurposes)
];
export default watchedSagas;
export function* handleFetchPurposes(
  action: ActionType<typeof actions.fetchPurposes>
) {
  yield put(actions.setPurposes({ isFetching: true, purposes: [] }));

  yield put(
    actions.setPurposes({
      isFetching: false,
      purposes: [
        {
          id: 1,
          name: "Rent"
        },
        {
          id: 2,
          name: "Salary"
        },
        {
          id: 3,
          name: "Invoice"
        }
      ]
    })
  );
}
