import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "../../selectors";

const mapStateToProps = (state: RootState) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  isBusiness: selectors.getIsBusinessAccount(state),
  isPersonal: selectors.getIsPersonalAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state)
});

const mapDispatchToProps = {};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
