import * as React from "react";
import { IProps } from "./index";
import CountryPicker from "src/ipm-shared/components/CountryPicker";
import * as classNames from "classnames";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import {
  APAC_COUNTRIES,
  EUROPE_COUNTRIES,
  NORTH_AMERICA_COUNTRIES,
  UNITED_KINGDOM
} from "src/ipm-shared/store/model/OnBoarding/consts";
import { history } from "src/ipm-shared/store";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

import T from "src/ipm-shared/Utils/Intl";

class CountrySelector extends React.Component<IProps, any> {
  public render() {
    const type = this.props.isBusinessAccount ? "business" : "personal";
    const { isCrossBorder } = this.props;

    const listCountries = APAC_COUNTRIES.concat(
      ["SG"],
      EUROPE_COUNTRIES,
      NORTH_AMERICA_COUNTRIES,
      UNITED_KINGDOM,
      ["TH"]
    );

    return (
      <div
        className={classNames(`headline color-white ${type}-bg-color`, {
          "is-cross-border": isCrossBorder
        })}
      >
        <FeatureGate
          feature={"WALLEX_FLOW"}
          fallback={
            <FeatureGate
              feature={"CROSS_BORDER_PAYMENT"}
              fallback={<b>{T.transl("PAY_LABEL")}</b>}
            >
              {this.props.isBusinessAccount ? (
                <span>
                  {T.transl("PAY_IN_LABEL", {
                    b: (t: string) => <b>{t}</b>
                  })}
                </span>
              ) : (
                <span>
                  {T.transl("PAY_IN_NOW_LABEL", {
                    b: (t: string) => <b>{t}</b>
                  })}
                </span>
              )}
              <div className={"position-relative"}>
                <CountryPicker
                  allowCountries={["SG", "MY", "HK"]}
                  name={"cross_border"}
                  className={"cross-border-dropdown"}
                  isHiddenMobileCountryNumber={true}
                  isDisplayCurrencyCode={true}
                  onChangeCustom={this.changeCountryLegacy}
                  defaultValue={this.props.currentCountry}
                />
              </div>
            </FeatureGate>
          }
        >
          <span>
            <b>{T.transl("PAY_LABEL")}:</b>{" "}
          </span>

          <div className={"position-relative"}>
            <CountryPicker
              allowCountries={listCountries}
              name={"cross_border"}
              className={"cross-border-dropdown"}
              isHiddenMobileCountryNumber={true}
              onChangeCustom={this.handleInternationalPayment}
              defaultValue={this.props.currentCountry}
            />
          </div>
        </FeatureGate>
      </div>
    );
  }

  private handleInternationalPayment = (value: string) => {
    const { wallexKycStatus } = this.props;
    const countryCode = value.toUpperCase();

    // Seems to bypass. It should be useless now
    this.props.setControl({
      name: "cross_border",
      value: this.props.currentCountry
    });

    if (countryCode === "SG") {
      this.props.setCurrentCountry({
        code: countryCode,
        id: this.props.countries.filter(c => c.code === countryCode)[0].id
      });
      if (this.props.isDisplayCrossBorderNewIcon) {
        this.props.changeUserSetting({
          enable: false,
          property: "cross_border_new_icon"
        });
      }
    } else {
      if (wallexKycStatus === 0) {
        history.push("/onboarding/kyc");
      } else {
        // Case Pending, Reject, Document Need Resubmission
        if (
          wallexKycStatus <= WALLEX_KYC_STATUS.PENDING ||
          wallexKycStatus === WALLEX_KYC_STATUS.REJECTED ||
          wallexKycStatus >= WALLEX_KYC_STATUS.DOCUMENTS_NEED_RESUBMISSION
        ) {
          this.props.toggleModal(ModalID.KYC_STATUS_MODAL);
        } else {
          this.props.setCurrentCountry({
            code: countryCode,
            id: this.props.countries.filter(c => c.code === countryCode)[0].id
          });
          if (this.props.isDisplayCrossBorderNewIcon) {
            this.props.changeUserSetting({
              enable: false,
              property: "cross_border_new_icon"
            });
          }
        }
      }
    }
  };

  private changeCountryLegacy = (value: string) => {
    this.props.exchangeToken({
      countryCode: value.toUpperCase(),
      purpose: "switch_country_legacy"
    });
    if (this.props.isDisplayCrossBorderNewIcon) {
      this.props.changeUserSetting({
        enable: false,
        property: "cross_border_new_icon"
      });
    }
  };
}

export default CountrySelector;
