import * as React from "react";
import * as classNames from "classnames";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";
import { NavLink } from "react-router-dom";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";

class RecommendedSection extends React.Component<any> {
  public render() {
    const type = this.props.isBusiness ? "business" : "personal";
    const { isCrossBorder } = this.props;
    return (
      <div className="recommended-section section-block">
        <h2
          className={classNames(`headline ${type}-bg-color color-white`, {
            "is-cross-border": isCrossBorder
          })}
        >
          {T.transl("PAY_RECOMMENDED_ACTIONS_SECTION")}
        </h2>
        <div className="aside-content">
          <ul className="items d-flex flex-wrap">
            <li className="item">
              <div className="content">
                <div className="information">
                  <h5 className="item-title">
                    <a
                      className="cursor-pointer"
                      href="javascript:void(0)"
                      onClick={this.addCardModal}
                    >
                      {T.transl("LABEL_ADD_CARD_TO_WALLET")}
                    </a>
                  </h5>
                  <div className="item-description">
                    {T.transl("LABEL_RECOMMENT_ADD_CARD_DESC")}
                  </div>
                </div>
                <a
                  onClick={this.addCardModal}
                  className="btn btn-sm btn-learnmore cursor-pointer"
                />
              </div>
            </li>
            <li className="item">
              <div className="content">
                <div className="information">
                  <h5 className="item-title">
                    <NavLink to="/payments">
                      {T.transl("LABEL_RECOMMENT_VIEW_PAYMENT_TITLE")}
                    </NavLink>
                  </h5>
                  <div className="item-description">
                    {T.transl("LABEL_RECOMMENT_VIEW_PAYMENT_DESC")}
                  </div>
                </div>
                <NavLink to="/payments" className="btn btn-sm btn-learnmore" />
              </div>
            </li>
            <li className="item">
              <div className="content">
                <div className="informantion">
                  <h5 className="item-title">
                    <NavLink to="/wizard/select/rent?action=add">
                      {T.transl("LABEL_MAKE_PAYMENT_RECURRYING")}
                    </NavLink>
                  </h5>
                  <div className="item-description">
                    {T.transl("LABEL_RECOMMENT_SCHEDULE_PAYMENT_DESC")}{" "}
                  </div>
                </div>
                <NavLink
                  to="/wizard/select/rent?action=add"
                  className="btn btn-sm btn-learnmore"
                />
              </div>
            </li>
            <li className="item">
              <div className="content">
                <div className="information">
                  <h5 className="item-title">
                    <a
                      rel="noopener"
                      target="_blank"
                      href="https://help.ipaymy.com/"
                    >
                      {T.transl("PAY_NEED_HELP_LABEL")}
                    </a>
                  </h5>
                  <div className="item-description">
                    {T.transl("LABEL_RECOMMENT_SUPPORT_DESC")}
                  </div>
                </div>
                <a
                  className="btn btn-sm btn-learnmore"
                  rel="noopener"
                  target="_blank"
                  href="https://help.ipaymy.com/"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  private addCardModal = () => {
    const { accountProfile, toggleModal } = this.props;
    // If malaysian user must have NRIC photos ID
    if (
      (accountProfile.countryCode.toUpperCase() === "MY" ||
        accountProfile.countryCode.toUpperCase() === "AU") &&
      accountProfile.userSettingRequired.nricPhoto
    ) {
      toggleModal(ModalID.NRIC_ID_PHOTOS_UPLOAD);
    } else {
      toggleModal(ModalID.ADD_CARD_FORM);
    }
  };
}

export default RecommendedSection;
