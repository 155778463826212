import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

const mapStateToProps = (state: RootState) => ({
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  activityStatus: selectors.getPaymentCollectionActivity(state),
  collectedAccountCurrencyId: selectors.getCurrentBePaidCurrencyId(state),
  dashboardTheme: selectors.getDashboardTheme(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  getToken: selectors.getToken(state),
  hasCollectedAccount: selectors.hasCollectedAccount(state),
  paymentCollections: selectors.getPaymentCollections(state),
  paymentInProgress: selectors.getPaymentInProgress(state),
  paymentReceived: selectors.getPaymentReceived(state),
  paymentRequestFailed: selectors.getPaymentRequestFailed(state),
  paymentRequested: selectors.getPaymentRequested(state)
});

const mapDispatchToProps = {
  editCustomerId: actions.editCustomerId,
  fetchDashboardCollectedPayments: actions.fetchDashboardCollectedPayments,
  toggleMakePaymentModal: actions.toggleModal.bind(
    actions,
    actions.ModalID.BE_PAID_MAKE_PAYMENT
  ),
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
