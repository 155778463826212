import _get from "lodash-es/get";
import * as React from "react";
import { IProps } from "./index";
import { format } from "date-fns";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import FileUtils from "src/ipm-shared/Utils/Files";
import * as classNames from "classnames";
import {
  EMAIL_STATUS,
  SMS_STATUS
} from "src/ipm-shared/store/model/PaymentCollections/const";
import { FEE_PAYER } from "src/ipm-shared/store/model/CollectedAccount/const";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as copy from "copy-to-clipboard";
import CircleLinkIcon from "src/ipm-shared/images/circle-link-icon.svg";
import CircleBlueCheckedIcon from "src/ipm-shared/images/circle-blue-checked-icon.svg";
import CircleYellowCheckedIcon from "src/ipm-shared/images/circle-yellow-checked-icon.svg";
import CircleBellIcon from "src/ipm-shared/images/circle-bell-icon.svg";
import CircleXIcon from "src/ipm-shared/images/circle-x-icon.svg";
import CircleCheckedIcon from "src/ipm-shared/images/circle-checked-icon.svg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import T from "src/ipm-shared/Utils/Intl";

interface IState {
  actionToggle: {
    [id: number]: boolean;
  };
  isCopiedLink: {
    [id: number]: boolean;
  };
  isRemindCustomer: boolean;
  isResentRequest: boolean;
  isSmsInvoice: boolean;
  sendRemind: {
    [id: number]: boolean;
  };
}

class PaymentTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      actionToggle: {},
      isCopiedLink: {},
      isRemindCustomer: false,
      isResentRequest: false,
      isSmsInvoice: false,
      sendRemind: {}
    };
  }

  public render() {
    const { nullText, getCurrenciesById } = this.props;

    const type = this.props.isBusiness ? "business" : "personal";
    const isFnbTheme = this.props.dashboardThemeName === "fnb";
    return (
      <>
        <div
          className={classNames("bar-notify-successfully", {
            "is-active": this.state.isResentRequest
          })}
        >
          {this.state.isSmsInvoice
            ? T.transl("ACTION_RESEND_MOBILE_SUCCESS")
            : T.transl("ACTION_RESEND_EMAIL_SUCCESS")}
        </div>
        <div
          className={classNames("bar-notify-successfully", {
            "is-active": this.state.isRemindCustomer
          })}
        >
          {T.transl("ACTION_RESEND_SUCCESS")}
        </div>
        <div className="table-responsive">
          {this.props.payments.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th />
                  <th style={{ minWidth: 115, width: 115, paddingLeft: 0 }}>
                    {isFnbTheme
                      ? T.transl("TABLE_ORDER_NUMBER")
                      : T.transl("TABLE_INVOICE_NUMBER")}
                  </th>
                  <th style={{ minWidth: 130, width: 130 }}>
                    {T.transl("TABLE_CUSTOMER")}
                  </th>
                  <th
                    style={{ width: 115, minWidth: 115 }}
                    className={"text-right"}
                  >
                    {T.transl("AMOUNT_LABEL")}
                  </th>
                  <th
                    style={{ width: 140, minWidth: 140 }}
                    className={"text-right"}
                  >
                    {T.transl("TABLE_ESTIMATED_FEE")}
                  </th>
                  <th
                    style={{ width: 250, minWidth: 250 }}
                    className={"text-right pr-5"}
                  >
                    {T.transl("TABLE_ESTIMATED_NET_AMOUNT")}
                  </th>
                  <th style={{ width: 120, minWidth: 120 }}>
                    {T.transl("TABLE_DATE_REQUEST")}
                  </th>
                  <th>{T.transl("TABLE_DUE_DATE")}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.payments.map(payment => {
                  const { collectionRates } = this.props;

                  let fee = 0;

                  if (this.props.hasActiveProgram) {
                    fee = 0;
                  } else {
                    if (payment.feePayer === FEE_PAYER.COMPANY_PAY) {
                      fee = Math.round(
                        (payment.grossAmount *
                          collectionRates.filter(r => r.brand_id === 2)[0]
                            .national) /
                          100 /
                          100
                      );
                    } else if (payment.feePayer === FEE_PAYER.SHARED_PAY) {
                      let requesterNationalRate = payment.requesterRates.filter(
                        r => r.brand_id === 2
                      )[0].national;

                      if (
                        collectionRates.filter(r => r.brand_id === 2)[0]
                          .national < requesterNationalRate
                      ) {
                        requesterNationalRate = collectionRates.filter(
                          r => r.brand_id === 2
                        )[0].national;
                      }
                      fee = Math.round(
                        (payment.grossAmount * requesterNationalRate) /
                          100 /
                          100
                      );
                    }
                  }

                  const currencyId = payment.currencyId;
                  const currencySymbol = getCurrenciesById[currencyId].symbol;

                  const isResend =
                    payment.emailStatusId === EMAIL_STATUS.SUCCESS ||
                    payment.emailStatusId === EMAIL_STATUS.SENDING ||
                    payment.smsStatusId === SMS_STATUS.SUCCESS ||
                    payment.smsStatusId === SMS_STATUS.SENDING;

                  return (
                    <tr
                      key={payment.id}
                      className={classNames({
                        "request-failed":
                          payment.emailStatusId === EMAIL_STATUS.ERROR ||
                          payment.smsStatusId === SMS_STATUS.ERROR
                      })}
                    >
                      <td style={{ width: "89px", minWidth: "89px" }}>
                        <Dropdown
                          className="actions-dropdown"
                          isOpen={this.state.actionToggle[payment.id]}
                          toggle={this.actionToggle.bind(this, payment.id)}
                        >
                          <DropdownToggle tag="span" caret={true}>
                            {T.transl("TABLE_ACTION")}
                          </DropdownToggle>
                          <DropdownMenu tag="ul">
                            <DropdownItem tag="li">
                              <span
                                onClick={this.copy.bind(
                                  this,
                                  payment.id,
                                  payment.payUrl
                                )}
                                className={"cursor-pointer"}
                              >
                                <img
                                  id={`icon-link-${payment.id}`}
                                  width={22}
                                  src={
                                    this.state.isCopiedLink[payment.id]
                                      ? CircleBlueCheckedIcon
                                      : CircleLinkIcon
                                  }
                                  alt="Copied Link icon"
                                />
                                {this.state.isCopiedLink[payment.id]
                                  ? T.transl("ACTION_COPY_LINK_CONFIRMED")
                                  : T.transl("ACTION_COPY_LINK")}
                              </span>
                            </DropdownItem>

                            {(payment.emailStatusId === EMAIL_STATUS.ERROR ||
                              payment.smsStatusId === SMS_STATUS.ERROR) && (
                              <DropdownItem tag="li">
                                <span
                                  onClick={this.openResendModal.bind(this, {
                                    customerEmail: payment.customer.email,
                                    id: payment.id,
                                    isSmsInvoice: payment.isSmsInvoice,
                                    mobileCountryId:
                                      payment.customer.mobileCountryId,
                                    mobileNumber: payment.customer.mobileNumber
                                  })}
                                  className={"cursor-pointer"}
                                >
                                  <img
                                    id={`icon-bell-${payment.id}`}
                                    width={22}
                                    src={CircleBellIcon}
                                    alt="Bell icon"
                                  />
                                  {T.transl("ACTION_RESEND_REQUEST")}
                                </span>
                              </DropdownItem>
                            )}
                            {isResend && (
                              <DropdownItem tag="li">
                                <span
                                  onClick={this.handleRemind.bind(
                                    this,
                                    payment.id
                                  )}
                                  className={"cursor-pointer"}
                                >
                                  <img
                                    id={`icon-remind-bell-${payment.id}`}
                                    width={22}
                                    src={
                                      this.state.sendRemind[payment.id]
                                        ? CircleYellowCheckedIcon
                                        : CircleBellIcon
                                    }
                                    alt="Bell icon"
                                  />
                                  {this.state.sendRemind[payment.id]
                                    ? T.transl("ACTION_RESEND_SENT")
                                    : T.transl("ACTION_RESEND_REMIND")}
                                </span>
                              </DropdownItem>
                            )}
                            <DropdownItem tag="li">
                              <span
                                onClick={this.handleMarkAsPaid.bind(
                                  this,
                                  payment.id,
                                  payment.invoiceNo
                                )}
                              >
                                <img
                                  width={22}
                                  src={CircleCheckedIcon}
                                  alt="Circle Checked Icon"
                                />
                                {T.transl("ACTION_MARK_PAID")}
                              </span>
                            </DropdownItem>
                            <DropdownItem tag="li">
                              <span
                                onClick={this.handleCancelRequest.bind(
                                  this,
                                  payment.id
                                )}
                                className={"cursor-pointer"}
                              >
                                <img
                                  id={`icon-cancel-${payment.id}`}
                                  width={22}
                                  src={CircleXIcon}
                                  alt="Cancel icon"
                                />
                                {T.transl("ACTION_CANCEL")}
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                      <td className="pl-0">
                        <span
                          onClick={this.openDocumentFile.bind(
                            this,
                            _get(payment, "supportingDocument[0].url", "")
                          )}
                          className={classNames("text-link", {
                            "have-document":
                              _get(payment, "supportingDocument[0].url", "") !==
                              ""
                          })}
                        >
                          {payment.invoiceNo}
                        </span>
                      </td>

                      <td
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        {payment.customer.name}
                      </td>
                      <td className={`text-right ${type}-color`}>
                        {currencySymbol}
                        <ShortCurrency value={payment.grossAmount} />
                      </td>
                      {fee > 0 ? (
                        <td className={classNames(`text-right ${type}-color`)}>
                          ({currencySymbol}
                          {<ShortCurrency value={fee} />})
                        </td>
                      ) : (
                        <td className={classNames(`text-right ${type}-color`)}>
                          {currencySymbol}
                          {<ShortCurrency value={fee} />}
                        </td>
                      )}

                      <td className={`text-right ${type}-color pr-5`}>
                        {currencySymbol}
                        <ShortCurrency value={payment.grossAmount - fee} />
                      </td>
                      <td>
                        {format(new Date(payment.createdAt), "DD/MM/YYYY")}
                      </td>
                      <td>
                        {payment.isSmsInvoice
                          ? "-"
                          : format(
                              new Date(_get(payment, "dueDate", "")),
                              "DD/MM/YYYY"
                            )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            nullText
          )}
        </div>
      </>
    );
  }

  private openResendModal = (args: {
    id: number;
    customerEmail: string;
    isSmsInvoice: boolean;
    mobileNumber: string;
    mobileCountryId: number;
  }) => {
    this.props.editCustomerId(args.id);
    this.props.toggleModal(ModalID.RESEND_PAYMENT_REQUEST, {
      callback: () => {
        this.setState({
          isResentRequest: true,
          isSmsInvoice: args.isSmsInvoice
        });
        setTimeout(() => {
          this.setState({ isResentRequest: false });
        }, 5000);
      },
      customerEmail: args.customerEmail,
      isSmsInvoice: args.isSmsInvoice,
      mobileCountryId: args.mobileCountryId,
      mobileNumber: args.mobileNumber,
      paymentId: args.id
    });
  };

  private handleRemind = (id: number) => {
    this.props.remind(id, () => {
      this.setState(preState => ({
        isRemindCustomer: true,
        sendRemind: {
          ...preState.sendRemind,
          [id]: true
        }
      }));
      setTimeout(() => {
        this.setState(preState => ({
          isRemindCustomer: false,
          sendRemind: {
            ...preState.sendRemind,
            [id]: false
          }
        }));
      }, 5000);
    });
  };

  private openDocumentFile = (url: string) => {
    if (url !== "") {
      FileUtils.getFileUrl(url);
    } else {
      return;
    }
  };

  private handleCancelRequest = (paymentId: number) => {
    this.props.toggleModal(ModalID.CONFIRMATION_DELETE, {
      callback: () => {
        this.props.cancelPaymentRequest(paymentId);
      },
      confirmBtnLabel: T.transl("ACTION_CANCEL_YES"),
      conformBtnCancel: T.transl("ACTION_CANCEL_NO"),
      description: T.transl("ACTION_CANCEL_INSTRUCTIONS"),
      title: T.transl("ACTION_CANCEL_HERO")
    });
  };

  private copy = (id: number, url: string) => {
    copy(url);
    this.setState({
      isCopiedLink: {
        [id]: true
      }
    });
    setTimeout(() => {
      this.setState({
        isCopiedLink: {
          [id]: false
        }
      });
    }, 10000);
  };

  private handleMarkAsPaid = (id: number, orderNumber: string) => {
    this.props.toggleModal(ModalID.BE_PAID_MARK_PAYMENT_AS_PAID, {
      id,
      orderNumber
    });
  };

  private actionToggle = (id: number) => {
    this.setState(prevState => ({
      ...prevState.actionToggle,
      actionToggle: {
        [id]: !this.state.actionToggle[id]
      }
    }));
  };
}

export default PaymentTable;
