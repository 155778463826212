/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { ReferralType, ReferralDetailType } from "./reducer";

export const searchReferralList = createAction(
  "adminReferral/Search",
  resolve => (offset?: number, pageCount?: number) =>
    resolve({ offset, pageCount })
);

export const setReferralList = createAction(
  "adminReferral/setReferralList",
  resolve => (args: {
    isFetching: boolean;
    referralList: ReferralType[];
    total: number;
  }) => resolve(args)
);

export const getReferralDetail = createAction(
  "adminReferral/getReferralDetail",
  resolve => (referralId: number) => resolve({ referralId })
);

export const setReferralDetail = createAction(
  "adminReferral/setReferralDetail",
  resolve => (args: {
    isFetchingReferralDetail: boolean;
    referralDetail: ReferralDetailType[];
  }) => resolve(args)
);
