import { ActionType, getType } from "typesafe-actions";
import { select, call, put, takeLatest } from "redux-saga/effects";
import * as escalationActions from "./actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as commonActions from "../actions";
import * as commonSelectors from "../selectors";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import { RootState } from "../reducers";
import _get from "lodash-es/get";
import _maxBy from "lodash-es/maxBy";
import _isEmpty from "lodash-es/isEmpty";
import { ADMIN_ESCALATION_FORM, PAGE_COUNT, SEARCH_FORM } from "./const";
import RestClient from "src/ipm-shared/services/Rest";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";

const actions = {
  ...escalationActions,
  ...commonActions
};

const selectors = {
  ...commonSelectors,
  ...formSelectors
};
const watchedSagas = [
  reTryTakeLatest(actions.fetchEscalations, handleFetchEscalations),
  takeLatest(getType(actions.submitAddEscalation), handleAddEscalation),
  takeLatest(getType(actions.updateEscalation), handleUpdateEscalation),
  reTryTakeLatest(
    actions.fetchAdminEscalationComments,
    handleFetchAdminEscalationComments
  )
];
export default watchedSagas;

export function* handleFetchEscalations(
  action: ActionType<typeof actions.fetchEscalations>
) {
  yield put(
    actions.setEscalations({
      isFetching: true,
      escalations: []
    })
  );

  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;

  query.offset = action.payload.offset;
  if (query.search_email) {
    query.email = query.search_email.toLowerCase();
  }
  query.page_count = PAGE_COUNT;

  query.date = query.search_date;
  query.country_id = query.search_country_id;
  query.statuses = query.search_statuses;
  query.type_id = query.search_type_id;
  delete query.search_date;
  delete query.search_country_id;
  delete query.search_statuses;
  delete query.search_type_id;
  delete query.search_email;

  const res: Response = yield call(RestClient.send, {
    query,
    service: "admin_get_escalations"
  });

  if (!res) {
    yield put(actions.setEscalations({ isFetching: false }));
    return;
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    const total: number = _get(json, "total", 0);

    yield put(
      actions.setEscalations({
        isFetching: false,
        total,
        escalations: data.map(escalation => ({
          id: escalation.id,
          userId: escalation.user_id,
          createdBy: escalation.created_by,
          countryId: escalation.country_id,
          email: escalation.email,
          natureId: escalation.nature_id,
          date: escalation.date,
          typeId: escalation.type_id,
          statusId: escalation.status_id,
          numberOfHitsOnTheParty: escalation.number_of_hits_on_the_party,
          nameAndAliasOfParty: escalation.name_and_alias_of_party,
          categoryTransaction: escalation.category_transaction,
          receiptNumber: escalation.receipt_number,
          categoryOnboarding: escalation.category_onboarding,
          reason: escalation.reason,
          description: escalation.description,
          chargeDate: escalation.scheduled_charge_date,
          payoutDate: escalation.payout_date,
          paymentTotal: escalation.payment_total,
          paymentId: escalation.payment_id,
          comments: (escalation.comments ?? []).map((comment: any) => ({
            id: comment.id,
            escalationId: comment.escalation_id,
            comments: comment.comments,
            commentedBy: comment.comment_by,
            createdAt: comment.created_at,
            updatedAT: comment.updated_at
          }))
        }))
      })
    );

    const seenComments: { [key: string]: number }[] = JSON.parse(
      localStorage.getItem("seen_escalation_comment") || "[]"
    );

    for (const escalation of data) {
      let newCommentsCount = 0;

      const escalationId = escalation.id;

      const existingComment = seenComments.find(item => item[escalationId]);
      const allCommentsCount = (escalation.comments ?? []).length;

      if (existingComment) {
        const currentCount = existingComment[escalationId];
        if (allCommentsCount > currentCount) {
          newCommentsCount = allCommentsCount - currentCount;
        }
      } else {
        newCommentsCount = allCommentsCount;
      }

      yield put(
        actions.setNewEscalationCommentsCount({
          newEscalationCommentsCount: {
            [escalationId]: newCommentsCount
          }
        })
      );
    }
  } catch (e) {
    window.Logger.error("handleFetchUsers: ", e.message);
  }
  return;
}

export function* handleAddEscalation(
  action: ActionType<typeof actions.submitAddEscalation>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADMIN_ESCALATION_FORM);
  const comments = _get(formState, "comments.value", "");
  const email = _get(formState, "email.value", "");
  const userId = _get(formState, "user_id.value", "");
  const countryId = _get(formState, "country_id.value");
  const statusId = _get(formState, "status_id.value", 3);
  const date = _get(formState, "date.value", "");
  const natureId = _get(formState, "nature_of_party_involved.value", 0);
  const numberOfHitsOnTheParty = _get(
    formState,
    "number_of_hits_on_the_party.value",
    ""
  );
  const nameAndAliasOfParty = _get(
    formState,
    "name_and_alias_of_party_involved.value",
    ""
  );
  const categoryTransaction = _get(
    formState,
    "category_of_transaction_escalation.value",
    ""
  );
  const categoryOnboarding = _get(
    formState,
    "category_of_onboarding_escalation.value",
    ""
  );
  const receiptNumber = _get(formState, "receipt_number.value", "");
  const reason = _get(formState, "reason_of_escalation.value", "");
  const description = _get(formState, "description.value", "");
  const paymentId = _get(formState, "payment_id.value", undefined);

  yield put(actions.showGlobalLoader());

  const res: { data: any } = yield call(RestClient.send, {
    body: {
      comments: comments,
      email: email,
      user_id: +userId,
      country_id: +countryId,
      status_id: +statusId,
      type_id: action.payload.typeId,
      nature_id: +natureId,
      date: date ? new Date(date) : undefined,
      number_of_hits_on_the_party: numberOfHitsOnTheParty,
      name_and_alias_of_party: nameAndAliasOfParty,
      category_transaction: categoryTransaction,
      receipt_number: receiptNumber,
      category_onboarding: categoryOnboarding,
      reason: reason,
      description: description,
      payment_id: paymentId ? +paymentId : undefined
    },
    service: "admin_add_escalation",
    timeout: 20000
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADMIN_ESCALATION_FORM));
    return;
  }

  if (action.payload.cb) {
    action.payload.cb();
  }

  yield put(actions.closeModal(actions.ModalID.ADMIN_ESCALATION_MODAL));
  return;
}

export function* handleUpdateEscalation(
  action: ActionType<typeof actions.updateEscalation>
) {
  yield put(actions.showGlobalLoader());
  const state = yield select();
  const formState = formSelectors.getControls(state, ADMIN_ESCALATION_FORM);

  const comment = _get(formState, "escalation_detail_comment.value", "");

  const res: { data: any } = yield call(RestClient.send, {
    params: {
      id: action.payload.escalationId
    },
    body: {
      status_id: action.payload.statusId,
      comments: comment
    },
    service: "admin_update_escalation",
    timeout: 20000
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADMIN_ESCALATION_FORM));
    return;
  }

  if (action.payload.escalationId && comment) {
    yield put(
      actions.fetchAdminEscalationComments(action.payload.escalationId)
    );
  } else {
    yield put(
      actions.setEscalationDetail({
        escalationId: action.payload.escalationId,
        statusId: action.payload.statusId
      })
    );
  }
  return;
}

export function* handleFetchAdminEscalationComments(
  action: ActionType<typeof actions.fetchAdminEscalationComments>
) {
  const escalationId = action.payload.id;

  if (escalationId < 0) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    query: {
      escalation_id: escalationId
    },
    service: "admin_get_escalation_comments",
    showGlobalLoader: false
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any = _get(res, "data");

  if (data) {
    let seenComments: { [key: string]: number }[] = JSON.parse(
      localStorage.getItem("seen_escalation_comment") || "[]"
    );
    const existingComment = seenComments.find(item => item[escalationId]);

    if (existingComment) {
      existingComment[escalationId] = data.length;
    } else {
      seenComments.push({ [escalationId]: data.length });
    }
    localStorage.setItem(
      "seen_escalation_comment",
      JSON.stringify(seenComments)
    );
  }

  yield put(
    actions.setSelectedEscalationComments({
      isFetching: false,
      selectedEscalationComments: (data ?? []).map((item: any) => ({
        id: item.id,
        escalationId: item.escalation_id,
        comments: item.comments,
        commentedBy: item.commented_by,
        createdAt: item.created_at,
        updatedAt: item.updated_at
      }))
    })
  );
}
