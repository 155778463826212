import countries from "src/ipm-shared/store/metadata/country";

const CountryUtil = {
  getCountryCodeFromCountryId: (countryId: number): string => {
    switch (countryId) {
      case 1:
        return "SG";
      case 2:
        return "MY";
      case 3:
        return "HK";
      case 16:
        return "AU";
      case 229:
        return "US";
      default:
        return "";
    }
  },
  getCountryCodeFromCurrencyId: (currencyId: number): string => {
    switch (currencyId) {
      case 1:
        return "SG";
      case 2:
        return "MY";
      case 3:
        return "HK";
      case 11:
        return "AU";
      case 73:
        return "HK";
      default:
        return "";
    }
  },
  getCountryIdFromCurrencyId: (currencyId: number): number => {
    switch (currencyId) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 11:
        return 16;
      default:
        return 0;
    }
  },
  getCountryIdFromCountryCode: (countryCode: string): number => {
    switch (countryCode) {
      case "SG":
        return 1;
      case "MY":
        return 2;
      case "HK":
        return 3;
      case "AU":
        return 16;
      default:
        return 0;
    }
  },

  getCountryLabelFromCountryId: (countryId: number): string => {
    const foundCountry = countries.find(c => c.id === countryId);
    return foundCountry ? foundCountry.name : "n/t";
  },

  getCurrencyCodeFromCountryId: (countryId: number): string => {
    switch (countryId) {
      case 1:
        return "SGD";
      case 2:
        return "MYR";
      case 3:
        return "HKD";
      case 16:
        return "AUD";
      case 73:
        return "HK_USD";
      default:
        return "";
    }
  },

  getCountryIdFromCountryLabel: (countryLabel?: string): number => {
    const foundCountry = countries.find(c => c.name === countryLabel);
    return foundCountry ? foundCountry.id : 1;
  }
};

export default CountryUtil;
