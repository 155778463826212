/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { AccountProfile } from "./reducers";

export type AccountType = "business" | "personal" | "enterprise";

export const fetchAccountProfiles = createAction(
  "accountProfile/FETCH",
  resolve => (cb?: () => void) => resolve({ cb })
);

export const setAccountType = createAction(
  "accountProfile/SET_ACCOUNT_TYPE",
  resolve => (
    type: AccountType,
    accountId?: number,
    userId?: number,
    email?: string
  ) => resolve({ accountType: type, accountId, userId, email })
);

export const setAccountProfiles = createAction(
  "accountProfile/SET",
  resolve => (args: {
    isFetching: boolean;
    hasFetched: boolean;
    profiles: AccountProfile[];
  }) => resolve(args)
);

export const setCurrentAccountProfile = createAction(
  "accountProfile/SET_CURRENT",
  resolve => (args: { email: string }) => resolve(args)
);

export const changeUserSetting = createAction(
  "accountProfile/CHANGE_USER_SETTING",
  resolve => (args: {
    enable?: boolean;
    property: string;
    targetUrl?: string;
    refreshDisable?: boolean;
    modalID?: string;
    value?: string;
    disableGlobalLoader?: boolean;
    fetchAccountProfile?: boolean;
  }) => resolve(args)
);

export const changeTheme = createAction(
  "accountProfile/CHANGE_THEME",
  resolve => (args: { targetUrl?: string; themeName: string }) => resolve(args)
);

export const setNricPhotos = createAction(
  "accountProfile/SET_NRIC_PHOTOS",
  resolve => (extraInfo: any) => resolve({ extraInfo })
);

export const fetchNricPhotos = createAction(
  "accountProfile/FETCH_NRIC_PHOTOS",
  resolve => (cb?: (args: any) => void, id?: number) => resolve({ cb, id })
);

export const fetchIDVerificationPhoto = createAction(
  "accountProfile/FETCH_ID_VERIFICATION_PHOTO",
  resolve => (cb?: (args: any) => void, userId?: number, payerId?: number) =>
    resolve({ cb, userId, payerId })
);

export const updateUserSetting = createAction(
  "accountProfile/UPDATE_USER_SETTING",
  resolve => (
    prop: keyof AccountProfile["userSettingRequired"] | "",
    value: any
  ) => resolve({ prop, value })
);

// @Deprecated
// export const setCrossBorderCardFlag = createAction(
//   "accountProfile/SET_CROSS_BORDER_CARD_FLAG",
//   resolve => (crossBorderCardFlag: boolean) => resolve({ crossBorderCardFlag })
// );

// This will be called when changing country dropdown in dashboard
// Country ID is the unique property in access token data that is allowed to change.
// It stays under account profile
export const setCurrentCountry = createAction(
  "accountProfile/SET_CURRENT_COUNTRY",
  resolve => (args: { code: string; id: number }) => resolve(args)
);

export const enterProgram = createAction(
  "accountProfile/ENTER_PROGRAM",
  resolve => (programName: string) => resolve({ programName })
);

export const joinReferral = createAction(
  "accountProfile/JOIN_REFERRAL",
  resolve => (cb?: (args: any) => void) => resolve({ cb })
);

// TODO: this could be in another state, not account profile
export const getReferralReport = createAction(
  "accountProfile/GET_REFERRAL_REPORT",
  resolve => (cb?: (args: any) => void) => resolve({ cb })
);

// TODO: this could be in another state, not account profile
export const loginMyInfoSingPass = createAction(
  "accountProfile/LOGIN_MY_INFO_SING_PASS",
  resolve => (isModal: boolean, redirectUrl: string) =>
    resolve({ isModal, redirectUrl })
);

// TODO: this could be in another state, not account profile
export const fetchMyInfoSingPass = createAction(
  "accountProfile/FETCH_MY_INFO_SING_PASS",
  resolve => (cb?: (args: any) => void) => resolve({ cb })
);

// TODO: this could be in another state, not account profile
export const confirmMyInfoSingPass = createAction(
  "accountProfile/CONFIRM_MY_INFO_SING_PASS",
  resolve => (cb?: (args: any) => void) => resolve({ cb })
);

export const adminGetAdditionalData = createAction(
  "accountProfile/GET_ADDITIONAL_DATA",
  resolve => (cb?: (args: any) => void, id?: number) => resolve({ cb, id })
);

export const displayLoadingAdditionalData = createAction(
  "accountProfile/DISPLAY_LOADING_ADDITIONAL_DATA",
  resolve => (value: boolean) => resolve({ value })
);
