import * as React from "react";
import { IProps } from "./index";
import GeneralShortCardInfo from "src/ipm-shared/components/GeneralShortCardInfo";

class ShortCardInfo extends React.Component<IProps> {
  public render() {
    const card = this.props.cardsById[this.props.cardId];
    if (!card) {
      return "-";
    }

    return (
      <GeneralShortCardInfo cardBrandId={card.brandId} last4={card.last4} />
    );
  }
}

export default ShortCardInfo;
