/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { default as cardsReducer /*Card*/ } from "./reducers";
import * as cardBrankSelectors from "../CardBrand/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
// import store from "src/ipm-shared/store";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
ReducerFactory.registerReducer({ cards: cardsReducer });

const selectors = {
  ...cardBrankSelectors
};

// Use this to get available cards in wallet
export const getCards = (state: RootState, defaultValues?: number) => {
  const cards = state.cards.byId;
  const cardBrands = selectors.brandsById(state);
  const cardsMap = Object.keys(cards);
  const cardBrandsMap = Object.keys(cardBrands);
  if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
    return [];
  }
  const availabelAcquirerIds = accountProfileSelectors
    .getAvailableAcquirers(state)
    .map((o: any) => o.id);
  return cardsMap
    .filter(
      cardId =>
        cards[cardId].isWallet === true ||
        parseInt(cardId, 10) === defaultValues
    )
    .filter(cardId => availabelAcquirerIds.includes(cards[cardId].acquirerId))
    .map(cardId => cards[cardId])
    .map(card => ({
      ...card,
      brand: cardBrands[card.brandId],
      label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
    }));
};

export const getAllCards = createSelector(
  (state: RootState) => state.cards.byId,
  (state: RootState) => selectors.brandsById(state),
  (state: RootState) => accountProfileSelectors.getAvailableAcquirers(state),
  (cards, cardBrands, availabelAcquirers) => {
    const cardsMap = Object.keys(cards);
    const cardBrandsMap = Object.keys(cardBrands);
    if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
      return [];
    }
    const availabelAcquirerIds = availabelAcquirers.map((o: any) => o.id);
    return cardsMap
      .filter(cardId => cards[cardId].isWallet === true)
      .filter(cardId => availabelAcquirerIds.includes(cards[cardId].acquirerId))
      .map(cardId => cards[cardId])
      .map(card => ({
        ...card,
        brand: cardBrands[card.brandId],
        label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
      }));
  }
);

export const getSelectedCard = (state: RootState) => state.cards.selectedId;

// export const getEditedCard = (state: RootState) =>
//   _get(state.cards.byId, state.cards.editedId, {}) as Card;

// export const getAdyenTokenizedCard = (state: RootState) => state.cards.token;
export const shouldDisplayLoadingCards = (state: RootState) =>
  state.cards.isFetching;

// Use this to get all cards including deleted ones
export const cardsById = (state: RootState) => state.cards.byId;

export const getCardsByCountryId = createSelector(
  (state: RootState) => state.cards.byId,
  (state: RootState) => selectors.brandsById(state),
  (state: RootState) => accountProfileSelectors.getAvailableAcquirers(state),
  (cards, cardBrands, availabelAcquirers) => {
    const cardsMap = Object.keys(cards);
    const cardBrandsMap = Object.keys(cardBrands);
    if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
      return [];
    }
    const availabelAcquirerIds = availabelAcquirers.map((o: any) => o.id);
    return cardsMap
      .filter(cardId => cards[cardId].isWallet === true)
      .filter(cardId => availabelAcquirerIds.includes(cards[cardId].acquirerId))
      .map(cardId => cards[cardId])
      .map(card => ({
        ...card,
        brand: cardBrands[card.brandId],
        label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
      }));
  }
);
