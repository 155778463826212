import * as React from "react";
import { IProps } from "./index";
import PaymentTable from "./PaymentTable";
import Loader from "src/ipm-shared/components/Loader";
import T from "src/ipm-shared/Utils/Intl";
import SelectedPayment from "src/ipm-shared/components/SelectedPayment";
import * as classNames from "classnames";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import _get from "lodash-es/get";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";

interface IState {
  noteColumnWidth: number[];
  isTogglePayDetail: boolean;
}

class PaymentStatus extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isTogglePayDetail:
        props.dashboardTheme === "fnb" || props.isPersonalAccount,
      noteColumnWidth: []
    };
  }
  public componentDidMount() {
    if (this.state.isTogglePayDetail) {
      this.props.fetchDashboardPayments();
    }
  }
  public render() {
    const noteColumnWidth = Math.max(...this.state.noteColumnWidth);
    const { isBusinessAccount, activity, accountCurrencyId } = this.props;
    const type = isBusinessAccount ? "business" : "personal";
    return (
      <div className="main-content">
        {this.props.isBusinessAccount && (
          <div
            className={
              "activity-block pay-in-activity-block section-block mb-3"
            }
          >
            <h6>
              {T.transl("ACTIVITY_TITLE", {
                b: (text: any) => <b>{text}</b>
              })}
            </h6>
            <div className={"payment-activity-status"}>
              <div className="row">
                <div className="col-xl-3 col-sm-6">
                  <div className={"payment-status"}>
                    <div className={"text"}>
                      <span>{T.transl("ACTIVITY_PAYMENTS_IN_PROGRESS")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.395"
                        height="28.395"
                        viewBox="0 0 28.395 28.395"
                      >
                        <rect
                          id="Rectangle_276"
                          data-name="Rectangle 276"
                          width="28.395"
                          height="28.395"
                          rx="3.074"
                          fill="#f4e9fa"
                          opacity="0.5"
                        />
                        <g
                          id="Group_462"
                          data-name="Group 462"
                          transform="translate(-428.978 -459.537)"
                        >
                          <line
                            id="Line_130"
                            data-name="Line 130"
                            y1="0.099"
                            x2="14.998"
                            transform="translate(434.919 473.6)"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_1117"
                            data-name="Path 1117"
                            d="M445.856,478.364l4.59-4.674-4.321-4.5"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={"currency"}>
                      {CurrencyUtil.convertFromId(accountCurrencyId).$}
                      <b>
                        <ShortCurrency
                          value={_get(activity, "inprogress", 0)}
                        />
                      </b>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className={"payment-status"}>
                    <div className={"text"}>
                      <span>{T.transl("ACTIVITY_COMPLETED_PAYMENTS")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.395"
                        height="28.395"
                        viewBox="0 0 28.395 28.395"
                      >
                        <g
                          id="Group_461"
                          data-name="Group 461"
                          transform="translate(-716.228 -458.17)"
                        >
                          <rect
                            id="Rectangle_278"
                            data-name="Rectangle 278"
                            width="28.395"
                            height="28.395"
                            rx="3.074"
                            transform="translate(716.228 458.17)"
                            fill="#f4e9fa"
                            opacity="0.5"
                          />
                        </g>
                        <g
                          id="Group_463"
                          data-name="Group 463"
                          transform="translate(-716.228 -458.17)"
                        >
                          <line
                            id="Line_131"
                            data-name="Line 131"
                            x1="12.069"
                            y2="11.65"
                            transform="translate(724.289 466.416)"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_1118"
                            data-name="Path 1118"
                            d="M723.9,471.074l-.111,7.326,6.97.31"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={"currency"}>
                      {CurrencyUtil.convertFromId(accountCurrencyId).$}
                      <b>
                        <ShortCurrency value={_get(activity, "completed", 0)} />
                      </b>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className={"payment-status"}>
                    <div className={"text"}>
                      <span>{T.transl("ACTIVITY_SCHEDULED_PAYMENTS")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.395"
                        height="28.395"
                        viewBox="0 0 28.395 28.395"
                      >
                        <rect
                          id="Rectangle_277"
                          data-name="Rectangle 277"
                          width="28.395"
                          height="28.395"
                          rx="3.074"
                          fill="#f4e9fa"
                          opacity="0.5"
                        />
                        <g
                          id="Group_460"
                          data-name="Group 460"
                          transform="translate(-1002.06 -458.216)"
                        >
                          <line
                            id="Line_129"
                            data-name="Line 129"
                            y1="10.313"
                            x2="10.89"
                            transform="translate(1010.26 469.127)"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_1116"
                            data-name="Path 1116"
                            d="M1021.437,475.381l.162-6.549-6.229-.337"
                            fill="none"
                            stroke="#4940a0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={"currency"}>
                      {CurrencyUtil.convertFromId(accountCurrencyId).$}
                      <b>
                        <ShortCurrency value={_get(activity, "scheduled", 0)} />
                      </b>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className={"buttons-block"}>
                    <button
                      onClick={this.openMakePaymentModal}
                      className={"btn btn-sm mb-3 btn-info btn-round"}
                    >
                      {T.transl("SCHEDULE_PAYMENT_BUTTON")}
                    </button>
                    <button
                      onClick={this.handleTogglePayDetail}
                      className={
                        "btn btn-sm btn-outline-info btn-bepaid btn-round"
                      }
                    >
                      {this.state.isTogglePayDetail
                        ? T.transl("HIDE_DETAILS_BUTTON")
                        : T.transl("VIEW_DETAILS_BUTTON")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.isTogglePayDetail && (
          <div className="payment-status-section">
            <Loader
              color="#00A9BE"
              loading={this.props.shouldDisplayLoadingPayments}
            >
              <div
                className={classNames(
                  `payment-status-block ${type}-border-color lasted-payments`,
                  { "is-cross-border": this.props.isCrossBorder }
                )}
              >
                <h5>
                  {T.transl("DETAILS_PAYMENTS_IN_PROGRESS", {
                    b: (text: any) => <b>{text}</b>
                  })}
                </h5>
                <PaymentTable
                  isCrossBorder={this.props.isCrossBorder}
                  payments={this.props.inProgressPayments}
                  noteColumnWidth={noteColumnWidth}
                  getNoteColumnWidth={this.getDimension}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("LABEL_DASHBOARD_PAYMENT_EMPTY")}
                      <span
                        className="clickable-link"
                        onClick={this.props.toggleModal}
                      >
                        {T.transl("DETAILS_MAKE_PAYMENT_NOW")}
                      </span>
                    </p>
                  }
                />
              </div>

              <div
                className={classNames(
                  `payment-status-block ${type}-border-color upcoming-payments`,
                  { "is-cross-border": this.props.isCrossBorder }
                )}
              >
                <h5>
                  {T.transl("DETAILS_COMPLETED_PAYMENTS", {
                    b: (text: any) => <b>{text}</b>
                  })}
                </h5>
                <PaymentTable
                  isCrossBorder={this.props.isCrossBorder}
                  payments={this.props.completedPayments}
                  noteColumnWidth={noteColumnWidth}
                  getNoteColumnWidth={this.getDimension}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("DETAILS_NO_COMPLETED_PAYMENTS")}
                      <span
                        className="clickable-link"
                        onClick={this.props.toggleModal}
                      >
                        {T.transl("LABEL_PAY_YOUR_EMPLOYEE")}
                      </span>
                    </p>
                  }
                />
              </div>

              <div
                className={classNames(
                  `payment-status-block ${type}-border-color completed-payments`,
                  { "is-cross-border": this.props.isCrossBorder }
                )}
              >
                <h5>
                  {T.transl("DETAILS_SCHEDULED_PAYMENTS", {
                    b: (text: any) => <b>{text}</b>
                  })}
                </h5>
                <PaymentTable
                  isCrossBorder={this.props.isCrossBorder}
                  payments={this.props.upcomingPayments}
                  noteColumnWidth={noteColumnWidth}
                  getNoteColumnWidth={this.getDimension}
                  status={"scheduled"}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("DETAILS_NO_SCHEDULED_PAYMENTS")}{" "}
                      <span
                        className="clickable-link"
                        onClick={this.props.toggleModal}
                      >
                        {T.transl("DETAILS_SCHEDULE_PAYMENT_NOW")}
                      </span>
                    </p>
                  }
                />
              </div>

              <div hidden={true}>
                <SelectedPayment paymentIds={this.getPaymentIds()} />
              </div>
            </Loader>
          </div>
        )}
      </div>
    );
  }

  private openMakePaymentModal = () => {
    this.props.toggleModal();
  };

  private handleTogglePayDetail = () => {
    const willOpen = !this.state.isTogglePayDetail;
    this.setState({ isTogglePayDetail: willOpen });

    if (willOpen) {
      this.props.fetchDashboardPayments();
    }
  };

  private getDimension = (value: number) => {
    this.setState(prevState => ({
      noteColumnWidth: [...prevState.noteColumnWidth, value]
    }));
  };

  private getPaymentIds = (): number[] => {
    let paymentIds: number[] = [];
    paymentIds = paymentIds.concat(
      this.props.inProgressPayments.map(p => p.id)
    );
    paymentIds = paymentIds.concat(this.props.completedPayments.map(p => p.id));
    paymentIds = paymentIds.concat(this.props.upcomingPayments.map(p => p.id));

    return paymentIds;
  };
}

export default PaymentStatus;
