import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "../actions";
import * as selectors from "../selectors";

const mapStateToProps = (state: RootState) => ({
  company: selectors.getCompany(state),
  isBusiness: selectors.getIsBusinessAccount(state),
  otherCompanies: selectors.getOtherCompanies(state),
  shouldDisplayLoadingCompany: selectors.shouldDisplayLoadingCompanies(state)
});

const mapDispatchToProps = {
  requestFullScreen: actions.requestFullScreen,
  toggleAddCompany: actions.toggleModal.bind(
    actions,
    actions.ModalID.ADD_COMPANY_FORM
  )
};
// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
