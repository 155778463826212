import * as React from "react";
import NavDropdown from "../NavDropdown";
import Notification from "./Notification";
import { NavLink } from "react-router-dom";
import * as Images from "src/ipm-shared/components/Images";
import { IProps } from "./index";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import Dropdown from "reactstrap/lib/Dropdown";
import * as classNames from "classnames";
import T from "src/ipm-shared/Utils/Intl";

import { ReferButton } from "src/ipm-shared/images/ReferButton";

import { DashboardSwitchLanguage } from "src/ipm-shared/components/SwitchLanguge";

interface IState {
  dropdownOpen: boolean;
}

class AppHeaderView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public render() {
    return (
      <header
        className={classNames("header-block", {
          "business-account": this.props.isBusiness,
          "personal-account": !this.props.isBusiness
        })}
      >
        <div className="logo-block col-md-2 col-sm-2 col-xs-12">
          <NavLink to="/" className="logo">
            <img src={Images.logo} alt="ipaymy logo" />
          </NavLink>
        </div>

        <div className="header-contain col-md-10 col-sm-10 col-xs-12">
          {this.props.company && (
            <div className="company-info">
              <Dropdown
                className="companies-block mr-1 mr-sm-4"
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                tag="div"
              >
                <DropdownToggle tag="div" className="dropdown-toggle">
                  <div className="logo">
                    <span className="avatar">
                      {this.props.company.name.charAt(0)}
                    </span>
                  </div>
                  <div className="info">
                    <h6>{this.props.company.name}</h6>
                    <p>
                      {`${T.transl("TOP_COMPANIES_MENU_REGISTRATION_LABEL")}: ${
                        this.props.company.registrationNumber
                      }`}
                    </p>
                  </div>
                  {this.props.otherCompanies.length > 0 && (
                    <img
                      className="arr"
                      src="data:image/svg+xml;charset=UTF-8, %3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 5'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:none;stroke:%23333;stroke-linecap:round;stroke-linejoin:round;%7d%3c/style%3e%3c/defs%3e%3ctitle%3eAsset 2%3c/title%3e%3cg id='Layer_2' data-name='Layer 2'%3e%3cg id='Header'%3e%3cpolyline class='cls-1' points='0.5 0.5 4.5 4.5 8.5 0.5'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
                    />
                  )}
                </DropdownToggle>
                <DropdownMenu
                  tag="div"
                  hidden={this.props.otherCompanies.length === 0}
                  className="dropdown-normalize dropdown-companies"
                >
                  <h5 className="headline">
                    {T.transl("TOP_COMPANIES_MENU_HEADER")}
                  </h5>
                  <ul className="companies-section">
                    {this.props.otherCompanies.map((company, idx) => (
                      <li
                        className="dropdown-item unread"
                        key={idx}
                        onClick={this.exchangeToken.bind(undefined, company.id)}
                      >
                        <span className="avatar">{company.name.charAt(0)}</span>
                        <div className="item-content">
                          <h6>{company.name}</h6>
                          <span className="reg-no">
                            {`${T.transl(
                              "TOP_COMPANIES_MENU_REGISTRATION_LABEL"
                            )}: ${company.registrationNumber}`}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="dropdown-footer">
                    <button
                      onClick={this.props.toggleAddCompany}
                      className="btn btn-sm btn-round btn-info btn-bepaid"
                    >
                      {T.transl("TOP_COMPANIES_MENU_ADD_BUTTON")}
                    </button>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
          {/*</Loader>*/}
          <div className="header-contain-info">
            <a
              href="#"
              onClick={this.props.requestFullScreen}
              className="link-expand"
            >
              <img src={Images.expand_icon} alt="Expand Icon" />
            </a>

            <DashboardSwitchLanguage />

            <a
              className={"refer-button mr-1 mr-sm-3"}
              target={"_blank"}
              href="https://www.ipaymy.com/referral/"
            >
              {this.renderReferralButton()}
            </a>
            <div className="d-flex align-items-center">
              <div className="d-none">
                <Notification />
              </div>
              <NavDropdown />
            </div>
          </div>
        </div>
      </header>
    );
  }

  private renderReferralButton = () => {
    const { accountCountryCode } = this.props;
    let size = "small";

    if (accountCountryCode === "HK") {
      if (T.locale === "en") {
        size = "medium";
      }

      if (T.locale === "zh-hk") {
        size = "small";
      }

      if (T.locale === "zlm") {
        size = "big";
      }
    } else {
      if (T.locale === "zlm") {
        size = "medium";
      } else {
        size = "small";
      }
    }

    const props = {
      amount: {
        AU: "A$200",
        HK: "HK$1,200",
        MY: "RM600",
        SG: "S$200"
      }[accountCountryCode],
      size
    };

    return <ReferButton {...props} />;
  };

  private toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  private exchangeToken = (value: number) => {
    const { accountCountryCode, paymentPaidCurrencyCode } = this.props;
    // When change company should redirect to country which account registered.
    this.props.exchangeToken({
      companyId: value,
      countryCode: accountCountryCode,
      currencyCode: paymentPaidCurrencyCode,
      paidCurrencyCode: paymentPaidCurrencyCode,
      purpose: "switch_company"
    });
  };
}

export default AppHeaderView;
