/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "../reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import banks from "./reducers";
import _orderBy from "lodash-es/orderBy";
import _get from "lodash-es/get";

ReducerFactory.registerReducer({ banks });

// Banks
export const getBanksMap = (state: RootState) => state.banks.byId || {};
export const getBanksOptions = (state: RootState) =>
  _orderBy(
    Object.keys(state.banks.byId)
      .map(key => state.banks.byId[key])
      .map(bank => ({
        countryId: bank.countryId,
        displayName: bank.displayName,
        id: bank.id,
        isDeleted: bank.isDeleted,
        label: bank.name,
        value: bank.swiftCode
      })),
    ["label"],
    ["asc"]
  );

export const getBanksIdOptions = (state: RootState) =>
  _orderBy(
    Object.keys(state.banks.byId)
      .map(key => state.banks.byId[key])
      .map(bank => ({
        countryId: bank.countryId,
        displayName: bank.displayName,
        isDeleted: bank.isDeleted,
        label: bank.name,
        swiftCode: bank.swiftCode,
        value: bank.id
      })),
    ["label"],
    ["asc"]
  );

export const getBanksBSBOptions = (state: RootState) =>
  _orderBy(
    Object.keys(state.banks.bankBSB.byId)
      .map(key => state.banks.bankBSB.byId[key])
      .map(bankBSB => {
        const bank = state.banks.byId[bankBSB.bankId];

        return {
          countryId: bank.countryId,
          displayName: `${bank.name}, ${bankBSB.branchName}, ${bankBSB.bsb}`,
          id: bankBSB.id,
          isDeleted: bankBSB.isDeleted,
          label: bankBSB.branchName,
          swiftCode: bank.swiftCode,
          value: bankBSB.id,
          bsbCode: bankBSB.bsb
        };
      }),
    ["label"],
    ["asc"]
  );

export const getBank = (state: RootState, id: string) => {
  return Object.keys(state.banks.byId)
    .filter(key => key === id)
    .map(key => state.banks.byId[key])
    .map(bank => ({
      countryId: bank.countryId,
      displayName: bank.displayName,
      isDeleted: bank.isDeleted,
      label: bank.name,
      swiftCode: bank.swiftCode,
      value: bank.id
    }))[0];
};

export const getBankBSB = (state: RootState, id: string) => {
  return Object.keys(state.banks.bankBSB.byId)
    .filter(key => key === id)
    .map(key => state.banks.bankBSB.byId[key])
    .map(bank => ({
      countryId: "",
      displayName: "",
      isDeleted: bank.isDeleted,
      label: bank.bsb,
      swiftCode: "",
      value: bank.id
    }))[0];
};

export const mapSwiftCodeToId = (state: RootState, swiftCode: string) => {
  const id = _get(state.banks.bySwiftCode, `${swiftCode}.0.id`, undefined);
  return {
    id: id ? id : swiftCode,
    ok: id !== undefined
  };
};

export const mapIdToSwiftCode = (state: RootState, id: string) => {
  return _get(state.banks.byId, `${id}.swiftCode`, id);
};
