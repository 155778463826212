import * as React from "react";
import { IProps } from "./index";
import PageBase from "src/ipm-platform/pages/Base";
import RecommendedSection from "src/ipm-platform/pages/Dashboard/Pay/Recommended";
import BePaidGeneralSection from "src/ipm-platform/pages/Dashboard/BePaid/GeneralSection";
import PayGeneralSection from "src/ipm-platform/pages/Dashboard/Pay/GeneralSection";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

class BasicDashboard extends PageBase<IProps, any> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { isBusiness, isPersonal } = this.props;

    return (
      <>
        <FeatureGate feature="NORMAL_PAYMENT">
          <PayGeneralSection />
        </FeatureGate>

        {isBusiness && <BePaidGeneralSection theme="basic" />}

        {isPersonal && <RecommendedSection />}
      </>
    );
  }
}

export default BasicDashboard;
