import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "../actions";
import * as selectors from "../selectors";
const mapStateToProps = (state: RootState) => ({
  dashboardThemeName: selectors.getDashboardTheme(state),
  hasCompany: selectors.hasCompany(),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  email: selectors.getCurrentAccountProfile(state).email
});

const mapDispatchToProps = {
  toast: actions.toast,
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Component);
