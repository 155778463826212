import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./../selectors";
import * as actions from "./../actions";

const mapStateToProps = (state: RootState) => ({
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  activity: selectors.getPaymentActivity(state),
  brandsById: selectors.brandsById(state),
  cardsById: selectors.cardsById(state),
  completedPayments: selectors.getCompletedPayments(state),
  dashboardTheme: selectors.getDashboardTheme(state),
  inProgressPayments: selectors.getInProgressPayments(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  shouldDisplayLoadingPayments: selectors.shouldDisplayLoadingPayments(state),
  upcomingPayments: selectors.getUpcommingPayments(state)
});

const mapDispatchToProps = {
  fetchDashboardPayments: actions.fetchDashboardPayments,
  toggleModal: actions.toggleModal.bind(
    actions,
    actions.ModalID.MAKE_PAYMENT_MODAL
  )
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    isCrossBorder?: boolean;
  } & ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
