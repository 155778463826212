import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as actions from "./../actions";
import * as selectors from "./../selectors";
import * as guiActions from "src/ipm-shared/components/GlobalUI/actions";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state),
  allCards: selectors.getAllCards(state),
  cards: selectors.getCards(state),
  hasCrossBorderPayment: false // selectors.hasCrossBorderPayment(state).
});

const mapDispatchToProps = {
  deleteCard: actions.deleteCard,
  fetchCards: actions.fetchCards,
  selectCardToEdit: actions.selectCardToEdit,
  toggleModal: guiActions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & {
    acquirerId: number;
  };

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
