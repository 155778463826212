import * as React from "react";
import RobotImage from "src/ipm-shared/images/ipm-robot.svg";

export default () => {
  return (
    <div className="beta-icon-section">
      <img src={RobotImage} alt="Ipaymy International Payment Beta" />
      <a target={"_blank"} href={"https://try.ipaymy.com/intbeta"}>
        Join our <span className={"font-weight-bold"}>Pay Abroad Beta!</span>
        <span className={"arrow-hover"} />
      </a>
    </div>
  );
};
