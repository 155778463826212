/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";

import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import uen from "./reducer";
import _get from "lodash-es/get";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";

ReducerFactory.registerReducer({ uen });

export const getUenList = (state: RootState) => state.uen.uenList;

export const getTotalUen = (state: RootState) => state.uen.total;

export const shouldDisplayLoadingUen = (state: RootState) =>
  state.uen.isFetching;

export const getUenById = (state: RootState, regno: string) => {
  const uenData = state.uen.uenList.filter(item => item.regno === regno)[0];
  return uenData;
};

export const getUenLabel = (state: RootState) => {
  const currentCurrencyId = _get(
    formSelectors.getControl(state, "switch_control_currency_id"),
    "value",
    1
  ) as number;

  switch (+currentCurrencyId) {
    case 2:
      return "SSM";
    case 3:
      return "BRN";
    case 11:
      return "ABN";
    default:
      return "UEN";
  }
};
