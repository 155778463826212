import * as React from "react";

class PageBase<P, S> extends React.Component<P, S> {
  public componentDidMount() {
    console.info(`Enter ${window.location.href}`);

    try {
      if ((window as any).Intercom) {
        (window as any).Intercom("update");
      }
    } catch (e) {
      window.Logger.guestError("Intercom update failed: " + e.toString());
    }
  }
}

export default PageBase;
