/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import _get from "lodash-es/get";
import { RootState } from "../reducers";
import * as actions from "./actions";
import * as commonActions from "../actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { ADD_FORM, SEARCH_FORM, CHANGE_FORM } from "./const";
import * as commonSelectors from "../selectors";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import RestClient from "src/ipm-shared/services/Rest";
import { format } from "date-fns";
import _isEmpty from "lodash-es/isEmpty";

const watchedSagas = [
  reTryTakeLatest(actions.fetchRates, handleFetchRates),

  takeLatest(getType(actions.add), handleAdd),
  takeLatest(getType(actions.change), handleChange),
  takeLatest(getType(actions.adminListAccountRate), handleAdminListAccountRate),
  takeLatest(getType(actions.adminAddAccountRate), handleAdminAddAccountRate),
  takeLatest(
    getType(actions.adminUpdateAccountRate),
    handleAdminUpdateAccountRate
  ),
  takeLatest(
    getType(actions.adminDeleteAccountRate),
    handleAdminDeleteAccountRate
  )
];
export default watchedSagas;

export function* handleFetchRates(
  action: ActionType<typeof actions.fetchRates>
) {
  const state: RootState = yield select();
  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  yield put(
    actions.setRates({
      isFetching: true,
      rates: []
    })
  );

  const queryString = formSelectors.getControlsAsObject(state, SEARCH_FORM);

  // Mock fetching from api.
  // TODO: use a higher level generator.
  const res: Response = yield call(RestClient.send, {
    query: queryString,
    service: "admin_get_account_rates"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);

    yield put(
      actions.setRates({
        isFetching: false,
        rates: data.map(rate => ({
          accountRegisteredAfter: rate.account_registered_after,
          bankId: rate.bank_id,
          cardBrandId: rate.card_brand_id,
          countryId: rate.country_id,
          couponCode: rate.coupon_code,
          currencyId: rate.currency_id,
          id: rate.id,
          isBusinessAccount: rate.is_business_account,
          minAmount: rate.min_amount,
          minScheduleCycles: rate.min_schedule_cycles,
          newRate: rate.new_rate,
          partnershipCode: rate.partnership_code,
          paymentMadeAfter: rate.payment_made_after,
          paymentMadeBefore: rate.payment_made_before,
          priority: rate.priority,
          rate: rate.rate,
          scheduleFrequency: rate.schedule_frequency,
          validTill: rate.valid_till
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchRates: ", e.message);
  }
}

export function* handleAdd(action: ActionType<typeof actions.add>) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);
  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      account_registered_after: _get(
        formState,
        "account_registered_after.value"
      ),
      bank_id: _get(formState, "bank_id.value"),
      card_brand_id: _get(formState, "card_brand_id.value"),
      country_id: _get(formState, "country_id.value"),
      coupon_code: _get(formState, "coupon_code.value"),
      currency_id: _get(formState, "currency_id.value"),
      min_amount: parseInt(_get(formState, "min_amount.value"), 10),
      min_schedule_cycles: parseInt(
        _get(formState, "min_schedule_cycles.value"),
        10
      ),
      partnership_code: _get(formState, "partnership_code.value"),
      priority: parseInt(_get(formState, "priority.value"), 10),
      rate: parseInt(_get(formState, "rate.value"), 10),
      schedule_frequency: _get(formState, "schedule_frequency.value")
    },

    service: "admin_get_rate"
  });

  if (res.status === 422) {
    try {
      const json = yield res.json();
      const errors = _get(json, "data", {});
      yield put(formActions.parseServerErrors(errors, ADD_FORM));
      return;
    } catch (e) {
      window.Logger.error(e);
    }
  }

  if (res.status !== 200) {
    return;
  }

  yield put(actions.fetchRates());
  yield put(commonActions.closeModal(commonActions.ModalID.ADD_RATE_FORM));
}

export function* handleChange(action: ActionType<typeof actions.change>) {
  const state: RootState = yield select();
  const changeFormState = formSelectors.getControls(state, CHANGE_FORM);
  const searchFormState = formSelectors.getControls(state, SEARCH_FORM);
  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      account_id: parseInt(_get(searchFormState, "account_id.value"), 10),
      new_rate: parseInt(_get(changeFormState, "new_rate.value"), 10),
      rate_id: parseInt(_get(changeFormState, "rate_id.value"), 10),
      valid_till: _get(changeFormState, "valid_till.value")
    },
    service: "admin_update_account_rates"
  });

  if (res.status === 422) {
    try {
      const json = yield res.json();
      const errors = _get(json, "data", {});
      yield put(formActions.parseServerErrors(errors, CHANGE_FORM));
    } catch (e) {
      window.Logger.error(e);
    }
    return;
  }

  if (res.status !== 200) {
    return;
  }

  // yield put(
  //   commonActions.toast(getIntl().formatMessage({ id: "SUCCESS_RATE_EDITED" }))
  // );
  yield put(actions.fetchRates());
  yield put(commonActions.closeModal(commonActions.ModalID.CHANGE_RATE_FORM));
}

export function* handleAdminListAccountRate(
  action: ActionType<typeof actions.adminListAccountRate>
) {
  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.userId
    },
    service: "admin_list_account_rate"
  });

  const groupData = {};

  const data = res?.data?.data || [];

  for (let i = 0, len = data.length; i < len; i++) {
    const item = data[i];
    const slug = (item.CompanyName?.replace(/\s/g, "") || "").toLowerCase();

    if (!groupData[slug]) {
      groupData[slug] = {
        companyName: item.CompanyName,
        companyId: slug,
        data: []
      };
    }

    groupData[slug].data.push({
      id: item.Id,
      purposeId: item.PurposeId,
      createdAt: item.CreatedAt ? format(item.CreatedAt, "DD/MM/YYYY") : "",
      minScheduleCycles: item.MinScheduleCycles,
      newRate: item.NewRate,
      validTill: item.ValidTill,
      companyName: item.CompanyName,
      deletedAt: item.DeletedAt ? format(item.DeletedAt, "DD/MM/YYYY") : "",
      groupId: item.GroupId,
      cardBrandId: item.CardBrandId,
      accountRateId: item.AccountRateId,
      currencyId: item.CurrencyId
    });
  }

  yield put(actions.setListAccountRate(Object.values(groupData)));
}

export function* handleAdminAddAccountRate(
  action: ActionType<typeof actions.adminAddAccountRate>
) {
  const state: RootState = yield select();
  const addFormState = formSelectors.getControls(state, ADD_FORM);
  const { cb } = action.payload;

  const res: Response = yield call(RestClient.send, {
    body: {
      account_id: _get(addFormState, "account_id.value"),
      new_rate: Math.round(
        parseFloat(_get(addFormState, "new_rate.value", 0)) * 100
      ),
      category: _get(addFormState, "category.value"),
      group_id: _get(addFormState, "group_id.value"),
      card_brand_id: _get(addFormState, "card_brand_id.value"),
      expiry_date: _get(addFormState, "expiry_date.value"),
      currency_id: _get(addFormState, "currency_id.value"),
      user_id: Number(_get(addFormState, "user_id.value"))
    },
    service: "admin_add_account_rate"
  });

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return;
  }

  yield put(
    commonActions.closeModal(
      commonActions.ModalID.ADMIN_CHANGE_RATE_DETAIL_FORM
    )
  );

  if (cb) {
    cb();
  }
}

export function* handleAdminUpdateAccountRate(
  action: ActionType<typeof actions.adminUpdateAccountRate>
) {
  const state: RootState = yield select();
  const changeFormState = formSelectors.getControls(state, CHANGE_FORM);
  const { cb } = action.payload;
  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      account_id: _get(changeFormState, "account_id.value"),
      new_rate: Number(_get(changeFormState, "new_rate.value")) * 100,
      category: _get(changeFormState, "category.value"),
      group_id: _get(changeFormState, "group_id.value"),
      card_brand_id: _get(changeFormState, "card_brand_id.value"),
      account_rate_id: Number(_get(changeFormState, "account_rate_id.value")),
      expiry_date: _get(changeFormState, "expiry_date.value"),
      currency_id: _get(changeFormState, "currency_id.value"),
      user_id: Number(_get(changeFormState, "user_id.value"))
    },
    service: "admin_update_account_rate"
  });

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CHANGE_FORM));
    return;
  }

  yield put(
    commonActions.closeModal(
      commonActions.ModalID.ADMIN_CHANGE_RATE_DETAIL_FORM
    )
  );

  if (cb) {
    cb();
  }
}

export function* handleAdminDeleteAccountRate(
  action: ActionType<typeof actions.adminDeleteAccountRate>
) {
  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.accountRateId
    },
    service: "admin_delete_account_rate"
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }
}
