import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import _findIndex from "lodash-es/findIndex";
import { PayeeType } from "../Payee/reducers";
import { CollectionRates } from "../CollectedAccount/reducers";

export type RefundCommonAttrs = {
  actionType: string;
  chargeDate: string;
  createdAt: string;
  createdBy: {
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
  };
  email: string;
  paymentId: number;
  paymentAmount: number;
  paymentFees: number;
  paymentTotal: number;
  payoutDate: string;
  requestId: number;
  receiptNumber: number;
  scheduledChargeDate: string;
  isInternationalPayment: boolean;
};

export type RefundType = RefundCommonAttrs & {
  metaData: {
    newPaymentFees: number;
    newPaymentTotal: number;
    newPayoutDate: string;
    newRate: number;
    otherRefusalReason: string;
    paymentRefNo: string;
    refundedFee?: number;
    refundedAmount?: number;
    refundedTotal?: number;
    refusalReason: string;
    refusalReasonCode: string;
    bankId: number;
    comments: string;
    accountNumber: string;
  };
};

export type EditPaymentPayeeRequest = RefundCommonAttrs & {
  metaData: [
    {
      payeeId: number;
      bankId: number;
      comments: string;
      accountNumber: string;
      bankRawName: string;
      recipientName: string;
    }
  ];
  oldMetaData: [
    {
      payeeId: number;
      bankId: number;
      comments: string;
      accountNumber: string;
      bankRawName: string;
      recipientName: string;
    }
  ];
};

export type ScheduleType = {
  isCancelled?: boolean;
  purposeId: number;
  scheduleStartDate: string;
  scheduleEndDate: string;
  scheduleFrequency: string;
  scheduleStatus?: string;
  cards: [
    {
      id: number;
      statementDescriptor: string;
      amount?: number;
      token?: string;
      last4?: string;
      cardBrand?: number;
    }
  ];
  paymentAmount: number;
  paymentStatusId: number;
  paymentFees: number;
  paymentInstantPayFees?: number;
  paymentFlashPayFees?: number;
  paymentGSTFees?: number;
  paymentBankPayoutFees?: number;
  paymentMinimumTransactionFees?: number;
  paymentTotal: number;
  scheduleId: number;
  payees: PayeeType[];
  upcomingPayment?: {
    payments: UpcomingPaymentType[];
  };
  paymentSetting?: PaymentType;
  lastPayout?: string;
  hasSpecialRate?: boolean;
  wallexReference: string;
};

export type UpcomingPaymentType = {
  id: number;
  chargeDate: string;
  payoutDate: string;
  paymentFee: number;
  paymentAmount: number;
  paymentTotal: number;
  cards: [
    {
      id: number;
      statementDescriptor: string;
      amount?: number;
      token?: string;
      last4?: string;
      cardBrand?: number;
    }
  ];
  defaultComments: string;
  editable: boolean;
};

export type PaymentType = ScheduleType & {
  accountId?: number;
  id: number;
  cardCharge?: [
    {
      amount: number;
      chargeDate: string;
      id: string;
      receiptNumber: string;
      status: string;
      refuseReason?: string[];
      refundedAmount: number;
      refundedReason?: string[];
      refundedReasonCode?: string;
      refundedFee: number;
      refundedBankFee?: number;
      refundedInstantPayFee?: number;
      refundedFlashPayFee?: number;
      refundedGSTFee?: number;
      refundedBankPayoutFee?: number;
      refundedMinimumTransactionFee?: number;
      processedAccountID?: string;
      standardMCC?: string;
    }
  ];
  exchangeRate?: number;
  receiptNumber?: string;
  referenceNumber?: string;
  createdAt: string;
  channelFees?: number;
  feeRate?: number;
  origPayoutDate?: string;
  creatorEmail?: string;
  customerEmail?: string;
  customerName?: string;
  updatedAt: string;
  currencyId?: number;
  payoutDate: string;
  payees: PayeeType[];
  /**
   * 1: Rental
   * 2: Salary
   * 3: Invoice
   */
  paymentStatusId: number;
  paymentMethodId?: number;
  scheduledChargeDate: string;
  couponCode?: string;
  company?: string;
  settlementTime?: string;
  isInternationalPayment?: boolean;
  isBusiness?: boolean;
  isCryptoPayment?: boolean;
  isDeductedRate?: boolean;
  originalAmount?: number;
  displayCryptoCurrencyId?: number;

  // For payment detail response structure
  supportingDocument: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;

  amountOff?: number;
  order?: number;
  commissionAmount?: number;
  requesterRates?: CollectionRates[];
  bankTransferFees?: number;
  merchantFees?: number;
  schemeFees?: number;
  interchangeFees?: number;
  settlementFile?: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;

  // For refund pending detail
  refundActionType?: string;
  refundMetadata?: {
    newPaymentAmount: number;
    newPaymentBankPayoutFees: number;
    newPaymentFees: number;
    newPaymentInstantPayFees: number;
    newPaymentFlashPayFees: number;
    newPaymentGstFees: number;
    newPaymentMinimumTransactionFees: number;
    newPaymentTotal: number;
    newRate: number;
    otherRefusalReason: string;
    payees?: {
      refundedAmount: number;
    }[];
    paymentRefNo: string;
    refundedAmount: number;
    refundedBankPayoutFee: number;
    refundedDate: string;
    refundedFee: number;
    refundedBankFee: number;
    refundedInstantPayFee: number;
    refundedFlashPayFee: number;
    refundedGstFee: number;
    refundedMinimumTransactionFee: number;
    refundedTotal: number;
    refusalReason: string;
    refusalReasonCode: string;
  };
};

export type FeesVerboseType = {
  coupon: string;
  fee: number;
  rate: number;
  rateBeforeCoupon: number;
  savings: number;
  total: number;
};

export type LogChargeAttempt = {
  id: number;
  success: boolean;
  metadata: string;
  errors: string;
  actionType: string;
  last4: string;
  cardBrandId: number;
  name: string;
  cardType: string;
  bankIssuer: string;
  cardFunding: string;
};

export type PayoutPaymentsRequestType = {
  id: number;
  countryId: number;
  createdAt: string;
  currencyCode: string;
  currencyId: number;
  numberOfPayment: number;
  payoutDate: string;
  status: string;
  totalAmount: number;
  payments: [
    {
      id: number;
      accountNumber: string;
      amount: number;
      bank: string;
      bsbCode: string;
      countryId: number;
      currencyId: number;
      email: string;
      payee: string;
      payees: {
        id: number;
        accountNumber: string;
        recipientName: string;
        payeeData: {
          supportingDocuments: Array<string> | null;
        };
        countryId: number;
        createdAt: string;
        email: string;
        accountId: number;
        amount: number;
        bsbCode: string;
        firstName: string;
        lastName: string;
        bankBsbId: number;
        currencyId: number;
        comments: string;
      }[];
      purpose: string;
      receiptNumber: string;
    }
  ];
};

export type PaymentCommentType = {
  id: number;
  createdAt: string;
  updateAt: string | null;
  createdBy: number;
  paymentId: number;
  comment: string;
  commentedBy: string;
};

export type PaymentState = {
  readonly isFetching: boolean;
  readonly selectedPaymentId: number;
  readonly selectedPaymentIds: number[];
  readonly payments: PaymentType[];
  readonly schedules: ScheduleType[];
  readonly selectedScheduleId: number;
  readonly refundRequests: Array<RefundType | EditPaymentPayeeRequest>;
  readonly refundRequesteds: Array<RefundType | EditPaymentPayeeRequest>;
  readonly total: number;
  readonly sumPaymentAmount: number;
  readonly sumPaymentTotal: number;
  readonly totalStripeTransactions: number;
  readonly totalBankPayout: number;
  readonly totalBatch: number;
  readonly activity: {
    scheduled?: number;
    inprogress?: number; // include onhold payment
    completed?: number;
  };
  readonly sumPaymentInThisMonth: {
    scheduled?: number;
    inprogress?: number; // include onhold payment
    completed?: number;
  };
  readonly feesVerbose: { [paymentId: number]: FeesVerboseType };
  readonly updatedPaymentStatus: {
    success: boolean;
    failed: boolean;
  };
  readonly logChargeAttempt: LogChargeAttempt[];
  readonly payoutPaymentsRequests: PayoutPaymentsRequestType[];
  readonly selectedPaymentComments: PaymentCommentType[];
};

const defaultState: PaymentState = {
  activity: {},
  feesVerbose: {},
  isFetching: false,
  payments: [],
  refundRequests: [],
  refundRequesteds: [],
  schedules: [],
  selectedPaymentId: -1,
  selectedPaymentIds: [],
  selectedScheduleId: -1,
  sumPaymentAmount: 0,
  sumPaymentInThisMonth: {},
  sumPaymentTotal: 0,
  total: 0,
  totalBankPayout: 0,
  totalBatch: 0,
  totalStripeTransactions: 0,
  updatedPaymentStatus: { success: false, failed: false },
  logChargeAttempt: [],
  payoutPaymentsRequests: [],
  selectedPaymentComments: []
};

export default (state: PaymentState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPayments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payments: action.payload.payments
          ? action.payload.payments
          : state.payments,
        sumPaymentAmount: action.payload.sumPaymentAmount,
        sumPaymentTotal: action.payload.sumPaymentTotal,
        total: action.payload.total,
        totalBankPayout: action.payload.totalBankPayout,
        totalBatch: action.payload.totalBatch,
        totalStripeTransactions: action.payload.totalStripeTransactions
      };
    case getType(actions.appendPayments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payments: state.payments.concat(action.payload.payments)
      };
    case getType(actions.setRefundRequests):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        refundRequests: action.payload.refundRequests
      };
    case getType(actions.setRefundRequesteds):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        refundRequesteds: action.payload.refundRequesteds
      };
    case getType(actions.setSchedules):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        schedules: action.payload.schedules,
        total: action.payload.total
      };
    case getType(actions.updatePaymentDetail):
      const idx = _findIndex(state.payments, o => o.id === action.payload.id);
      const payments = state.payments;
      if (idx > -1 && action.payload.payment) {
        payments.splice(idx, 1, action.payload.payment);
      } else if (action.payload.payment) {
        payments.push(action.payload.payment);
      }

      return {
        ...state,
        payments,
        selectedPaymentId: action.payload.id
      };
    case getType(actions.setPaymentActivity):
      return {
        ...state,
        activity: action.payload.activity
      };

    case getType(actions.setSumPaymentInThisMonth):
      return {
        ...state,
        sumPaymentInThisMonth: action.payload.sumPaymentInThisMonth
      };

    case getType(actions.setScheduleDetail):
      const schedules = state.schedules;
      const sIdx = _findIndex(
        schedules,
        o => o.scheduleId === action.payload.scheduleId
      );
      const scheduleDetail = schedules
        .filter(s => s.scheduleId === action.payload.scheduleId)
        .map(ns => ({
          ...ns,
          lastPayout: action.payload.lastPayout,
          paymentSetting: action.payload.paymentSetting,
          upcomingPayment: {
            payments: action.payload.payments
          }
        }))[0];

      if (sIdx > -1) {
        schedules.splice(sIdx, 1, scheduleDetail);
      }

      return {
        ...state,
        schedules
      };
    case getType(actions.selectSchedule):
      return {
        ...state,
        selectedScheduleId: action.payload.id
      };

    case getType(actions.selectPayments):
      return {
        ...state,
        selectedPaymentIds: action.payload.ids
      };

    case getType(actions.setFeesVerbose):
      return {
        ...state,
        feesVerbose: action.payload.feesVerbose
      };

    case getType(actions.setUpdatedPaymentStatus):
      return {
        ...state,
        updatedPaymentStatus: action.payload.updatedPaymentStatus
      };

    case getType(actions.setLogChargeAttempt):
      return {
        ...state,
        logChargeAttempt: action.payload.logChargeAttempt
      };
    case getType(actions.setPayoutPaymentsRequests):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payoutPaymentsRequests: action.payload.payoutPaymentsRequests
      };
    case getType(actions.setSelectedPaymentComments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        selectedPaymentComments: action.payload.selectedPaymentComments
      };
  }
  return state;
};
