/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import _get from "lodash-es/get";
import * as reconcileActions from "./actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as paymentSelectors from "../Payment/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { RootState } from "../reducers";
import { ADMIN_RECONCILE_FORM } from "./const";
import { CREATE_PAYOUT_FORM, SEARCH_FORM } from "../Payment/const";
import { COUNTRY_OPTION_FORM } from "src/ipm-shared/components/SwitchCountryControl/const";
import RestClient from "src/ipm-shared/services/Rest";
import _isEmpty from "lodash-es/isEmpty";
import { format } from "date-fns";
import utils from "src/ipm-shared/Utils/Number";
import {
  MapCountryPayments,
  MapReconcileSummaryType,
  MapReconcileSummaryWPType,
  MapCountryPayoutPayments,
  MapReconcilePayoutSummaryType,
  ReconcileBankType,
  ReconcileBankCommentType
} from "./reducer";
import CountryUtil from "src/ipm-shared/Utils/Country";

const actions = {
  ...reconcileActions,
  ...formActions
};

const selectors = {
  ...formSelectors,
  ...paymentSelectors
};

const watchedSagas = [
  takeLatest(actions.reconcileRequest, handleReconcileRequest),
  takeLatest(actions.reconcilePayoutRequest, handleReconcilePayoutRequest),
  takeLatest(
    actions.getReconcilePayoutRequest,
    handleGetReconcilePayoutRequest
  ),
  takeLatest(actions.reconcileBankRequest, handleReconcileBankRequest),
  takeLatest(actions.addReconcileBankRequest, handleAddReconcileBankRequest),
  takeLatest(
    actions.addReconcileBankCommentRequest,
    handleAddReconcileBankCommentRequest
  )
];
export default watchedSagas;

export function* handleReconcileRequest() {
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    ADMIN_RECONCILE_FORM
  ) as any;
  const querySelectedDate = formSelectors.getControl(
    state,
    "selected_date"
  ) as any;
  const selectedDate = _get(querySelectedDate, "value", "");
  query.selected_date = format(selectedDate, "YYYY-MM-DD");

  const res: Response = yield call(RestClient.send, {
    query,
    service: "reconcile_payment",
    showGlobalLoader: true,
    timeout: -1
  });

  try {
    const data: any = _get(res, "data.payments", {}) || {};
    let dataFromPaymentServiceProvider: any =
      _get(res, "data.data_from_payment_service_provider", []) || [];
    let dataIPAYMY: any = _get(res, "data.dataIPAYMY", []) || [];
    const isReconcile: boolean = _get(res, "data.is_reconcile", false);
    const summaries: any = _get(res, "data.summaries", {});
    const isReconcileWP: boolean = _get(res, "data.is_reconcile_wp", false);
    const summariesWP: any = _get(res, "data.summaries_wp", {});
    const resultPayments: MapCountryPayments = {};
    const summaryRegionPayments: MapReconcileSummaryType = {};
    const summaryWPRegionPayments: MapReconcileSummaryWPType = {};

    if (Object.keys(data).length > 0) {
      const keys = Object.keys(data);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        resultPayments[key] = [];
        data[key].map((item: any) => {
          resultPayments[key].push({
            Id: item.id,
            PaymentIntentId: item.payment_service_provider_payment_id,
            Description: item.description,
            Amount: item.amount,
            AmountRefunded: item.amount_refunded,
            Currency: item.currency,
            Status: item.status,
            StatementDescriptor: item.statement_descriptor,
            Paid: item.paid,
            Created: item.created
              ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
              : item.created,
            CardLast4: item.card_last_4,
            BankIssuer: item.bank_issuer,
            CardBrand: item.card_brand,
            CardFunding: item.card_funding,
            CardIssueCountry: item.card_issue_country,
            CardBin: item.card_bin,
            CardType: item.card_type,
            Reason: item.reason,
            Source: item.data_source,
            CountryCurrency: item.country_currency,
            StripeAccount: item.stripe_account || ""
          });
        });
      }
    }

    if (summaries && Object.keys(summaries).length > 0) {
      const keys = Object.keys(summaries);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        summaryRegionPayments[key] = {
          AmountIpaymy: summaries[key]?.amount_ipaymy || 0,
          AmountTotalIpaymy: summaries[key]?.amount_total_ipaymy || 0,
          AmountTotalInPaymentServiceProvider:
            summaries[key]?.amount_total_in_payment_service_provider || 0,
          TotalTransactionsIPAYMY:
            summaries[key]?.total_transactions_ipaymy || 0,
          TotalTransactionsInPaymentServiceProvider:
            summaries[key]?.total_transactions_in_payment_service_provider || 0
        };
      }
    }

    if (summariesWP && Object.keys(summariesWP).length > 0) {
      const keys = Object.keys(summariesWP);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        summaryWPRegionPayments[key] = {
          AmountTotalAuthorisedWP:
            summariesWP[key]?.amount_total_authorised_wp || 0,
          AmountTotalCapturedWP:
            summariesWP[key]?.amount_total_captured_wp || 0,
          TotalTransactionsAuthorisedWP:
            summariesWP[key]?.total_transactions_authorised_wp || 0,
          TotalTransactionsCapturedWP:
            summariesWP[key]?.total_transactions_captured_wp || 0
        };
      }
    }

    dataFromPaymentServiceProvider = dataFromPaymentServiceProvider.map(
      (item: any) => ({
        Id: item.id,
        PaymentIntentId: item.payment_service_provider_payment_id,
        Description: item.description,
        Amount: item.amount,
        AmountRefunded: item.amount_refunded,
        Currency: item.currency,
        Status: item.status,
        StatementDescriptor: item.statement_descriptor,
        Paid: item.paid,
        Created: item.created
          ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
          : item.created,
        CardLast4: item.card_last_4,
        BankIssuer: item.bank_issuer,
        CardBrand: item.card_brand,
        CardFunding: item.card_funding,
        CardIssueCountry: item.card_issue_country,
        CardBin: item.card_bin,
        CardType: item.card_type,
        Reason: item.reason,
        Source: item.data_source,
        CountryCurrency: item.country_currency,
        StripeAccount: item.stripe_account || ""
      })
    );

    dataIPAYMY = dataIPAYMY.map((item: any) => ({
      Id: item.id,
      PaymentIntentId: item.payment_intent_id || "",
      Description: item.description || "",
      Amount: item.payment_total,
      AmountRefunded: 0,
      Currency: item.currency,
      Status: item.status,
      StatementDescriptor: item.statement_descriptor || "",
      Paid: true,
      Created: item.created
        ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
        : item.created,
      CardLast4: item.card_last_4,
      BankIssuer: item.bank_issuer || "",
      CardBrand: item.card_brand || "",
      CardFunding: item.card_funding || "",
      CardIssueCountry: item.card_issue_country || "",
      CardBin: item.card_bin || "",
      CardType: item.card_type || "",
      Reason: "",
      Source: "IPAYMY",
      CountryCurrency: "",
      StripeAccount: ""
    }));

    yield put(
      actions.reconcileSuccess(
        isReconcile,
        isReconcileWP,
        resultPayments,
        summaryRegionPayments,
        summaryWPRegionPayments,
        dataFromPaymentServiceProvider,
        dataIPAYMY
      )
    );
  } catch (error) {}
}

export function* handleGetReconcilePayoutRequest() {
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    ADMIN_RECONCILE_FORM
  ) as any;
  const querySelectedDate = formSelectors.getControl(
    state,
    "selected_date"
  ) as any;
  const selectedDate = _get(querySelectedDate, "value", "");
  query.selected_date = format(selectedDate, "YYYY-MM-DD");

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_reconcile_payout_payment",
    showGlobalLoader: true,
    timeout: -1
  });

  try {
    const data: any = _get(res, "data.payments", {}) || {};
    const isReconcile: boolean = _get(res, "data.is_reconcile", false);
    const summaries: any = _get(res, "data.summaries", {});
    const resultPayoutPayments: MapCountryPayoutPayments = {};
    const summaryRegionPayoutPayments: MapReconcilePayoutSummaryType = {};

    if (Object.keys(data).length > 0) {
      const keys = Object.keys(data);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        resultPayoutPayments[key] = [];
        data[key].map((item: any) => {
          resultPayoutPayments[key].push({
            PayoutDate: item.payout_date,
            ReferenceNo: item.reference_no,
            PaymentTotal: item.payment_total,
            ReceiptNumber: item.receipt_number,
            Currency: item.currency,
            AcquirerId: item.acquirer_id,
            CurrencyId: item.currency_id,
            ScheduledChargeDate: item.scheduled_charged_date,
            PaymentAmount: item.payment_amount,
            TotalPayee: item.total_payee,
            Email: item.email
          });
        });
      }
    }

    if (summaries && Object.keys(summaries).length > 0) {
      const keys = Object.keys(summaries);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        summaryRegionPayoutPayments[key] = {
          TotalTransactions: summaries[key]?.total_transactions || 0,
          TotalSettlementAmount: summaries[key]?.total_settlement_amount || 0,
          TotalPayee: summaries[key]?.total_payee || 0
        };
      }
    }

    yield put(
      actions.reconcilePayoutSuccess(
        isReconcile,
        resultPayoutPayments,
        summaryRegionPayoutPayments
      )
    );
  } catch (error) {
    console.log(error);
  }
}

export function* handleReconcileBankRequest() {
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    ADMIN_RECONCILE_FORM
  ) as any;
  const querySelectedDate = formSelectors.getControl(
    state,
    "selected_date"
  ) as any;
  const selectedDate = _get(querySelectedDate, "value", "");
  query.selected_date = format(selectedDate, "YYYY-MM-DD");

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  query.country_id = _get(queryCountry, "switch_control_country_id", 1);
  query.currency_id = _get(query, "switch_control_currency_id", 1);

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_reconcile_bank",
    showGlobalLoader: true,
    timeout: -1
  });

  try {
    const data: any = _get(res, "data.data", {}) || {};
    const total: number = _get(res, "data.total", 0);
    const sumPaymentAmount: any = _get(res, "data.sum_payment_amount", 0);
    const comments: ReconcileBankCommentType[] = [];
    const reconcileBank: ReconcileBankType = {
      Date: data.date,
      WorldpayDeposit: data.worldpay_deposit,
      AmexDeposit: data.amex_deposit,
      StripeDeposit: data.stripe_deposit,
      OpeningBalance: data.opening_balance,
      ClosingBalance: data.closing_balance,
      Fees: data.fees,
      CountryId: data.country_id,
      CurrencyId: data.currency_id,
      Total: total,
      SumPaymentAmount: sumPaymentAmount,
      Comments: []
    };

    if (data.comments && data.comments.length > 0) {
      for (let i = 0, len = data.comments.length; i < len; i++) {
        const comment = data.comments[i];
        comments.push({
          ID: comment.id,
          Date: comment.date,
          CreatedAt: comment.created_at,
          UpdateAt: comment.updated_at,
          CreatedBy: comment.created_by,
          Comment: comment.comment,
          CommentedBy: comment.commented_by
        });
      }
    }

    reconcileBank.Comments = comments;

    yield put(actions.reconcileBankSuccess(reconcileBank));
  } catch (error) {
    console.log(error);
  }
}

export function* handleAddReconcileBankRequest() {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADMIN_RECONCILE_FORM);
  const environment = _get(formState, "general_environment.value", "");
  const worldpayDeposit =
    _get(formState, "worldpay_deposit.value") !== null
      ? utils.amountStringToInt(_get(formState, "worldpay_deposit.value"))
      : null;
  const amexDeposit =
    _get(formState, "amex_deposit.value") !== null
      ? utils.amountStringToInt(_get(formState, "amex_deposit.value"))
      : null;
  const stripeDeposit =
    _get(formState, "stripe_deposit.value") !== null
      ? utils.amountStringToInt(_get(formState, "stripe_deposit.value"))
      : null;
  const closingBalance =
    _get(formState, "manual_closing_balance.value") !== null
      ? utils.amountStringToInt(_get(formState, "manual_closing_balance.value"))
      : null;
  const fees =
    _get(formState, "fees.value") !== null
      ? utils.amountStringToInt(_get(formState, "fees.value"))
      : null;

  const querySelectedDate = formSelectors.getControl(
    state,
    "selected_date"
  ) as any;
  const selectedDate = format(
    _get(querySelectedDate, "value", ""),
    "YYYY-MM-DD"
  );

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 1);
  const currencyId = _get(formState, "switch_control_currency_id.value", 1);

  const res: Response = yield call(RestClient.send, {
    body: {
      country_id: +countryId,
      currency_id: +currencyId,
      selected_date: selectedDate,
      environment: environment,
      worldpay_deposit: worldpayDeposit,
      amex_deposit: amexDeposit,
      stripe_deposit: stripeDeposit,
      closing_balance: closingBalance,
      fees: fees
    },
    service: "add_reconcile_bank",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADMIN_RECONCILE_FORM));
    return;
  }

  yield put(actions.reconcileBankRequest());
  return;
}

export function* handleAddReconcileBankCommentRequest() {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADMIN_RECONCILE_FORM);
  const environment = _get(formState, "general_environment.value", "");
  const comment = _get(formState, "payment_detail_comment.value", "");

  const querySelectedDate = formSelectors.getControl(
    state,
    "selected_date"
  ) as any;
  const selectedDate = format(
    _get(querySelectedDate, "value", ""),
    "YYYY-MM-DD"
  );

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 1);
  const currencyId = _get(formState, "switch_control_currency_id.value", 1);

  const res: Response = yield call(RestClient.send, {
    body: {
      country_id: +countryId,
      currency_id: +currencyId,
      selected_date: selectedDate,
      environment: environment,
      comment: comment
    },
    service: "add_reconcile_bank_comment",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADMIN_RECONCILE_FORM));
    return;
  }

  yield put(actions.reconcileBankRequest());
  return;
}

export function* handleReconcilePayoutRequest() {
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;

  const { environment } = query;
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let countryId = _get(queryCountry, "switch_control_country_id", 0);
  countryId =
    +countryId !== 0
      ? +countryId
      : CountryUtil.getCountryIdFromCountryCode(
          CountryUtil.getCountryCodeFromCurrencyId(+currencyId)
        );
  const paymentIds = selectors
    .getAdminPayments(state)
    .map(payment => payment.id);

  const controlValue = _get(queryCountry, "switch_control_value", "");

  const res: Response = yield call(RestClient.send, {
    body: {
      country_id: countryId,
      currency_id: +currencyId,
      environment,
      payment_ids: paymentIds,
      payment_type: controlValue
    },
    service: "reconcile_payout_payment",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CREATE_PAYOUT_FORM));
    return;
  }

  try {
    const data: any = _get(res, "data.payments", {}) || {};
    let dataFromPaymentServiceProvider: any =
      _get(res, "data.data_from_payment_service_provider", []) || [];
    let dataIPAYMY: any = _get(res, "data.dataIPAYMY", []) || [];
    const isReconcile: boolean = _get(res, "data.is_reconcile", false);
    const summaries: any = _get(res, "data.summaries", {});
    const isReconcileWP: boolean = _get(res, "data.is_reconcile_wp", false);
    const summariesWP: any = _get(res, "data.summaries_wp", {});
    const resultPayments: MapCountryPayments = {};
    const summaryRegionPayments: MapReconcileSummaryType = {};
    const summaryWPRegionPayments: MapReconcileSummaryWPType = {};

    if (Object.keys(data).length > 0) {
      const keys = Object.keys(data);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        resultPayments[key] = [];
        data[key].map((item: any) => {
          resultPayments[key].push({
            Id: item.id,
            PaymentIntentId: item.payment_service_provider_payment_id,
            Description: item.description,
            Amount: item.amount,
            AmountRefunded: item.amount_refunded,
            Currency: item.currency,
            Status: item.status,
            StatementDescriptor: item.statement_descriptor,
            Paid: item.paid,
            Created: item.created
              ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
              : item.created,
            CardLast4: item.card_last_4,
            BankIssuer: item.bank_issuer,
            CardBrand: item.card_brand,
            CardFunding: item.card_funding,
            CardIssueCountry: item.card_issue_country,
            CardBin: item.card_bin,
            CardType: item.card_type,
            Reason: item.reason,
            Source: item.data_source,
            CountryCurrency: item.country_currency,
            StripeAccount: item.stripe_account || ""
          });
        });
      }
    }

    if (summaries && Object.keys(summaries).length > 0) {
      const keys = Object.keys(summaries);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        summaryRegionPayments[key] = {
          AmountIpaymy: summaries[key]?.amount_ipaymy || 0,
          AmountTotalIpaymy: summaries[key]?.amount_total_ipaymy || 0,
          AmountTotalInPaymentServiceProvider:
            summaries[key]?.amount_total_in_payment_service_provider || 0,
          TotalTransactionsIPAYMY:
            summaries[key]?.total_transactions_ipaymy || 0,
          TotalTransactionsInPaymentServiceProvider:
            summaries[key]?.total_transactions_in_payment_service_provider || 0
        };
      }
    }

    if (summariesWP && Object.keys(summariesWP).length > 0) {
      const keys = Object.keys(summariesWP);
      for (let i = 0, len = keys.length; i < len; i++) {
        const key = keys[i];
        summaryWPRegionPayments[key] = {
          AmountTotalAuthorisedWP:
            summariesWP[key]?.amount_total_authorised_wp || 0,
          AmountTotalCapturedWP:
            summariesWP[key]?.amount_total_captured_wp || 0,
          TotalTransactionsAuthorisedWP:
            summariesWP[key]?.total_transactions_authorised_wp || 0,
          TotalTransactionsCapturedWP:
            summariesWP[key]?.total_transactions_captured_wp || 0
        };
      }
    }

    dataFromPaymentServiceProvider = dataFromPaymentServiceProvider.map(
      (item: any) => ({
        Id: item.id,
        PaymentIntentId: item.payment_service_provider_payment_id,
        Description: item.description,
        Amount: item.amount,
        AmountRefunded: item.amount_refunded,
        Currency: item.currency,
        Status: item.status,
        StatementDescriptor: item.statement_descriptor,
        Paid: item.paid,
        Created: item.created
          ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
          : item.created,
        CardLast4: item.card_last_4,
        BankIssuer: item.bank_issuer,
        CardBrand: item.card_brand,
        CardFunding: item.card_funding,
        CardIssueCountry: item.card_issue_country,
        CardBin: item.card_bin,
        CardType: item.card_type,
        Reason: item.reason,
        Source: item.data_source,
        CountryCurrency: item.country_currency,
        StripeAccount: item.stripe_account || ""
      })
    );

    dataIPAYMY = dataIPAYMY.map((item: any) => ({
      Id: item.id,
      PaymentIntentId: item.payment_intent_id || "",
      Description: item.description || "",
      Amount: item.payment_total,
      AmountRefunded: 0,
      Currency: item.currency,
      Status: item.status,
      StatementDescriptor: item.statement_descriptor || "",
      Paid: true,
      Created: item.created
        ? format(new Date(item.created * 1000), "DD/MM/YYYY HH:mm")
        : item.created,
      CardLast4: item.card_last_4,
      BankIssuer: item.bank_issuer || "",
      CardBrand: item.card_brand || "",
      CardFunding: item.card_funding || "",
      CardIssueCountry: item.card_issue_country || "",
      CardBin: item.card_bin || "",
      CardType: item.card_type || "",
      Reason: "",
      Source: "IPAYMY",
      CountryCurrency: "",
      StripeAccount: ""
    }));

    yield put(
      actions.reconcileSuccess(
        isReconcile,
        isReconcileWP,
        resultPayments,
        summaryRegionPayments,
        summaryWPRegionPayments,
        dataFromPaymentServiceProvider,
        dataIPAYMY
      )
    );
  } catch (error) {}
}
