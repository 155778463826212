// export const FORM = "";
export enum ROLES {
  INTERNAL_ADMIN_1 = 1,
  INTERNAL_ADMIN_2,
  ACCOUNT_ADMIN,
  ACCOUNT_MEMBER,
  AM_ADMIN = 7
}

export const ACCOUNT_TYPE = {
  BUSINESS: "business",
  PERSONAL: "personal",
  ENTERPRISE: "enterprise"
};
