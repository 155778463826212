import { createAction } from "typesafe-actions";
import { PayeeType } from "../Payee/reducers";

export const applyCoupon = createAction("coupon/APPLY", resolve => () =>
  resolve()
);

export const deleteCouponUsed = createAction(
  "coupon/DELETE_COUPON_USED",
  resolve => (paymentToken: string) => resolve({ paymentToken })
);

export const selectCard = createAction(
  "payment_request/SELECT_CARD",
  resolve => (id: number, paymentToken?: string) =>
    resolve({ id, paymentToken })
);

export const fulfilPaymentSubmit = createAction(
  "payment_request/PAYMENT_SUBMIT"
);

export const approveClient3dsPayment = createAction(
  "payment_request/PAYMENT_3DS_APPROVED",
  resolve => (threedsToken: string) => resolve({ threedsToken })
);

export const addPaymentRequestSubmit = createAction(
  "payment_request/ADD",
  resolve => (purpose: string) => resolve({ purpose })
);

export const validatePaymentPayeeSubmit = createAction(
  "payment_request/VALIDATE_PAYMENT_PAYEE",
  resolve => (purpose: string) => resolve({ purpose })
);

export const editPaymentRequestSubmit = createAction(
  "payment_request/EDIT",
  resolve => (purpose: string) => resolve({ purpose })
);

export const setPaymentRequest = createAction(
  "payment_request/SET_CURRENT",
  resolve => (args: {
    channelFees: number;
    currencyId: number;
    isScheduled?: boolean;
    payees: Array<Partial<PayeeType>>;
    purposeId: number;
    statementDescriptor: string;
    subtotal: number;
    token: string;
    paidCard: number;
  }) => resolve(args)
);

export const setFees = createAction(
  "payment_request/SET_FEE",
  resolve => (args: {
    amountOff?: number;
    fee: number;
    savings: number;
    rate: number;
    rateBeforeCoupon: number;
    total: number;
    coupon: string;
    exchangeRate: number;
    paymentAmount: number;
    payeeQuoteToken?: string;
  }) => resolve(args)
);

export const setCurrentPurpose = createAction(
  "payment_request/SET_CURRENT_PURPOSE",
  resolve => (purpose: number) => resolve({ purpose })
);

export const fetchPaymentRequest = createAction(
  "payment_request/FETCH",
  resolve => (token: string) => resolve({ token })
);

export const fetchPaymentFee = createAction(
  "payment_request/FETCH_FEE",
  resolve => (paymentToken: string, hasCouponApplied: boolean = false) =>
    resolve({ paymentToken, hasCouponApplied })
);

export const setCoupon = createAction(
  "payment_request/SET_COUPON",
  resolve => (coupon: string) => resolve({ coupon })
);

export const validateAmount = createAction(
  "payments/VALIDATE_AMOUNT",
  resolve => (args: {
    amount: number;
    fieldName: string;
    form?: string;
    bePaid?: boolean;
  }) => resolve(args)
);

export const getExchangeRate = createAction("payments/GET_EXCHANGE_RATE");

export const setExchangeRate = createAction(
  "payments/SET_EXCHANGE_RATE",
  resolve => (exchangeRate: number) => resolve({ exchangeRate })
);
