import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "../actions";
import * as selectors from "../selectors";

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state), // accept without requireProfileComponent
  secondaryAccountProfiles: selectors.getSecondaryAccountProfiles(state)
});

const mapDispatchToProps = {
  addAccount: actions.addAccount,
  switchAccount: actions.switchAccount
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
