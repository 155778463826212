export enum ESCALATION_TYPE {
  // all = 0,
  screening = 1,
  onboarding = 2,
  transaction = 3,
  adhoc = 4
}

export const ESCALATION_TYPE_OPTIONS = [
  // {
  //   label: "All",
  //   value: ESCALATION_TYPE.all
  // },
  {
    label: "Transaction",
    value: ESCALATION_TYPE.transaction
  },
  {
    label: "Ad-hoc",
    value: ESCALATION_TYPE.adhoc
  }
];

export const ADMIN_ESCALATION_FORM = "admin_escalation_form";

export const ESCALATION_NATURE_OF_PARTY_OPTIONS = [
  {
    label: "Business (User)",
    value: 1
  },
  {
    label: "Personal (User)",
    value: 2
  },
  {
    label: "Beneficiary",
    value: 3
  },
  {
    label: "Merchant’s customer",
    value: 4
  },
  {
    label: "N/A at this stage",
    value: 5
  }
];

export const ESCALATION_NUMBER_OF_HITS_OPTIONS = [
  {
    label: "Less than 10",
    value: "Less than 10"
  },
  {
    label: "10-50",
    value: "10-50"
  },
  {
    label: "More than 50",
    value: "More than 50"
  }
];

export const ESCALATION_CATEGORY_OPTIONS = [
  {
    label: "Incomplete, inaccurate or insufficient supporting documents",
    value: "Incomplete, inaccurate or insufficient supporting documents"
  },
  {
    label: "Suspected self funding",
    value: "Suspected self funding"
  },
  {
    label: "Personal payments made under a Business account",
    value: "Personal payments made under a Business account"
  },
  {
    label: "Business payments made under a Personal account",
    value: "Business payments made under a Personal account"
  },
  {
    label: "Restricted / High Risk payment type",
    value: "Restricted / High Risk payment type"
  },
  {
    label:
      "Unexplainable / unusual payment payment(s) structure based on payment size, nature, frequency or other factors",
    value:
      "Unexplainable / unusual payment payment(s) structure based on payment size, nature, frequency or other factors"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const ONBOARDING_ESCALATION_NATURE_OPTIONS = [
  {
    label: "Business",
    value: 1
  },
  {
    label: "Personal",
    value: 2
  }
];

export const ONBOARDING_ESCALATION_CATEGORY_OPTIONS = [
  {
    label:
      "Outdated or incorrect company incorporation document (e.g. ACRA, SSM company profile)",
    value:
      "Outdated or incorrect company incorporation document (e.g. ACRA, SSM company profile)"
  },
  {
    label:
      "Incomplete onboarding / periodic review document(s) (e.g. LOA, Shareholding chart)",
    value:
      "Incomplete onboarding / periodic review document(s) (e.g. LOA, Shareholding chart)"
  },
  {
    label:
      "Additional information required for CDD completion (e.g. more details on business model, website)",
    value:
      "Additional information required for CDD completion (e.g. more details on business model, website)"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const ESCALATION_STATUS_OPTIONS = [
  {
    value: 1,
    label: "Approved"
  },
  {
    value: 2,
    label: "Rejected"
  },
  {
    value: 3,
    label: "Pending"
  }
];

export const SEARCH_FORM = "admin_escalation_search_form";
export const PAGE_COUNT = 200;
