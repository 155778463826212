import * as React from "react";
import CreateInvoiceIcon from "src/ipm-shared/images/create-invoice-icon.svg";
import UploadInvoiceIcon from "src/ipm-shared/images/upload-invoice-icon.svg";
import SmsRequestIcon from "src/ipm-shared/images/sms-request-icon.svg";
import { IProps } from "./index";
import _get from "lodash-es/get";
import * as classNames from "classnames";

import T from "src/ipm-shared/Utils/Intl";
import * as actions from "src/ipm-platform/layout/actions";

interface IState {
  isHiddenIRASTooltip: boolean;
}

class BePaidMakePayment extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isHiddenIRASTooltip: localStorage.getItem("iras-tooltip-closed") === "yes"
    };
  }
  public render() {
    const isFnbTheme = this.props.dashboardTheme === "fnb";
    return (
      <ul
        className={classNames("services-items", {
          "fnb-theme": isFnbTheme
        })}
      >
        <li className="col-item" onClick={this.showBePaidUnavailableModal}>
          <div className="item">
            <div className="item-content">
              <img src={CreateInvoiceIcon} alt={"Create Invoice Icon"} />

              {!isFnbTheme && <span>{T.transl("LABEL_CREATE_INVOICE")}</span>}
            </div>
            <span className={"btn btn-round btn-info"}>
              {isFnbTheme
                ? T.transl("CREATE_ORDER_INVOICE_BUTTON")
                : ` ${T.transl("LABEL_CREATE_INVOICE")}`}
            </span>
          </div>
        </li>

        <li className="col-item" onClick={this.showBePaidUnavailableModal}>
          <div className="item">
            <div className="item-content">
              <img src={UploadInvoiceIcon} alt={"Upload Invoice Icon"} />
              {!isFnbTheme && <span>{T.transl("LABEL_UPLOAD_INVOICE")}</span>}
            </div>
            <span className={"btn btn-round btn-info"}>
              {isFnbTheme
                ? T.transl("UPLOAD_ORDER_INVOICE_BUTTON")
                : T.transl("LABEL_UPLOAD_INVOICE")}
            </span>
          </div>
        </li>

        <li
          className="col-item sms-request-item"
          onClick={this.showBePaidUnavailableModal}
        >
          <div className="item">
            <div className="item-content">
              <img src={SmsRequestIcon} alt={"SMS icon"} />
              {!isFnbTheme && <span>{T.transl("SMS_REQUEST_BUTTON")}</span>}
            </div>
            <span className={"btn btn-round btn-info"}>
              {isFnbTheme
                ? T.transl("INSTANT_REQUEST_BUTTON")
                : T.transl("SMS_REQUEST_BUTTON")}
            </span>
          </div>
        </li>
      </ul>
    );
  }

  // private goTo = (path: string) => {
  //   window.location.href = path;
  //   this.props.closeModal();
  // };
  // private openBePaidSmsNotify = () => {
  //   this.props.closeModal();
  //   this.props.toggleModal(ModalID.BE_PAID_SMS_NOTIFY);
  // };

  private showBePaidUnavailableModal = () => {
    this.props.toggleModal(actions.ModalID.BEPAID_UNAVAILABLE_MODAL);
  };
}

export default BePaidMakePayment;
