/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

export const requestResetPasswordSubmit = createAction(
  "auth/REQUEST_RESET_PASSWORD"
);

export const setNewPasswordSubmit = createAction("auth/SET_NEW_PASSWORD");

export * from "src/ipm-shared/store/model/actions";
