import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./../selectors";
import * as actions from "./../actions";
import BeteIcon from "src/ipm-platform/pages/Dashboard/Pay/BeteIcon";
import CountrySelector from "src/ipm-platform/pages/Dashboard/Pay/CountrySelector";
import PaymentStatus from "src/ipm-platform/pages/Dashboard/Pay/PaymentStatus";
import CashFlowCalendar from "src/ipm-platform/pages/Dashboard/Pay/CashFlowCalendar";
import MakePayment from "src/ipm-platform/pages/Dashboard/Pay/MakePayment";
import CardSlider from "src/ipm-platform/pages/Dashboard/Pay/CardSlider";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";

const mapStateToProps = (state: RootState) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  acquirerId: selectors.getAcquirerId(),
  features: selectors.getFeatures(),
  isBusiness: selectors.getIsBusinessAccount(state),
  isCrossBorder: selectors.isCrossBorder(state),
  isPersonal: selectors.getIsPersonalAccount(state)
});

const mapDispatchToProps = {
  changeUserSetting: actions.changeUserSetting
};

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class PayGeneralSection extends React.Component<IProps> {
  public render() {
    const {
      accountCountryCode,
      acquirerId,
      isBusiness,
      isPersonal,
      isCrossBorder,
      features
    } = this.props;

    const shouldDisplayIntBetaIcon =
      accountCountryCode === "SG" &&
      !FeatureGateUtil.verifyFeature(features, "WALLEX_FLOW");
    return (
      <>
        <div className="d-flex slider-section">
          {shouldDisplayIntBetaIcon && <BeteIcon />}
          <div className="services-section section-block">
            <CountrySelector />

            <div className={"inner-content"}>
              <MakePayment />
              {isBusiness && <CashFlowCalendar />}
            </div>
          </div>

          {isBusiness && <PaymentStatus isCrossBorder={isCrossBorder} />}

          {isPersonal && acquirerId > -1 && (
            <CardSlider acquirerId={acquirerId} />
          )}
        </div>
        {isPersonal && <PaymentStatus isCrossBorder={isCrossBorder} />}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayGeneralSection);
