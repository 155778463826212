import { ActionType, getType } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
const watchedSagas = [
  takeLatest(getType(actions.fetchNotifications), handleFetchNotifications)
];
export default watchedSagas;
export function* handleFetchNotifications(
  action: ActionType<typeof actions.fetchNotifications>
) {
  yield put(actions.setNotifications({ isFetching: true, notifications: [] }));

  yield put(
    actions.setNotifications({
      isFetching: false,
      notifications: [{ id: 1 }]
    })
  );
}
