import * as React from "react";
import * as classNames from "classnames";

import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import Dropdown from "reactstrap/lib/Dropdown";

import { FILTER_EXPORT_TYPES } from "./index";

type IProps = {
  icon: any;
  text: string;
  type: number;
  content?: any;
  dropdownOpen?: boolean;
  onClick?: any;
};

class FilterExportButton extends React.Component<IProps> {
  public render() {
    const { content, dropdownOpen, icon, text } = this.props;
    return (
      <Dropdown
        className="mr-1 mr-sm-4"
        isOpen={dropdownOpen}
        toggle={this.handleClick}
      >
        <DropdownToggle tag="button" className="btn btn-sm btn-filter-export">
          <div className="btn-content">
            {text}
            <img
              src={icon}
              className={classNames({
                "filter-icon": this.props.type === FILTER_EXPORT_TYPES.FILTER
              })}
            />
          </div>
        </DropdownToggle>
        {content && (
          <DropdownMenu
            tag="div"
            hidden={!dropdownOpen}
            className="dropdown-filter-export"
          >
            {content}
          </DropdownMenu>
        )}
      </Dropdown>
    );
  }

  private handleClick = (): void => {
    if (this.props.onClick) {
      this.props.onClick(this.props.type);
    }
  };
}

export default FilterExportButton;
