import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";
import { AccountProfile } from "src/ipm-shared/store/model/AccountProfile/reducers";

const AccountProfileUtil = {
  getAccountTypeString: (isBusiness: boolean): string => {
    return isBusiness ? "business" : "personal";
  },

  getWallexStatusText: (statusId: number, accountType: string): string => {
    switch (statusId) {
      case WALLEX_KYC_STATUS.REQUESTED:
      case WALLEX_KYC_STATUS.SIGNUP:
      case WALLEX_KYC_STATUS.UPDATE_USER_DETAIL:
      case WALLEX_KYC_STATUS.UPLOAD_DOCS:
      case WALLEX_KYC_STATUS.SCREENING:
      case WALLEX_KYC_STATUS.PENDING:
      case WALLEX_KYC_STATUS.REVIEW:
        return accountType === "personal"
          ? "Your identity is being verified to pay abroad."
          : "Your company details are being verified to pay abroad.";
      case WALLEX_KYC_STATUS.APPROVED:
        return "Good news - you are all set to pay abroad!";
      case WALLEX_KYC_STATUS.REJECTED:
      case WALLEX_KYC_STATUS.PENDING_VERIFICATION:
      case WALLEX_KYC_STATUS.PENDING_DOCS:
      case WALLEX_KYC_STATUS.PENDING_PHONE:
      case WALLEX_KYC_STATUS.DOCUMENTS_NEED_RESUBMISSION:
        return accountType === "personal"
          ? "Your identity verification has been declined to pay abroad."
          : "Your company details have been declined to pay abroad.";
      default:
        return "";
    }
  },

  getUserSettingObjectPropName: (
    prop: string
  ): keyof AccountProfile["userSettingRequired"] | "" => {
    switch (prop) {
      case "account_type":
        return "accountType";
      case "be_paid_tnc_confirmation":
        return "bePaidTncConfirmation";
      case "close_kyc_status_bar":
        return "closeKycStatusBar";
      case "credit_program_offer_v1_enabled":
        return "creditProgramOfferV1Enabled";
      case "credit_program_v1_notification":
        return "creditProgramV1Notification";
      case "cross_border_announce":
        return "crossBorder";
      case "cross_border_card_flag":
        return "crossBorderCardFlag";
      case "cross_border_new_icon":
        return "crossBorderNewIcon";
      case "dashboard_theme":
        return "dashboardTheme";
      case "edit_schedule_indicator":
        return "editScheduleIndicator";
      case "fb199":
        return "fb199";
      case "first_my_signup":
        return "firstMySignup";
      case "int_pay_enabled_notification":
        return "intPayEnabledNotification";
      case "iras_popup":
        return "irasPopup";
      case "my_credit_card":
        return "myCreditCard";
      case "next_day_payout":
        return "nextDayPayout";
      case "nric_photo":
        return "nricPhoto";
      case "payment_interest_confirmation":
        return "paymentInterestConfirmation";
      case "points_guarantee":
        return "pointsGuarantee";
      case "referral":
        return "referral";
      case "rentmay_promo":
        return "rentPromo";
      case "sg_myinfo":
        return "sgMyInfo";
      case "sg_tax_2019":
        return "sgTax2019";
      case "two_day_payout_announce":
        return "twoDayPayoutAnnouncement";
    }

    return "";
  }
};

export default AccountProfileUtil;
