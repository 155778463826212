import * as React from "react";
import * as Images from "src/ipm-shared/components/Images";
import { IProps } from "./index";
import { history } from "src/ipm-shared/store";
import * as classNames from "classnames";
import T from "src/ipm-shared/Utils/Intl";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";
import { ADD_FORM as PAYMENT_ADD_FORM } from "src/ipm-shared/store/model/Payment/const";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { IconIRASTooltip } from "src/ipm-shared/images/IconIRASTooltip";
import { IconClose } from "src/ipm-shared/images/IconClose";
import PersonalRentIcon from "src/ipm-shared/images/personal-rent-icon.svg";
import PersonalInvoiceIcon from "src/ipm-shared/images/personal-invoice-icon.svg";
import PersonalTaxIcon from "src/ipm-shared/images/personal-tax-icon.svg";
import PersonalMoneyTransferIcon from "src/ipm-shared/images/personal-moneytransfer-icon.svg";
import IconMoneyTransfer from "src/ipm-shared/images/money-transfer-icon.svg";
import PersonalMoreIcon from "src/ipm-shared/images/personal-new-more-icon.svg";
import {
  WALLEX_KYC_STATUS,
  COUNTRY_MULTIPLE_CURRENCY
} from "src/ipm-shared/store/model/OnBoarding/consts";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import { FormattedMessage } from "react-intl";

interface IState {
  isHiddenIRASTooltip: boolean;
}

class MakePaymentView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isHiddenIRASTooltip: localStorage.getItem("iras-tooltip-closed") === "yes"
    };
  }
  public render() {
    const {
      wallexKycStatus,
      isBusiness,
      isPersonal,
      hasCompany,
      isDisplayIrasPopup
    } = this.props;
    const isWallexKycApproved = wallexKycStatus === WALLEX_KYC_STATUS.APPROVED;
    const { countryCode, accountCountryCode } = this.props;

    const colItemStyle = classNames("col-item", { ["personal"]: isPersonal });

    const IrasTooltip = () => {
      if (
        countryCode === "SG" &&
        !this.state.isHiddenIRASTooltip &&
        isBusiness &&
        isDisplayIrasPopup
      ) {
        return (
          <div className="iras-tooltip d-flex">
            <span onClick={this.closeTooltip}>
              <IconClose />
            </span>
            <div
              className={"d-flex align-items-center"}
              onClick={this.goToIRASPayment}
            >
              <div className={"text-block"}>
                <h6>{T.transl("PAY_TAX_TOOLTIP")}</h6>
                <p className={"mb-0"}>
                  {T.transl("IRAS_TOOLTIP", {
                    b: (text: any) => <b>{text}</b>
                  })}
                </p>
              </div>
              <IconIRASTooltip />
            </div>
          </div>
        );
      }

      return null;
    };
    const Rent1 = () => (
      <li
        className={classNames(colItemStyle, "salaries")}
        onClick={this.props.toggleModal.bind(
          this,
          ModalID.PAYMENT_WORK_FLOW_INFO_MODAL
        )}
      >
        <div className="item">
          <div className="ribbon">
            <span className="text-uppercase">{T.transl("COMING_SOON")}</span>
          </div>
          <div className="item-content">
            <img
              src={isBusiness ? Images.rent_icon : PersonalRentIcon}
              alt={"Rent Illustration"}
            />
          </div>
          <span
            className={classNames(`btn btn-round`, {
              "btn-info": isBusiness,
              "btn-outline-info": isPersonal
            })}
          >
            {T.transl("RENT_BUTTON")}
          </span>
        </div>
      </li>
    );

    const Rent2 = () => (
      <li
        id="pay-rent-btn"
        onClick={this.goTo.bind(
          undefined,
          "/wizard/select/rent?action=add",
          !isBusiness || hasCompany
        )}
        className={colItemStyle}
      >
        <div className="item">
          <div className="item-content">
            <img
              src={isBusiness ? Images.rent_icon : PersonalRentIcon}
              alt={"Rent Illustration"}
            />
            <span>
              {!isBusiness || hasCompany
                ? T.transl("LABEL_PAY_YOUR_LANDLORD")
                : "Please register your company first"}{" "}
            </span>
          </div>
          <span
            className={classNames(`btn btn-round`, {
              "btn-info": isBusiness,
              "btn-outline-info": isPersonal,
              disabled: !hasCompany && isBusiness
            })}
          >
            <FormattedMessage id={"RENT_BUTTON"} />
          </span>
        </div>
      </li>
    );

    const Rent3 = () => (
      <li
        onClick={this.goToWallexFlow.bind(
          undefined,
          "/wizard/select/rent?action=add",
          !isBusiness || hasCompany
        )}
        id="pay-rent-btn"
        className={colItemStyle}
      >
        <div className="item">
          <div className="item-content">
            <img
              src={isBusiness ? Images.rent_icon : PersonalRentIcon}
              alt={"Rent Illustration"}
            />
            <span>
              {!isBusiness || hasCompany
                ? T.transl("LABEL_PAY_YOUR_LANDLORD")
                : "Please register your company first"}
            </span>
          </div>
          <span
            className={classNames(`btn btn-round`, {
              "btn-info": isBusiness,
              "btn-outline-info": isPersonal,
              disabled: !hasCompany && isBusiness
            })}
          >
            {T.transl("RENT_BUTTON")}
          </span>
        </div>
      </li>
    );

    const Salaries1 = () => (
      <li
        className="item salaries"
        onClick={this.props.toggleModal.bind(
          this,
          ModalID.PAYMENT_WORK_FLOW_INFO_MODAL
        )}
      >
        <div className="ribbon">
          <span>{T.transl("COMING_SOON")}</span>
        </div>
        <div className="item-content">
          <img src={Images.salaries_icon} alt={"salaries icon"} />
        </div>
        <span className="btn btn-round btn-info">
          {T.transl("SALARIES_BUTTON")}
        </span>
      </li>
    );

    const Salaries2 = () => (
      <li
        onClick={this.goTo.bind(
          undefined,
          "/wizard/select/salary?action=add",
          hasCompany
        )}
        className={colItemStyle}
      >
        <div className="item">
          <div className="item-content">
            <img src={Images.salaries_icon} alt={"salaries icon"} />
            <span>
              {hasCompany
                ? "Pay your employee"
                : "Please register your company first"}{" "}
            </span>
          </div>
          <span
            className={classNames("btn btn-round btn-info", {
              disabled: !hasCompany
            })}
          >
            {T.transl("SALARIES_BUTTON")}
          </span>
        </div>
      </li>
    );

    const Salaries3 = () => (
      <li
        onClick={this.goToWallexFlow.bind(
          undefined,
          "/wizard/select/salary?action=add",
          hasCompany
        )}
        className={colItemStyle}
      >
        <div className="item">
          <div className="item-content">
            <img src={Images.salaries_icon} alt={"salaries icon"} />
            <span>
              {hasCompany
                ? T.transl("LABEL_PAY_YOUR_EMPLOYEE")
                : T.transl("REGISTER_FIRST")}{" "}
            </span>
          </div>
          <span
            className={classNames("btn btn-round btn-info", {
              disabled: !hasCompany
            })}
          >
            {T.transl("SALARIES_BUTTON")}
          </span>
        </div>
      </li>
    );

    const Invoice1 = () => (
      <li
        className={classNames(colItemStyle, "disabled")}
        onClick={this.props.toggleModal.bind(
          this,
          ModalID.PAYMENT_WORK_FLOW_INFO_MODAL
        )}
      >
        <div className="item">
          <div className="ribbon">
            <span>COMING SOON</span>
          </div>
          <div className="item-content">
            <img
              src={isBusiness ? Images.invoices_icon : PersonalInvoiceIcon}
              alt={"invoices icon"}
            />
          </div>
          <span
            className={classNames(`btn btn-round`, {
              "btn-info": isBusiness,
              "btn-outline-info": isPersonal
            })}
          >
            {T.transl("INVOICES_BUTTON")}
          </span>
        </div>
      </li>
    );

    const renderInvoice2 = (onClick: () => void) => {
      return (
        <li className={colItemStyle}>
          <div className={"item"} onClick={onClick}>
            <div className="item-content">
              <img
                src={isBusiness ? Images.invoices_icon : PersonalInvoiceIcon}
                alt={"invoices icon"}
              />
              <span>{T.transl("LABEL_PAY_YOUR_SUPPLIER")}</span>
            </div>
            <span
              className={classNames(`btn btn-round`, {
                "btn-info": isBusiness,
                "btn-outline-info": isPersonal
              })}
            >
              {T.transl("INVOICES_BUTTON")}
            </span>
          </div>
          <IrasTooltip />
        </li>
      );
    };

    const Tax = () => (
      <li
        className={colItemStyle}
        onClick={this.goToIRASPayment}
        id="pay-tax-btn"
      >
        <div className="item">
          <div className="item-content">
            <img src={PersonalTaxIcon} alt={"tax icon"} />
            <span>{T.transl("LABEL_PAY_YOUR_TAX")}</span>
          </div>
          <span className="btn btn-round btn-outline-info">
            {T.transl("TAX_BUTTON")}
          </span>
        </div>
      </li>
    );

    const MoneyTransfer = () => (
      <li
        onClick={this.handleDirectMoneyTransfer}
        className={classNames(colItemStyle, {
          disabled: !isWallexKycApproved
        })}
      >
        <div className="item">
          <div className="item-content">
            <img
              src={isBusiness ? IconMoneyTransfer : PersonalMoneyTransferIcon}
              alt={T.transl("LABEL_MONEY_TRANSFER")}
            />

            <span>{T.transl("LABEL_MONEY_TRANSFER")}</span>
          </div>
          <span
            className={classNames("btn btn-round", {
              "btn-info": isBusiness,
              "btn-outline-info": isPersonal,
              disabled: false
            })}
          >
            {T.transl("MONEY_TRANSFER_BUTTON")}
          </span>
        </div>
      </li>
    );

    const More = (moreProps: any) => {
      const { isMoneyTransfer } = moreProps;

      return (
        <li
          className={classNames(colItemStyle, {
            "d-none": isBusiness,
            disabled: true
          })}
        >
          <div className="item">
            <div className="item-content">
              {isMoneyTransfer ? (
                <img
                  src={
                    isBusiness ? IconMoneyTransfer : PersonalMoneyTransferIcon
                  }
                  alt={T.transl("LABEL_MONEY_TRANSFER")}
                />
              ) : (
                <img
                  src={PersonalMoreIcon}
                  alt={"Personal more illustration"}
                />
              )}
              <span>Coming Soon</span>
            </div>
            <span
              className={classNames("btn btn-round", {
                "btn-info": isBusiness,
                "btn-outline-info": isPersonal,
                disabled: false
              })}
            >
              {T.transl("MORE_BUTTON")}
            </span>
          </div>
        </li>
      );
    };

    return (
      <ul
        className={classNames("services-items", {
          "personal-account": !isBusiness,
          // 4 items max
          "sg-int-pay-biz":
            isBusiness && accountCountryCode === "SG" && countryCode !== "SG"
        })}
      >
        <FeatureGate feature="RENT_WORK_FLOW" fallback={<Rent1 />}>
          <FeatureGate feature="WALLEX_FLOW" fallback={<Rent2 />}>
            <Rent3 />
          </FeatureGate>
        </FeatureGate>

        {isBusiness && countryCode !== "CN" && (
          <FeatureGate feature="SALARY_WORK_FLOW" fallback={<Salaries1 />}>
            <FeatureGate feature="WALLEX_FLOW" fallback={<Salaries2 />}>
              <Salaries3 />
            </FeatureGate>
          </FeatureGate>
        )}

        <FeatureGate feature="INVOICE_WORK_FLOW" fallback={<Invoice1 />}>
          <FeatureGate
            feature="WALLEX_FLOW"
            fallback={renderInvoice2(
              this.goTo.bind(undefined, "/wizard/select/invoice?action=add")
            )}
          >
            {renderInvoice2(
              this.goToWallexFlow.bind(
                undefined,
                "/wizard/select/invoice?action=add"
              )
            )}
          </FeatureGate>
        </FeatureGate>

        {isPersonal && countryCode === "SG" && <Tax />}

        {accountCountryCode === "SG" && countryCode !== "SG" && (
          <FeatureGate
            feature="SELF_TRANSFER_WORK_FLOW"
            fallback={<More isMoneyTransfer={false} />}
          >
            {countryCode === "CN" ? (
              <More isMoneyTransfer={true} />
            ) : (
              <MoneyTransfer />
            )}
          </FeatureGate>
        )}

        {(accountCountryCode === "HK" || accountCountryCode === "MY") && (
          <More isMoneyTransfer={false} />
        )}
      </ul>
    );
  }

  private closeTooltip = () => {
    this.setState({ isHiddenIRASTooltip: true });
    localStorage.setItem("iras-tooltip-closed", "yes");
    this.props.changeUserSetting({
      enable: false,
      property: "iras_popup",
      refreshDisable: true
    });
  };

  private goToIRASPayment = () => {
    this.props.fetchPayees({
      cb: payees => {
        this.props.closeModal(); // Close Modal Make Payment first

        const ids = payees
          .filter(payee => payee.ipaymy_event === "TAX2019")
          .map(p => {
            return parseInt(p.id, 10);
          });
        this.props.selectPayees({ purpose: "invoice", ids, removedIds: "all" });

        history.push("/wizard/summary/invoice/?action=add&directSummary=1");
      },
      offset: 0,
      showGlobalLoader: true,
      type: "invoice"
    });
  };

  private goToWallexFlow = (path: string, condition: boolean) => {
    const { countryCode } = this.props;
    if (condition) {
      // skip it for now cos we refreshed the page alr

      // When start a workflow,  need to remove old workflow state
      this.props.removeForm(PAYMENT_ADD_FORM);
      // history.push(path);
      this.props.closeModal(); // Close Modal Make Payment first
      if (COUNTRY_MULTIPLE_CURRENCY.indexOf(countryCode) > -1) {
        this.props.toggleModal(ModalID.CHOOSE_CURRENCY_MODAL, {
          countryCode,
          redirectPath: path
        });
      } else {
        this.props.closeModal();
        if (
          this.getCurrencyCode(countryCode) === this.props.currentCurrency &&
          countryCode === this.props.countryCode &&
          "SGD" === this.props.currentPaidCurrency
        ) {
          UrlHelper.redirect(path);
          return; // No need to exchange token cos it's alr there.
        }
        this.props.exchangeToken({
          countryCode,
          currencyCode: this.getCurrencyCode(countryCode),
          paidCurrencyCode: "SGD",
          purpose: "switch_country_wallex_flow",
          redirectPath: path
        });
      }
    }
  };

  private handleDirectMoneyTransfer = () => {
    const { wallexKycStatus } = this.props;

    if (wallexKycStatus === 0) {
      history.push("/onboarding/kyc");
    } else {
      if (wallexKycStatus <= WALLEX_KYC_STATUS.PENDING) {
        this.props.toggleModal(ModalID.KYC_STATUS_MODAL);
      } else if (
        wallexKycStatus === WALLEX_KYC_STATUS.REJECTED ||
        wallexKycStatus >= WALLEX_KYC_STATUS.DOCUMENTS_NEED_RESUBMISSION
      ) {
        this.props.toggleModal(ModalID.KYC_STATUS_MODAL);
      } else {
        this.goToWallexFlow(
          "/wizard/select/self_transfer?action=add",
          !this.props.isBusiness || this.props.hasCompany
        );
      }
    }
  };

  private goTo = (path: string, condition: boolean) => {
    if (condition) {
      // When start a workflow,  need to remove old workflow state
      this.props.removeForm(PAYMENT_ADD_FORM);
      // history.push(path);

      // TODO: use this as temporarily solution to clear cache

      if (window.stop) {
        window.stop();
      }
      window.location.href = path;
    }
  };

  private getCurrencyCode = (countryCode: string) => {
    const { countries } = this.props;
    return countries.filter(c => c.code === countryCode)[0].currency;
  };
}

export default MakePaymentView;
