import _includes from "lodash-es/includes";
import _filter from "lodash-es/filter";
import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import { ModalID } from "./actions";
import { ModalProps } from "reactstrap/lib/Modal";

export type GlobalUIState = {
  readonly opened: ModalID[];
  readonly showLoader: boolean;
  readonly extraInfo: {
    [modalId: number]: any;
  };
  readonly settings: Partial<ModalProps>;
  readonly loaderMessage?: string;
};

const defaultSettings = {
  backdrop: "static" as "static",
  keyboard: false
};

const defaultState: GlobalUIState = {
  extraInfo: {},
  opened: [], // Stack
  settings: defaultSettings,
  showLoader: false
};

export default (state = defaultState, action: types.Action) => {
  switch (action.type) {
    case getType(actions.toggleModal): {
      const contains = _includes(state.opened, action.payload.modalId);
      if (contains) {
        return {
          ...state,
          extraInfo: {
            ...state.extraInfo,
            [action.payload.modalId]: {}
          },
          opened: _filter(state.opened, action.payload.modalId),
          settings: action.payload.settings
            ? { ...defaultSettings, ...action.payload.settings }
            : defaultSettings
        };
      }
      return {
        ...state,
        extraInfo: {
          ...state.extraInfo,
          [action.payload.modalId]: action.payload.extraInfo
        },
        opened: [...state.opened, action.payload.modalId],
        settings: action.payload.settings
          ? { ...defaultSettings, ...action.payload.settings }
          : defaultSettings
      };
    }
    case getType(actions.closeModal): {
      const contains = _includes(state.opened, action.payload);
      if (contains) {
        return {
          ...state,
          extraInfo: {
            ...state.extraInfo,
            [action.payload]: {}
          },
          opened: _filter(state.opened, action.payload)
        };
      }
      return state;
    }
    case getType(actions.showGlobalLoader): {
      state = { ...state, loaderMessage: "" };
      if (state.showLoader) {
        return state; // No need to update. (Prevent flash on UI)
      }
      return { ...state, showLoader: true };
    }
    case getType(actions.hideGlobalLoader): {
      return { ...state, showLoader: false };
    }
    case getType(actions.updateExtraInfo): {
      return {
        ...state,
        extraInfo: {
          ...state.extraInfo,
          [action.payload.modalID]: action.payload.extraInfo
        }
      };
    }
    case getType(actions.resetAllModals): {
      return defaultState;
    }
    case getType(actions.updateLoaderMessage):
      return {
        ...state,
        loaderMessage: action.payload.loadMessage
      };
  }
  return state;
};
