import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "./actions";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state)
});

const mapDispatchToProps = {
  fetchAccountProfiles: actions.fetchAccountProfiles,
  // fetchCardBrands: actions.fetchCardBrands,
  fetchCards: actions.fetchCards,
  fetchCompanies: actions.fetchCompanies,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  // fetchCountries: actions.fetchCountries,
  // fetchCurrencies: actions.fetchCurrencies,
  // fetchDateConditions: actions.fetchDateConditions,
  // fetchNotifications: actions.fetchNotifications,
  fetchEscalations: actions.fetchEscalations,
  start: actions.start
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
