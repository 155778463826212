/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { ExtraPayee, PayeeType, ExtraInfo } from "./reducers";
import { PURPOSE } from "./const";

export const bulkAddPayeeSubmit = createAction(
  "payees/BULK_ADD_PAYEE_SUBMIT",
  resolve => (purpose: PURPOSE, csvData: string[][], ids: string[]) =>
    resolve({ purpose, csvData, ids })
);
export const addSupplierSubmit = createAction(
  "payees/ADD_SUPPLIER_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const editSupplierSubmit = createAction(
  "payees/EDIT_SUPPLIER_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const addLandlordSubmit = createAction(
  "payees/ADD_LANDLORD_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const editLandlordSubmit = createAction(
  "payees/EDIT_LANDLORD_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const addEmployeeSubmit = createAction(
  "payees/ADD_EMPLOYEE_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const editEmployeeSubmit = createAction(
  "payees/EDIT_EMPLOYEE_SUBMIT",
  resolve => (getAllPayees?: boolean) => resolve({ getAllPayees })
);
export const addSelfTransferSubmit = createAction(
  "payees/ADD_SELF_TRANSFER_SUBMIT"
);
export const editSelfTransferSubmit = createAction(
  "payees/EDIT_SELF_TRANSFER_SUBMIT"
);

// note to supplier
export const noteToSupplier = createAction(
  "payees/NOTE_TO_SUPPLIER_FORM",
  resolve => (purpose: PURPOSE) => resolve({ purpose })
);

export const fetchPayees = createAction(
  "payees/FETCH",
  resolve => (args: {
    type: PURPOSE;
    offset?: number;
    cb?: (payees: any[]) => void;
    showGlobalLoader?: boolean;
    getAllPayees?: boolean;
  }) => resolve(args)
);

export const deletePayee = createAction(
  "payees/DELETE",
  resolve => (id: number, purpose: PURPOSE, getAllPayees?: boolean) =>
    resolve({ id, purpose, getAllPayees })
);

export const hidePayee = createAction(
  "payees/HIDE",
  resolve => (id: number, purpose: PURPOSE, getAllPayees?: boolean) =>
    resolve({ id, purpose, getAllPayees })
);

export const setPayees = createAction(
  "payees/SET",
  resolve => (args: {
    isFetching: boolean;
    purpose: PURPOSE;
    payees?: PayeeType[];
    total: number;
  }) => resolve(args)
);

export const selectPayees = createAction(
  "payees/SELECT",
  resolve => (args: {
    purpose: PURPOSE;
    ids: number[];
    removedIds: number[] | "all";
  }) => resolve(args)
);

export const resetSelectedPayees = createAction(
  "payees/RESET_SELECTED",
  resolve => (purpose: PURPOSE) => resolve({ purpose })
);

export const editPayee = createAction(
  "payees/SELECT_TO_EDIT",
  resolve => (id: number) => resolve(id)
);

export const updateExtraPayee = createAction(
  "payees/UPDATE_EXTRA_PAYEE",
  resolve => (payeeId: number, uid: string, payee: ExtraPayee) =>
    resolve({ payeeId, uid, payee })
);

export const updateExtraPayees = createAction(
  "payees/UPDATE_EXTRA_PAYEES",
  resolve => (payees: object) => resolve({ payees })
);

export const deleteExtraPayee = createAction(
  "payees/DELETE_EXTRA_PAYEE",
  resolve => (payeeId: number, uid: string) => resolve({ payeeId, uid })
);

export const deleteExtraPayeesByPayeeId = createAction(
  "payees/DELETE_EXTRA_PAYEES_BY_PAYEE_ID",
  resolve => (id: number) => resolve({ id })
);

export const checkValidNoteToRecipient = createAction(
  "payees/CHECK_VALID",
  resolve => (
    value: string,
    defaultCommentsFieldName: string,
    cb: (arg?: any) => void
  ) => resolve({ value, defaultCommentsFieldName, cb })
);

export const checkPayee = createAction(
  "payees/CHECK_PAYEE",
  resolve => (args: {
    payeeId: number;
    purpose: PURPOSE;
    cb?: (extraInfo: ExtraInfo) => void;
  }) => resolve(args)
);

export const setExtraInfo = createAction(
  "payee/SET_EXTRA_INFO",
  resolve => (args: {
    payeeId: number;
    extraInfo: ExtraInfo;
    purpose: PURPOSE;
  }) => resolve(args)
);

export const updatePayeeCurrencyId = createAction(
  "payee/UPDATE_PAYEE_CURRENCY_ID",
  resolve => (purpose: PURPOSE, currencyId: number) =>
    resolve({ purpose, currencyId })
);

export const updateEditingPayeeCurrencyId = createAction(
  "payee/UPDATE_EDITING_PAYEE_CURRENCY_ID",
  resolve => (purpose: PURPOSE, currencyId: number | undefined) =>
    resolve({ purpose, currencyId })
);
