import { connect } from "react-redux";
import GlobalModal from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "../selectors";
import * as actions from "../actions";

const mapStateToProps = (state: RootState) => ({
  isBusiness: selectors.getIsBusinessAccount(state),
  isOpen: selectors.hasOpenedModal(state),
  modalId: selectors.getMostRecentModalId(state),
  settings: selectors.getSettings(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);
