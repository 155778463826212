import * as React from "react";
import { NavLink } from "react-router-dom";
import * as Images from "src/ipm-shared/components/Images";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { IProps } from "./index";
import T from "src/ipm-shared/Utils/Intl";

interface IState {
  dropdownOpen: boolean;
}

class NavDropdownView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public render() {
    return (
      <Dropdown
        className="user-profile"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle tag="span" className="user-name btn-action">
          <span className="user-profile-image">
            <img src={Images.avatar_user} alt="Avatar User" />
          </span>
          {this.props.accountProfile.displayedFirstName &&
            this.props.accountProfile.displayedFirstName.length > 0 && (
              <span className="mr-2 fullname">
                {T.transl("NEW_GREETING_NAVBAR", {
                  render: (
                    <React.Fragment>
                      {this.props.accountProfile.displayedFirstName}
                    </React.Fragment>
                  )
                })}
              </span>
            )}
          <span className="arrow-down" />
        </DropdownToggle>
        {this.renderMenu()}
      </Dropdown>
    );
  }

  private toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  private renderMenu() {
    if (this.state.dropdownOpen) {
      return (
        <DropdownMenu tag="ul">
          <DropdownItem tag="li" className="business-account account-block">
            <img src={Images.avatar_user} alt="Profile image" />
            <div className="account-info">
              <h6>
                {this.props.accountProfile.displayedFirstName}{" "}
                {this.props.accountProfile.displayedLastName}
              </h6>
              <p>{this.props.accountProfile.displayedEmail}</p>
              {this.props.accountProfile.type === "business" && (
                <span className="text-primary">
                  {T.transl("TOP_ACCOUNT_MENU_BUSINESS")}
                </span>
              )}
              {this.props.accountProfile.type === "personal" && (
                <span className="text-primary">
                  {T.transl("TOP_ACCOUNT_MENU_PERSONAL")}
                </span>
              )}
            </div>
          </DropdownItem>
          <DropdownItem className="profile-item mb-2 d-none" tag="li">
            <a href="">{T.transl("TOP_ACCOUNT_MENU_MY_ACCOUNT")}</a>
          </DropdownItem>
          {this.props.secondaryAccountProfiles.map(profile => (
            <DropdownItem
              onClick={this.props.switchAccount.bind(
                this,
                profile.displayedEmail
              )}
              key={profile.displayedEmail}
              tag="li"
              className="personal-account account-block"
            >
              <img src={Images.avatar_user} alt="Profile image" />
              <div className="account-info">
                <h6>
                  {profile.displayedFirstName} {profile.displayedLastName}
                </h6>
                <p>{profile.displayedEmail}</p>
                {profile.type === "business" && (
                  <a href="#">ipaymy Business Account</a>
                )}
                {profile.type === "personal" && (
                  <a href="#">ipaymy Personal Account</a>
                )}
              </div>
            </DropdownItem>
          ))}
          <DropdownItem tag="li" className="change-password-block">
            <NavLink
              className="btn text-info btn-block p-0 text-left py-2"
              to="/change-password"
            >
              {T.transl("TOP_ACCOUNT_CHANGE_PASSWORD")}
            </NavLink>
          </DropdownItem>
          <DropdownItem tag="li" className="actions-block">
            <NavLink
              onClick={this.props.addAccount}
              className="btn btn-sm btn-info btn-bepaid"
              to="/login"
              hidden={true}
            >
              {T.transl("TOP_ACCOUNT_ADD")}
            </NavLink>
            <a onClick={this.logout} href="#" className="btn btn-sm btn-logout">
              {T.transl("TOP_ACCOUNT_SIGN_OUT")}
            </a>
          </DropdownItem>
        </DropdownMenu>
      );
    }
    return null;
  }

  private logout = () => {
    window.location.href = window.location.origin + "/login";
  };
}

export default NavDropdownView;
