import * as React from "react";
import Tooltip from "reactstrap/lib/Tooltip";
import { TooltipProps } from "reactstrap/lib/Tooltip";
import IconQuestion from "src/ipm-shared/images/IconQuestion";
import IconQuestionError from "src/ipm-shared/images/IconQuestionError";
import * as uuidv1 from "uuid/v1";

interface IState {
  tooltip: boolean;
  uuid: string;
}

export type IProps = TooltipProps & {
  label?: string | React.Component | JSX.Element;
  description?: string | React.Component | JSX.Element;
  errorType?: boolean;
  displayIcon?: boolean;
  autoHide?: boolean;
  iconColor?: string; // primary | danger | info | normal
};

class ToolTip extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tooltip: false,
      uuid: uuidv1()
    };
  }

  public render() {
    const {
      label,
      description,
      placement,
      className,
      innerClassName,
      errorType,
      target,
      displayIcon,
      iconColor,
      delay
    } = this.props;
    const uuid = this.state.uuid;
    return (
      <>
        {label && label}
        {displayIcon &&
          (errorType ? (
            <span
              id={target === "random" ? `tt-${uuid}` : (target as string)}
              style={{ position: "relative", top: "3px" }}
            >
              <IconQuestionError className="ml-1" />
            </span>
          ) : (
            <span
              id={target === "random" ? `tt-${uuid}` : (target as string)}
              style={{ position: "relative", top: "1px" }}
            >
              <IconQuestion className={`ml-1 ${iconColor}`} />
            </span>
          ))}
        <Tooltip
          placement={placement || "top"}
          delay={delay || 0}
          isOpen={this.state.tooltip}
          target={target === "random" ? `tt-${uuid}` : (target as string)}
          toggle={this.tooltip}
          autoHide={false}
          innerClassName={innerClassName}
          className={className}
        >
          {description}
        </Tooltip>
      </>
    );
  }

  private tooltip = () => {
    this.setState({
      tooltip: !this.state.tooltip
      // tooltip: true  // Enable to styling
    });
  };
}

export default ToolTip;
