import * as React from "react";
import ClonedValue from "src/ipm-shared/components/Form/helpers/ClonedValue";
import InputText from "src/ipm-shared/components/Form/controls/InputText";

type IProps = {
  paymentIds: number[];
};

class SelectedPayment extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <ClonedValue name="selected_payment">
        {(value: string) => {
          if (!value) {
            return null;
          }

          const nextId = this.getNextPayments(parseInt(value, 10));

          return (
            <InputText name="next_selected_payment" defaultValue={nextId} />
          );
        }}
      </ClonedValue>
    );
  }

  private getNextPayments = (currentId: number) => {
    const paymentIds = this.props.paymentIds;
    const idx = paymentIds.indexOf(currentId);
    let nextIdx = idx + 1;

    if (nextIdx >= paymentIds.length) {
      nextIdx = 0;
    }

    return paymentIds[nextIdx];
  };
}

export default SelectedPayment;
