/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import {createBrowserHistory} from "history"
export const setToken = createAction(
  "auth/SET_TOKEN",
  resolve => (args: { token: string }) => resolve(args)
);

export const setSmsOtpToken = createAction(
  "auth/SET_SMS_OTP_TOKEN",
  resolve => (args: { smsOtpToken: string }) => resolve(args)
);

export const logout = createAction("auth/LOGOUT");

export const addAccount = createAction("auth/ADD_ACCOUNT");

export const switchAccount = createAction(
  "auth/SWITCH_ACCOUNT",
  resolve => (email: string) => resolve({ email })
);

export const changePasswordSubmit = createAction(
  "auth/CHANGE_PASSWORD",
  resolve => (successCallback: () => void) => resolve({ successCallback })
);

export const exchangeToken = createAction(
  "auth/EXCHANGE_TOKEN",
  resolve => (args: {
    countryCode?: string;
    companyId?: number;
    currencyCode?: string;
    paidCurrencyCode?: string;
    preventDefault?: boolean;
    redirectPath?: string;
    purpose?: string;
  }) => resolve(args)
);

export const register = createAction(
  "auth/REGISTER",
  resolve => (
    history: ReturnType<typeof createBrowserHistory>,
    recaptchaToken: string,
    accountType: "business" | "personal"
  ) => resolve({ history, recaptchaToken, accountType })
);

export const loginSubmit = createAction(
  "auth/SUBMIT",
  resolve => (redirectUrl: string, defaultAccount?: number) =>
    resolve({ defaultAccount, redirectUrl })
);

export const requestVerifyEmail = createAction("auth/REQUEST_VERIFY_EMAIL");

export const requestResendVerifyEmail = createAction(
  "login/REQUEST_RESEND_VERIFY_EMAIL",
  resolve => () => resolve()
);

export const resetEmailVerificationRequired = createAction(
  "login/RESET_VERIFICATION_REQUIRED",
  resolve => () => resolve()
);

export const verifyMobile = createAction(
  "login/VERIFY_MOBILE",
  resolve => (smsOtp: string) => resolve({ smsOtp })
);

export const requestResendVerifyMobile = createAction(
  "login/REQUEST_RESEND_VERIFY_MOBILE",
  resolve => (cb: () => void) => resolve({ cb })
);

export const setDisplayIntPayReminderPopup = createAction(
  "login/SET_DISPLAY_INT_PAY_REMINDER_POPUP",
  resolve => () => resolve()
);
