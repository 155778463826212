/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { BusinessType, BusinessIndustryType } from "./reducers";

export const fetchBusinessTypes = createAction("businessType/FETCH");

export const setBusinessTypes = createAction(
  "businessType/SET",
  resolve => (args: { isFetching: boolean; businessTypes: BusinessType[] }) =>
    resolve(args)
);

export const fetchBusinessIndustries = createAction("businessIndustries/FETCH");

export const setBusinessIndustries = createAction(
  "businessIndustries/SET",
  resolve => (args: {
    isFetching: boolean;
    businessIndustries: BusinessIndustryType[];
  }) => resolve(args)
);
