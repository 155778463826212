import * as React from "react";
import * as Images from "src/ipm-shared/components/Images";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";

interface IState {
  dropdownOpen: boolean;
}

class Notification extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public render() {
    return (
      <Dropdown
        className="notify-block mr-4"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        tag="div"
      >
        <DropdownToggle tag="span" className="btn-notify">
          <img src={Images.notify_icon} width="20" alt="Notify Icon" />
          <span className="notify-count">2</span>
        </DropdownToggle>

        <DropdownMenu
          tag="div"
          className="dropdown-notification dropdown-normalize"
        >
          <h5 className="headline">Notifications</h5>
          <ul className="notification-section">
            <li className="dropdown-item unread">
              <h6>Your card is expiring soon. (Card ending - 20000)</h6>
              <span className="timestamp">Just now</span>
            </li>
            <li className="dropdown-item">
              <h6>You have drafted a payment, complete it now.</h6>
              <span className="timestamp">1 day ago</span>
            </li>
            <li className="dropdown-item">
              <h6>
                Your payment has been made successfully!Your payment has been
                made successfully!Your payment has been made successfully!Your
                payment has been made successfully!
              </h6>
              <span className="timestamp">2 days ago</span>
            </li>
            <li className="dropdown-item">
              <h6>
                Your payment has been made successfully Your payment has been
                made successfullyYour payment has been made successfullyYour
                payment has been made successfully!
              </h6>
              <span className="timestamp">2 days ago</span>
            </li>
            <li className="dropdown-item">
              <h6>Welcome to ipaymy!</h6>
              <span className="timestamp">1 week ago</span>
            </li>
            <li className="dropdown-item">
              <h6>You have drafted a payment, complete it now.</h6>
              <span className="timestamp">1 day ago</span>
            </li>
            <li className="dropdown-item">
              <h6>Your payment has been made successfully!</h6>
              <span className="timestamp">2 days ago</span>
            </li>
            <li className="dropdown-item">
              <h6>Your payment has been made successfully!</h6>
              <span className="timestamp">2 days ago</span>
            </li>
            <li className="dropdown-item">
              <h6>Welcome to ipaymy!</h6>
              <span className="timestamp">1 week ago</span>
            </li>
          </ul>
          <div className="notifications-see-all d-none">See all</div>
        </DropdownMenu>
      </Dropdown>
    );
  }

  private toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
}

export default Notification;
