import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

type Notification = {
  id: number;
};

export type NotificationsState = {
  readonly isFetching: boolean;
  readonly byId: { [id: string]: Notification };
};

const defaultState: NotificationsState = {
  byId: {},
  isFetching: false
};

export default (state: NotificationsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setNotifications):
      const m = {};
      action.payload.notifications.forEach(notification => {
        m[notification.id] = notification;
      });
      return {
        ...state,
        byId: m
      };
  }
  return state;
};
