import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import { FormState, ControlErrorType, ControlType } from "./types";
import _isEmpty from "lodash-es/isEmpty";
import _uniq from "lodash-es/uniq";
import _remove from "lodash-es/remove";
import _concat from "lodash-es/concat";

const defaultControl = {
  errors: [],
  form: "default",
  ignoredErrors: [],
  value: undefined
};
const defaultState: FormState = {
  controls: {},
  forms: {},
  submitButton: {
    isSubmitting: false
  }
};

export default (state = defaultState, action: types.Action) => {
  switch (action.type) {
    case getType(actions.setControl):
      const { control } = action.payload;
      let oldControl = {};
      let newControl = {};

      if (!_isEmpty(state.controls[control.name as string])) {
        oldControl = { ...state.controls[control.name as string] };
      }

      newControl = { ...defaultControl, ...oldControl, ...control };

      return {
        ...state,
        controls: {
          ...state.controls,
          [control.name as string]: newControl
        }
      };
    case getType(actions.setErrors):
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.payload.name as string]: {
            ...state.controls[action.payload.name],
            displayError: true,
            errors: action.payload.errors
          }
        }
      };

    case getType(actions.setControlPattern):
      const newControlState = { ...state.controls };
      for (const name in state.controls) {
        if (action.payload.control.name.test(name)) {
          newControlState[name] = {
            ...newControlState[name],
            ...action.payload.control,
            name // override action.payload.control.name which is regexp
          };
        }
      }

      return {
        ...state,
        controls: newControlState
      };

    case getType(actions.appendControlValue):
      const presentedValue = action.payload.value;

      if (typeof presentedValue !== "string") {
        // Only accept string value
        return state;
      }

      let oldC = {};
      let newC = {} as ControlType;

      if (!_isEmpty(state.controls[action.payload.name as string])) {
        oldC = { ...state.controls[action.payload.name as string] };
      }

      newC = {
        ...defaultControl,
        ...oldC,
        name: action.payload.name
      };

      let items = newC.value ? (newC.value as string).split(",") : [];
      items.push(presentedValue);
      items = _uniq(items);

      let value = "";
      if (items.length > 0) {
        value = items.join(",");
      }

      newC.value = value;
      if (action.payload.cb) {
        action.payload.cb(items);
      }

      return {
        ...state,
        controls: {
          ...state.controls,
          [action.payload.name as string]: newC
        }
      };

    case getType(actions.spliceControlValue):
      const removedPresentedValue = action.payload.value;

      if (typeof removedPresentedValue !== "string") {
        // Only accept string value
        return state;
      }

      let oldCtrl = {};
      let newCtrl = {} as ControlType;

      if (!_isEmpty(state.controls[action.payload.name as string])) {
        oldCtrl = { ...state.controls[action.payload.name as string] };
      }

      newCtrl = {
        ...defaultControl,
        ...oldCtrl,
        name: action.payload.name
      };

      let its = newCtrl.value ? (newCtrl.value as string).split(",") : [];
      its = _remove(its, i => removedPresentedValue !== i);
      its = _uniq(its);

      let v = "";
      if (its.length > 0) {
        v = its.join(",");
      }

      newCtrl.value = v;
      if (action.payload.cb) {
        action.payload.cb(its);
      }

      return {
        ...state,
        controls: {
          ...state.controls,
          [action.payload.name as string]: newCtrl
        }
      };

    case getType(actions.removeForm):
      const aclonedControls = { ...state.controls };
      Object.keys(aclonedControls)
        .filter(c => aclonedControls[c].form === action.payload)
        .map(c => {
          delete aclonedControls[c];
        });

      delete state.forms[action.payload];

      return {
        ...state,
        controls: {
          ...aclonedControls
        }
      };

    case getType(actions.removeGroup):
      const bclonedControls = { ...state.controls };
      Object.keys(bclonedControls)
        .filter(c => bclonedControls[c].group === action.payload)
        .map(c => {
          delete bclonedControls[c];
        });

      return {
        ...state,
        controls: bclonedControls
      };

    case getType(actions.removeControl):
      const controlLists = { ...state.controls };

      delete controlLists[action.payload];

      return {
        ...state,
        controls: {
          ...controlLists
        }
      };

    case getType(actions.removeControlPattern):
      const newControlState1 = { ...state.controls };
      for (const name in state.controls) {
        if (action.payload.test(name)) {
          delete newControlState1[name];
        }
      }

      return {
        ...state,
        controls: newControlState1
      };

    case getType(actions.parseServerErrors):
      const { controls, form, formErrors } = action.payload;

      const newControls = { ...state.controls } || {};
      controls.map((c: { name: string; errors: ControlErrorType[] }) => {
        if (c.name in newControls) {
          newControls[c.name] = {
            ...newControls[c.name],
            displayError: true,
            errors: c.errors
          };
        } else {
          newControls[c.name] = {
            displayError: true,
            errors: c.errors,
            form,
            ignoredErrors: [],
            name: c.name,
            value: undefined
          };
        }
      });

      return {
        ...state,
        controls: newControls,
        forms: {
          ...state.forms,
          [form]: {
            errors: formErrors
          }
        }
      };

    case getType(actions.resetErrors):
      const clonedControls = { ...state.controls } || {};
      Object.keys(clonedControls).map(name => {
        if (action.payload.form === clonedControls[name].form) {
          clonedControls[name] = { ...clonedControls[name], errors: [] };
        }
      });

      return {
        ...state,
        controls: clonedControls
      };

    case getType(actions.resetFormErrors):
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.form]: {
            ...state.forms[action.payload.form],
            errors: []
          }
        }
      };

    case getType(actions.resetControlErrors):
      const clonedControls2 = { ...state.controls } || {};
      Object.keys(clonedControls2).map(name => {
        if (action.payload.name === name) {
          clonedControls2[name] = {
            ...clonedControls2[name],
            errors: []
          };
        }
      });

      return {
        ...state,
        controls: clonedControls2
      };

    case getType(actions.setSubmitButtonState):
      return {
        ...state,
        submitButton: {
          isSubmitting: action.payload.isSubmitting
        }
      };

    case getType(actions.ignoreError):
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.payload.control.name]: {
            ...state.controls[action.payload.control.name],
            ignoredErrors: _uniq(
              _concat(
                state.controls[action.payload.control.name].ignoredErrors,
                action.payload.control.errorCode
              )
            )
          }
        }
      };

    case getType(actions.removeIgnoredError):
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.payload.control.name]: {
            ...state.controls[action.payload.control.name],
            ignoredErrors: state.controls[
              action.payload.control.name
            ].ignoredErrors.filter(e => e !== action.payload.control.errorCode)
          }
        }
      };

    case getType(actions.displayControlErrors):
      const displayedControls = {};
      Object.keys(state.controls).map(name => {
        displayedControls[name] = {
          ...state.controls[name],
          displayError: true
        };
      });
      return {
        ...state,
        controls: displayedControls
      };
  }
  return state;
};
