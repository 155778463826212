export const ACQUIRER_ID = {
  ADYEN_PROD: 2,
  ADYEN_TEST: 1,
  CHECKOUT_PROD: 22,
  CHECKOUT_TEST: 21,
  STRIPE_PROD: 4,
  STRIPE_PROD_HK: 8,
  STRIPE_PROD_HK_TP1: 10,
  STRIPE_PROD_MASTERCARD_VIP13: 12,
  STRIPE_PROD_MY: 6,
  STRIPE_TEST: 3,
  STRIPE_TEST_HK: 7,
  STRIPE_TEST_HK_TP1: 9,
  STRIPE_TEST_MASTERCARD_VIP13: 11,
  STRIPE_TEST_MY: 5,
  WORLDPAY_SG_TEST: 81,
  WORLDPAY_SG_LIVE: 82,
  WORLDPAY_AU_TEST: 91,
  WORLDPAY_AU_LIVE: 92,
  WORLDPAY_HK_TEST: 93,
  WORLDPAY_HK_LIVE: 94,
  WORLDPAY_MY_TEST: 95,
  WORLDPAY_MY_LIVE: 96
};
export const ADD_FORM = "add_adyen_card_form";
export const CHECKOUT_FORM = "checkout_form";
export const ADD_CARD_FORM = "add_card_form";
