import * as React from "react";
import { IProps } from "./index";
import PageBase from "src/ipm-platform/pages/Base";
import DayPicker from "react-day-picker";
import { format } from "date-fns";
import _get from "lodash-es/get";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import T from "src/ipm-shared/Utils/Intl";
// import validate = WebAssembly.validate;

class CashFlowCalendar extends PageBase<IProps, any> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    const {
      outComingPayments,
      sumPaymentInThisMonth,
      accountCurrencyId
    } = this.props;

    const modifiers = {
      outComingPayments: outComingPayments.map(day => new Date(day))
    };

    return (
      <div className="cash-flow-block">
        <DayPicker
          className={"cash-flow-calendar"}
          modifiers={modifiers}
          showOutsideDays={true}
          weekdaysShort={["S", "M", "T", "W", "T", "F", "S"]}
          onMonthChange={this.handleMonthYearChange}
        />
        <div className={"cash-flow-describe"}>
          <div className={"top-section"}>
            <h6 className={"head-title"}>{T.transl("PAY_CALENDAR_TITLE")}</h6>
            <p className={"copy"}>{T.transl("PAY_CALENDAR_SUBTITLE")}</p>
            <div className={"currency"}>
              {CurrencyUtil.convertFromId(accountCurrencyId).$}
              <b>
                <ShortCurrency
                  value={_get(sumPaymentInThisMonth, "completed", 0)}
                />
              </b>
            </div>
          </div>
          <ul>
            <li className={"out-coming"}>
              <span>
                {T.transl("LEGEND_OUTGOING_PAYMENT", {
                  b: (text: any) => <b>{text}</b>
                })}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  private handleMonthYearChange = (date: Date) => {
    const month = parseInt(format(new Date(date), "M"), 10);
    const year = parseInt(format(new Date(date), "YYYY"), 10);
    this.props.fetchPaymentCalendar({ month, year });
  };
}

export default CashFlowCalendar;
