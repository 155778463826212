// declare var feather: any;
import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as classnames from "classnames";
import * as Pages from "src/ipm-platform/pages";
import requireComponent from "src/ipm-shared/HOCs/ComponentLoader";
import { IProps } from "./index";
import Header from "./Header";
import HeaderAdmin from "./HeaderAdmin";
import Menu from "./Menu";
import MenuAdmin from "./MenuAdmin";
import requireAuthorize from "src/ipm-shared/components/Authorize/HOC";
import PersonalAccountMenu from "./PersonalAccountMenu";
import GlobalNotification from "./GlobalNotification";
import SelectLanguageMobile from "src/ipm-shared/components/SelectLanguageMobile/View";

const Container: React.SFC<IProps & {
  adminStyle: boolean;
  isAppReady: boolean;
  isPersonalAccount: boolean;
  isBusinessAccount: boolean;
}> = props =>
  !props.isAppReady ? null : (
    <div
      className={classnames("logged-page", {
        "admin-page": props.adminStyle,
        "user-page": !props.adminStyle
      })}
    >
      {props.adminStyle ? <HeaderAdmin /> : <Header />}
      <div className="container-fluid">
        <div className="row">
          {props.adminStyle ? (
            <MenuAdmin />
          ) : (
            (props.isPersonalAccount && <PersonalAccountMenu />) ||
            (props.isBusinessAccount && <Menu />)
          )}

          <main
            role="main"
            className={classnames("dashboard-page container-page", {
              "business-account": props.isBusinessAccount,
              "dashboard-admin": props.adminStyle,
              "personal-account": !props.isBusinessAccount
            })}
          >
            <div className={"dashboard-language-dropdown-mobile"}>
              <SelectLanguageMobile
                dropdownId={"DashboardSelectLanguageMobile"}
              />
            </div>

            <Switch
              key={props.location && props.location.key}
              location={props.location}
            >
              <Route path="/" exact={true} component={Pages.DashboardPage} />
              <Route
                path="/wizard/add/:purpose"
                exact={true}
                component={requireComponent(Pages.WizardPayeeAdd)}
              />
              <Route
                path="/wizard/select/:purpose"
                exact={true}
                component={requireComponent(Pages.WizardSelect)}
              />
              <Route
                path="/wizard/schedule/:purpose"
                exact={true}
                component={requireComponent(Pages.WizardSchedule)}
              />
              <Route
                path="/wizard/summary/:purpose"
                exact={true}
                component={requireComponent(Pages.WizardSummary)}
              />
              <Route
                path="/wizard/verification/:purpose"
                exact={true}
                component={requireComponent(Pages.WizardVerification)}
              />
              <Route
                path="/companies"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.CompaniesPage),
                  "__BUSINESS_ACCOUNT__"
                )}
              />
              <Route
                path="/properties"
                exact={true}
                component={requireComponent(Pages.PropertiesPage)}
              />
              <Route
                path="/invoices"
                exact={true}
                component={requireComponent(Pages.InvoicesPage)}
              />
              <Route
                path="/employees"
                exact={true}
                component={requireComponent(Pages.EmployeesPage)}
              />
              <Route
                path="/schedules"
                exact={true}
                component={requireComponent(Pages.MySchedules)}
              />
              <Route
                path="/payments"
                exact={true}
                component={requireComponent(Pages.MyPayments)}
              />
              <Route
                path="/wallet"
                exact={true}
                component={requireComponent(Pages.MyWalletPage)}
              />
              <Route
                path="/change-password"
                exact={true}
                component={requireComponent(Pages.ChangePasswordPage)}
              />
              <Route
                path="/checkout/:token"
                exact={true}
                component={requireComponent(Pages.CheckoutPage)}
              />
              <Route
                path="/history"
                component={requireComponent(Pages.PaymentCollections)}
              />
              <Route
                path="/customers"
                component={requireComponent(Pages.Customers)}
              />
              <Route
                path="/customer/add"
                component={requireComponent(Pages.CustomerAdd)}
              />
              <Route
                path="/upload-invoice/invoice-details"
                exact={true}
                component={requireComponent(Pages.CustomerInvoiceDetails)}
              />
              <Route
                path={"/collected-account/create"}
                component={requireComponent(Pages.CreateCollectedAccount)}
              />
              <Route
                path={"/collected-account/settings"}
                component={requireComponent(Pages.CollectedAccountSettings)}
              />
              <Route
                path={"/onboarding/kyc/step3"}
                component={requireComponent(Pages.OnBoardingStep3)}
              />
              <Route
                path={"/onboarding/kyc/step2"}
                component={requireComponent(Pages.OnBoardingStep2)}
              />
              <Route
                path={"/onboarding/kyc/step1"}
                component={requireComponent(Pages.OnBoardingStep1)}
              />
              <Route
                path={"/onboarding/kyc"}
                exact={true}
                component={requireComponent(Pages.OnBoarding)}
              />
              <Route
                path={"/invoice-creation/create"}
                exact={true}
                component={requireComponent(Pages.BePaidCreateInvoice)}
              />
              <Route
                path={"/invoice-creation/preview"}
                exact={true}
                component={requireComponent(Pages.BePaidPreviewInvoice)}
              />
              <Route
                path={"/invoice-creation"}
                exact={true}
                component={requireComponent(Pages.BePaidInvoiceCreation)}
              />
              <Route
                path={"/sms-request"}
                exact={true}
                component={requireComponent(Pages.BePaidSmsRequest)}
              />
              <Route
                path={"/upload-invoice/select-customer"}
                exact={true}
                component={requireComponent(Pages.BePaidUploadInvoice)}
              />
              <Route
                path="/referral-program"
                exact={true}
                component={requireComponent(Pages.ReferralProgram)}
              />
              <Route
                path="/admin/users"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminUser),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/account_rates"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminAccountRates),
                  "__ADMIN__"
                )}
              />
              {/*<Route*/}
              {/*  path="/admin/coupons"*/}
              {/*  exact={true}*/}
              {/*  component={requireComponent(Pages.AdminCoupon)}*/}
              {/*/>*/}
              <Route
                path="/admin/payments"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminPayment),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/operations"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminOperations),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/international"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminInternational),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/promo_code"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminPromoCode),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/payment_check"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminCheckPayment),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/collected_account"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminCollectedAccount),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/uen"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminUEN),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/worldpay-test"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.WorldpayTest),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/reconciliations"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminReconciliations),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/screening"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminScreening),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/bank"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminConfirmPayout),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/escalation"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminEscalation),
                  "__ADMIN__"
                )}
              />
              <Route
                path="/admin/referrals"
                exact={true}
                component={requireAuthorize(
                  requireComponent(Pages.AdminReferral),
                  "__ADMIN__"
                )}
              />
              <Route component={requireComponent(Pages.NotFound)} />
            </Switch>
          </main>
        </div>
      </div>
      <GlobalNotification />
    </div>
  );

type IState = {
  isAppReady: boolean;
};
class AppView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isAppReady: this.props.hasToken
    };

    props.start();
    props.fetchAccountProfiles();
    props.fetchEscalations();
    if (props.isBusinessAccount) {
      props.fetchCompanies();
    }
    props.fetchMainCollectedAccount();

    props.fetchCards();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.location !== prevProps.location) {
      window.vgo("process");
      window.vgo("update");
    }
  }

  public render() {
    const isAdminPage = !!(
      this.props.location && this.props.location.pathname.includes("/admin/")
    );
    if (!this.props.hasToken) {
      return null;
    }

    return (
      <Container
        {...this.props}
        adminStyle={isAdminPage}
        isPersonalAccount={this.props.isPersonalAccount}
        isBusinessAccount={this.props.isBusinessAccount}
        isAppReady={this.state.isAppReady}
      />
    );
  }
}

export default AppView;
