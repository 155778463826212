import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./../../selectors";
import * as actions from "./../../actions";
import * as guiActions from "src/ipm-shared/components/GlobalUI/actions";

const mapStateToProps = (state: RootState) => ({
  brandsById: selectors.brandsById(state),
  cardsById: selectors.cardsById(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  isBusiness: selectors.getIsBusinessAccount(state)
});

const mapDispatchToProps = {
  selectPayment: actions.selectPayment,
  toggleModal: guiActions.toggleModal.bind(
    guiActions,
    guiActions.ModalID.PAYMENT_DETAIL
  )
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & {
    isCrossBorder?: boolean;
    payments: ReturnType<typeof selectors.getPayments>;
    nullText: JSX.Element;
    status?: string;
    getNoteColumnWidth: (value: number) => void;
    noteColumnWidth: number;
  };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
