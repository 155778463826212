import { RootState } from "../reducers";
import countries from "./reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
ReducerFactory.registerReducer({ countries });

// Currency

export const getCountryIdFromCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case "SG":
      return 1;
    case "MY":
      return 2;
    case "HK":
      return 3;
    default:
      return 0;
  }
};

export const getCountryCodeFromCountryId = (
  state: RootState,
  countryId: number
): string => {
  if (state.countries.byId[countryId]) {
    return state.countries.byId[countryId].code;
  }

  return "";
};

export const getCurrencyCodeFromCountryId = (
  state: RootState,
  countryId: number
): string => {
  if (state.countries.byId[countryId]) {
    return state.countries.byId[countryId].currency;
  }

  return "";
};

export const getCountriesById = (state: RootState) => {
  return state.countries.byId;
};

export const getAllCountries = (state: RootState) => {
  return Object.keys(state.countries.byId).map(id => state.countries.byId[id]);
};

// Country
export const getCountryOptions = (state: RootState) =>
  Object.keys(state.countries.byId)
    .map(id => state.countries.byId[id])
    .map(country => ({
      currency: country.currency,
      id: country.id,
      label: country.name,
      value: country.code
    }));

export const getCountryFromCountryId = (
  state: RootState,
  countryId?: number
) => {
  if (countryId && state.countries.byId[countryId]) {
    return state.countries.byId[countryId];
  }
  return null;
};
