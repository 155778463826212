import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "../selectors";
import * as actions from "../actions";

const mapStateToProps = (state: RootState) => ({
  collectionRates: selectors.getCollectionRates(state),
  dashboardThemeName: selectors.getDashboardTheme(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  hasActiveProgram: selectors.getHasActiveProgram(state),
  isBusiness: selectors.getIsBusinessAccount(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & {
    payments: ReturnType<typeof selectors.getPaymentCollections>;
    nullText: JSX.Element;
    status?: string;
  };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
