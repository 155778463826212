import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type EscalationCommentType = {
  id: number;
  escalationId: number;
  comments: string;
  commentedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type Escalation = {
  id: number;
  userId: number;
  createdBy: number;
  countryId: number;
  email: string;
  natureId: number;
  date: string;
  comments: string;
  typeId: number;
  statusId: number;
  numberOfHitsOnTheParty: string;
  nameAndAliasOfParty: string;
  categoryTransaction: string;
  receiptNumber: string;
  categoryOnboarding: string;
  reason?: string;
  description?: string;
  chargeDate?: string;
  payoutDate?: string;
  paymentTotal?: number;
  paymentId?: number;
};

export type EscalationsState = {
  readonly isFetching: boolean;
  readonly escalations: Escalation[];
  readonly total: number;
  readonly byId: { [id: string]: Escalation };
  readonly selectedId: number;
  readonly editedId: number;
  readonly selectedEscalationComments: EscalationCommentType[];
  readonly newEscalationsCommentsCount: { [id: string]: number }[];
};

const defaultState: EscalationsState = {
  byId: {},
  escalations: [],
  total: 0,
  editedId: -1,
  isFetching: false,
  selectedId: 0,
  selectedEscalationComments: [],
  newEscalationsCommentsCount: []
};

export default (state: EscalationsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setEscalations):
      const m = {};
      action.payload.escalations?.forEach(escalation => {
        m[escalation.id] = escalation;
      });

      return {
        ...state,
        escalations: action.payload.escalations
          ? action.payload.escalations
          : state.escalations,
        total: action.payload.total,
        byId: m,
        isFetching: action.payload.isFetching,
        selectedId: action.payload.selectedId
      };
    case getType(actions.setSelectedEscalationComments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        selectedEscalationComments: action.payload.selectedEscalationComments
      };

    case getType(actions.selectEscalation):
      return {
        ...state,
        selectedId: action.payload.id
      };

    case getType(actions.setNewEscalationCommentsCount):
      const newEscalationCommentsCount =
        action.payload.newEscalationCommentsCount;
      const escalationId = Object.keys(newEscalationCommentsCount)[0];
      const newCount = newEscalationCommentsCount[escalationId];

      const existingItem = state.newEscalationsCommentsCount.find(
        item => Object.keys(item)[0] === escalationId
      );

      const updatedCommentsCount = existingItem
        ? state.newEscalationsCommentsCount.map(item =>
            Object.keys(item)[0] === escalationId
              ? { [escalationId]: newCount }
              : item
          )
        : [...state.newEscalationsCommentsCount, newEscalationCommentsCount];

      return {
        ...state,
        newEscalationsCommentsCount: updatedCommentsCount
      };

    case getType(actions.setEscalationDetail):
      const updatedEscalations = state.escalations.map(escalation =>
        escalation.id === action.payload.escalationId
          ? {
              ...escalation,
              statusId: action.payload.statusId
            }
          : escalation
      );

      return {
        ...state,
        isFetching: false,
        escalations: updatedEscalations
      };
  }
  return state;
};
