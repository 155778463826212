/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { CustomerType, ExtraCustomer } from "./reducers";
import { CUSTOMERS_PAGE_COUNT } from "./const";

export const fetchCustomers = createAction(
  "customers/FETCH",
  resolve => (
    offset: number = 0,
    smsCustomers: string = "n",
    acceptEmptyData?: boolean,
    forceRefresh: boolean = true,
    setDefaultSelected: boolean = false,
    pageCount: number = CUSTOMERS_PAGE_COUNT,
    getAll: string = "n"
  ) =>
    resolve({
      acceptEmptyData,
      forceRefresh,
      getAll,
      offset,
      pageCount,
      setDefaultSelected,
      smsCustomers
    })
);

export const setCustomers = createAction(
  "customers/SET",
  resolve => (args: {
    isFetching: boolean;
    customers: CustomerType[];
    total: number;
  }) => resolve(args)
);

export const appendCustomers = createAction(
  "customers/APPEND",
  resolve => (args: { isFetching: boolean; customers: CustomerType[] }) =>
    resolve(args)
);

export const addCustomer = createAction(
  "customer/ADD",
  resolve => (
    setDefaultSelected: boolean = false,
    isFetchAll: boolean = false,
    setPageCount: number = CUSTOMERS_PAGE_COUNT,
    toCreateOrUploadInvoice?: "sms" | "upload" | "create" | "none",
    successCallback?: (customerId: number) => void
  ) =>
    resolve({
      isFetchAll,
      setDefaultSelected,
      setPageCount,
      successCallback,
      toCreateOrUploadInvoice
    })
);

export const editCustomerId = createAction(
  "customer/EDIT",
  resolve => (id: number) => resolve({ id })
);

export const editCustomer = createAction(
  "customer/SUBMIT_EDIT",
  resolve => (toCreateOrUploadInvoice?: "sms" | "upload" | "create" | "none") =>
    resolve({ toCreateOrUploadInvoice })
);

export const deleteCustomer = createAction(
  "customer/DELETE",
  resolve => (id: number) => resolve({ id })
);

export const selectCustomers = createAction(
  "customer/SELECT",
  resolve => (ids: number[]) => resolve(ids)
);

export const updateExtraCustomer = createAction(
  "customer/UPDATE_EXTRA_CUSTOMER",
  resolve => (customerId: number, uid: string, customer: ExtraCustomer) =>
    resolve({ customerId, uid, customer })
);

export const updateExtraCustomers = createAction(
  "customer/UPDATE_EXTRA_CUSTOMERS",
  resolve => (customers: object) => resolve({ customers })
);

export const deleteExtraCustomer = createAction(
  "customer/DELETE_EXTRA_CUSTOMER",
  resolve => (customerId: number, uid: string) => resolve({ customerId, uid })
);

export const deleteExtraCustomersByCustomerId = createAction(
  "customer/DELETE_EXTRA_CUSTOMERS_BY_CUSTOMER_ID",
  resolve => (id: number) => resolve({ id })
);

export const invoiceDetailSubmitted = createAction(
  "customer/INVOICE_DETAIL_SUBMIT"
);
