import * as React from "react";
import { IProps } from "./index";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import Slider from "react-slick";
import * as Images from "src/ipm-shared/components/Images";
import CardBankIcon from "src/ipm-shared/components/Images/CardBankIcon";
import CardTypeIcon from "src/ipm-shared/components/Images/CardTypeIcon";
import CardUtils from "src/ipm-shared/Utils/Card";
import TooltipInfo from "src/ipm-shared/components/Tooltip";
import T from "src/ipm-shared/Utils/Intl";

const cardSlider = {
  dots: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500
};

class CardSlider extends React.Component<IProps> {
  public render() {
    const { hasCrossBorderPayment } = this.props;
    return (
      <div className="slider-card-section">
        {this.props.cards.length > 0 ? (
          <div className="slider-card-items">
            <Slider {...cardSlider}>
              {this.props.allCards.map((card: any, idx: number) => (
                <React.Fragment key={`${card.id}-${idx}`}>
                  <div className="card-item-block has-card">
                    <div className="card-item">
                      <div className="card-head-logo">
                        <CardBankIcon bankId={card.bankId} nullText={<div />} />
                        <CardTypeIcon brandId={card.brandId} />
                      </div>
                      <div className="card-infomation">
                        <div className="card-number">
                          <span className="label">
                            {T.transl("CARD_IMAGE_NUMBER")}
                          </span>
                          <span className="value">{`**** **** **** ${card.last4}`}</span>
                          {hasCrossBorderPayment && (
                            <span className={"card-flag-icon"}>
                              {CardUtils.isSGCard(card.acquirerId) && (
                                <span className={"flag-icon flag-icon-sg"} />
                              )}
                              {CardUtils.isMyCard(card.acquirerId) && (
                                <span className={"flag-icon flag-icon-my"} />
                              )}
                              {CardUtils.isHKCard(card.acquirerId) && (
                                <span className={"flag-icon flag-icon-hk"} />
                              )}
                              {(CardUtils.isSGCard(card.acquirerId) ||
                                CardUtils.isMyCard(card.acquirerId) ||
                                CardUtils.isHKCard(card.acquirerId)) && (
                                <TooltipInfo
                                  className={
                                    "country-card-flag-tt general-tooltip"
                                  }
                                  target="random"
                                  innerClassName={"text-left"}
                                  placement={"bottom"}
                                  displayIcon={true}
                                  description={T.transl(
                                    "CARD_SLIDER_AUTHORIZED_TOOLTIP"
                                  )}
                                />
                              )}
                            </span>
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="card-name-exp-section justify-content-between w-100">
                            <div className="card-name mr-3">
                              <span className="label">
                                {T.transl("CARD_IMAGE_HOLDER")}
                              </span>
                              <span className="value">
                                {card.name.toUpperCase()}
                              </span>
                            </div>
                            <div className="card-exp">
                              <span className="label">
                                {T.transl("CARD_IMAGE_EXPIRY")}
                              </span>
                              <span className="value">
                                {card.expiryMonth}/{card.expiryYear}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-section">
                    <button
                      onClick={this.openModal}
                      className="btn btn-round btn-sm btn-info"
                    >
                      {T.transl("WALLET_NEW_CARD_BUTTON")}
                    </button>
                    <button
                      hidden={!CardUtils.isAdyen(this.props.acquirerId)}
                      className="btn btn-round btn-sm btn-outline-info ml-3"
                      onClick={this.editCard.bind(this, card.id)}
                    >
                      {T.transl("CARD_SLIDER_BUTTON")}
                    </button>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="slider-card-items no-card-yet">
            <div className="card-item-block has-card">
              <div onClick={this.openModal} className="card-item">
                <div className="card-head-logo">
                  <img
                    width={60}
                    className="card-brand"
                    src={Images.logo}
                    alt="Card Icon"
                  />
                  <img
                    className="card-icon"
                    // src={
                    //   CardUtils.isMyCard(this.props.acquirerId)
                    //     ? Images.adyen_card_exp
                    //     : Images.card_exp
                    // }
                    src={Images.adyen_card_exp}
                    alt="Card Icon"
                  />
                </div>
                <div className="card-infomation">
                  <span className="card-number">
                    {T.transl("CARD_SLIDER_ADD_INSTRUCTIONS")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="card-name-exp-section justify-content-between w-100">
                    <div className="card-name mr-3">
                      <span className="label">
                        {T.transl("CARD_IMAGE_HOLDER")}
                      </span>
                      <span className="value">
                        {this.props.accountProfile.firstName}{" "}
                        {this.props.accountProfile.lastName}
                      </span>
                    </div>
                    <div className="card-exp d-flex flex-column flex-grow-0">
                      <span className="label no-wrap">
                        {T.transl("CARD_IMAGE_EXPIRY")}
                      </span>
                      <span className="value">{"** / **"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    this.props.fetchCards();
  }

  private openModal = () => {
    const { accountProfile, selectCardToEdit, toggleModal } = this.props;
    selectCardToEdit({ id: -1 });

    // If malaysian user must have NRIC photos ID
    if (
      (accountProfile.countryCode.toUpperCase() === "MY" ||
        accountProfile.countryCode.toUpperCase() === "AU") &&
      accountProfile.userSettingRequired.nricPhoto
    ) {
      toggleModal(ModalID.NRIC_ID_PHOTOS_UPLOAD);
    } else {
      toggleModal(ModalID.ADD_CARD_FORM);
    }
  };

  private editCard = (id: number) => {
    if (CardUtils.isAdyen(this.props.acquirerId)) {
      this.props.selectCardToEdit({ id });
      this.props.toggleModal(ModalID.ADD_CARD_FORM);
    } else {
      // Cannot edit Stripe card
    }
  };
}

export default CardSlider;
