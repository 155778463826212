/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import {
  CollectedAccountState,
  CollectionRates,
  InvoiceLineItemType,
  ItemType,
  MainCollectedAccountData,
  TaxType
} from "./reducers";
import {
  COLLECTED_ACCOUNT_REVIEW_TYPE,
  COLLECTED_ACCOUNT_STATUS
} from "./const";
import { OtherSettingsCollectedAccount } from "./reducers";

export const createCollectedAccount = createAction(
  "collectedAccount/CREATE",
  resolve => (draft?: boolean) => resolve({ draft })
);

export const updateCollectedAccount = createAction(
  "collectedAccount/UPDATE",
  resolve => (args: { feeSettings: CollectionRates[] }) => resolve(args)
);

export const updateAdditionalInvoiceSetting = createAction(
  "collectedAccount/UPDATE_ADDITIONAL_INVOICE_SETTING"
);

export const fetchNextInvoiceNumber = createAction(
  "collectedAccount/FETCH_NEXT_INVOICE_NUMBER"
);

export const setupInvoiceNumber = createAction(
  "collectedAccount/SET_UP_INVOICE_NUMBER"
);

export const fetchTaxes = createAction(
  "collectedAccount/FETCH_TAXES",
  resolve => (uid?: string, forceRefresh?: boolean) =>
    resolve({ uid, forceRefresh })
);

export const setTaxes = createAction(
  "collectedAccount/SET_TAXES",
  resolve => (args: { isFetching: boolean; taxes: TaxType[] }) => resolve(args)
);

export const addNewTax = createAction(
  "collectedAccount/ADD_NEW_TAX",
  resolve => (args: {
    uid: string;
    fromModalID?: number;
    cb?: (id: number) => void;
  }) => resolve(args)
);

export const fetchItems = createAction(
  "collectedAccount/FETCH_ITEMS",
  resolve => (uid?: string, forceRefresh?: boolean) =>
    resolve({ uid, forceRefresh })
);

export const setItems = createAction(
  "collectedAccount/SET_ITEMS",
  resolve => (args: { isFetching: boolean; items: ItemType[] }) => resolve(args)
);

export const addNewItem = createAction(
  "collectedAccount/ADD_NEW_ITEM",
  resolve => (uid: string) => resolve({ uid })
);

export const editItem = createAction(
  "collectedAccount/EDIT_ITEM",
  resolve => (uid: string, itemId: number) => resolve({ uid, itemId })
);

export const updateInvoiceLineItem = createAction(
  "collectedAccount/UPDATE_INVOICE_LINE_ITEM",
  resolve => (args: { uid: string; item: InvoiceLineItemType }) => resolve(args)
);

export const deleteInvoiceLineItem = createAction(
  "collectedAccount/DELETE_INVOICE_LINE_ITEM",
  resolve => (uid: string) => resolve({ uid })
);

export const selectItemId = createAction(
  "collectedAccount/SELECT_ITEM_ID",
  resolve => (args: { name: string; id: number }) => resolve(args)
);

export const deleteSelectItemId = createAction(
  "collectedAccount/DELETE_SELECTED_ITEM_ID",
  resolve => (name: string) => resolve({ name })
);

export const selectTaxId = createAction(
  "collectedAccount/SELECT_TAX_ID",
  resolve => (args: { name: string; id: number }) => resolve(args)
);

export const deleteSelectedTaxId = createAction(
  "collectedAccount/DELETE_SELECTED_TAX_ID",
  resolve => (name: string) => resolve({ name })
);

export const setCollectedAccounts = createAction(
  "collectedAccount/SET_COLLECTED_ACCOUNTS",
  resolve => (
    collectedAccounts: CollectedAccountState["list"],
    total: number
  ) => resolve({ collectedAccounts, total })
);

export const fetchAdminCollectedAccounts = createAction(
  "collectedAccount/FETCH_ADMIN_COLLECTED_ACCOUNTS",
  resolve => (
    status: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS],
    offset?: number,
    pageCount?: number
  ) => resolve({ status, offset, pageCount })
);

export const updateCollectedAccountStatus = createAction(
  "collectedAccount/UPDATE_COLLECTED_ACCOUNT_STATUS",
  resolve => (
    id: number,
    prevStatus: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS],
    status: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS],
    reviewType: typeof COLLECTED_ACCOUNT_REVIEW_TYPE[keyof typeof COLLECTED_ACCOUNT_REVIEW_TYPE]
  ) => resolve({ id, prevStatus, status, reviewType })
);

export const updateCollectedAccountDocument = createAction(
  "collectedAccount/UPDATE_COLLECTED_ACCOUNT_DOCUMENT"
);

export const displayLoading = createAction(
  "collectedAccount/DISPLAY_LOADING",
  resolve => (value: boolean) => resolve({ value })
);

export const fetchMainCollectedAccount = createAction(
  "collectedAccount/FETCH_MAIN",
  resolve => () => resolve()
);

export const setMainCollectedAccount = createAction(
  "collectedAccount/SET_MAIN_COLLECTED_ACCOUNT",
  resolve => (ca: {
    data?: MainCollectedAccountData;
    hasActiveProgram: boolean;
    isFetched: boolean;
    rates: CollectionRates;
  }) => resolve(ca)
);

export const updateOtherSettingsCollectedAccount = createAction(
  "collectedAccount/UPDATE_OTHER_SETTINGS_COLLECTED_ACCOUNT",
  resolve => (args: OtherSettingsCollectedAccount) => resolve(args)
);

export const updateNextNumberInvoiceCollectedAccount = createAction(
  "collectedAccount/UPDATE_NEXT_NUMBER_INVOICE_COLLECTED_ACCOUNT",
  resolve => (nextNumber: string) => resolve({ nextNumber })
);

export const setHasActiveProgram = createAction(
  "collectedAccount/SET_HAS_ACTIVE_PROGRAM",
  resolve => (args: {
    hasActiveProgram: boolean;
    requesterInternationalRate?: number;
    requesterNationalRate?: number;
  }) => resolve(args)
);

export const checkHasActiveProgram = createAction(
  "collectedAccount/HAS_ACTIVE_PROGRAM",
  resolve => (cb?: () => void) => resolve({ cb })
);

export const adminListCollectedAccount = createAction(
  "adminUser/LIST_COLLECTED_ACCOUNT",
  resolve => (userId: number) => resolve({ userId })
);

export const setListCollectedAccount = createAction(
  "collectedAccount/SET_LIST_COLLECTED_ACCOUNT",
  resolve => (collectedAccounts: CollectedAccountState["list"]) =>
    resolve({ collectedAccounts })
);
