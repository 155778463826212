import * as React from "react";
import { IProps } from "./index";
import InputText from "src/ipm-shared/components/Form/controls/InputText";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { LOGIN_FORM } from "src/ipm-shared/store/model/Auth/const";
import { NavLink } from "react-router-dom";
import * as queryString from "query-string";
import { ControlErrorType } from "src/ipm-shared/components/Form/types";
import PageBase from "src/ipm-platform/pages/Base";

import GuestFooter from "src/ipm-shared/components/GuestFooter";
import GuestHeader from "src/ipm-shared/components/GuestHeader";

import T from "src/ipm-shared/Utils/Intl";

class LoginView extends PageBase<IProps, any> {
  private qs: any = {};
  public constructor(props: IProps) {
    super(props);
    props.logout();
    const qs = queryString.parse(window.location.search);
    if (qs.email) {
      this.qs.defaultEmail = qs.email;
    }
    this.qs.verified = qs.verified;

    this.qs.redirectUrl = qs.redirect_url;
    this.qs.utmOrigin = qs.utm_origin ? qs.utm_origin : qs.origin;
    this.qs.utmCampaign = qs.utm_campaign;
    if (qs.def_acct) {
      this.qs.defaultAccount = parseInt(qs.def_acct as string, 10);
    }
    this.qs.utm = this.qs.utmOrigin
      ? `?utm_origin=${this.qs.utmOrigin}&utm_campaign=${this.qs.utmCampaign}`
      : "";
  }

  public render() {
    return (
      <div className="login-page auth-page">
        <div className="container">
          <GuestHeader />
          <div className="content-section">
            <form
              className="login-form auth-form"
              onSubmit={this.handleLoginSubmit}
            >
              {/* <div className="headeline">
                <h6>{T.transl("SIGNIN_HERO")}</h6>
                <div>
                  {T.transl("SIGNUP_SIGNIN_OPTION", {
                    // @ts-ignore
                    styling1: text => (
                      <a href={`/register${this.qs.utm}`}>{text}</a>
                    )
                  })}
                </div>
              </div> */}
              <div className="login-form-content">
                {(() => {
                  switch (this.qs.verified) {
                    case "EXPIRED_EMAIL_TOKEN":
                    case "EMAIL_ALREADY_VERIFIED_ERROR":
                    case "INVALID_EMAIL_TOKEN":
                      return (
                        <div className="alert alert-warning" role="alert">
                          {T.transl(this.qs.verified)}
                        </div>
                      );
                    case "ERR":
                      return (
                        <div className="alert alert-warning" role="alert">
                          {T.transl("INVALID_EMAIL_TOKEN")}
                        </div>
                      );
                    case "OK":
                      return (
                        <div className="alert alert-primary" role="alert">
                          {T.transl("EMAIL_VERIFIED")}
                        </div>
                      );
                    default:
                      return null;
                  }
                })()}
                <FormErrors
                  ignoreErrors={[
                    "TOO_MANY_MOBILE_PHONE_VERIFICATION_ERROR",
                    "TOO_MANY_RESEND_MOBILE_PHONE_VERIFICATION_ERROR"
                  ]}
                  form={LOGIN_FORM}
                  className="small mb-2"
                />
                <div className="form-group">
                  <span className="label">Email</span>
                  <InputText
                    onChangeCustom={this.props.resetEmailVerificationRequired}
                    name="email"
                    placeholder={T.translText("EMAIL_PLACEHOLDER")}
                    form={LOGIN_FORM}
                    deboundBrowerAutocomplete={true}
                    defaultValue={
                      this.qs.defaultEmail ? this.qs.defaultEmail : undefined
                    }
                    id={"email"}
                    reserveValueOnUnmount={true}
                    autoFocus={true}
                  />
                </div>
                <div className="form-group">
                  <span className="label">Password</span>
                  <InputText
                    onChangeCustom={this.props.resetEmailVerificationRequired}
                    form={LOGIN_FORM}
                    name="password"
                    className="mb-0"
                    hideValue={true}
                    placeholder={T.translText("PASSWORD_PLACEHOLDER")}
                    overrideMessage={this.customMessage}
                    id={"password"}
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="cb-remember"
                      />
                      <label
                        htmlFor="cb-remember"
                        className="custom-control-label"
                      >
                        {T.transl("LABEL_REMEMBER_ME")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  {this.props.isEmailVerificationRequired ? (
                    <button
                      type="button"
                      className="btn btn-info btn-round"
                      onClick={this.props.requestResendVerifyEmail}
                    >
                      {T.transl("RESEND_EMAIL_BUTTON")}
                    </button>
                  ) : (
                    <button
                      className="btn-bepaid btn btn-info btn-sm btn-login w-100"
                      id="sign-in-mobile"
                    >
                      {T.transl("SIGNIN_BUTTON")}
                    </button>
                  )}
                </div>

                <div className="xero-account-confirm mb-1 text-center">
                  Don't have an account?{" "}
                  <a href={`/register${this.qs.utm}`}>{"sign up"}</a>
                </div>

                <div className="xero-account-confirm mb-4 text-center">
                  {T.transl("FORGOT_PASSWORD_INSTRUCTIONS", {
                    RESET_PASSWORD_LINK: (
                      <a href="/reset-password/request">
                        {T.transl("RESET_PASSWORD_LINK")}
                      </a>
                    )
                  })}
                  {/*{Is.SGVisitor() && (*/}
                  {/*  <>*/}
                  {/*    <br />*/}
                  {/*    {T.transl("XERO_ACCESS_INSTRUCTIONS", {*/}
                  {/*      styling1: (t: string) => (*/}
                  {/*        <a href="https://dashboard.ipaymy.com">{t}</a>*/}
                  {/*      )*/}
                  {/*    })}*/}
                  {/*  </>*/}
                  {/*)}*/}
                </div>
              </div>

              {/* <div className="xero-account-confirm show-desktop">
                {T.transl("FORGOT_PASSWORD_INSTRUCTIONS", {
                  RESET_PASSWORD_LINK: (
                    <a href="/reset-password/request">
                      {T.transl("RESET_PASSWORD_LINK")}
                    </a>
                  )
                })}
              </div> */}

              {/* <div className="d-flex justify-content-center show-desktop">
                {this.props.isEmailVerificationRequired ? (
                  <button
                    type="button"
                    className="btn btn-info btn-round"
                    onClick={this.props.requestResendVerifyEmail}
                  >
                    {T.transl("RESEND_EMAIL_BUTTON")}
                  </button>
                ) : (
                  <button
                    className="btn btn-info btn-round btn-login"
                    id="sign-in"
                  >
                    {T.transl("SIGNIN_BUTTON")}
                  </button>
                )}
              </div> */}
            </form>
          </div>

          <GuestFooter />
        </div>
      </div>
    );
  }

  private handleLoginSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.props.loginSubmit(this.qs.redirectUrl, this.qs.defaultAccount);
  };

  private customMessage = (error: ControlErrorType) => {
    switch (error.code) {
      case "INVALID_CREDENTIALS":
        return (
          <span>
            {T.transl(error.message as string)}{" "}
            <NavLink to={"/reset-password/request"}>
              {" "}
              {T.transl("RESET_PASSWORD_LINK")}{" "}
            </NavLink>
          </span>
        );
    }

    return error.message;
  };
}

export default LoginView;
