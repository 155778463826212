import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "./actions";
import * as authActions from "src/ipm-shared/store/model/Auth/actions";
import * as selectors from "src/ipm-shared/store/model/Auth/selectors";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(),
  isEmailVerificationRequired: selectors.isEmailVerificationRequired(state)
});

const mapDispatchToProps = {
  loginSubmit: actions.loginSubmit,
  logout: authActions.logout,
  requestResendVerifyEmail: actions.requestResendVerifyEmail,
  resetEmailVerificationRequired: actions.resetEmailVerificationRequired,
  toggleModal: actions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & { qs: { [param: string]: any } };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
