import { ActionType, getType } from "typesafe-actions";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { RootState } from "../reducers";
import RestClient from "src/ipm-shared/services/Rest";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as onboardingSelectors from "src/ipm-shared/store/model/OnBoarding/selectors";
import { KYC_FORM, RESIDENTIAL_STATUS } from "./consts";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as commonActions from "src/ipm-shared/store/model/actions";
import * as onBoardingActions from "./actions";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import FilesUtil from "src/ipm-shared/Utils/Files";
import { history } from "src/ipm-shared/store";

const selectors = {
  ...formSelectors,
  ...accountProfileSelectors,
  ...onboardingSelectors
};

const actions = {
  ...commonActions,
  ...formActions,
  ...onBoardingActions
};

const watchedSagas = [
  takeLatest(getType(actions.wallexKycPersonal), handleWallexKycPersonal),
  takeLatest(getType(actions.wallexKycBusiness), handleWallexKycBusiness),
  takeEvery(getType(actions.paymentCalculator), handlePaymentCalculator),
  reTryTakeLatest(actions.getAddressByPostalCode, handleGetAddressByPostalCode),
  takeEvery(actions.fetchEmployeeStuff, handleFetchEmployeeStuff),
  reTryTakeLatest(actions.fetchWallexKycData, handleFetchWallexKycData)
];
export default watchedSagas;

export function* handleFetchWallexKycData(
  action: ActionType<typeof actions.fetchWallexKycData>
) {
  yield put(
    actions.setWallexKycData({
      metadata: [],
      step: 0
    })
  );

  const res: Response = yield call(RestClient.send, {
    service: "get_wallex_kyc_info"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data = _get(res, "data.kyc", {});
    const step =
      _get(data, "step") === null || _get(data, "step") === undefined
        ? 0
        : _get(data, "step");
    yield put(
      actions.setWallexKycData(
        {
          metadata:
            data !== null
              ? Object.keys(data.metadata).map((id: any) => {
                  const md = data.metadata[id];
                  return {
                    isDirectorShareHolder: md.is_director_share_holder,
                    isMain: md.is_main,
                    kycDocuments: {
                      bAcraBizfile: _get(
                        md.kyc_documents,
                        "b_acra_bizfile",
                        null
                      ),
                      bBoardResolution: _get(
                        md.kyc_documents,
                        "b_board_resolution",
                        null
                      ),
                      bLetterOfAuthorization: _get(
                        md.kyc_documents,
                        "b_letter_of_authorization",
                        null
                      ),
                      // bPoba: _get(md.kyc_documents, "b_poba", null),
                      bStructuredChart: _get(
                        md.kyc_documents,
                        "b_structured_chart",
                        null
                      ),
                      backNric: _get(md.kyc_documents, "back_nric", null),
                      frontNric: _get(md.kyc_documents, "front_nric", null),
                      frontPassport: _get(
                        md.kyc_documents,
                        "front_passport",
                        null
                      ),
                      holdingNricPhoto: _get(
                        md.kyc_documents,
                        "holding_nric_photo",
                        null
                      ),
                      holdingPassportPhoto: _get(
                        md.kyc_documents,
                        "holding_passport_photo",
                        null
                      ),
                      poa: _get(md.kyc_documents, "poa", null)
                    },
                    userDetail: {
                      countryCode: md.user_detail.country_code,
                      countryOfBirth: md.user_detail.country_of_birth,
                      countryOfResidence: md.user_detail.country_of_residence,
                      dateOfBirth: md.user_detail.date_of_birth,
                      employeeIndustry: md.user_detail.employee_industry,
                      employeePosition: md.user_detail.employee_position,
                      employeeStatus: md.user_detail.employee_status,
                      expiryDate: md.user_detail.expiry_date,
                      firstName: md.user_detail.first_name,
                      gender: md.user_detail.gender,
                      identificationNumber:
                        md.user_detail.identification_number,
                      identificationType: md.user_detail.identification_type,
                      issueDate: md.user_detail.issue_date,
                      lastName: md.user_detail.last_name,
                      mobileCountryCode: md.user_detail.mobileCountry_code,
                      mobileNumber: md.user_detail.mobile_number,
                      nationality: md.user_detail.nationality,
                      postalCode: md.user_detail.postal_code,
                      residentialAddress: md.user_detail.residential_address,
                      residentialStatus: md.user_detail.residential_status,
                      unitNumber: md.user_detail.unit_number
                    }
                  };
                })
              : [],
          step
        },
        true
      )
    );
  } catch (e) {
    window.Logger.error("handleFetchWallexKycData: ", e.message);
  }
}

export function* handleWallexKycPersonal(
  action: ActionType<typeof actions.wallexKycPersonal>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, KYC_FORM);
  const countryCode = selectors.getAccountCountryCode(state);
  const mobileCountryCode = selectors.getMobileCountryCode(state);
  const getMobileNumber = selectors.getMobileNumber(state);
  const kycStatus = action.payload.kycStatus;

  const backNric = _get(formState, "back_nric_0.value");
  const frontPassport = _get(formState, "front_passport_0.value");
  const frontNric = _get(formState, "front_nric_0.value");
  const holdingPassportPhoto = _get(
    formState,
    "holding_passport_photo_0.value"
  );
  const holdingNricPhoto = _get(formState, "holding_nric_photo_0.value");
  const poa = _get(formState, "poa_0.value");

  const res: Response = yield call(RestClient.send, {
    body: {
      kyc_list: [
        {
          b_acra_bizfile: [], // This property for Business
          b_letter_of_authorization: [], // This property for Business
          // b_poba: [], // This property for Business
          back_nric: backNric ? [backNric] : [],
          country_code: countryCode,
          country_of_birth: _get(
            formState,
            "nationality_0.value",
            countryCode
          ).toUpperCase(),
          country_of_residence: _get(
            formState,
            "nationality_0.value",
            countryCode
          ).toUpperCase(),
          date_of_birth: _get(formState, "date_of_birth_0.value", ""),
          employee_industry: Number(
            _get(formState, "employee_industry_0.value", 0)
          ),
          employee_position: Number(
            _get(formState, "employee_position_0.value", 0)
          ),
          employee_status: Number(
            _get(formState, "employee_status_0.value", 0)
          ),
          expiry_date: _get(formState, "expiry_date_0.value", ""),
          first_name: _get(formState, "first_name_0.value", ""),
          front_nric: frontNric ? [frontNric] : [],
          front_passport: frontPassport ? [frontPassport] : [],
          gender: _get(formState, "gender_0.value", ""),
          holding_nric_photo: holdingNricPhoto ? [holdingNricPhoto] : [],
          holding_passport_photo: holdingPassportPhoto
            ? [holdingPassportPhoto]
            : [],
          identification_number: _get(
            formState,
            "identification_number_0.value",
            ""
          ),
          identification_type:
            kycStatus === RESIDENTIAL_STATUS.FOREIGNER ? "passport" : "nric",
          is_director_share_holder: false, // This property for Business
          is_main: true, // Always is true when is Personal account.
          issue_date: _get(formState, "issue_date_0.value", ""),
          last_name: _get(formState, "last_name_0.value", ""),
          mobile_country_code: mobileCountryCode,
          mobile_number: getMobileNumber,
          nationality: _get(
            formState,
            "nationality_0.value",
            countryCode
          ).toUpperCase(),
          poa: poa ? [poa] : [],
          postal_code: _get(formState, "postal_code_0.value", ""),
          residential_address: _get(
            formState,
            "residential_address_0.value",
            ""
          ),
          residential_status: kycStatus,
          unit_number: _get(formState, "unit_number_0.value")
        }
      ],
      tnc_confirmed: true
    },
    service: "wallex_kyc",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors) && errors !== 0) {
    yield put(actions.parseServerErrors(errors, KYC_FORM));
    return;
  }

  yield put(actions.toggleModal(actions.ModalID.KYC_STATUS_MODAL));
}

export function* handleWallexKycBusiness(
  action: ActionType<typeof actions.wallexKycBusiness>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, KYC_FORM);
  const kycData = selectors.getWallexKycData(state);
  const accountCountryCode = selectors.getAccountCountryCode(state);
  const accountMobileCountryCode = selectors.getMobileCountryCode(state);
  const accountMobileNumber = selectors.getMobileNumber(state);

  // General
  let isDraft: boolean = false;
  const { step } = action.payload;
  if (step < 3) {
    isDraft = true;
  }

  let body = {};

  if (kycData.metadata.length === 0) {
    // This is start from step #1
    body = {
      draft: isDraft,
      kyc_list: [
        {
          b_acra_bizfile: FilesUtil.convertToArray(
            _get(formState, "b_acra_bizfile_0.value")
          ),
          b_board_resolution: FilesUtil.convertToArray(
            _get(formState, "b_board_resolution_0.value")
          ),
          // b_poba: FilesUtil.convertToArray(_get(formState, "b_poba.value")),
          is_main: true
        }
      ],
      step
    };
  } else {
    // Other steps
    const { kycDocuments, userDetail } = kycData.metadata[0];
    let { isDirectorShareHolder } = kycData.metadata[0];
    let numberShareHolders = kycData.metadata.length - 1;

    let {
      bAcraBizfile,
      bBoardResolution,
      bLetterOfAuthorization,
      bStructuredChart,
      // bPoba,
      backNric,
      frontNric,
      frontPassport,
      holdingNricPhoto,
      holdingPassportPhoto,
      poa
    } = kycDocuments;

    let {
      // countryOfBirth,
      // countryOfResidence,
      dateOfBirth,
      employeeIndustry,
      employeePosition,
      employeeStatus,
      expiryDate,
      firstName,
      gender,
      identificationNumber,
      identificationType,
      issueDate,
      lastName,
      nationality,
      postalCode,
      residentialAddress,
      residentialStatus,
      unitNumber
    } = userDetail;

    let isMoreLevelShareholder = false;

    switch (step) {
      case 1:
        bAcraBizfile = FilesUtil.convertToArray(
          _get(formState, "b_acra_bizfile_0.value")
        );
        // bPoba = FilesUtil.convertToArray(_get(formState, "b_poba.value"));
        bBoardResolution = FilesUtil.convertToArray(
          _get(formState, "b_board_resolution_0.value")
        );
        break;

      case 2:
        // countryOfResidence = _get(
        //   formState,
        //   "nationality.value",
        //   accountCountryCode
        // );
        // countryOfBirth = _get(
        //   formState,
        //   "nationality.value",
        //   accountCountryCode
        // );
        isDirectorShareHolder =
          _get(formState, "is_director_share_holder_0.value") === "true" ||
          _get(formState, "is_director_share_holder_0.value") === "yes" ||
          _get(formState, "is_director_share_holder_0.value") === true;
        residentialStatus = _get(formState, "residential_status_0.value");
        identificationType =
          _get(formState, "residential_status_0.value") ===
          RESIDENTIAL_STATUS.FOREIGNER
            ? "passport"
            : "nric";
        firstName = _get(formState, "first_name_0.value");
        lastName = _get(formState, "last_name_0.value");
        dateOfBirth = _get(formState, "date_of_birth_0.value");
        gender = _get(formState, "gender_0.value");
        identificationNumber = _get(formState, "identification_number_0.value");
        issueDate = _get(formState, "issue_date_0.value");
        nationality = _get(formState, "nationality_0.value");
        expiryDate = _get(formState, "expiry_date_0.value");
        postalCode = _get(formState, "postal_code_0.value");
        residentialAddress = _get(formState, "residential_address_0.value");
        unitNumber = _get(formState, "unit_number_0.value");
        employeeIndustry = Number(_get(formState, "employee_industry_0.value"));
        employeePosition = Number(_get(formState, "employee_position_0.value"));
        employeeStatus = Number(_get(formState, "employee_status_0.value"));
        frontNric = FilesUtil.convertToArray(
          _get(formState, "front_nric_0.value")
        );
        backNric = FilesUtil.convertToArray(
          _get(formState, "back_nric_0.value")
        );
        holdingNricPhoto = FilesUtil.convertToArray(
          _get(formState, "holding_nric_photo_0.value")
        );
        poa = FilesUtil.convertToArray(_get(formState, "poa_0.value"));
        frontPassport = FilesUtil.convertToArray(
          _get(formState, "front_passport_0.value")
        );
        holdingPassportPhoto = FilesUtil.convertToArray(
          _get(formState, "holding_passport_photo_0.value")
        );
        bLetterOfAuthorization = FilesUtil.convertToArray(
          _get(formState, "b_letter_of_authorization_0.value")
        );

        break;

      case 3:
        bStructuredChart = FilesUtil.convertToArray(
          _get(formState, "b_structured_chart_0.value")
        );
        isMoreLevelShareholder =
          _get(formState, "is_more_level_share_holder_0.value") === "yes";
        numberShareHolders = _get(formState, "number_share_holders_0.value");
        break;

      default:
        break;
    }

    const kycList = [];
    for (let i = 1; i < numberShareHolders + 2; i++) {
      if (i === 1) {
        kycList.push({
          b_acra_bizfile: bAcraBizfile, // #1
          b_board_resolution: bBoardResolution, // #1
          b_letter_of_authorization: bLetterOfAuthorization,
          // b_poba: bPoba, // #1
          b_structured_chart: bStructuredChart,
          back_nric: backNric, // Step 2
          country_code: accountCountryCode,
          country_of_birth: nationality
            ? nationality.toUpperCase()
            : accountCountryCode,
          country_of_residence: nationality
            ? nationality.toUpperCase()
            : accountCountryCode,
          date_of_birth: dateOfBirth,
          employee_industry: employeeIndustry,
          employee_position: employeePosition,
          employee_status: employeeStatus,
          expiry_date: expiryDate,
          first_name: firstName,
          front_nric: frontNric, // Step 2
          front_passport: frontPassport,
          gender,
          holding_nric_photo: holdingNricPhoto, // Step 2
          holding_passport_photo: holdingPassportPhoto,
          identification_number: identificationNumber,
          identification_type: identificationType,
          is_director_share_holder: isDirectorShareHolder,
          is_main: i === 1,
          is_more_level_share_holder: isMoreLevelShareholder,
          issue_date: issueDate,
          last_name: lastName,
          mobile_country_code: accountMobileCountryCode,
          mobile_number: accountMobileNumber,
          nationality: nationality
            ? nationality.toUpperCase()
            : accountCountryCode,
          poa,
          postal_code: postalCode,
          residential_address: residentialAddress,
          residential_status: residentialStatus,
          unit_number: unitNumber
        });
      } else {
        kycList.push({
          front_nric:
            step === 3
              ? FilesUtil.convertToArray(
                  _get(formState, `front_nric_${i - 1}.value`)
                )
              : _get(kycData, `metadata[${i - 1}].kycDocuments.frontNric`),
          front_passport:
            step === 3
              ? FilesUtil.convertToArray(
                  _get(formState, `front_passport_${i - 1}.value`)
                )
              : _get(kycData, `metadata[${i - 1}].kycDocuments.frontPassport`),
          is_main: false,
          poa:
            step === 3
              ? FilesUtil.convertToArray(_get(formState, `poa_${i - 1}.value`))
              : _get(kycData, `metadata[${i - 1}].kycDocuments.poa`),
          residential_status:
            step === 3
              ? _get(formState, `residential_status_${i - 1}.value`)
              : _get(kycData, `metadata[${i - 1}].userDetail.residentialStatus`)
        });
      }
    }

    body = {
      draft: isDraft,
      kyc_list: kycList,
      step
    };
  }

  const res: Response = yield call(RestClient.send, {
    body,
    service: "wallex_kyc",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors) && errors !== 0) {
    yield put(actions.parseServerErrors(errors, KYC_FORM));
    return;
  }

  if (step < 3) {
    history.push(`/onboarding/kyc/step${step + 1}`);
  } else if (step === 3) {
    yield put(actions.toggleModal(actions.ModalID.KYC_STATUS_MODAL));
  } else {
    window.location.href = "/";
  }
}

export function* handlePaymentCalculator(
  action: ActionType<typeof actions.paymentCalculator>
) {
  const { countryId, currencyId, paidCurrencyId, cb } = action.payload;

  const res = yield call(RestClient.send, {
    query: {
      country_id: countryId,
      currency_id: currencyId,
      paid_currency_id: paidCurrencyId
    },
    service: "payment_calculation_basic"
  });

  if (!res) {
    return;
  }

  const data = _get(res, "data", {});

  cb({
    channelFee: data.channel_fee,
    fee: data.fee,
    otherFee: data.other_fee,
    processingDay: data.processing_day
  });
}

export function* handleFetchEmployeeStuff(
  action: ActionType<typeof actions.fetchEmployeeStuff>
) {
  const { type } = action.payload;

  const res: Response = yield call(RestClient.send, {
    service: `wallex_employee_${type}`
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);

    yield put(
      actions.setEmployeeStuff({
        employeeData: data,
        type
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchEmployeeStuff: ", e.message);
  }
}

export function* handleGetAddressByPostalCode(
  action: ActionType<typeof actions.getAddressByPostalCode>
) {
  const res: Response = yield call(RestClient.send, {
    params: {
      postal_code: action.payload.postalCode
    },
    service: "get_address"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data = _get(res, "data", {});

    yield put(actions.setResidentialAddress(data.address));
  } catch (e) {
    window.Logger.error("handleGetAddressByPostalCode: ", e.message);
  }
}
