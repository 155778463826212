import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type DateConditionsState = {
  readonly isFetching: boolean;
  readonly earliest?: Date;
  readonly exclusions: Date[];
  readonly chargeDate: Date;
  readonly isChargeToday: boolean;
  readonly inComingPayments: Date[];
  readonly outComingPayments: Date[];
};

const defaultState: DateConditionsState = {
  chargeDate: new Date(),
  earliest: undefined,
  exclusions: [],
  inComingPayments: [],
  isChargeToday: false,
  isFetching: false,
  outComingPayments: []
};

export default (state: DateConditionsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setDateConditions):
      return {
        ...state,
        chargeDate: action.payload.chargeDate
          ? action.payload.chargeDate
          : state.chargeDate,
        earliest: action.payload.earliest
          ? action.payload.earliest
          : state.earliest,
        exclusions: action.payload.exclusions
          ? action.payload.exclusions
          : state.exclusions,
        isChargeToday: action.payload.isChargeToday,
        isFetching: action.payload.isFetching
      };
    case getType(actions.setPaymentCalendar):
      const {
        isFetching,
        inComingPayments,
        outComingPayments
      } = action.payload;
      return {
        ...state,
        inComingPayments: inComingPayments
          ? inComingPayments
          : state.inComingPayments,
        isFetching,
        outComingPayments: outComingPayments
          ? outComingPayments
          : state.outComingPayments
      };
  }
  return state;
};
