import React, { useContext, useState } from "react";
import Tooltip from "reactstrap/lib/Tooltip";
import { LanguageContext } from "src/ipm-shared/Contexts";

interface IProps {
  dropdownId?: string;
}

export default function SelectLanguageMobile({ dropdownId }: IProps) {
  const {
    changeLanguage: handleChangeLanguage,
    locale,
    languageOptions
  } = useContext(LanguageContext);

  const [isOpenLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
  const toggleShowLanguageSwitcher = () => {
    setShowLanguageSwitcher(!isOpenLanguageSwitcher);
  };

  const changeLanguage = (lang: string) => () => {
    handleChangeLanguage(lang);
  };

  const targetID = dropdownId || "LanguageSwitcherMobile";

  return (
    <div className={"select-language-mobile"}>
      <div id={targetID}>
        <div className={"select-language-mobile__icon"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <g
              id="Group_10"
              data-name="Group 10"
              transform="translate(-365.986 -196.986)"
            >
              <g id="globe" transform="translate(367 198)">
                <circle
                  id="Ellipse_6"
                  data-name="Ellipse 6"
                  cx="7.5"
                  cy="7.5"
                  r="7.5"
                  transform="translate(-0.014 -0.014)"
                  fill="none"
                  stroke="#34405b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_1"
                  data-name="Line 1"
                  x2="15"
                  transform="translate(-0.014 6.986)"
                  fill="none"
                  stroke="#34405b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M10.6,2a9.952,9.952,0,0,1,2.6,6.5,9.952,9.952,0,0,1-2.6,6.5A9.952,9.952,0,0,1,8,8.5,9.952,9.952,0,0,1,10.6,2Z"
                  transform="translate(-3.21 -1.205)"
                  fill="none"
                  stroke="#34405b"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.547"
            height="4.369"
            viewBox="0 0 7.547 4.369"
          >
            <g
              id="Group_9"
              data-name="Group 9"
              transform="translate(-467.204 -115.171)"
            >
              <g id="Group_14" data-name="Group 14">
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M470.337,119.246a.845.845,0,0,0,1.281,0l2.9-3.225c.421-.467.25-.85-.379-.85h-6.328c-.628,0-.8.383-.378.85l2.9,3.225Z"
                  fill="#364059"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </svg>
        </div>

        <Tooltip
          isOpen={isOpenLanguageSwitcher}
          autohide={false}
          target={targetID}
          toggle={toggleShowLanguageSwitcher}
          placement="bottom"
          className={
            "nav-properties-tooltip switcher-language-tooltip switcher-language-tooltip--bottom switcher-language-tooltip--mobile"
          }
        >
          <div className={"tooltip-dropdown"}>
            {languageOptions.map(languageOption => (
              <div key={languageOption.value}>
                <button
                  className={locale === languageOption.value ? "active" : ""}
                  onClick={changeLanguage(languageOption.value)}
                >
                  {languageOption.label}
                </button>
              </div>
            ))}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
