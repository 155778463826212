import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type CompanyType = {
  accountId: number;
  allowDelete?: boolean;
  id: number;
  isCurrent?: boolean;
  countryCode?: string;
  name: string;
  registrationNumber?: string;
  profileUrl?: string;
  hasCollectedAccount?: boolean;
};

export type PredefinedUenType = {
  accountNumber: string;
  bankName: string;
  companyName: string[];
};

export type CompaniesState = {
  readonly hasFetched: boolean;
  readonly isFetching: boolean;
  readonly companies: CompanyType[];
  readonly editedId: number;
  readonly predefinedUen: PredefinedUenType[];
};

const defaultState: CompaniesState = {
  companies: (() => {
    const currentCompanyStr = window.localStorage.getItem("current_company");
    if (currentCompanyStr !== null && currentCompanyStr !== "") {
      return [JSON.parse(currentCompanyStr)] as CompanyType[];
    }

    return [];
  })(),
  editedId: -1,
  hasFetched: false,
  isFetching: false,
  predefinedUen: []
};

export default (state: CompaniesState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setCompanies):
      return {
        ...state,
        companies: action.payload.companies
          ? action.payload.companies
          : state.companies,
        hasFetched: true,
        isFetching: action.payload.isFetching
      };

    case getType(actions.selectCompanyToEdit):
      return {
        ...state,
        editedId: action.payload
      };
    case getType(actions.setPredefinedUen):
      return {
        ...state,
        predefinedUen: action.payload.predefinedUen
      };
  }
  return state;
};
