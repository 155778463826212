/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { PURPOSE } from "./const";
import { ExtraPayee, PayeeType } from "./reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import * as selectors from "../Bank/selectors";
import { default as payeesReducer } from "./reducers";
import store from "src/ipm-shared/store";
ReducerFactory.registerReducer({ payees: payeesReducer });

export const isValidatedWizardSelectPayee = (state: RootState) =>
  state.payees.selectedPayeeIds.length > 0;

export const getPayees = (state: RootState, purpose: PURPOSE) => {
  const payees = _get(state.payees.payees, purpose, {});
  const ids = Object.keys(payees);
  return ids
    .map(id => ({
      ...payees[id],
      bankName: _get(state.banks.byId, `${payees[id].bankId}.name`, ""),
      currentAmount: payees[id].currentAmount
    }))
    .filter(p => p.display === true && !p.dashboardHidden)
    .sort((a, b) => a.order - b.order);
};

export const selectedPayee = (state: RootState) =>
  state.payees.selectedPayeeIds;

// Payees
export const shouldDisplayLoadingPayees = (state: RootState) =>
  state.payees.isFetching;

export type PayeeTypeEx = PayeeType & {
  bankName: string;
  extraPayees: {
    [uid: string]: ExtraPayee;
  };
};
export const getSelectedPayees = <P extends PayeeTypeEx>(state: RootState) => {
  const purpose = state.payees.selectedPurpose as PURPOSE;
  const payees = state.payees.payees[purpose];

  return state.payees.selectedPayeeIds
    .filter(id => payees[id] !== undefined) // For safe, should check payee exists first
    .map(id => ({
      ...payees[id],
      bankName: _get(state.banks.byId, `${payees[id].bankId}.name`, ""),
      extraPayees: _get(state.payees.extraPayees, id, {})
    })) as P[];
};

export const hasSelectedPayee = (state: RootState) =>
  state.payees.selectedPayeeIds.length > 0;

export const getEditedPayee = (state: RootState, purpose: PURPOSE) => {
  const payee = state.payees.payees[purpose][state.payees.editedId];
  if (payee) {
    return {
      ...payee,
      bankCode: _get(
        selectors.getBanksMap(store.getState()),
        `${payee.bankId}.swiftCode`,
        ""
      )
    };
  } else {
    return {} as any;
  }
};

export const hasPayeeAlready = (state: RootState, purpose: string) =>
  !_isEmpty(state.payees.payees[purpose]);

export const getExtraPayees = (state: RootState) => state.payees.extraPayees;

export const getTotalPayees = (state: RootState) => state.payees.total;
