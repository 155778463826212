import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import { PayeeType } from "../Payee/reducers";

export type PaymentRequestState = {
  readonly amountOff?: number;
  readonly channelFees: number;
  readonly isFetching: boolean;
  readonly currencyId: number;
  readonly isScheduled?: boolean;
  readonly payees: PayeeType[];
  readonly purposeId: number;
  readonly statementDescriptor: string;
  readonly subtotal: number;
  readonly licenseFee: number;
  readonly token: string;
  readonly total: number;
  readonly savings: number;
  readonly rateBeforeCoupon: number;
  readonly rateUsed: number;
  readonly couponUsed: string;
  readonly currentPurpose: number;
  readonly paidCard: number;
  readonly exchangeRate: number;
  readonly paymentAmount: number;
  readonly payeeQuoteToken?: string;
};

const defaultState: PaymentRequestState = {
  channelFees: 0,
  couponUsed: "",
  currencyId: 0,
  currentPurpose: 0,
  exchangeRate: 0,
  isFetching: false,
  isScheduled: false,
  licenseFee: 0,
  paidCard: 0,
  payees: [],
  paymentAmount: 0,
  purposeId: 0,
  rateBeforeCoupon: 0,
  rateUsed: 0,
  savings: 0,
  statementDescriptor: "",
  subtotal: 0,
  token: "",
  total: 0,
  payeeQuoteToken: ""
};

export { defaultState };
export default (state: PaymentRequestState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPaymentRequest):
      return {
        ...state,
        ...action.payload
      };
    case getType(actions.setFees):
      return {
        ...state,
        amountOff: action.payload.amountOff,
        couponUsed: action.payload.coupon,
        exchangeRate: action.payload.exchangeRate,
        licenseFee: action.payload.fee,
        paymentAmount: action.payload.paymentAmount,
        rateBeforeCoupon: action.payload.rateBeforeCoupon,
        rateUsed: action.payload.rate,
        savings: action.payload.savings,
        total: action.payload.total,
        payeeQuoteToken: action.payload.payeeQuoteToken
      };

    case getType(actions.setCoupon):
      return {
        ...state,
        couponUsed: action.payload.coupon
      };

    case getType(actions.setCurrentPurpose):
      return {
        ...state,
        currentPurpose: action.payload.purpose
      };

    case getType(actions.setExchangeRate):
      return {
        ...state,
        exchangeRate: action.payload.exchangeRate
      };
  }
  return state;
};
