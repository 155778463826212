import _get from "lodash-es/get";
import * as React from "react";
import { IProps } from "./index";
import * as classNames from "classnames";
import {
  PAYMENT_STATUS,
  PURPOSE
} from "src/ipm-shared/store/model/Payment/const";
import { format } from "date-fns";
import T from "src/ipm-shared/Utils/Intl";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import { history } from "src/ipm-shared/store";
import PaymentUtil from "src/ipm-shared/Utils/Payment";
import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";
import Is from "src/ipm-shared/Utils/Is";
import { createRef } from "react";
import CircleEyeIcon from "src/ipm-shared/images/circle-eye-icon.svg";
import ToolTip from "src/ipm-shared/components/Tooltip";

class PaymentTable extends React.Component<IProps> {
  private measureNoteColumn = createRef<HTMLTableHeaderCellElement>();

  public componentDidMount() {
    const noteWidth = this.measureNoteColumn.current;
    if (noteWidth) {
      this.props.getNoteColumnWidth(noteWidth.offsetWidth);
    }
  }

  public render() {
    const { nullText, noteColumnWidth } = this.props;
    const type = this.props.isBusiness ? "business" : "personal";
    return (
      <div className="table-responsive">
        {this.props.payments.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th
                  hidden={this.props.status === "scheduled"}
                  style={{ minWidth: 115 }}
                >
                  {T.transl("TABLE_RECEIPT_NUMBER")}
                </th>
                <th
                  hidden={!(this.props.status === "scheduled")}
                  style={{ minWidth: 115, paddingLeft: 0 }}
                >
                  {T.transl("TABLE_FREQUENCY")}
                </th>
                <th style={{ minWidth: 70 }}>{T.transl("TABLE_TYPE")}</th>
                <th
                  style={{
                    minWidth: noteColumnWidth > 200 ? noteColumnWidth : 200 // 180 is width default of this column.
                  }}
                  ref={this.measureNoteColumn}
                >
                  {T.transl("TABLE_NOTE")}
                </th>
                <th style={{ width: 140 }} className={"text-right pr-5"}>
                  {T.transl("AMOUNT_LABEL")}
                </th>
                <th>{T.transl("TABLE_CARD")}</th>
                <th>{T.transl("TABLE_CHARGE_DATE")}</th>
                <th>{T.transl("TABLE_PAYOUT_DATE")}</th>
                <th className="text-center">{T.transl("TABLE_ACTION")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.payments.map(payment => {
                const isDeclined =
                  payment.paymentStatusId === PAYMENT_STATUS.DECLINED;
                const isDraft =
                  payment.paymentStatusId === PAYMENT_STATUS.DRAFT;
                const isCancelled =
                  payment.paymentStatusId ===
                    PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD ||
                  payment.paymentStatusId ===
                    PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE;
                const countryId = payment.payees[0].countryId;
                const currencyId = payment.payees[0].currencyId;
                const isWallexPayment = currencyId !== countryId;
                const isEditable = isDraft;
                const payeeAmount =
                  payment.payees[0].defaultAmount +
                  payment.payees[0].refundedAmount;
                const payeeRefundedAmount = payment.payees
                  .map(amount => amount.refundedAmount)
                  .reduce((a, b) => a + b);
                return (
                  <tr key={payment.id}>
                    <td hidden={this.props.status === "scheduled"}>
                      {isDeclined ? (
                        <span className={"text-danger"}>
                          {T.transl("TABLE_PAYMENT_DECLINED")}
                        </span>
                      ) : isCancelled ? (
                        <span className={"text-danger"}>
                          {T.transl("TABLE_PAYMENT_CANCELLED")}
                        </span>
                      ) : (
                        _get(payment, "receiptNumber", "-")
                      )}
                    </td>
                    <td
                      style={{ paddingLeft: 0 }}
                      hidden={!(this.props.status === "scheduled")}
                    >
                      {PaymentUtil.convertFrequencyString(
                        payment.scheduleFrequency
                      )}
                    </td>
                    <td>
                      {payment.purposeId === PURPOSE.RENTAL &&
                        T.transl("PURPOSE_RENT")}
                      {payment.purposeId === PURPOSE.SALARY &&
                        T.transl("PURPOSE_SALARY")}
                      {payment.purposeId === PURPOSE.INVOICE &&
                        T.transl("PURPOSE_INVOICE")}
                      {payment.purposeId === PURPOSE.SELF_TRANSFER &&
                        T.transl("PURPOSE_MONEY_TRANSFER")}
                    </td>
                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "180px"
                      }}
                    >
                      {payment.payees.length < 2
                        ? _get(payment, "payees.0.defaultComments", "-")
                        : Is.sameValues("defaultComments", payment.payees)
                        ? _get(payment, "payees.0.defaultComments", "-")
                        : T.transl("MULTIPLE_RECIPIENTS")}
                    </td>
                    <td
                      className={classNames(`text-right ${type}-color pr-5`, {
                        "txt-blue": isWallexPayment
                      })}
                    >
                      {/* <span className={`fw-600`}>
                        {payment.currencyId &&
                          CurrencyUtil.convertFromId(payment.currencyId).$}
                      </span>
                      <ShortCurrency value={payment.paymentTotal} /> */}
                      {isWallexPayment ? (
                        <>
                          {
                            this.props.getCurrenciesById[
                              _get(payment, "payees[0].currencyId", 1)
                            ].symbol
                          }
                          <ShortCurrency value={payeeAmount} />
                        </>
                      ) : (
                        <>
                          {
                            this.props.getCurrenciesById[
                              _get(payment, "currencyId", 1)
                            ].symbol
                          }
                          <ShortCurrency
                            value={payeeRefundedAmount + payment.paymentAmount}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <ShortCardInfo cardId={_get(payment.cards, "0.id", 0)} />
                    </td>
                    <td>
                      {isCancelled ? (
                        <span className="text-danger">
                          {T.transl("CANCELLED")}
                        </span>
                      ) : (
                        format(
                          new Date(payment.scheduledChargeDate),
                          "DD/MM/YYYY"
                        )
                      )}
                    </td>
                    <td>
                      {format(new Date(payment.payoutDate), "DD/MM/YYYY")}
                    </td>
                    <td className="text-center">
                      <a
                        href=""
                        className={classNames("payment-action", {
                          "action-edit": isEditable,
                          "action-view": !isEditable
                        })}
                        onClick={
                          isEditable
                            ? this.editPayment.bind(this, payment.id)
                            : this.viewPayment.bind(this, payment.id)
                        }
                      >
                        {isEditable ? (
                          T.transl("EDIT")
                        ) : (
                          <>
                            <ToolTip
                              className="dashboard-icon-tooltip general-tooltip view-details-tooltip"
                              description={T.transl("VIEW_DETAILS_TOOLTIP")}
                              target={`icon-details-${payment.id}`}
                            />
                            <img
                              id={`icon-details-${payment.id}`}
                              width={24}
                              src={CircleEyeIcon}
                              alt="View details icon"
                            />
                          </>
                        )}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          nullText
        )}
      </div>
    );
  }

  private editPayment = (id: number, e: React.ChangeEvent<any>) => {
    e.preventDefault();

    const payment = this.props.payments.filter(p => p.id === id)[0];
    this.props.selectPayment(id);

    switch (payment.purposeId) {
      case PURPOSE.INVOICE:
        history.push("/wizard/select/invoice");
        break;
      case PURPOSE.RENTAL:
        history.push("/wizard/select/rent");
        break;
      case PURPOSE.SALARY:
        history.push("/wizard/select/salary");
        break;
    }
  };

  private viewPayment = (id: number, e: React.ChangeEvent<any>) => {
    e.preventDefault();

    this.props.selectPayment(id);
    this.props.toggleModal();
  };
}

export default PaymentTable;
