export const FORM = "add_payee";
export const BULK_ADD_FORM = "bulk_add_payee";
export const SELECT_PAYEE_FORM = "select_payee_form";
export const NOTE_TO_EMPLOYEE_FORM = "note_to_employee";
export const NOTE_TO_SUPPLIER_FORM = "note_to_supplier";
export const PAYEES_PAGE_COUNT = 30;
export type PURPOSE =
  | "rent"
  | "salary"
  | "invoice"
  | "collection"
  | "self_transfer"
  | "partner";

// International
export const INTERNATIONAL_FORM = "add_recipient_form";

export const COUNTRY_NATIONAL_ID_NUMBER = ["MY", "IN", "CN", "TH", "PH"];

export const COUNTRY_REGISTRATION_NUMBER = ["MY", "IN", "TH", "PH"];

export const COUNTRY_BUSINESS_RELATIONSHIP = ["PH", "MY", "IN", "TH"];
export const COUNTRY_INDIVIDUAL_RELATIONSHIP = ["PH", "MY", "IN", "TH"];
