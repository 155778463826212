import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _isNumber from "lodash-es/isNumber";
import _find from "lodash-es/find";
import { ActionType, getType } from "typesafe-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import RestClient from "src/ipm-shared/services/Rest";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import utilsNumber from "src/ipm-shared/Utils/Number";
import utilCountry from "src/ipm-shared/Utils/Country";

import * as commonActions from "./actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { ModalID } from "src/ipm-shared/components/Form/actions";
import * as collectedAccountSelectors from "src/ipm-shared/store/model/CollectedAccount/selectors";
import * as accountProfileActions from "src/ipm-shared/store/model/AccountProfile/actions";
import * as paymentCollectionSelectors from "src/ipm-shared/store/model/PaymentCollections/selectors";
import {
  ADD_FORM,
  ADD_ITEM_FORM,
  ADD_TAX_FORM,
  ADDITIONAL_INVOICE_FORM,
  SETUP_INVOICE_NUMBER,
  FNB_CATEGORY,
  FEE_PAYER,
  UPLOAD_DOCUMENT_FORM
} from "./const";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import {
  CollectionRates,
  emptyInvoiceLineItem
} from "src/ipm-shared/store/model/CollectedAccount/reducers";
import { OtherSettingsCollectedAccount } from "./reducers";
import _forIn from "lodash-es/forIn";
import { SEARCH_CDD_FORM } from "../Payment/const";

const actions = {
  ...commonActions,
  ...formActions,
  ...accountProfileActions
};

const selectors = {
  ...formSelectors,
  ...accountProfileSelectors,
  ...collectedAccountSelectors,
  ...paymentCollectionSelectors
};

const watchedSagas = [
  reTryTakeLatest(actions.checkHasActiveProgram, handleCheckHasActiveProgram),
  reTryTakeLatest(
    actions.fetchMainCollectedAccount,
    handleFetchMainCollectedAccount
  ),
  reTryTakeLatest(actions.fetchNextInvoiceNumber, handleFetchNextInvoiceNumber),
  reTryTakeLatest(actions.fetchTaxes, handleFetchTaxes),
  reTryTakeLatest(actions.fetchItems, handleFetchItems),
  reTryTakeLatest(
    actions.fetchAdminCollectedAccounts,
    handleFetchAdminCollectedAccounts
  ),

  takeLatest(
    getType(actions.createCollectedAccount),
    handleCreateCollectedAccount
  ),
  takeLatest(getType(actions.addNewTax), handleAddNewTax),
  takeLatest(
    getType(actions.updateCollectedAccount),
    handleUpdateCollectedAccount
  ),
  takeLatest(
    getType(actions.updateAdditionalInvoiceSetting),
    handleAdditionalInvoiceSetting
  ),
  takeLatest(getType(actions.setupInvoiceNumber), handleSetupInvoiceNumber),
  takeLatest(getType(actions.addNewItem), handleAddNewItem),
  takeLatest(getType(actions.editItem), handleEditItem),
  takeLatest(
    actions.updateCollectedAccountStatus,
    handleUpdateCollectedAccountStatus
  ),
  takeLatest(
    actions.updateCollectedAccountDocument,
    handleUpdateCollectedAccountDocument
  ),
  takeLatest(
    getType(actions.adminListCollectedAccount),
    handleAdminListCollectedAccount
  )
];

export default watchedSagas;

export function getFeePayer(
  defaultRate: number,
  collectionRate: number
): number {
  // decide which feePayer: COMPANY_PAY | SHARED_PAY
  return defaultRate === collectionRate
    ? FEE_PAYER.COMPANY_PAY
    : FEE_PAYER.SHARED_PAY;
}

export function getFeeRateAndPayerParams(
  feeSettings: CollectionRates,
  defaultFeeSettings: CollectionRates
): {
  national: number;
  international: number;
  feePayer: number;
  intFeePayer: number;
} {
  if (defaultFeeSettings.national < feeSettings.national) {
    feeSettings.national = defaultFeeSettings.national;
  }

  if (defaultFeeSettings.international < feeSettings.international) {
    feeSettings.international = defaultFeeSettings.international;
  }

  const feePayer = getFeePayer(
    feeSettings.national,
    defaultFeeSettings.national
  );
  const intFeePayer = getFeePayer(
    feeSettings.international,
    defaultFeeSettings.international
  );

  return {
    feePayer,
    intFeePayer,
    international: feeSettings.international,
    national: feeSettings.national
  };
}

export function* handleCreateCollectedAccount(
  action: ActionType<typeof actions.createCollectedAccount>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);

  const bankStatement = _get(formState, "bank_documents.value", undefined);
  const recipientEmail = _get(formState, "recipient_email.value").trim();
  const confirmRecipientEmail = _get(
    formState,
    "confirm_recipient_email.value"
  ).trim();
  const accountNumber = _get(formState, "account_number.value");
  const confirmAccountNumber = _get(formState, "confirm_account_number.value");
  const businessIndustry = _get(formState, "business_industry.value");
  const businessType = _get(formState, "business_type.value");
  const bankId = _get(formState, "bank_id.value");
  let currencyId = selectors.getAccountCurrencyId(state);

  if (selectors.isHongKongAccount(state)) {
    currencyId = Number(_get(formState, "currency_id.value"));
  }

  if (!action.payload.draft) {
    if (recipientEmail !== confirmRecipientEmail) {
      yield put(
        actions.parseServerErrors(
          {
            fields: {
              confirm_recipient_email: ["EMAIL_CONFIRM_DOES_NOT_MATCH"]
            },
            form: []
          },
          ADD_FORM
        )
      );
      return;
    }
    if (accountNumber !== confirmAccountNumber) {
      yield put(
        actions.parseServerErrors(
          {
            fields: {
              confirm_account_number: ["ACCOUNT_NUMBER_CONFIRM_DOES_NOT_MATCH"]
            },
            form: []
          },
          ADD_FORM
        )
      );
      return;
    }
  }

  const collectionRates = selectors.getCollectionRates(state);
  const defaultNationRateVisa = parseInt(
    _get(formState, "requester_national_rate_visa.value"),
    10
  );
  const defaultInternationalRateVisa = parseInt(
    _get(formState, "requester_international_rate_visa.value"),
    10
  );
  const defaultNationRateUnion = parseInt(
    _get(formState, "requester_national_rate_union.value"),
    10
  );
  const defaultInternationalRateUnion = parseInt(
    _get(formState, "requester_international_rate_union.value"),
    10
  );
  const defaultNationRateAmex = parseInt(
    _get(formState, "requester_national_rate_amex.value"),
    10
  );
  const defaultInternationalRateAmex = parseInt(
    _get(formState, "requester_international_rate_amex.value"),
    10
  );

  const res: Response = yield call(RestClient.send, {
    body: {
      account_id: _get(formState, "account_id.value", 0),
      account_number: accountNumber,
      bank_bsb_id: _get(formState, "bank_bsb_id.value"),
      bank_documents: bankStatement ? bankStatement.split(",") : [],
      bank_id: bankId ? parseInt(bankId, 10) : null,
      business_industry: businessIndustry
        ? parseInt(businessIndustry, 10)
        : null,
      business_type: businessType ? parseInt(businessType, 10) : null,
      company_sms_name: _get(formState, "company_sms_name.value", null),
      confirmed_account_number: confirmAccountNumber,
      confirmed_email: confirmRecipientEmail,
      confirmed_policy:
        _get(formState, "cf_create_account.value") === "confirm",
      confirmed_tnc:
        _get(formState, "cf_create_account_tc.value") === "confirm",
      currency_id: currencyId,
      default_statement_descriptor: _get(
        formState,
        "default_statement_descriptor.value"
      ),
      draft: action.payload.draft,
      payment_description: "",
      purpose_id: 4,
      recipient_email: recipientEmail,
      requester_rates: [
        {
          fee_payer: getFeePayer(
            defaultNationRateVisa,
            collectionRates.filter(r => r.brand_id === 2)[0].national
          ),
          int_fee_payer: getFeePayer(
            defaultInternationalRateVisa,
            collectionRates.filter(r => r.brand_id === 2)[0].international
          ),
          brand_id: 2,
          national: defaultNationRateVisa,
          international: defaultInternationalRateVisa
        },
        {
          fee_payer: getFeePayer(
            defaultNationRateUnion,
            collectionRates.filter(r => r.brand_id === 5)[0].national
          ),
          int_fee_payer: getFeePayer(
            defaultInternationalRateUnion,
            collectionRates.filter(r => r.brand_id === 5)[0].international
          ),
          brand_id: 5,
          national: defaultNationRateUnion,
          international: defaultInternationalRateUnion
        },
        {
          fee_payer: getFeePayer(
            defaultNationRateAmex,
            collectionRates.filter(r => r.brand_id === 4)[0].national
          ),
          int_fee_payer: getFeePayer(
            defaultInternationalRateAmex,
            collectionRates.filter(r => r.brand_id === 4)[0].international
          ),
          brand_id: 4,
          national: defaultNationRateAmex,
          international: defaultInternationalRateAmex
        }
      ]
    },
    service: "create_collected_account",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_FORM));
    return;
  }

  if (action.payload.draft) {
    UrlHelper.redirect(`/`);
    return;
  }

  const data = _get(res, "data", {});
  if (data.enabled_program === "PC_MONTHLY_CREDIT_3") {
    UrlHelper.redirect(`/?m=${ModalID.CREDIT_PROGRAM_CONFIRMED_MODAL}`);
    return;
  }

  if (currencyId !== 73 && data.credit_program_offer_v1_enabled) {
    yield put(
      actions.toggleModal(actions.ModalID.CREDIT_PROGRAM_CONFIRM_MODAL, {
        countryCode: selectors.getAccountCountryCode(state)
      })
    );
  } else {
    if (FNB_CATEGORY.indexOf(parseInt(businessIndustry, 10)) > -1) {
      yield put(
        actions.toggleModal(actions.ModalID.OPTIMIZE_DASHBOARD_CONFIRM, {
          path: `/?m=${ModalID.BE_PAID_MAKE_PAYMENT}`
        })
      );
    } else {
      UrlHelper.redirect(`/?m=${ModalID.BE_PAID_MAKE_PAYMENT}`);
    }
  }
}

export function* handleUpdateCollectedAccount(
  action: ActionType<typeof actions.updateCollectedAccount>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);
  const collectionRates = selectors.getCollectionRates(state);
  const collectedAccount = selectors.getCollectedAccount(state);

  if (!collectedAccount) {
    console.error("Weird. Collected Account should be fetched alr.");
    return;
  }

  const bankStatement = _get(
    formState,
    "bank_documents.value",
    collectedAccount.payeeData.bankDocuments.join(",")
  );

  // update fee rate by action params
  const {
    national: nationalVisa,
    international: internationalVisa,
    feePayer: feePayerVisa,
    intFeePayer: intFeePayerVisa
  } = getFeeRateAndPayerParams(
    action.payload.feeSettings.filter(r => r.brand_id === 2)[0],
    collectionRates.filter(r => r.brand_id === 2)[0]
  );

  const {
    national: nationalUnion,
    international: internationalUnion,
    feePayer: feePayerUnion,
    intFeePayer: intFeePayerUnion
  } = getFeeRateAndPayerParams(
    action.payload.feeSettings.filter(r => r.brand_id === 5)[0],
    collectionRates.filter(r => r.brand_id === 5)[0]
  );

  const res: Response = yield call(RestClient.send, {
    body: {
      account_id: collectedAccount.accountId,
      account_number: _get(formState, "account_number.value"),
      bank_bsb_id: _get(formState, "bank_bsb_id.value"),
      bank_documents: bankStatement ? bankStatement.split(",") : [],
      bank_id: parseInt(_get(formState, "bank_id.value"), 10),
      branding_color: _get(formState, "branding_color.value", null),
      business_industry: collectedAccount.payeeData.businessIndustry,
      business_type: collectedAccount.payeeData.businessType,
      company_address_line_1: _get(
        formState,
        "company_address_line_1.value",
        null
      ),
      company_address_line_2: _get(
        formState,
        "company_address_line_2.value",
        null
      ),
      company_logo: _get(formState, "company_logo.value", null),
      company_sms_name: _get(formState, "company_sms_name.value", null),
      currency_id: collectedAccount.currencyId,
      default_statement_descriptor: _get(
        formState,
        "default_statement_descriptor.value"
      ),
      gst_registration_number: _get(
        formState,
        "gst_registration_number.value",
        null
      ),
      payment_description: "",
      purpose_id: collectedAccount.purposeId,
      recipient_email: _get(formState, "recipient_email.value"),
      requester_rates: [
        {
          fee_payer: feePayerVisa,
          int_fee_payer: intFeePayerVisa,
          brand_id: 2,
          national: nationalVisa,
          international: internationalVisa
        },
        {
          fee_payer: feePayerUnion,
          int_fee_payer: intFeePayerUnion,
          brand_id: 5,
          national: nationalUnion,
          international: internationalUnion
        }
      ]
    },
    service: "update_collected_account",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_FORM));
    return;
  }

  UrlHelper.redirect("/collected-account/settings?success=y");
}

export function* handleAdditionalInvoiceSetting(
  _: ActionType<typeof actions.updateAdditionalInvoiceSetting>
) {
  yield put(actions.checkHasActiveProgram());

  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADDITIONAL_INVOICE_FORM);

  const collectedAccount = selectors.getCollectedAccount(state);

  if (!collectedAccount) {
    console.error("Weird. Collected Account should be fetched alr.");
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      branding_color: _get(formState, "branding_color.value", null),
      company_address_line_1: _get(
        formState,
        "company_address_line_1.value",
        null
      ),
      company_address_line_2: _get(
        formState,
        "company_address_line_2.value",
        null
      ),
      company_logo: _get(formState, "company_logo.value", null),
      gst_registration_number: _get(
        formState,
        "gst_registration_number.value",
        null
      ),
      to_create_or_upload_invoice: _get(
        formState,
        "to_create_or_upload_invoice.value",
        null
      )
    },
    service: "update_collected_account_additional_setting",
    showGlobalLoader: true
  });
  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADDITIONAL_INVOICE_FORM));
    return;
  }

  UrlHelper.redirect("/invoice-creation/create");
}

export function* handleFetchNextInvoiceNumber(
  _: ActionType<typeof actions.fetchNextInvoiceNumber>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_next_invoice_number"
  });

  if (!res) {
    return;
  }

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data = _get(res, "data", {});

  yield put(actions.updateNextNumberInvoiceCollectedAccount(data.next_number));
}

export function* handleSetupInvoiceNumber(
  _: ActionType<typeof actions.setupInvoiceNumber>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, SETUP_INVOICE_NUMBER);
  const collectedAccount = selectors.getCollectedAccount(state);

  if (!collectedAccount) {
    console.error("Weird. Collected Account should be fetched alr.");
    return;
  }

  const generateOrManualIN = _get(
    formState,
    "to_generate_or_add_manual_invoice_number.value"
  );
  const invoiceNumberPrefix = _get(
    formState,
    "invoice_number_prefix.value",
    _get(collectedAccount, "otherSettings.invoiceNumberPrefix")
  );
  const invoiceNumberTemplate = _get(
    formState,
    "invoice_number_template.value",
    _get(collectedAccount, "otherSettings.invoiceNumberTemplate")
  );

  const res: Response = yield call(RestClient.send, {
    body: {
      invoice_number_prefix: invoiceNumberPrefix,
      invoice_number_template: invoiceNumberTemplate,
      to_create_or_upload_invoice:
        collectedAccount.payeeData.toCreateOrUploadInvoice,
      to_generate_or_add_manual_invoice_number: generateOrManualIN
    },
    service: "collection_update_other_setting",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, SETUP_INVOICE_NUMBER));
    return;
  }

  yield put(
    actions.updateOtherSettingsCollectedAccount({
      generateOrManualIN,
      invoiceNumberPrefix,
      invoiceNumberTemplate
    })
  );
  yield put(actions.closeModal(ModalID.BE_PAID_SETUP_INVOICE_NUMBER));
}

export function* handleFetchTaxes(_: ActionType<typeof actions.fetchTaxes>) {
  const res: Response = yield call(RestClient.send, {
    service: "get_taxes"
  });

  if (!res) {
    const state: RootState = yield select();

    yield put(
      actions.setTaxes({
        isFetching: false,
        taxes: selectors.getTaxes(state) || []
      })
    );

    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);

  yield put(
    actions.setTaxes({
      isFetching: false,
      taxes: data.map(t => ({
        id: t.id,
        name: t.tax_name,
        rate: t.tax_rate
      }))
    })
  );

  // if (action.payload.uid) {
  //   yield put(
  //     actions.selectTaxId({ name: action.payload.uid, id: _last(data).id })
  //   );
  // }
}

export function* handleAddNewTax(action: ActionType<typeof actions.addNewTax>) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_TAX_FORM);

  const res: Response = yield call(RestClient.send, {
    body: {
      tax_name: _get(formState, "tax_name.value"),
      tax_rate: Number(_get(formState, "tax_rate.value"))
    },
    service: "add_new_tax",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_TAX_FORM));
    return;
  }

  if (action.payload.cb) {
    action.payload.cb(_get(res, "data[0].id"));
  }

  yield put(actions.closeModal(ModalID.BE_PAID_INVOICE_ADD_NEW_TAX));
  yield put(actions.fetchTaxes(action.payload.uid, true));
  if (action.payload.fromModalID) {
    yield put(
      actions.toggleModal(action.payload.fromModalID, {
        uid: action.payload.uid
      })
    );
  }
}

export function* handleAddNewItem(
  action: ActionType<typeof actions.addNewItem>
) {
  const state: RootState = yield select();
  const formStateItem = selectors.getControls(state, ADD_ITEM_FORM);
  const formStateTax = selectors.getControls(state, ADD_TAX_FORM);
  const discount = _get(formStateItem, "discount.value", undefined);
  const discountType = _get(formStateItem, "discount_type.value", undefined);
  const taxName = _get(formStateTax, "tax_name.value", undefined);
  const taxRate = _get(formStateTax, "tax_rate.value", undefined);
  let taxId = _get(formStateItem, "tax_id.value");

  if (taxName && taxRate) {
    const res1: Response = yield call(RestClient.send, {
      body: {
        tax_name: taxName,
        tax_rate: Number(taxRate)
      },
      service: "add_new_tax",
      showGlobalLoader: true
    });

    if (!res1) {
      return;
    }

    const errors1 = _get(res1, "errors", {});

    if (!_isEmpty(errors1)) {
      yield put(actions.parseServerErrors(errors1, ADD_TAX_FORM));
      return;
    }
    yield put(actions.fetchTaxes(action.payload.uid, true));
    taxId = _get(res1, "data.tax_id");
  }

  yield put(actions.showGlobalLoader());

  const selectedTax = _find(selectors.getTaxes(state), { id: taxId });

  const newItem = {
    discount:
      discountType === "percentage"
        ? Number(discount)
        : utilsNumber.amountStringToInt(discount || 0),
    discountType,
    name: _get(formStateItem, "name.value"),
    taxId,
    taxName: _get(selectedTax, "name"),
    taxRate: _get(selectedTax, "rate"),
    uid: action.payload.uid,
    unitPrice: utilsNumber.amountStringToInt(
      _get(formStateItem, "unit_price.value", 0)
    )
  };

  const res: Response = yield call(RestClient.send, {
    body: {
      discount: newItem.discount,
      discount_type: newItem.discountType,
      name: newItem.name,
      tax_id: newItem.taxId,
      unit_price: newItem.unitPrice
    },
    service: "add_new_item"
    // showGlobalLoader: true
  });

  if (!res) {
    yield put(actions.hideGlobalLoader());
    return;
  }

  yield put(actions.hideGlobalLoader());

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_ITEM_FORM));
    return;
  }
  yield put(actions.closeModal(actions.ModalID.BE_PAID_INVOICE_ADD_NEW_ITEM));
  yield put(actions.fetchItems(action.payload.uid));
  yield put(actions.removeForm(ADD_ITEM_FORM)); // To set empty input when opening

  // need to update new item into invoiceLineItem
  yield put(
    actions.updateInvoiceLineItem({
      item: {
        ...emptyInvoiceLineItem,
        ...newItem,
        itemId: _get(res, "data.id", 0)
      },
      uid: action.payload.uid
    })
  );

  yield put(
    actions.selectItemId({
      id: _get(res, "data.id", 0),
      name: action.payload.uid
    })
  );

  if (taxId) {
    yield put(
      actions.selectTaxId({
        id: taxId,
        name: action.payload.uid
      })
    );
  }
}

export function* handleEditItem(action: ActionType<typeof actions.editItem>) {
  const state: RootState = yield select();
  const formStateItem = selectors.getControls(state, ADD_ITEM_FORM);
  const formStateTax = selectors.getControls(state, ADD_TAX_FORM);
  const discount = _get(formStateItem, "discount.value", undefined);
  const discountType = _get(formStateItem, "discount_type.value", undefined);
  const taxName = _get(formStateTax, "tax_name.value", undefined);
  const taxRate = _get(formStateTax, "tax_rate.value", undefined);
  let taxId = _get(formStateItem, "tax_id.value");

  if (taxName && taxRate) {
    const res1: Response = yield call(RestClient.send, {
      body: {
        tax_name: taxName,
        tax_rate: Number(taxRate)
      },
      service: "add_new_tax",
      showGlobalLoader: true
    });

    if (!res1) {
      return;
    }

    const errors1 = _get(res1, "errors", {});

    if (!_isEmpty(errors1)) {
      yield put(actions.parseServerErrors(errors1, ADD_TAX_FORM));
      return;
    }
    yield put(actions.fetchTaxes(action.payload.uid, true));
    taxId = _get(res1, "data.tax_id");
  }

  const oldData = selectors.getInvoiceLineItems(state)[action.payload.uid];

  const selectedTax = _find(selectors.getTaxes(state), { id: taxId });

  const existedItem = {
    ...oldData,
    discount:
      discountType === "percentage"
        ? Number(discount)
        : utilsNumber.amountStringToInt(discount || 0),
    discountType,
    name: _get(formStateItem, "name.value"),
    taxId,
    taxName: _get(selectedTax, "name"),
    taxRate: _get(selectedTax, "rate"),
    uid: action.payload.uid,
    unitPrice: utilsNumber.amountStringToInt(
      _get(formStateItem, "unit_price.value", 0)
    )
  };

  const res: Response = yield call(RestClient.send, {
    body: {
      discount: existedItem.discount,
      discount_type: existedItem.discountType,
      name: existedItem.name,
      tax_id: existedItem.taxId,
      unit_price: existedItem.unitPrice
    },
    params: {
      item_id: action.payload.itemId
    },
    service: "edit_item",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_ITEM_FORM));
    return;
  }

  yield put(actions.closeModal(ModalID.BE_PAID_INVOICE_ADD_NEW_ITEM));
  yield put(actions.fetchItems(action.payload.uid));
  yield put(actions.removeForm(ADD_ITEM_FORM)); // To set empty input when opening

  // need to update edited item into invoiceLineItem
  yield put(
    actions.updateInvoiceLineItem({
      item: {
        ...existedItem,
        itemId: action.payload.itemId
      },
      uid: action.payload.uid
    })
  );

  yield put(
    actions.selectItemId({
      id: action.payload.itemId,
      name: action.payload.uid
    })
  );

  if (taxId) {
    yield put(
      actions.selectTaxId({
        id: taxId,
        name: action.payload.uid
      })
    );
  }
}

export function* handleFetchItems(_: ActionType<typeof actions.fetchItems>) {
  const res: Response = yield call(RestClient.send, {
    service: "get_items"
  });

  if (!res) {
    const state: RootState = yield select();

    yield put(
      actions.setItems({
        isFetching: false,
        items: selectors.getItems(state) || []
      })
    );

    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);

  yield put(
    actions.setItems({
      isFetching: false,
      items: data.map((i: any) => ({
        discount:
          i.discount !== null && _isNumber(Number(i.discount))
            ? Number(i.discount)
            : undefined,
        discountType: !!i.discount_type ? undefined : i.discount_type,
        id: i.id,
        name: i.name || undefined,
        taxId: i.tax_id || undefined,
        unitPrice: i.unit_price || undefined
      }))
    })
  );

  // if (action.payload.uid) {
  //   yield put(
  //     actions.selectItemId({ name: action.payload.uid, id: _last(data).id })
  //   );
  // }
}

export function* handleFetchAdminCollectedAccounts(
  action: ActionType<typeof actions.fetchAdminCollectedAccounts>
) {
  yield put(actions.displayLoading(true));

  const state: RootState = yield select();
  const formState = selectors.getControls(state, SEARCH_CDD_FORM);
  const orderBy = _get(formState, "sort_cdd.value", "").trim();
  const pageCount = _get(formState, "page_count.value");
  const offset = _get(formState, "offset.value");
  const query = {
    offset: offset,
    page_count: pageCount,
    status: action.payload.status
  };
  if (orderBy) {
    const sort = orderBy.split(" ");
    query[sort[0]] = sort[1];
  }
  const region = _get(formState, "region.value", "").trim();
  if (region != "") {
    query["region"] = region;
  }
  const reviewType = _get(formState, "review_type.value", "").trim();
  if (reviewType != "") {
    query["review_type"] = reviewType;
  }
  const email = _get(formState, "email.value");
  if (email != "") {
    query["email"] = email;
  }
  let is_fetch_cdd_payees: boolean = false;
  if (query["status"] === "B") {
    is_fetch_cdd_payees = true;
  }

  const res: Response = yield call(RestClient.send, {
    query,
    service: is_fetch_cdd_payees
      ? "admin_fetch_cdd_payees"
      : "admin_fetch_collected_account",
    timeout: 30000
  });

  if (!res) {
    return;
  }

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data = _get(res, "data", []);
  const total = _get(res, "total", 0);

  yield put(
    actions.setCollectedAccounts(
      data.map((ca: any) => ({
        accountId: ca.account_id,
        accountNumber: ca.account_number,
        approvedAt: ca.approved_at ? new Date(ca.approved_at) : undefined,
        companyName: ca.company_name,
        countryLabel: utilCountry.getCountryLabelFromCountryId(ca.country_id),
        email: ca.email,
        hasCollected: ca.has_collected,
        hasRequest: ca.has_request,
        id: ca.id,
        payeeData: {
          bankDocumentsView: ca.payee_data.bank_documents_view,
          // brandingColor: ca.payee_data.branding_color,
          // businessIndustry: ca.payee_data.business_industry,
          businessType: ca.payee_data.business_type,
          // companyAddressLine1: ca.payee_data.company_address_line_1,
          // companyAddressLine2: ca.payee_data.company_address_line_2,
          // companyLogo: ca.payee_data.company_logo,
          // companyLogoView: ca.payee_data.company_logo_view || [],
          // companySmsName: ca.payee_data.company_sms_name,
          // defaultFeePayer: ca.payee_data.default_fee_payer,
          // defaultIntFeePayer: ca.payee_data.default_int_fee_payer,
          // defaultStatementDescriptor: ca.payee_data.default_statement_descriptor,
          // gstRegistrationNumber: ca.payee_data.gst_registration_number,
          // paymentDescription: ca.payee_data.payment_description,
          // recipientEmail: ca.payee_data.recipient_email,
          // requesterInternationalRate: Number(
          //   (ca.payee_data.requester_oversea_rate / 100).toFixed(2)
          // ),
          // requesterNationalRate: Number(
          //   (ca.payee_data.requester_national_rate / 100).toFixed(2)
          // ),
          // toCreateOrUploadInvoice:
          // ca.payee_data.to_create_or_upload_invoice
          supportingDocuments: ca.payee_data.supporting_documents,
          kycDocument: {
            bLetterOfAuthorization: _get(
              ca.payee_data,
              "kyc_document.b_letter_of_authorization"
            ),
            bAcraBizfile: _get(ca.payee_data, "kyc_document.b_acra_bizfile"),
            bPoba: _get(ca.payee_data, "kyc_document.b_poba"),
            bStructuredChart: _get(
              ca.payee_data,
              "kyc_document.b_structured_chart"
            )
          }
        },
        status: ca.status,
        updatedAt: new Date(ca.updated_at),
        createdAt: new Date(ca.created_at),
        reviewType: ca.review_type,
        recipientName: ca.recipient_name
      })),
      total
    )
  );
  yield put(actions.displayLoading(false));
}

export function* handleUpdateCollectedAccountStatus(
  action: ActionType<typeof actions.updateCollectedAccountStatus>
) {
  if (!action.payload.status) {
    return;
  }
  yield put(actions.displayLoading(true));
  const res: Response = yield call(RestClient.send, {
    params: {
      id: action.payload.id,
      status: action.payload.status,
      type: _get(action.payload, "reviewType", "").toLocaleLowerCase()
    },
    service: "admin_update_collected_account_status"
  });

  if (!res) {
    return;
  }

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const errors = _get(res, "errors", undefined);
  if (errors) {
    window.Logger.error(errors);
    return;
  }

  yield put(actions.fetchAdminCollectedAccounts(action.payload.prevStatus));
}

export function* handleFetchMainCollectedAccount(
  _: ActionType<typeof actions.fetchMainCollectedAccount>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_collected_account"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const data = _get(res, "data", {});
    const rates = data.rates;
    const collectionPayeeData = _get(json, "data.collected_account.payee_data");
    const collectionOtherSettings = _get(
      json,
      "data.collected_account.other_setting",
      undefined
    );

    const ca = {
      data: data.collected_account
        ? {
            accountId: data.collected_account.account_id,
            accountNumber: data.collected_account.account_number,
            bankBSBId: collectionPayeeData.bank_bsb_id,
            bankCode: collectionPayeeData.bank_code,
            bankId: data.collected_account.bank_id,
            bsbCode: collectionPayeeData.bsb_code,
            currencyId: data.collected_account.currency_id,
            draft: data.collected_account.draft,
            id: data.collected_account.id,
            otherSettings: collectionOtherSettings
              ? ({
                  generateOrManualIN:
                    collectionOtherSettings.to_generate_or_add_manual_invoice_number,
                  invoiceNumberPrefix:
                    collectionOtherSettings.invoice_number_prefix,
                  invoiceNumberTemplate:
                    collectionOtherSettings.invoice_number_template
                } as OtherSettingsCollectedAccount)
              : ({
                  generateOrManualIN: "generate",
                  invoiceNumberPrefix: "INV# - ",
                  invoiceNumberTemplate: "001"
                } as OtherSettingsCollectedAccount),
            payeeData: {
              bankDocuments: collectionPayeeData.bank_documents || [],
              brandingColor: collectionPayeeData.branding_color || undefined,
              businessIndustry: collectionPayeeData.business_industry,
              businessType: collectionPayeeData.business_type,
              companyAddressLine1: collectionPayeeData.company_address_line_1,
              companyAddressLine2: collectionPayeeData.company_address_line_2,
              companyLogo: collectionPayeeData.company_logo,
              companyLogoView: collectionPayeeData.company_logo_view || [],
              companySmsName: collectionPayeeData.company_sms_name,
              confirmedAccountNumber:
                collectionPayeeData.confirmed_account_number,
              confirmedEmail: collectionPayeeData.confirmed_email,
              confirmedPolicy: collectionPayeeData.confirmed_policy,
              confirmedTnc: collectionPayeeData.confirmed_tnc,
              defaultFeePayer: collectionPayeeData.default_fee_payer,
              defaultIntFeePayer: collectionPayeeData.default_int_fee_payer,
              defaultStatementDescriptor:
                collectionPayeeData.default_statement_descriptor,
              gstRegistrationNumber:
                collectionPayeeData.gst_registration_number,
              paymentDescription: collectionPayeeData.payment_description,
              recipientEmail: collectionPayeeData.recipient_email,
              requesterRates: _get(
                collectionPayeeData,
                "requester_rates",
                null
              ),
              toCreateOrUploadInvoice:
                collectionPayeeData.to_create_or_upload_invoice
            },
            purposeId: data.collected_account.purpose_id
          }
        : undefined,
      hasActiveProgram: data.has_active_program,
      isFetched: true,
      rates: _get(rates, "all_rates", [])
    };

    yield put(actions.setMainCollectedAccount(ca));

    // Profile hasActiveProgram property
    yield put(
      actions.setHasActiveProgram({
        hasActiveProgram: data.has_active_program
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchAccountProfiles: ", e.message);
  }
}

export function* handleCheckHasActiveProgram(
  _: ActionType<typeof actions.checkHasActiveProgram>
) {
  const res: Response = yield call(RestClient.send, {
    service: "check_has_active_program"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    yield put(
      actions.setHasActiveProgram({
        hasActiveProgram: _get(res, "data.has_active_program")
        // requesterInternationalRate:
        //   res.data.has_active_program === true
        //     ? Number((res.data.oversea_rate / 100).toFixed(2))
        //     : undefined,
        // requesterNationalRate:
        //   res.data.has_active_program === true
        //     ? Number((res.data.national_rate / 100).toFixed(2))
        //     : undefined
      })
    );
  } catch (e) {
    window.Logger.error(e);
  }
}

export function* handleUpdateCollectedAccountDocument(
  action: ActionType<typeof actions.updateCollectedAccountDocument>
) {
  yield put(actions.displayLoading(true));
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    UPLOAD_DOCUMENT_FORM
  ) as any;
  const supportingDocuments = query.supporting_documents;
  const reviewType = query.review_type;
  const bAcraBizfile = query.b_acra_bizfile;
  const bLetterOfAuthorization = query.b_letter_of_authorization;
  const bPoba = query.b_poba;
  const bStructuredChart = query.b_structured_chart;
  const res: Response = yield call(RestClient.send, {
    body: {
      id: Number(query.id),
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : null,
      review_type: reviewType,
      kyc_documents: {
        b_acra_bizfile: bAcraBizfile ? bAcraBizfile.split(",") : null,
        b_letter_of_authorization: bLetterOfAuthorization
          ? bLetterOfAuthorization.split(",")
          : null,
        b_poba: bPoba ? bPoba.split(",") : null,
        b_structured_chart: bStructuredChart
          ? bStructuredChart.split(",")
          : null
      }
    },
    service: "admin_update_collected_account_document",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", undefined);
  if (errors) {
    yield put(formActions.parseServerErrors(errors, UPLOAD_DOCUMENT_FORM));
    return;
  }
  yield put(actions.closeModal(actions.ModalID.UPLOAD_DOCUMENT_BEPAID_MODAL));
  yield put(actions.fetchAdminCollectedAccounts(query.status));
}

export function* handleAdminListCollectedAccount(
  action: ActionType<typeof actions.adminListCollectedAccount>
) {
  yield put(actions.displayLoading(true));

  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.userId
    },
    service: "admin_list_collected_account"
  });

  const data = _get(res, "data", []);

  yield put(
    actions.setListCollectedAccount(
      data.map((ca: any) => ({
        accountId: ca.account_id,
        accountNumber: ca.account_number,
        approvedAt: ca.approved_at ? new Date(ca.approved_at) : undefined,
        companyName: ca.company_name,
        countryLabel: utilCountry.getCountryCodeFromCountryId(ca.country_id),
        email: ca.email,
        hasCollected: ca.has_collected,
        hasRequest: ca.has_request,
        id: ca.id,
        payeeData: {
          // bankDocumentsView: ca.payee_data.bank_documents_view,
          // brandingColor: ca.payee_data.branding_color,
          businessIndustry: ca.payee_data.business_industry,
          businessType: ca.payee_data.business_type,
          // companyAddressLine1: ca.payee_data.company_address_line_1,
          // companyAddressLine2: ca.payee_data.company_address_line_2,
          // companyLogo: ca.payee_data.company_logo,
          // companyLogoView: ca.payee_data.company_logo_view || [],
          // companySmsName: ca.payee_data.company_sms_name,
          // defaultFeePayer: ca.payee_data.default_fee_payer,
          // defaultIntFeePayer: ca.payee_data.default_int_fee_payer,
          // defaultStatementDescriptor: ca.payee_data.default_statement_descriptor,
          // gstRegistrationNumber: ca.payee_data.gst_registration_number,
          // paymentDescription: ca.payee_data.payment_description,
          // recipientEmail: ca.payee_data.recipient_email,
          // requesterInternationalRate: Number(
          //   (ca.payee_data.requester_oversea_rate / 100).toFixed(2)
          // ),
          // requesterNationalRate: Number(
          //   (ca.payee_data.requester_national_rate / 100).toFixed(2)
          // ),
          // toCreateOrUploadInvoice:
          // ca.payee_data.to_create_or_upload_invoice
          // supportingDocuments: ca.payee_data.supporting_documents,
          kycDocument: {
            bLetterOfAuthorization: _get(
              ca.payee_data,
              "kyc_document.b_letter_of_authorization"
            ),
            bAcraBizfile: _get(ca.payee_data, "kyc_document.b_acra_bizfile"),
            bPoba: _get(ca.payee_data, "kyc_document.b_poba"),
            bDirector: _get(ca.payee_data, "kyc_document.b_director"),
            bShareholder: _get(ca.payee_data, "kyc_document.b_shareholder"),
            bStructuredChart: _get(
              ca.payee_data,
              "kyc_document.b_structured_chart"
            )
          },
          businessOperatingModel: ca.payee_data.business_operating_model,
          customerServe: ca.payee_data.customer_serve,
          businessWebsite: ca.payee_data.business_website,
          averageInvoiceNumber: ca.payee_data.average_invoice_number
        },
        status: ca.status,
        updatedAt: new Date(ca.updated_at),
        createdAt: new Date(ca.created_at),
        reviewType: ca.review_type,
        recipientName: ca.recipient_name
      }))
    )
  );

  yield put(actions.displayLoading(false));
}

// @Deprecate
// Note: previously we need to fetch this separately. After refactored, collection rates
// are fetched along with main collected account.
// export function* handleFetchCollectionRates(
//   action: ActionType<typeof actions.fetchCollectionRates>
// ) {
//   yield put(
//     actions.setCollectionRates({
//       rates: {
//         international: 0,
//         national: 0
//       }
//     })
//   );
//
//   const res: Response = yield call(RestClient.send, {
//     service: "get_rate"
//   });
//
//   if (!res) {
//     yield put(
//       actions.setCollectionRates({
//         rates: {
//           international: 0,
//           national: 0
//         }
//       })
//     );
//     throw new HttpRequestError("Failed to fetch");
//   }
//
//   const data: any[] = _get(res, "data", []);
//
//   yield put(
//     actions.setCollectionRates({
//       rates: {
//         international: Number(
//           (data.filter(d => !d.national)[0].rate / 100).toFixed(2)
//         ),
//         national: Number(
//           (data.filter(d => d.national)[0].rate / 100).toFixed(2)
//         )
//       }
//     })
//   );
// }
