import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as queryString from "querystring";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import RestClient from "src/ipm-shared/services/Rest";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";
import { CHANGE_PASSWORD_FORM } from "./const";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/ipm-platform/pages/Register/selectors";
import {
  PERSONAL_REGISTER_FORM,
  REGISTER_FORM,
  LOGIN_FORM,
  VERIFY_FORM
} from "src/ipm-shared/store/model/Auth/const";
import Is from "src/ipm-shared/Utils/Is";
import T from "src/ipm-shared/Utils/Intl";
import { history } from "src/ipm-shared/store";
import * as commonSelector from "src/ipm-shared/store/model/Auth/selectors";
// import T from "src/ipm-shared/Utils/Intl";

const watchedSagas = [
  takeLatest(getType(actions.register), handleRegister),
  takeLatest(getType(actions.addAccount), handleAddAccount),
  takeLatest(getType(actions.switchAccount), handleSwitchAccount),
  takeLatest(getType(actions.logout), handleLogout),
  takeLatest(getType(actions.changePasswordSubmit), handleChangePassword),
  takeLatest(getType(actions.exchangeToken), handleExchangeToken),
  takeLatest(getType(actions.loginSubmit), handleLogin),
  takeLatest(
    getType(actions.requestResendVerifyEmail),
    handleRequestResendEmailVerification
  ),
  takeLatest(getType(actions.verifyMobile), handleVerifyMobile),
  takeLatest(
    getType(actions.requestResendVerifyMobile),
    handleRequestResendVerifyMobile
  )
];
export default watchedSagas;
export function* handleAddAccount(
  action: ActionType<typeof actions.addAccount>
) {
  try {
    yield call([localStorage, "setItem"], "token", "");
    yield put(actions.setToken({ token: "" }));
    // yield put(
    //   commonActions.toast(
    //     T.transl("SUCCESS_ADD_ACCOUNT")
    //   )
    // );
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleSwitchAccount(
  action: ActionType<typeof actions.switchAccount>
) {
  try {
    yield call([localStorage, "setItem"], "token", "");
    yield put(actions.setToken({ token: "" }));
    // yield put(
    //   commonActions.toast(
    //     T.transl("SUCCESS_SWITCH_ACCOUNT")
    //   )
    // );

    UrlHelper.redirect(
      `/login?${queryString.stringify({
        email: action.payload.email
      })}`
    );
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleLogout(action: ActionType<typeof actions.logout>) {
  try {
    if (window.localStorage.getItem("token")) {
      yield call(RestClient.send, {
        service: "logout"
      });
    }
    yield call([localStorage, "clear"], "token", "");
    // yield put(
    //   commonActions.toast(T.transl({ id: "SUCCESS_LOG_OUT" }))
    // );
    yield put(actions.setToken({ token: "" }));
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleChangePassword(
  action: ActionType<typeof actions.changePasswordSubmit>
) {
  const { successCallback } = action.payload;
  const state = yield select();
  const formState = formSelectors.getControls(state, CHANGE_PASSWORD_FORM);
  const newPassword = _get(formState, "new_password.value");
  const newPasswordConfirm = _get(formState, "new_password_confirm.value");
  const oldPassword = _get(formState, "old_password.value");

  if (newPassword !== newPasswordConfirm) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            new_password_confirm: ["PASSWORD_CONFIRM_DOES_NOT_MATCH"]
          },
          form: []
        },
        CHANGE_PASSWORD_FORM
      )
    );
    return;
  }

  const res = yield call(RestClient.send, {
    body: {
      new_password: newPassword,
      old_password: oldPassword
    },
    service: "change_password",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CHANGE_PASSWORD_FORM));

    return;
  }

  // TODO: after success, take new token and refresh the page. Revoke old token in backend
  yield put(
    formActions.setControl({
      name: "new_password",
      value: ""
    })
  );

  yield put(
    formActions.setControl({
      name: "new_password_confirm",
      value: ""
    })
  );

  yield put(
    formActions.setControl({
      name: "old_password",
      value: ""
    })
  );

  if (successCallback) {
    successCallback();
  }
}

export function* handleExchangeToken(
  action: ActionType<typeof actions.exchangeToken>
) {
  const {
    countryCode,
    companyId,
    currencyCode,
    paidCurrencyCode,
    preventDefault,
    redirectPath,
    purpose
  } = action.payload;

  if (window.stop) {
    window.stop();
  }

  const res: Response = yield call(RestClient.send, {
    query: {
      company_id: companyId,
      country: countryCode,
      currency: currencyCode,
      paid_currency: paidCurrencyCode,
      purpose
    },
    service: "exchange_token",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  try {
    const token = _get(res, "data.token", "");
    yield call([localStorage, "clear"], "token", "");
    yield call([localStorage, "setItem"], "token", token);
    yield put(actions.setToken({ token }));

    if (preventDefault) {
      return;
    }

    // Note: Will redirect to login if token is empty
    if (redirectPath) {
      UrlHelper.redirect(redirectPath);
    } else {
      const isDisplayIntPayReminderPopup = _get(
        res,
        "data.int_pay_reminder_notification",
        false
      );

      // only display IntPayReminderPopup (after logged in) or after exchange token
      if (isDisplayIntPayReminderPopup) {
        UrlHelper.redirect(`/?m=${ModalID.INTERNATIONAL_KYC_REMINDER_MODAL}`);
      } else {
        // root page
        if (window.stop) {
          window.stop();
        }
        window.location.reload();
      }
    }
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleRegister(action: ActionType<typeof actions.register>) {
  const state: RootState = yield select();
  let formState;
  if (action.payload.accountType === "business") {
    formState = selectors.getControls(state, REGISTER_FORM);
  } else {
    formState = selectors.getControls(state, PERSONAL_REGISTER_FORM);
  }

  const password = _get(formState, "password.value");
  const passwordConfirm = _get(formState, "password_confirm.value");
  let qs = queryString.parse(_get(location, "search", "").replace("?", ""));
  let qsOrigin = _get(qs, "utm_origin", _get(qs, "origin", "")) as any;
  qsOrigin = qsOrigin
    .replaceAll(/undefined/gi, "")
    .trim()
    .toLowerCase();
  let utmCampaign = _get(qs, "utm_campaign", "") as any;
  utmCampaign = utmCampaign
    .replaceAll(/undefined/gi, "")
    .trim()
    .toLowerCase();

  if (password !== passwordConfirm) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            password_confirm: ["PASSWORD_CONFIRM_DOES_NOT_MATCH"]
          },
          form: []
        },
        REGISTER_FORM
      )
    );
    return;
  }
  const env = _get(formState, "env_type.value");
  let isLive = Is.live();

  if (!isLive) {
    isLive = env === "live";
  }

  qs = queryString.parse(_get(window.location, "search", ""));

  const res = yield call(RestClient.send, {
    body: {
      account_type: action.payload.accountType,
      allow_weak: _get(formState, "password_allow_weak.value") === "allowed",
      by_pass_phone_number_code: Is.live() ? undefined : "jBLrg*:X~6~Vz*x#9*%J",
      country_code: _get(formState, "country_code.value"),
      email: _get(formState, "email.value"),
      first_name: _get(formState, "first_name.value"),
      google_recaptcha_token: action.payload.recaptchaToken,
      is_production: isLive,
      last_name: _get(formState, "last_name.value"),
      mobile: _get(formState, "mobile.value"),
      mobile_country_code: _get(formState, "mobile_country_code.value"),
      origin: qsOrigin,
      partnership: qs.utm_campaign,
      password: _get(formState, "password.value"),
      password_confirm: _get(formState, "password_confirm.value"),
      prefilled_company: _get(formState, "prefilled_company.value"),
      program_interest: qs.program,
      // @ts-ignore
      recaptcha_version: action.payload.history.recaptchaVersion,
      referral_code: _get(formState, "referral_code.value"),
      referrer: document.referrer,
      register_url: window.location.href,
      utm_campaign: utmCampaign
    },
    service: "create_account",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    const formError = _get(errors, "form", [])[0];
    if (formError === "You are not verified.") {
      // @ts-ignore
      action.payload.history.recaptchaVerifyFailed = true;

      const submitRegisterForm = document.getElementById(
        "submit-register-form"
      );
      if (submitRegisterForm) {
        submitRegisterForm.click();
      }
      return;
    }

    yield put(formActions.parseServerErrors(errors, REGISTER_FORM));
    return;
  }

  // @ts-ignore
  action.payload.history.recaptchaVerifyFailed = false;
  action.payload.history.push(
    "/post-register?" +
      queryString.stringify({
        account_type: action.payload.accountType,
        email: _get(formState, "email.value")
      })
  );
}

export function* handleLogin(action: ActionType<typeof actions.loginSubmit>) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, LOGIN_FORM);
  const email = _get(formState, "email.value", "");
  const password = _get(formState, "password.value", "");

  if (!email) {
    yield put(
      formActions.setControl({
        errors: [
          {
            code: "0",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: LOGIN_FORM,
        name: "email"
      })
    );
    return;
  }

  if (!password) {
    yield put(
      formActions.setControl({
        errors: [
          {
            code: "0",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: LOGIN_FORM,
        name: "password"
      })
    );
    return;
  }

  yield put(formActions.resetErrors(LOGIN_FORM));
  const res = yield call(RestClient.send, {
    body: {
      client_id: 1,
      default_account: action.payload.defaultAccount,
      email: _get(formState, "email.value"),
      password: _get(formState, "password.value")
    },
    service: "post_login",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", undefined);

  if (errors) {
    yield put(formActions.parseServerErrors(errors, LOGIN_FORM));

    const errorForm = _get(errors, "form") || [];
    const errorFields = _get(errors, "fields") || {};

    const errorFieldEmail = _get(errorFields, "email") || [];

    if (
      errorFieldEmail.length > 0 &&
      errorFieldEmail.includes("EMAIL_VERIFICATION_REQUIRED")
    ) {
      yield put(actions.requestVerifyEmail());
    }
    if (
      errorForm.length > 0 &&
      errorForm.includes("UNVERIFIED_PHONE_NUMBE_ERROR")
    ) {
      yield call([localStorage, "setItem"], "sms_otp_token", res.data.token);
      yield put(actions.setSmsOtpToken({ smsOtpToken: res.data.token }));
      history.push("/mobile-verification");
    }
    return;
  }

  try {
    yield call([localStorage, "setItem"], "token", res.data.token);
    yield put(actions.setToken({ token: res.data.token }));

    if (action.payload.redirectUrl) {
      if (
        UrlHelper.isUrl(action.payload.redirectUrl) &&
        !UrlHelper.isMatchWhiteListSite(action.payload.redirectUrl)
      ) {
        UrlHelper.redirect("/admin/payments");
      } else {
        UrlHelper.redirect(decodeURIComponent(action.payload.redirectUrl));
      }
    } else {
      const isDisplayIntPayReminderPopup = _get(
        res.data,
        "int_pay_reminder_notification",
        false
      );

      // display IntPayReminderPopup or root page (after logged in)
      if (isDisplayIntPayReminderPopup) {
        UrlHelper.redirect(`/?m=${ModalID.INTERNATIONAL_KYC_REMINDER_MODAL}`);
      } else {
        UrlHelper.redirect("/admin/payments");
      }
    }
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleRequestResendEmailVerification(
  action: ActionType<typeof actions.requestResendVerifyEmail>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, LOGIN_FORM);
  const email = _get(formState, "email.value");

  const res = yield call(RestClient.send, {
    body: {
      email
    },
    service: "post_resend_email_verification",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, LOGIN_FORM));
    return;
  }

  // yield put(
  //   actions.toast(T.transl({ id: "EMAIL_SENT" }, { email }))
  // );
}

export function* handleVerifyMobile(
  action: ActionType<typeof actions.verifyMobile>
) {
  const state: RootState = yield select();
  const { smsOtp } = action.payload;
  const smsOtpToken = commonSelector.getSmsOtpToken(state);
  yield put(formActions.resetFormErrors(VERIFY_FORM));
  const res = yield call(RestClient.send, {
    body: {
      smsotp: smsOtp,
      token: smsOtpToken
    },
    service: "verify_mobile",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, VERIFY_FORM));
    return;
  }

  try {
    // TODO: use proper persisting
    // yield put(actions.start());
    yield call([localStorage, "setItem"], "token", res.data.token);
    yield put(actions.setToken({ token: res.data.token }));
    // yield put(actions.clearCompany());

    window.location.href = "/";
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleRequestResendVerifyMobile(
  action: ActionType<typeof actions.requestResendVerifyMobile>
) {
  const state: RootState = yield select();
  const smsOtpToken = commonSelector.getSmsOtpToken(state);
  const { cb } = action.payload;

  yield put(formActions.resetFormErrors(VERIFY_FORM));
  const res = yield call(RestClient.send, {
    body: {
      token: smsOtpToken
    },
    service: "resend_mobile_verification",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, VERIFY_FORM));
    return;
  }

  try {
    yield call([localStorage, "setItem"], "sms_otp_token", res.data.token);
    yield put(actions.setSmsOtpToken({ smsOtpToken: res.data.token }));
    cb();
  } catch (e) {
    window.Logger.guestError(e);
  }
}
