import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

// import * as selectors from "../selectors";
import * as actions from "./actions";

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  closeModal: actions.closeModal.bind(
    actions,
    actions.ModalID.BE_PAID_MAKE_PAYMENT
  ),
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
