import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";
import BasicDashboard from "./Themes/Basic";
import FnBDashboard from "./Themes/FnB";
import { IconClose } from "src/ipm-shared/images/IconClose";
import AccountProfileUtil from "src/ipm-shared/Utils/AccountProfile";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";
import * as classNames from "classnames";
import FacebookTrack from "src/ipm-shared/components/FacebookPixelProvider/FacebookTrack";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

import { format, startOfToday } from "date-fns";

const mapStateToProps = (state: RootState) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  dashboardThemeName: selectors.getDashboardTheme(state),
  isBusiness: selectors.getIsBusinessAccount(state),
  isCloseKycStatusBar: selectors.isCloseKycStatusBar(state),
  isPersonal: selectors.getIsPersonalAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state)
});

const mapDispatchToProps = {
  changeUserSetting: actions.changeUserSetting,
  fetchDashboardCollectedPayments: actions.fetchDashboardCollectedPayments,
  fetchPaymentCalendar: actions.fetchPaymentCalendar,
  fetchPaymentCollectionsActivityStatus:
    actions.fetchPaymentCollectionsActivityStatus
};

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class DashboardPage extends React.Component<IProps> {
  public render() {
    const {
      accountCountryCode,
      wallexKycStatus,
      isBusiness,
      isPersonal,
      dashboardThemeName,
      isCloseKycStatusBar
    } = this.props;

    const closeableStatus =
      wallexKycStatus === WALLEX_KYC_STATUS.APPROVED ||
      wallexKycStatus === WALLEX_KYC_STATUS.REJECTED ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_VERIFICATION ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_DOCS ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_PHONE ||
      wallexKycStatus === WALLEX_KYC_STATUS.DOCUMENTS_NEED_RESUBMISSION;

    const isDeclined =
      wallexKycStatus === WALLEX_KYC_STATUS.REJECTED ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_VERIFICATION ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_DOCS ||
      wallexKycStatus === WALLEX_KYC_STATUS.PENDING_PHONE ||
      wallexKycStatus === WALLEX_KYC_STATUS.DOCUMENTS_NEED_RESUBMISSION;

    const isReview =
      wallexKycStatus < 7 || wallexKycStatus === WALLEX_KYC_STATUS.REVIEW;

    const isDisplayBetaIcon =
      accountCountryCode === "SG" && wallexKycStatus === 0;

    return (
      <div
        className={classNames(`main-dashboard`, {
          "beta-icon-space": isDisplayBetaIcon,
          "personal-dashboard": isPersonal
        })}
      >
        <FeatureGate feature="NORMAL_PAYMENT">
          {wallexKycStatus > 0 && !isCloseKycStatusBar && (
            <div
              className={classNames("account-review-status", {
                approved: wallexKycStatus === WALLEX_KYC_STATUS.APPROVED,
                declined: isDeclined,
                review: isReview
              })}
            >
              <p className="text-center mb-0">
                {AccountProfileUtil.getWallexStatusText(
                  wallexKycStatus,
                  isBusiness ? "business" : "personal"
                )}
              </p>
              {closeableStatus && (
                <span
                  className="icon-close-section"
                  onClick={this.handleCloseStatusBar}
                >
                  <IconClose />
                </span>
              )}
            </div>
          )}
        </FeatureGate>

        <FacebookTrack accountTypeCheck={true} title={"View"} />
        {isPersonal && (
          <FacebookTrack
            accountTypeCheck={false}
            title={"ViewPersonalDashboard"}
          />
        )}
        {isBusiness && (
          <FacebookTrack
            accountTypeCheck={false}
            title={"ViewBusinessDashboard"}
          />
        )}

        {dashboardThemeName === "fnb" ? <FnBDashboard /> : <BasicDashboard />}
      </div>
    );
  }

  public componentDidMount() {
    const today = startOfToday();
    const month = parseInt(format(new Date(today), "M"), 10);
    const year = parseInt(format(new Date(today), "YYYY"), 10);
    this.props.fetchPaymentCalendar({ month, year });
    this.props.fetchPaymentCollectionsActivityStatus();
    this.props.fetchDashboardCollectedPayments();
  }

  private handleCloseStatusBar = () => {
    this.props.changeUserSetting({
      enable: true,
      property: "close_kyc_status_bar"
    });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
