import * as React from "react";
import { IProps } from "./index";
import PageBase from "src/ipm-platform/pages/Base";
import DayPicker from "react-day-picker";
import { format } from "date-fns";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import _get from "lodash-es/get";
import { PAYMENT_STATUS } from "src/ipm-shared/store/model/Payment/const";

import T from "src/ipm-shared/Utils/Intl";

class BePaidCashFlowCalendar extends PageBase<IProps, any> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    const {
      inComingPayments,
      getSumPaymentInThisMonth,
      getCurrenciesById,
      collectedAccountCurrencyId
    } = this.props;

    const currencyId = collectedAccountCurrencyId;
    const modifiers = {
      inComingPayments: inComingPayments.map(day => new Date(day))
    };

    return (
      <div className="cash-flow-block">
        <DayPicker
          className={"cash-flow-calendar"}
          modifiers={modifiers}
          showOutsideDays={true}
          weekdaysShort={["S", "M", "T", "W", "T", "F", "S"]}
          onMonthChange={this.handleMonthYearChange}
        />
        <div className={"cash-flow-describe"}>
          <div className="top-section">
            <h6 className={"head-title"}>
              {T.transl("BE_PAID_CALENDAR_TITLE")}
            </h6>

            <p className={"copy"}>{T.transl("BE_PAID_CALENDAR_SUBTITLE")}</p>
            <div className={"currency"}>
              {currencyId && getCurrenciesById[currencyId].symbol}
              <b>
                <ShortCurrency
                  value={
                    _get(
                      getSumPaymentInThisMonth,
                      [PAYMENT_STATUS.COMPLETED],
                      0
                    ) + _get(getSumPaymentInThisMonth, [PAYMENT_STATUS.PAID], 0)
                  }
                />
              </b>
            </div>
          </div>
          <ul>
            <li className={"in-coming"}>
              <span>
                {T.transl("LEGEND_INCOMING_PAYMENT", {
                  b: (text: any) => <b>{text}</b>
                })}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  private handleMonthYearChange = (date: Date) => {
    const month = parseInt(format(new Date(date), "M"), 10);
    const year = parseInt(format(new Date(date), "YYYY"), 10);
    this.props.fetchPaymentCalendar({ month, year });
  };
}

export default BePaidCashFlowCalendar;
