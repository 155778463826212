import T from "src/ipm-shared/Utils/Intl";

export const ADD_FORM = "collected_account_form";
export const ADDITIONAL_INVOICE_FORM = "additional_invoice_form";
export const SETUP_INVOICE_NUMBER = "setup_invoice_number";
export const ADD_TAX_FORM = "add_tax_form";
export const ADD_ITEM_FORM = "add_item_form";
export const CREATE_INVOICE_FORM = "collected_create_invoice_form";
export const SMS_REQUEST_FORM = "collected_sms_request_form";
export const UPLOAD_DOCUMENT_FORM = "upload_document_form";

export enum FEE_PAYER {
  CUSTOMER_PAY = 1,
  COMPANY_PAY,
  SHARED_PAY
}

export const ADD_INVOICE_NUMBER_TYPE = {
  GENERATE: "generate",
  MANUAL: "manual"
};

export const STATEMENT_DESCRIPTOR_MAX_LENGTH = 22;
export const STATEMENT_DESCRIPTOR_MIN_LENGTH = 5;

export const TermsOptions = [
  {
    label: T.transl("INVOICE_DATE_PLACEHOLDER"),
    value: 0
  },
  {
    label: T.transl("INVOICE_DATE_NET_15_OPTION"),
    value: 15
  },
  {
    label: T.transl("INVOICE_DATE_NET_30_OPTION"),
    value: 30
  },
  {
    label: T.transl("INVOICE_DATE_NET_45_OPTION"),
    value: 45
  },
  {
    label: T.transl("INVOICE_DATE_NET_60_OPTION"),
    value: 60
  },
  {
    label: T.transl("INVOICE_DATE_CUSTOM_OPTION"),
    value: -1
  }
];

export const FNB_CATEGORY = [16, 17, 18, 19]; // Children items of Food and Drinks category.

export const COLLECTED_ACCOUNT_STATUS = {
  APPROVED: "",
  DECLINED: "D",
  UNDER__REVIEW: "U"
};

export const COLLECTED_ACCOUNT_REVIEW_TYPE = {
  PAY: "pay",
  FETCH: "fetch",
  ACCOUNT: "account"
};

export const NEW_ITEM_OPTION = {
  label: T.transl("INVOICE_ITEM_NEW_OPTION"),
  value: -1 // -1 Is add new
};

export const NEW_TAX_OPTION = {
  label: T.transl("INVOICE_TAX_NEW_OPTION"),
  value: -1 // -1 Is add new
};
