const URL_REGEX = /^(https?|ftps?|sftp):\/\/[^\s$.?#].[^\s]*$/i;
const WHITE_LIST_SITE_REGEX = /(?:http:\/\/)?(?:([^.]+)\.)?ipaymy\.com/i;

const isUrl = (url: string): RegExpMatchArray | null => {
  return url.match(URL_REGEX);
};

const isMatchWhiteListSite = (url: string): RegExpMatchArray | null => {
  return url.match(WHITE_LIST_SITE_REGEX);
};

const getQsRedirectUrlUserLoggedOut = (): string => {
  let qsRedirectUrl = "";

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/login"
  ) {
    qsRedirectUrl = `?redirect_url=${encodeURIComponent(
      window.location.pathname + window.location.search
    )}`;
  }

  return qsRedirectUrl;
};

// When use this:
// Case study 1: Clear cache / fetch new source code
// Case study 2: Re-fetch/Reset whole data. For example: start payment flow, login
function redirect(url: string): void {
  if (url.charAt(0) === "/") {
    if (window.stop) {
      window.stop();
    }
    window.location.href = url;
  } else {
    if (isUrl(url) && isMatchWhiteListSite(url)) {
      if (window.stop) {
        window.stop();
      }
      window.location.href = url;
      return;
    } else {
      console.error("Failed to redirect: url is not in whitelist, url=", url);
    }
  }
}
export default {
  getQsRedirectUrlUserLoggedOut,
  isMatchWhiteListSite,
  isUrl,
  redirect
};
