import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

import * as selectors from "../../selectors";
import * as actions from "../../actions";

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state),
  isBusiness: selectors.getIsBusinessAccount(),
  isCrossBorder: selectors.isCrossBorder(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
