import React, { useContext, useState } from "react";
import Tooltip from "reactstrap/lib/Tooltip";
import { LanguageContext } from "src/ipm-shared/Contexts";

interface IProps {
  dropdownId?: string;
}

export default function DashboardSwitchLanguage({ dropdownId }: IProps) {
  const {
    changeLanguage: handleChangeLanguage,
    languageOptions,
    currentLanguageValue
  } = useContext(LanguageContext);

  const [isOpenLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
  const toggleShowLanguageSwitcher = () => {
    setShowLanguageSwitcher(!isOpenLanguageSwitcher);
  };

  const changeLanguage = (lang: string) => () => {
    handleChangeLanguage(lang);
  };

  const targetID = dropdownId || "LanguageSwitcher";

  return (
    <div className={"dashboard-switch-language mr-3"} id={targetID}>
      <svg
        id="Component_1_1"
        data-name="Component 1 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="81.388"
        height="27.273"
        viewBox="0 0 81.388 27.273"
      >
        <g
          id="Rectangle_7"
          data-name="Rectangle 7"
          transform="translate(16.388 5.106)"
          fill="#fff"
          stroke="#34405b"
          strokeWidth="0.8"
        >
          <rect width="65" height="18" rx="9" stroke="none" />
          <rect
            x="0.4"
            y="0.4"
            width="64.2"
            height="17.2"
            rx="8.6"
            fill="none"
          />
        </g>
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="12.862"
          cy="12.862"
          r="12.862"
          transform="matrix(0.998, -0.062, 0.062, 0.998, 0, 1.599)"
          fill="#f2e5fa"
        />
        <text
          id="繁體中文"
          transform="translate(33.388 17.106)"
          fill="#34405b"
          fontSize="9"
          fontFamily="SourceHanSansHK-Bold, Source Han Sans HK"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            {currentLanguageValue.label}
          </tspan>
        </text>
        <g
          id="Group_11"
          data-name="Group 11"
          transform="translate(-1039.584 -9.879)"
        >
          <g id="globe" transform="translate(1045.972 15.985)">
            <circle
              id="Ellipse_6"
              data-name="Ellipse 6"
              cx="7.5"
              cy="7.5"
              r="7.5"
              transform="translate(0 0)"
              fill="none"
              stroke="#34405b"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_1"
              data-name="Line 1"
              x2="15"
              transform="translate(0 7)"
              fill="none"
              stroke="#34405b"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M10.916,2a11.156,11.156,0,0,1,2.916,7.291,11.156,11.156,0,0,1-2.916,7.291A11.156,11.156,0,0,1,8,9.291,11.156,11.156,0,0,1,10.916,2Z"
              transform="translate(-3.638 -2)"
              fill="none"
              stroke="#34405b"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>

      <Tooltip
        isOpen={isOpenLanguageSwitcher}
        autohide={false}
        target={targetID}
        toggle={toggleShowLanguageSwitcher}
        className={
          "nav-properties-tooltip switcher-language-tooltip switcher-language-tooltip--bottom"
        }
      >
        <div className={"tooltip-dropdown"}>
          {languageOptions.map(languageOption => (
            <div key={languageOption.value}>
              <button onClick={changeLanguage(languageOption.value)}>
                {languageOption.label}
              </button>
            </div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
}
