import * as React from "react";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import { FormattedNumber } from "react-intl";

interface IProps extends React.Props<any> {
  value: number;
  currencyId?: number;
  removedZeroDecimal?: boolean;
}

export const toCents = (
  value: number = 0,
  removedZeroDecimal: boolean = false
): number => {
  let isNegativeNumber: boolean = false;
  if (value < 0) {
    isNegativeNumber = true;
    value = Math.abs(value);
  }

  const s = value.toString();
  let sig: string;
  if (value < 100) {
    sig = "0";
  } else {
    sig = s.slice(0, s.length - 2);
  }
  let cents: string;
  if (value % 100 < 10) {
    cents = "0" + (value % 100);
  } else {
    cents = (value % 100).toString();
  }

  let res = `${sig}.${cents}`;
  if (removedZeroDecimal) {
    if (res.length > 3 && res.slice(-3) === ".00") {
      res = res.substr(0, res.length - 3);
    }
  }

  if (isNegativeNumber) {
    res = "-" + res;
  }

  return Number(res);
};

const Index: React.SFC<IProps> = props => (
  <FormattedNumber
    value={toCents(props.value, props.removedZeroDecimal)}
    minimumFractionDigits={props.removedZeroDecimal ? 0 : 2}
    maximumFractionDigits={2}
  >
    {(formatted: string) => {
      if (props.currencyId) {
        return `${
          CurrencyUtil.convertFromId(props.currencyId as number).$
        }${formatted}`;
      } else {
        return formatted;
      }
    }}
  </FormattedNumber>
);

export default Index;
