import * as React from "react";
import T from "src/ipm-shared/Utils/Intl";
import BetaRibbon from "src/ipm-shared/images/beta-ribbon.svg";
import BePaidMakePayment from "src/ipm-platform/pages/Dashboard/BePaid/MakePayment";
import BePaidMakePaymentCarousel from "src/ipm-platform/pages/Dashboard/BePaid/MakePaymentCarousel";
import BePaidCashFlowCalendar from "src/ipm-platform/pages/Dashboard/BePaid/CashFlowCalendar";
import BePaidPaymentPanels from "src/ipm-platform/pages/Dashboard/BePaid/PaymentPanels";

interface IProps {
  theme: "fnb" | "basic" | "";
}

export default (props: IProps) => {
  return (
    <div className={"d-flex slider-section"}>
      <div
        className={"services-section be-paid-services-section section-block"}
      >
        {props.theme !== "fnb" && (
          <div className={"headline color-white personal-bg-color"}>
            <span className={"beta-ribbon"}>
              <img src={BetaRibbon} alt={"Beta Ribbon Icon"} />
            </span>
            <b className={"text-uppercase"}>{T.transl("BE_PAID_LABEL")}</b>
          </div>
        )}
        <div className="inner-content">
          {props.theme === "fnb" ? (
            <BePaidMakePaymentCarousel />
          ) : (
            <BePaidMakePayment />
          )}
          <BePaidCashFlowCalendar />
        </div>
      </div>

      <BePaidPaymentPanels />
    </div>
  );
};
