import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType, getType } from "typesafe-actions";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as paymentRequestActions from "./actions";
import * as paymentActions from "../Payment/actions";
import * as commonSelectors from "../selectors";
import * as paymentSelectors from "./selectors";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as legacyFormSelectors from "../LegacyForms/selectors";
import * as countrySelectors from "src/ipm-shared/store/model/Country/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as cardSelectors from "../Card/selectors";
import * as accountProfileSelectors from "../AccountProfile/selectors";
import * as collectedAccountSelectors from "../CollectedAccount/selectors";
import * as dateSelectors from "../DateCondition/selectors";
import * as cardActions from "../Card/actions";
import * as commonActions from "../actions";
import * as purposeActions from "../Purpose/actions";
import * as dataConditionActions from "../DateCondition/actions";

import { ADD_FORM } from "../Payment/const";
import RestClient from "src/ipm-shared/services/Rest";
import ScheduleType from "src/ipm-shared/components/ScheduleType";
import { history } from "src/ipm-shared/store";
import * as payeeSelectors from "../Payee/selectors";
import { delay } from "redux-saga";
import { CHECKOUT_FORM } from "../Card/const";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import T from "src/ipm-shared/Utils/Intl";
import _uniq from "lodash-es/uniq";
import { FORM } from "../Payee/const";
import utils from "src/ipm-shared/Utils/Number";
import * as queryString from "query-string";
import { defaultState } from "./reducers";
import LocalStorage from "src/ipm-shared/Utils/LocalStorage";
import CardUtil from "src/ipm-shared/Utils/Card";
import {
  client,
  ThreeDSecure,
  threeDSecure,
  ThreeDSecureVerifyPayload
} from "braintree-web";
import { toCents } from "../../../components/ShortCurrency";
import { getStripe } from "../Stripe/actions";
import { paymentRequiredActionParams } from "../Payment/types";
import { toggleModal } from "src/ipm-shared/components/Form/actions";
import { StripeCardCvcElement } from "@stripe/stripe-js";

const selectors = {
  ...paymentSelectors,
  ...formSelectors,
  ...countrySelectors,
  ...commonSelectors,
  ...cardSelectors,
  ...legacyFormSelectors,
  ...payeeSelectors,
  ...accountProfileSelectors,
  ...dateSelectors,
  ...collectedAccountSelectors
};

const actions = {
  ...paymentRequestActions,
  ...paymentActions,
  ...formActions,
  ...commonActions,
  ...purposeActions,
  ...cardActions,
  ...dataConditionActions
};

const watchedSagas = [
  takeLatest(getType(actions.fulfilPaymentSubmit), handleFulfilPaymentRequest),
  takeLatest(getType(actions.addPaymentRequestSubmit), handleAddPaymentRequest),
  takeLatest(
    getType(actions.editPaymentRequestSubmit),
    handleEditPaymentRequest
  ),
  reTryTakeLatest(actions.fetchPaymentRequest, handleFetchPaymentRequest),
  reTryTakeLatest(actions.fetchPaymentFee, handleFetchFee),
  reTryTakeLatest(actions.getExchangeRate, handleGetExchangeRate),
  takeLatest(
    getType(actions.validatePaymentPayeeSubmit),
    handleValidatePaymentPayeeSubmit
  ),
  takeLatest(getType(actions.selectCard), handleSelectCard),
  takeLatest(getType(actions.applyCoupon), handleApplyCoupon),
  takeLatest(getType(actions.deleteCouponUsed), handleDeleteCouponUsed),
  takeLatest(getType(actions.validateAmount), handleValidateAmount)
];

export default watchedSagas;

export function* handleFulfilPaymentRequest(
  action: ActionType<typeof actions.fulfilPaymentSubmit>
) {
  const state: RootState = yield select();
  const token = selectors.getToken(state);
  if (!token) {
    return;
  }

  const paymentRequest = selectors.getCurrentPaymentRequest(state);
  const cardId = selectors.getSelectedCard(state);
  const card = selectors.cardsById(state)[cardId];
  const formState = formSelectors.getControls(state, CHECKOUT_FORM);
  const statementDescriptor = _get(formState, "statement_descriptor.value");
  const couponUsed = selectors.getCouponUsed(state);

  if (cardId <= 0) {
    return;
  }

  yield put(actions.showGlobalLoader());

  let wpSessionId: string | undefined;
  if (CardUtil.isWorldpay(card.acquirerId)) {
    wpSessionId = yield call(getWorldpaySessionId, cardId, card.acquirerId);
    if (!wpSessionId) {
      yield put(actions.hideGlobalLoader());
      return;
    }
  }
  const fees = selectors.getFees(state);
  let payeeQuoteToken = null;
  if (_get(fees, "payeeQuoteToken")) {
    payeeQuoteToken = _get(fees, "payeeQuoteToken");
  }
  const res = yield call(RestClient.send, {
    body: {
      card_id: Number(cardId),
      coupon_code: couponUsed,
      payment_token: paymentRequest.token,
      statement_descriptor: statementDescriptor,
      wp_session_id: wpSessionId,
      payee_quote_token: payeeQuoteToken,
      payment_method_type: "card",
      payment_method_id: 1
    },
    service: "create_payment"
  });

  if (!res) {
    yield put(actions.hideGlobalLoader());
    return;
  }

  const errors = _get(res, "errors", undefined);
  const data = _get(res, "data", undefined);
  const requiredAction = _get(res, "action_required", undefined);
  if (requiredAction) {
    if (requiredAction.recollect_cvv) {
      yield put(actions.hideGlobalLoader());
      yield put(
        actions.toggleModal(actions.ModalID.RECOLLECT_CVV, {
          callback: (cvv: string | StripeCardCvcElement) => {
            const it = processPaymentRequiredAction(
              {
                acquirerId: requiredAction.acquirer_id,
                callbackUrl: requiredAction.callback_url,
                threedsToken: requiredAction.threeds_token,
                paymentTotal: requiredAction.payment_total,
                bin: requiredAction.bin,
                provider: requiredAction.provider,
                clientToken: requiredAction.client_token,
                mid: requiredAction.mid ? requiredAction.mid : undefined,
                recollectCVV: requiredAction.recollect_cvv
              },
              cvv
            );

            while (!it.next().done) {
              // do nothing
            }
          },
          acquirerId: requiredAction.acquirer_id,
          provider: requiredAction.provider
        })
      );
    } else {
      yield processPaymentRequiredAction({
        acquirerId: requiredAction.acquirer_id,
        callbackUrl: requiredAction.callback_url,
        threedsToken: requiredAction.threeds_token,
        paymentTotal: requiredAction.payment_total,
        bin: requiredAction.bin,
        provider: requiredAction.provider,
        clientToken: requiredAction.client_token,
        mid: requiredAction.mid ? requiredAction.mid : undefined,
        recollectCVV: requiredAction.recollect_cvv
      });
    }

    return;
  }

  yield put(actions.hideGlobalLoader());

  if (_isEmpty(errors)) {
    if (data.was_charged_today) {
      yield put(
        actions.toggleModal(
          actions.ModalID.PAYMENT_SUCCESS,
          {
            currencyId: data.currency_id,
            paymentTotal: data.payment_total,
            payoutDate: data.payout_date,
            purposeId: paymentRequest.purposeId,
            receiptNumber: data.receipt_number
          },
          {
            backdrop: "static",
            keyboard: false
          }
        )
      );
    } else {
      yield put(
        actions.toggleModal(
          actions.ModalID.PAYMENT_SUCCESS_SCHEDULE,
          {
            chargeDate: data.charge_date,
            currencyId: data.currency_id,
            paymentTotal: data.payment_total,
            payoutDate: data.payout_date,
            purposeId: paymentRequest.purposeId
          },
          {
            backdrop: "static",
            keyboard: false
          }
        )
      );
    }
  } else {
    const formErrs = _get(errors, "form", []);
    let fieldErrs: string[] = [];
    Object.keys(_get(errors, "fields", {})).map(fieldName => {
      fieldErrs = fieldErrs.concat(_get(errors.fields, fieldName, []));
    });
    yield put(
      actions.toggleModal(actions.ModalID.PAYMENT_ERROR, {
        errors: formErrs.concat(fieldErrs)
      })
    );
  }
}

export async function getWPChallengeTransactionId(
  acquirerId: number,
  threedsToken: string,
  bin: string
): Promise<string | undefined> {
  return await new Promise<string | undefined>((resolve, reject) => {
    let iframeEl = document.getElementById(
      "wp_challenge_iframe"
    ) as HTMLIFrameElement;
    if (iframeEl) {
      iframeEl.remove();
    }
    const endpoint = CardUtil.getWorldpayChallengeEndpoint(
      CardUtil.isWorldpayProd(acquirerId)
    );
    const wpChallengeListener = (event: MessageEvent) => {
      let transactionId = "";
      if (
        event.origin.indexOf(
          new URL(process.env.REACT_APP_LOCAL_API_ENDPOINT as string).origin
        ) === 0 ||
        event.origin.indexOf(
          new URL(process.env.REACT_APP_DEV_API_ENDPOINT as string).origin
        ) === 0 ||
        event.origin.indexOf(
          new URL(process.env.REACT_APP_PROD_API_ENDPOINT as string).origin
        ) === 0
      ) {
        transactionId = event.data;
      }
      if (transactionId) {
        resolve(transactionId);
      } else {
        resolve(undefined);
      }

      if (iframeEl) {
        iframeEl.remove();
      }
    };

    window.removeEventListener("message", wpChallengeListener);
    window.addEventListener("message", wpChallengeListener, false);

    iframeEl = document.createElement("iframe");
    iframeEl.setAttribute("id", "wp_challenge_iframe");
    iframeEl.classList.add("iframe-centered");
    (document.getElementsByTagName("body") as any)[0].appendChild(iframeEl);
    iframeEl.contentDocument?.write(`
  <!-- This is a Cardinal Commerce URL in live -->
  <form id= "challengeForm" method= "POST" action="${endpoint}">
    <input type="hidden" name="JWT" value="${threedsToken}" />
    <input type="hidden" name="MD" value="${bin}" />
  </form>
  <script>
    document.getElementById('challengeForm').submit();
  </script>
      `);
  });
}
export async function getWorldpaySessionId(
  cardId: number,
  acquirerId: number,
  isPublic?: boolean,
  paymentToken?: string
) {
  const isProduction = CardUtil.isWorldpayProd(acquirerId);
  const serviceName = isPublic
    ? "wp_generate_ddc_jwt_public"
    : "wp_generate_ddc_jwt";

  const paramData = paymentToken
    ? {
        token: paymentToken
      }
    : {};

  let request: any = { body: { card_id: cardId } };
  if (isPublic) {
    request = { ...request, query: { acquirer_id: acquirerId } };
  } else {
    request = { body: { ...request.body, acquirer_id: acquirerId } };
  }

  const res1 = await RestClient.send({
    service: serviceName,
    params: paramData,
    ...request
  });

  const wpSessionId = await new Promise<string | undefined>(
    (resolve, reject) => {
      let iframeEl = document.getElementById(
        "wp_ddc_iframe"
      ) as HTMLIFrameElement;
      if (iframeEl) {
        iframeEl.remove();
      }

      const endpoint = CardUtil.getWorldpayDDCEndpoint(isProduction);
      const ddcSessionIdListener = (event: MessageEvent) => {
        let sessionId = "";
        if (event.origin.indexOf(new URL(endpoint).origin) === 0) {
          try {
            const eventData = JSON.parse(event.data);
            if (eventData !== undefined && eventData.Status) {
              sessionId = eventData.SessionId;
            }

            if (sessionId) {
              resolve(sessionId);
            } else {
              resolve(undefined);
            }

            if (iframeEl) {
              iframeEl.remove();
            }
          } catch (e) {
            window.Logger.error(e);
            resolve(undefined);
          }
        }
      };

      window.removeEventListener("message", ddcSessionIdListener);
      window.addEventListener("message", ddcSessionIdListener, false);

      iframeEl = document.createElement("iframe");
      iframeEl.setAttribute("id", "wp_ddc_iframe");
      (document.getElementsByTagName("body") as any)[0].appendChild(iframeEl);
      iframeEl.contentDocument?.write(`
        <form id="collectionForm" method="POST" action="${endpoint}">
          <input type="hidden" name="Bin" value="${res1.data.card_bin}" />
          <input type="hidden" name="JWT" value="${res1.data.token}" />
        </form>
        <script>
            document.getElementById('collectionForm').submit();
        </script>
      `);
    }
  );

  return wpSessionId;
}

/**
 * To apply coupon, it's 2 api calls.
 *
 * First, user needs to apply the coupon. This will append his coupon to the coupon_usage list.
 * This does not guarantee that the user has successfully applied the coupon.
 *
 * After that, make a call again to the "GET /fees" endpoint, at this point, we will calculate
 * if the coupon was used correctly, and the resulting fees after including coupon.
 */
export function* handleApplyCoupon(
  action: ActionType<typeof actions.applyCoupon>
) {
  const state: RootState = yield select();
  const paymentRequest = selectors.getCurrentPaymentRequest(state);
  const couponCode = formSelectors.getControl(state, "code", CHECKOUT_FORM)
    .value;

  const cardId = selectors.getSelectedCard(state);

  if (cardId <= 0) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            code: ["Please select a card"]
          },
          form: []
        },
        CHECKOUT_FORM
      )
    );
  }

  const res = yield call(RestClient.send, {
    body: {
      card_id: Number(cardId),
      code: (couponCode as string).trim(),
      payment_token: paymentRequest.token
    },
    service: "apply_coupon",
    showGlobalLoader: true
  });
  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CHECKOUT_FORM));
    return;
  }

  yield put(actions.setCoupon((couponCode as string).toUpperCase()));
  yield put(actions.fetchPaymentFee(paymentRequest.token, true));
}

function* clearFetchFeeHandler() {
  yield new Promise(resolve => {
    window.clearTimeout(window.fetchPaymentFeeTimer);
    resolve();
  });
}

function* sleepFetchFeeHandler(time: number) {
  yield new Promise(resolve => {
    window.fetchPaymentFeeTimer = window.setTimeout(resolve, time);
  });
}

export function* handleUnbouncedFetchFee(
  action: ActionType<typeof actions.fetchPaymentFee>
) {
  const state: RootState = yield select();
  const couponUsed = selectors.getCouponUsed(state);
  const selectedCard = selectors.getSelectedCard(state);
  const { paymentToken } = action.payload;

  if (selectedCard < 1) {
    return;
  }

  const res = yield call(RestClient.send, {
    query: {
      card_id: selectedCard,
      coupon_code: couponUsed,
      token: paymentToken,
      payment_method_type: "card"
    },
    service: "get_fees",
    showGlobalLoader: false
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    if (!res.data) {
      return;
    }

    yield put(
      actions.setFees({
        amountOff: res.data.amount_off,
        coupon: res.data.coupon,
        exchangeRate: res.data.exchange_rate,
        fee: res.data.fee,
        paymentAmount: res.data.payment_amount,
        rate: res.data.rate,
        rateBeforeCoupon: res.data.rateBeforeCoupon,
        savings: res.data.savings,
        total: res.data.total,
        payeeQuoteToken: res.data.payee_quote_token
      })
    );
    if (action.payload.hasCouponApplied) {
      if (_get(res.data, "coupon.length", 0) === 0) {
        yield put(actions.toast(T.transl("ERROR_COUPON_EXPIRED")));
      } else {
        // yield put(
        //   actions.toast(
        //     T.transl("SUCCESS_COUPON_APPLIED")
        //   )
        // );
      }
    }

    return;
  } catch (e) {
    window.Logger.error("handleUnbouncedFetchFee: ", e.message);
  }
}

export function* handleFetchFee(
  action: ActionType<typeof actions.fetchPaymentFee>
): any {
  yield call(clearFetchFeeHandler);
  yield handleUnbouncedFetchFee(action);
  yield call(sleepFetchFeeHandler, 5 * 60 * 1000);
  yield handleFetchFee(action); // Recursive call every 5m
}

export function* handleDeleteCouponUsed(
  action: ActionType<typeof actions.deleteCouponUsed>
) {
  const token = action.payload.paymentToken;

  const res = yield call(RestClient.send, {
    params: {
      token
    },
    service: "delete_applied_coupon",
    showGlobalLoader: true
  });
  if (!res) {
    return;
  }

  yield put(actions.setCoupon(""));
  yield put(actions.fetchPaymentFee(token));
}

export function* handleFetchPaymentRequest(
  action: ActionType<typeof actions.fetchPaymentRequest>
) {
  yield put(actions.setPayments({ isFetching: true, payments: [] }));

  yield put(actions.setPaymentRequest(defaultState));

  const res = yield call(RestClient.send, {
    params: {
      token: action.payload.token
    },
    service: "get_payment_request"
  });
  if (!res) {
    yield put(actions.setPayments({ isFetching: false, payments: [] }));
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = _get(res, "data", {});

    // in case user changes their company in checkout page => redirect to dashboard
    if (!json.payees) {
      history.push("/");
    }

    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );

    yield put(
      actions.setDateConditions({
        earliest: new Date(json.payout_date),
        isFetching: false
      })
    );

    yield put(
      actions.setPaymentRequest({
        channelFees: json.channel_fees,
        currencyId: json.currency_id,
        isScheduled: json.is_scheduled,
        paidCard: json.paid_card,
        payees: json.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          currencyId: payee.currency_id,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          international: {
            bankAccountHolderName: payee.bank_account_holder_name,
            bankRawName: payee.bank_raw_name
          },
          name: payee.recipient_name,
          uid: payee.uid
        })),
        purposeId: json.purpose_id,
        statementDescriptor: json.statement_descriptor,
        subtotal: json.payment_amount,
        token: action.payload.token
      })
    );

    yield put(actions.setCurrentPurpose(json.purpose_id));
    yield put(cardActions.fetchCards(action.payload.token, json.paid_card));

    if (json.is_charged === true) {
      const qs = queryString.parse(window.location.search);
      if (!qs.m) {
        history.push(`/payment_confirmation/${json.receipt_number}?type=n`); // type=normal payment
      }
    }

    return;
  } catch (e) {
    window.Logger.error("handleFetchPaymentRequest: ", e.message);
  }
}

export function* handleAddPaymentRequest(
  action: ActionType<typeof actions.addPaymentRequestSubmit>
) {
  try {
    const state: RootState = yield select();
    if (!selectors.hasSelectedPayee(state)) {
      yield put(commonActions.toast(T.transl("ERROR_NO_SELECTED_PAYEE")));
      return;
    }

    const formState = formSelectors.getControls(state, FORM);
    const selectedPayees = selectors.getSelectedPayees(state);

    let payees: Array<{
      accountNumber: string;
      bankId: number;
      bankBSBId: number;
      bankCode: string;
      bsbCode: string;
      currencyId: number;
      defaultAmount: number;
      defaultComments?: string;
      id: number;
      international?: {
        bankAccountHolderName: string;
        bankRawName: string;
        bankRawSwiftCode: string;
      };
      name: string;
      uid?: string;
    }> = [];

    const isHongKongAccount = selectors.isHongKongAccount(state);

    if (action.payload.purpose === "invoice") {
      selectedPayees.map(payee => {
        payees.push({
          accountNumber: payee.accountNumber,
          bankBSBId: payee.bankBSBId,
          bankCode: payee.bankCode,
          bankId: payee.bankId,
          bsbCode: payee.bsbCode,
          currencyId: isHongKongAccount
            ? selectedPayees[0].currencyId
            : payee.currencyId,
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`)
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });

        const extraPayees = _get(
          selectors.getExtraPayees(state),
          payee.id,
          undefined
        );
        if (extraPayees !== undefined) {
          Object.keys(extraPayees).map(key => {
            if (key in extraPayees) {
              payees.push({
                accountNumber: extraPayees[key].accountNumber,
                bankBSBId: extraPayees[key].bankBSBId,
                bankCode: extraPayees[key].bankCode,
                bankId: extraPayees[key].bankId,
                bsbCode: extraPayees[key].bsbCode,
                currencyId: isHongKongAccount
                  ? selectedPayees[0].currencyId
                  : extraPayees[key].currencyId,
                defaultAmount: utils.amountStringToInt(
                  extraPayees[key].defaultAmount
                ),
                defaultComments: extraPayees[key].defaultComments,
                id: extraPayees[key].id,
                name: extraPayees[key].name,
                uid: key
              });
            }
          });
        }
      });
    } else if (action.payload.purpose === "self_transfer") {
      selectedPayees.map(payee => {
        payees.push({
          accountNumber: payee.accountNumber,
          bankBSBId: payee.bankBSBId,
          bankCode: payee.bankCode,
          bankId: payee.bankId,
          bsbCode: payee.bsbCode,
          currencyId: payee.currencyId,
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`)
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          international: {
            bankAccountHolderName: _get(
              payee,
              "international.bankAccountHolderName",
              undefined
            ),
            bankRawName: _get(payee, "international.bankRawName", undefined),
            bankRawSwiftCode: _get(
              payee,
              "international.bankRawSwiftCode",
              undefined
            )
          },
          name: payee.name
        });
      });
    } else {
      payees = selectedPayees.map(payee => ({
        accountNumber: payee.accountNumber,
        bankBSBId: payee.bankBSBId,
        bankCode: payee.bankCode,
        bankId: payee.bankId,
        bsbCode: payee.bsbCode,
        currencyId: payee.currencyId,
        defaultAmount: payee.defaultAmount,
        defaultComments: payee.defaultComments,
        id: payee.id,
        name: payee.name
      }));
    }

    const scheduleType = selectors.getControl(state, "schedule_type", ADD_FORM)
      .value;
    const isRecurring = scheduleType === ScheduleType.OPTION_RECURRING;
    const earliestDate = selectors.getEarliestDate(state);

    let startDate = selectors.getControl(state, "start_date", ADD_FORM).value;

    if (
      selectors.isWallexPayment(state) ||
      action.payload.purpose === "self_transfer"
    ) {
      startDate = (earliestDate as Date).toISOString();
    }

    const endDate =
      selectors.getControl(state, "end_date", ADD_FORM).value || startDate;

    let frequency: string;
    if (!isRecurring) {
      frequency = "once";
    } else {
      frequency = selectors.getControl(state, "frequency").value as string;
    }

    const documentTags = formSelectors.getControlsPattern(
      state,
      /^document_tag_/
    );

    const paymentSupportingDocs: {
      [payeeId: number]: string[];
    } = {};
    documentTags.map(control => {
      const fileRef = control.name.replace("document_tag_", "");
      const supportingDocument = formSelectors.getControl(state, fileRef)
        .value as string;

      if (supportingDocument) {
        switch (control.value) {
          case "all":
            for (const payee of payees) {
              paymentSupportingDocs[payee.id] =
                paymentSupportingDocs[payee.id] || [];
              paymentSupportingDocs[payee.id].push(supportingDocument);
              paymentSupportingDocs[payee.id] = _uniq(
                paymentSupportingDocs[payee.id]
              );
            }
            break;
          default:
            const payeeId = parseInt(control.value as string, 10);
            paymentSupportingDocs[payeeId] =
              paymentSupportingDocs[payeeId] || [];
            paymentSupportingDocs[payeeId].push(supportingDocument);
            paymentSupportingDocs[payeeId] = _uniq(
              paymentSupportingDocs[payeeId]
            );
        }
      }
    });

    const res = yield call(RestClient.send, {
      body: {
        end_date: endDate,
        frequency,
        payees: payees.map(payee => ({
          account_number: payee.accountNumber,
          amount: Number(payee.defaultAmount),
          bank_account_holder_name: _get(
            payee,
            "international.bankAccountHolderName",
            undefined
          ),
          bank_bsb_id: payee.bankBSBId,
          bank_code: payee.bankCode,
          bank_id: payee.bankId,
          bank_raw_name: _get(payee, "international.bankRawName", undefined),
          bank_swift_code: _get(
            payee,
            "international.bankRawSwiftCode",
            undefined
          ),
          bsb_code: payee.bsbCode,
          comments:
            payee.defaultComments || action.payload.purpose.toUpperCase(),
          currency_id: payee.currencyId,
          id: payee.id,
          recipient_name: payee.name,
          uid: payee.uid
        })),
        payout_date: startDate,
        purpose: action.payload.purpose,
        start_date: startDate,
        supporting_documents: paymentSupportingDocs,
        payment_method_type: "card"
      },
      service: "request_payment",
      showGlobalLoader: true
    });

    if (!res) {
      return false;
    }

    const json = res;
    const errors = _get(json, "errors", {});

    if (!_isEmpty(errors)) {
      yield put(formActions.parseServerErrors(errors, ADD_FORM));
      return false;
    }

    let checkoutUrl = `/checkout/${json.token}?purpose=${action.payload.purpose}`;

    // check identity verification (for SG personal accounts)
    const myInfoRequired = _get(json, "myinfo_required", false);
    // const isPersonal = selectors.getIsPersonalAccount(state);

    if (myInfoRequired) {
      const qs = queryString.parse(window.location.search);
      let scheduleUrl = `/wizard/schedule/${action.payload.purpose}`;
      let verificationUrl = `/wizard/verification/${action.payload.purpose}`;

      let qsPrefix = "?";

      if (qs.action) {
        scheduleUrl = `${scheduleUrl}?action=${qs.action}`;
        verificationUrl = `${verificationUrl}?action=${qs.action}`;

        qsPrefix = "&";
      }

      verificationUrl = `${verificationUrl}${qsPrefix}purpose=${action.payload.purpose}&token=${json.token}`;

      // update checkoutUrl to detect the next step after confirming SG My Info
      checkoutUrl = `${checkoutUrl}&myinfo_process=1`;

      history.push(verificationUrl);
    } else {
      history.push(checkoutUrl);
    }

    // save checkout url to localStorage
    LocalStorage.updateLSItemObjectType(
      LocalStorage.keysLS.paymentRequestUrls,
      { wizardCheckout: checkoutUrl }
    );

    return true;
  } catch (e) {
    window.Logger.error("handleAddPaymentRequest: ", e.message);
    return false;
  }
}

export function* handleValidatePaymentPayeeSubmit(
  action: ActionType<typeof actions.validatePaymentPayeeSubmit>
) {
  try {
    const state: RootState = yield select();
    if (!selectors.hasSelectedPayee(state)) {
      yield put(commonActions.toast(T.transl("ERROR_NO_SELECTED_PAYEE")));
      return;
    }

    const formState = formSelectors.getControls(state, FORM);
    const selectedPayees = selectors.getSelectedPayees(state);

    let payees: Array<{
      accountNumber: string;
      bankId: number;
      currencyId: number;
      defaultAmount: number;
      defaultComments?: string;
      id: number;
      name: string;
      uid?: string;
    }> = [];

    if (
      action.payload.purpose === "invoice" ||
      action.payload.purpose === "self_transfer"
    ) {
      selectedPayees.map(payee => {
        payees.push({
          accountNumber: payee.accountNumber,
          bankId: payee.bankId,
          currencyId: payee.currencyId,
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`)
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });

        const extraPayees = _get(
          selectors.getExtraPayees(state),
          payee.id,
          undefined
        );

        if (extraPayees !== undefined) {
          Object.keys(extraPayees).map(key => {
            if (key in extraPayees) {
              payees.push({
                accountNumber: extraPayees[key].accountNumber,
                bankId: extraPayees[key].bankId,
                currencyId: payee.currencyId,
                defaultAmount: utils.amountStringToInt(
                  extraPayees[key].defaultAmount
                ),
                defaultComments: extraPayees[key].defaultComments,
                id: extraPayees[key].id,
                name: extraPayees[key].name,
                uid: key
              });
            }
          });
        }
      });
    } else {
      payees = selectedPayees.map(payee => ({
        accountNumber: payee.accountNumber,
        bankId: payee.bankId,
        currencyId: payee.currencyId,
        defaultAmount: payee.defaultAmount,
        defaultComments: payee.defaultComments,
        id: payee.id,
        name: payee.name
      }));
    }

    let {
      currencyId,
      paymentPaidCurrencyId
    } = selectors.getCurrentAccountProfile(state);

    if (selectors.isHongKongAccount(state)) {
      currencyId = _get(formState, "hk_multiple_currency_id.value");
      paymentPaidCurrencyId = _get(formState, "hk_multiple_currency_id.value");
    }

    const documentTags = formSelectors.getControlsPattern(
      state,
      /^document_tag_/
    );

    const paymentSupportingDocs: {
      [payeeId: number]: string[];
    } = {};
    documentTags.map(control => {
      const fileRef = control.name.replace("document_tag_", "");
      const supportingDocument = formSelectors.getControl(state, fileRef)
        .value as string;

      if (supportingDocument) {
        switch (control.value) {
          case "all":
            for (const payee of payees) {
              paymentSupportingDocs[payee.id] =
                paymentSupportingDocs[payee.id] || [];
              paymentSupportingDocs[payee.id].push(supportingDocument);
              paymentSupportingDocs[payee.id] = _uniq(
                paymentSupportingDocs[payee.id]
              );
            }
            break;
          default:
            const payeeId = parseInt(control.value as string, 10);
            paymentSupportingDocs[payeeId] =
              paymentSupportingDocs[payeeId] || [];
            paymentSupportingDocs[payeeId].push(supportingDocument);
            paymentSupportingDocs[payeeId] = _uniq(
              paymentSupportingDocs[payeeId]
            );
        }
      }
    });

    const res = yield call(RestClient.send, {
      body: {
        payees: payees.map(payee => ({
          account_number: payee.accountNumber,
          amount: Number(payee.defaultAmount),
          bank_id: payee.bankId,
          comments:
            action.payload.purpose === "invoice"
              ? payee.defaultComments
              : payee.defaultComments || action.payload.purpose.toUpperCase(),
          currency_id: currencyId,
          id: payee.id,
          paid_currency_id: paymentPaidCurrencyId,
          recipient_name: payee.name,
          uid: payee.uid
        })),
        purpose: action.payload.purpose,
        supporting_documents: paymentSupportingDocs
      },
      service: "validate_payment_payee"
    });

    if (!res) {
      return false;
    }

    yield put(actions.setSubmitButtonState(false));

    const json = res;
    const errors = _get(json, "errors", {});

    if (!_isEmpty(errors)) {
      yield put(formActions.parseServerErrors(errors, CHECKOUT_FORM));
      return false;
    }

    const qs = queryString.parse(window.location.search);
    let url = `/wizard/schedule/${action.payload.purpose}`;
    if (qs.action) {
      url = `${url}?action=${qs.action}`;
    }

    if (selectors.isWallexPayment(state)) {
      yield put(actions.addPaymentRequestSubmit(action.payload.purpose));
    } else {
      history.push(url);
    }

    return true;
  } catch (e) {
    window.Logger.error("handleValidatePaymentPayeeSubmit: ", e.message);
    return false;
  }
}

export function* handleEditPaymentRequest(
  action: ActionType<typeof actions.editPaymentRequestSubmit>
) {
  const state: RootState = yield select();
  if (!selectors.hasSelectedPayee(state)) {
    yield put(commonActions.toast(T.transl("ERROR_NO_SELECTED_PAYEE")));
    return;
  }
  yield delay(2000);
  return true;
}

export function* handleSelectCard(
  action: ActionType<typeof actions.selectCard>
) {
  const state: RootState = yield select();
  const paymentToken =
    action.payload.paymentToken ||
    selectors.getCurrentPaymentRequest(state).token;
  yield put(actions.setSelectedCard({ id: action.payload.id }));
  yield put(actions.fetchPaymentFee(paymentToken));
}

export function* handleValidateAmount(
  action: ActionType<typeof actions.validateAmount>
) {
  const state = yield select();
  const { form, amount, fieldName, bePaid = false } = action.payload;
  const formState = formSelectors.getControls(state, CHECKOUT_FORM);
  let currencyId = selectors.getCurrentCurrencyId(state);
  let paidCurrencyId = selectors.getCurrentPaidCurrencyId(state);

  if (bePaid) {
    currencyId = selectors.getCurrentBePaidCurrencyId(state);
    paidCurrencyId = selectors.getCurrentBePaidCurrencyId(state);
  } else {
    const HKCurrentCurrencyId = _get(
      formState,
      "hk_multiple_currency_id.value"
    );

    if (selectors.isHongKongAccount(state)) {
      currencyId = HKCurrentCurrencyId;
      paidCurrencyId = HKCurrentCurrencyId;
    }
  }

  const res = yield call(RestClient.send, {
    body: {
      amount: Math.round(amount),
      currency_id: currencyId,
      field_name: fieldName,
      paid_currency_id: paidCurrencyId
    },
    service: "validate_amount"
  });

  if (!res) {
    return;
  }
  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, form ? form : ADD_FORM));
    return;
  }
}

export function* handleGetExchangeRate(
  action: ActionType<typeof actions.getExchangeRate>
) {
  const state: RootState = yield select();
  const currencyCode = selectors.getCurrentCurrency(state);
  const paymentPaidCurrencyCode = selectors.getCurrentPaidCurrency(state);

  const res = yield call(RestClient.send, {
    query: {
      currency_code: currencyCode,
      paid_currency_code: paymentPaidCurrencyCode
    },
    service: "get_exchange_rate"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data = _get(res, "data", {});

  try {
    yield put(actions.setExchangeRate(data.exchange_rate));
    return;
  } catch (e) {
    window.Logger.error("handleGetExchangeRate: ", e.message);
  }
}

export function* processPaymentRequiredAction(
  requiredAction: paymentRequiredActionParams,
  cvv?: StripeCardCvcElement | string
) {
  if (requiredAction.provider === "braintree") {
    const clientInstance = yield call(client.create, {
      authorization: requiredAction.clientToken
    });

    const threeDSecureIns = yield call(threeDSecure.create, {
      client: clientInstance,
      version: 2
    });

    const result = yield call(
      braintreeVerifyCard,
      threeDSecureIns,
      requiredAction
    );
    if (!result.success) {
      yield call(toggleModal, actions.ModalID.PAYMENT_ERROR, {
        errors: [result.error as string]
      });
    }

    yield call(actions.hideGlobalLoader);

    if (result.payload.liabilityShifted) {
      window.location.href =
        requiredAction.callbackUrl + "&threeds_token=" + result.payload.nonce;
    }
  }

  if (requiredAction.provider === "stripe") {
    getStripe(requiredAction.acquirerId, requiredAction.mid).then(stripe => {
      if (!stripe) {
        return;
      }

      stripe
        .confirmCardPayment(requiredAction.clientToken, {
          payment_method: requiredAction.threedsToken,
          payment_method_options: cvv
            ? {
                card: {
                  cvc: cvv as StripeCardCvcElement
                }
              }
            : undefined
        })
        .then((result: any) => {
          window.location.href =
            requiredAction.callbackUrl +
            `&client_secret=${requiredAction.clientToken}&source_type=stripe_payment_intent`;
        });
    });
  }

  if (requiredAction.provider === "worldpay") {
    const threeDSTransactionId = yield call(
      getWPChallengeTransactionId,
      requiredAction.acquirerId,
      requiredAction.threedsToken,
      requiredAction.bin
    );

    yield call(actions.hideGlobalLoader);
    const success = !!threeDSTransactionId;
    window.location.href =
      requiredAction.callbackUrl +
      `&threeds_transaction_id=${threeDSTransactionId}&success=${success}`;
  }
}

async function braintreeVerifyCard(
  threeDSecureIns: ThreeDSecure,
  requiredAction: paymentRequiredActionParams
): Promise<{
  success: boolean;
  error?: string;
  payload?: ThreeDSecureVerifyPayload;
}> {
  return new Promise(resolve => {
    threeDSecureIns
      .verifyCard({
        // @ts-ignore
        onLookupComplete: (data: any, next: any) => {
          if (!data.threeDSecureInfo.liabilityShiftPossible) {
            resolve({
              success: false,
              error: "PAYMENT_CARD_UNSUPPORTED_3DS_ERROR"
            });
          }

          next();
        },
        amount: toCents(requiredAction.paymentTotal),
        nonce: requiredAction.threedsToken,
        bin: requiredAction.bin
      })
      .then(payload => {
        console.log(payload);
        resolve({
          success: true,
          payload
        });
      });
  });
}
