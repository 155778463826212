import { connect } from "react-redux";
import Component from "./View";
import * as selectors from "./selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { RoleType } from "src/ipm-shared/store/model/AccountProfile/types";

const mapStateToProps = (state: RootState, props: any) => ({
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  userRole: selectors.getRoleId(state)
});

const mapDispatchToProps = {};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    roles: RoleType[] | RoleType;
  };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
