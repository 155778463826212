/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as paymentCollectionRequestActions from "./actions";
import { ModalID } from "./actions";
import RestClient from "src/ipm-shared/services/Rest";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import T from "src/ipm-shared/Utils/Intl";
import _get from "lodash-es/get";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as paymentCollectionsActions from "src/ipm-shared/store/model/PaymentCollections/actions";
import * as paymentCollectionRequestSelectors from "src/ipm-shared/store/model/PaymentCollectionRequest/selectors";
import * as companySelectors from "src/ipm-shared/store/model/Company/selectors";
import * as authSelectors from "src/ipm-shared/store/model/Auth/selectors";
import * as collectedAccountSelectors from "src/ipm-shared/store/model/CollectedAccount/selectors";
import * as customerSelectors from "src/ipm-shared/store/model/Customer/selectors";
import * as paymentCollectionSelectors from "src/ipm-shared/store/model/PaymentCollections/selectors";
import { RESEND_FORM } from "./const";
import _isEmpty from "lodash-es/isEmpty";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as accountProfileActions from "src/ipm-shared/store/model/AccountProfile/actions";
import { history } from "src/ipm-shared/store";
import { CONFIRM_FORM } from "../Payment/const";
import {
  CREATE_INVOICE_FORM,
  SMS_REQUEST_FORM,
  TermsOptions
} from "../CollectedAccount/const";
import CurrencyUtils from "src/ipm-shared/Utils/Currency";
import { toCents } from "src/ipm-shared/components/ShortCurrency";
import { INVOICE_DETAIL_FORM } from "../Customer/const";
import _uniq from "lodash-es/uniq";
import utils from "src/ipm-shared/Utils/Number";
import { getFeeRateAndPayerParams } from "src/ipm-shared/store/model/CollectedAccount/sagas";
import { CollectionRates } from "../CollectedAccount/reducers";
import { processPaymentRequiredAction } from "../PaymentRequest/sagas";
import CardUtil from "src/ipm-shared/Utils/Card";
import { getWorldpaySessionId } from "src/ipm-shared/store/model/PaymentRequest/sagas";
import { StripeCardCvcElement } from "@stripe/stripe-js";

const selectors = {
  ...customerSelectors,
  ...formSelectors,
  ...paymentCollectionRequestSelectors,
  ...accountProfileSelectors,
  ...companySelectors,
  ...paymentCollectionSelectors,
  ...collectedAccountSelectors,
  ...authSelectors
};

const actions = {
  ...formActions,
  ...accountProfileActions,
  ...paymentCollectionsActions,
  ...paymentCollectionRequestActions
};

const watchedSagas = [
  reTryTakeLatest(
    actions.fetchPaymentCollectionRequest,
    handleFetchPaymentCollectionRequest
  ),
  // reTryTakeLatest(
  //   actions.fetchPaymentCollectionFees,
  //   handleFetchPaymentCollectionFees
  // ),
  takeLatest(
    getType(actions.chargePaymentCollection),
    handleChargePaymentCollection
  ),
  takeLatest(getType(actions.remindPaymentRequest), handleRemindPaymentRequest),
  takeLatest(getType(actions.resendPaymentRequest), handleResendPaymentRequest),
  takeLatest(getType(actions.cancelPaymentRequest), handleCancelPaymentRequest),
  takeLatest(getType(actions.sendPaymentRequest), handleSendPaymentRequest),
  takeLatest(
    getType(actions.submitCollectionInvoice),
    handleSubmitCollectionInvoice
  ),
  takeLatest(getType(actions.submitSmsRequest), handleSubmitSmsRequest),
  reTryTakeLatest(actions.getEstimateRate, handleGetEstimateRate),
  takeLatest(
    getType(actions.createPublicStripeCardIntent),
    handlePublicCreateStripeCardIntent
  )
];

export default watchedSagas;

export function* handleSubmitSmsRequest(
  _: ActionType<typeof actions.submitSmsRequest>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, SMS_REQUEST_FORM);

  const collectedAccount = selectors.getCollectedAccount(state);

  if (!collectedAccount) {
    return;
  }
  const customerByIds = selectors.getCustomerByIds(state);

  const accountNumber = collectedAccount.accountNumber;
  const bankId = collectedAccount.bankId;
  const bankBsbId = collectedAccount.bankBSBId;
  const bankCode = collectedAccount.bankCode;
  const bsbCode = collectedAccount.bankCode;
  const recipientName = selectors.getCompany(state).name;
  const id = collectedAccount.id;
  const currencyId = collectedAccount.currencyId;

  let customerId = _get(formState, "customer_id.value");

  let resCustomer: Response;

  if (!customerId) {
    resCustomer = yield call(RestClient.send, {
      body: {
        customer_name: _get(formState, "customer_name.value"),
        mobile: _get(formState, "mobile.value"),
        mobile_country_code: _get(formState, "mobile_country_code.value"),
        to_create_or_upload_invoice: "sms"
      },
      service: "add_customer",
      showGlobalLoader: true
    });
  } else {
    resCustomer = yield call(RestClient.send, {
      body: {
        address_line_1: customerByIds[customerId].addressLine1,
        address_line_2: customerByIds[customerId].addressLine2,
        company_name: customerByIds[customerId].companyName,
        customer_name: _get(formState, "customer_name.value"),
        email: customerByIds[customerId].email,
        mobile: _get(formState, "mobile.value"),
        mobile_country_code: _get(formState, "mobile_country_code.value"),
        registration_number: customerByIds[customerId].registrationNumber,
        to_create_or_upload_invoice: "sms"
      },
      params: {
        id: customerId
      },
      service: "edit_customer",
      showGlobalLoader: true
    });
  }

  if (!resCustomer) {
    return;
  }

  const errorsCustomer = _get(resCustomer, "errors", {});
  if (!_isEmpty(errorsCustomer)) {
    yield put(actions.parseServerErrors(errorsCustomer, SMS_REQUEST_FORM));
    return;
  }

  if (!customerId) {
    customerId = _get(resCustomer, "data.id");
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      purpose: "collection",
      requests: [
        {
          payees: [
            {
              account_number: accountNumber,
              bank_bsb_id: bankBsbId,
              bank_code: bankCode,
              bank_id: bankId,
              bsb_code: bsbCode,
              comments: _get(formState, "payment_description.value", ""),
              currency_id: currencyId,
              gross_amount: utils.amountStringToInt(
                _get(formState, "gross_amount.value")
              ),
              id,
              payment_description: _get(
                formState,
                "payment_description.value",
                ""
              ),
              recipient_name: recipientName
            }
          ],
          payer_id: Number(customerId),
          requester_rates: collectedAccount.payeeData.requesterRates,
          to_create_or_upload_invoice: "sms"
        }
      ]
    },

    service: "send_payment_request",
    showGlobalLoader: true
  });

  if (!res) {
    return false;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(
      actions.parseServerErrors(errors, SMS_REQUEST_FORM, {
        [`supplier_amount_${customerId}`]: "gross_amount"
      })
    );
    return false;
  }
  const checkoutUrls = _get(res, "data.checkout_url_list", []);

  const currency = CurrencyUtils.convertFromId(currencyId).code;
  yield put(
    actions.toggleModal(ModalID.REQUEST_PAYMENT_SUCCESSFULLY, {
      checkoutUrls,
      currency,
      paymentAmount: toCents(
        utils.amountStringToInt(_get(formState, "gross_amount.value"))
      )
    })
  );

  return;
}

export function* handleSendPaymentRequest(
  action: ActionType<typeof actions.sendPaymentRequest>
) {
  const { isValidate } = action.payload;

  const state: RootState = yield select();
  const formState = selectors.getControls(state, INVOICE_DETAIL_FORM);
  const selectedCustomers = selectors.getSelectedCustomers(state);
  const collectionRates = selectors.getCollectionRates(state);

  const requests: Array<{
    payees: {
      comments?: string; // this is invoice number
      grossAmount: number;
      uid?: string;
      dueDate?: string;
    };
    payerId: number;
    supportingDocuments: string;
    requesterRate: CollectionRates[];
  }> = [];

  selectedCustomers.map(customer => {
    const {
      national: nationalVisa,
      international: internationalVisa,
      feePayer: feePayerVisa,
      intFeePayer: intFeePayerVisa
    } = getFeeRateAndPayerParams(
      {
        brand_id: 2,
        international: parseInt(
          formState[`requester_international_rate_visa_${customer.id}`].value,
          10
        ),
        national: parseInt(
          formState[`requester_national_rate_visa_${customer.id}`].value,
          10
        )
      },
      collectionRates.filter(r => r.brand_id === 2)[0]
    );

    const {
      national: nationalUnion,
      international: internationalUnion,
      feePayer: feePayerUnion,
      intFeePayer: intFeePayerUnion
    } = getFeeRateAndPayerParams(
      {
        brand_id: 5,
        international: parseInt(
          formState[`requester_international_rate_union_${customer.id}`].value,
          10
        ),
        national: parseInt(
          formState[`requester_national_rate_union_${customer.id}`].value,
          10
        )
      },
      collectionRates.filter(r => r.brand_id === 5)[0]
    );

    const {
      national: nationalAmex,
      international: internationalAmex,
      feePayer: feePayerAmex,
      intFeePayer: intFeePayerAmex
    } = getFeeRateAndPayerParams(
      {
        brand_id: 4,
        international: parseInt(
          formState[`requester_international_rate_amex_${customer.id}`].value,
          10
        ),
        national: parseInt(
          formState[`requester_national_rate_amex_${customer.id}`].value,
          10
        )
      },
      collectionRates.filter(r => r.brand_id === 5)[0]
    );

    requests.push({
      payees: {
        comments: _get(formState, `payment_description_${customer.id}.value`),
        dueDate: _get(formState, `due_date_${customer.id}.value`),
        grossAmount: _get(formState, `supplier_amount_${customer.id}.value`)
      },
      payerId: customer.id,
      requesterRate: [
        {
          brand_id: 2,
          national: nationalVisa,
          international: internationalVisa,
          fee_payer: feePayerVisa,
          int_fee_payer: intFeePayerVisa
        },
        {
          brand_id: 5,
          national: nationalUnion,
          international: internationalUnion,
          fee_payer: feePayerUnion,
          int_fee_payer: intFeePayerUnion
        },
        {
          brand_id: 4,
          national: nationalAmex,
          international: internationalAmex,
          fee_payer: feePayerAmex,
          int_fee_payer: intFeePayerAmex
        }
      ],
      supportingDocuments: ""
    });

    const extraCustomers = _get(
      selectors.getExtraCustomers(state),
      customer.id,
      undefined
    );

    if (extraCustomers !== undefined) {
      Object.keys(extraCustomers).map(key => {
        if (key in extraCustomers) {
          const {
            national: nationalVisa,
            international: internationalVisa,
            feePayer: feePayerVisa,
            intFeePayer: intFeePayerVisa
          } = getFeeRateAndPayerParams(
            {
              brand_id: 2,
              international: parseInt(
                formState[
                  `requester_international_rate_visa_${customer.id}_${key}`
                ].value,
                10
              ),
              national: parseInt(
                formState[`requester_national_rate_visa_${customer.id}_${key}`]
                  .value,
                10
              )
            },
            collectionRates.filter(r => r.brand_id === 2)[0]
          );

          const {
            national: nationalUnion,
            international: internationalUnion,
            feePayer: feePayerUnion,
            intFeePayer: intFeePayerUnion
          } = getFeeRateAndPayerParams(
            {
              brand_id: 5,
              international: parseInt(
                formState[
                  `requester_international_rate_union_${customer.id}_${key}`
                ].value,
                10
              ),
              national: parseInt(
                formState[`requester_national_rate_union_${customer.id}_${key}`]
                  .value,
                10
              )
            },
            collectionRates.filter(r => r.brand_id === 5)[0]
          );

          const {
            national: nationalAmex,
            international: internationalAmex,
            feePayer: feePayerAmex,
            intFeePayer: intFeePayerAmex
          } = getFeeRateAndPayerParams(
            {
              brand_id: 4,
              international: parseInt(
                formState[
                  `requester_international_rate_amex_${customer.id}_${key}`
                ].value,
                10
              ),
              national: parseInt(
                formState[`requester_national_rate_amex_${customer.id}_${key}`]
                  .value,
                10
              )
            },
            collectionRates.filter(r => r.brand_id === 4)[0]
          );

          requests.push({
            payees: {
              comments: extraCustomers[key].invoiceNumber,
              dueDate: extraCustomers[key].dueDate,
              grossAmount: extraCustomers[key].amountDue || 0,
              uid: key
            },
            payerId: customer.id,
            requesterRate: [
              {
                brand_id: 2,
                national: nationalVisa,
                international: internationalVisa,
                fee_payer: feePayerVisa,
                int_fee_payer: intFeePayerVisa
              },
              {
                brand_id: 5,
                national: nationalUnion,
                international: internationalUnion,
                fee_payer: feePayerUnion,
                int_fee_payer: intFeePayerUnion
              },
              {
                brand_id: 4,
                national: nationalAmex,
                international: internationalAmex,
                fee_payer: feePayerAmex,
                int_fee_payer: intFeePayerAmex
              }
            ],
            supportingDocuments: ""
          });
        }
      });
    }
  });

  const collectedAccount = selectors.getCollectedAccount(state);

  if (!collectedAccount) {
    return;
  }

  const accountNumber = collectedAccount.accountNumber;
  const bankId = collectedAccount.bankId;
  const bankBsbId = collectedAccount.bankBSBId;
  const bankCode = collectedAccount.bankCode;
  const bsbCode = collectedAccount.bankCode;
  const recipientName = selectors.getCompany(state).name;
  const id = collectedAccount.id;
  const currencyId = collectedAccount.currencyId;
  const documentTags = selectors.getControlsPattern(state, /^document_tag_/);
  const requestSupportingDocs: {
    [payeeId: number]: string[];
  } = {};
  documentTags.map(control => {
    const fileRef = control.name.replace("document_tag_", "");
    const supportingDocument = selectors.getControl(state, fileRef)
      .value as string;
    const payeeId = control.value as string;
    requestSupportingDocs[payeeId] = requestSupportingDocs[payeeId] || [];
    requestSupportingDocs[payeeId].push(supportingDocument);
    requestSupportingDocs[payeeId] = _uniq(requestSupportingDocs[payeeId]);
  });

  for (const payeeId in requestSupportingDocs) {
    if (requestSupportingDocs[payeeId].length > 1) {
      yield put(
        actions.setErrors(
          ["INVALID_DUPLICATED_COLLECTION_INVOICED_DOCUMENT"],
          "supporting_documents"
        )
      );
      return;
    }
  }

  let amount = 0;

  const res: Response = yield call(RestClient.send, {
    body: {
      purpose: "collection",
      requests: requests.map(request => {
        if (!isValidate) {
          amount += utils.amountStringToInt(request.payees.grossAmount);
        }
        return {
          payees: [
            {
              account_number: accountNumber,
              bank_bsb_id: bankBsbId,
              bank_code: bankCode,
              bank_id: bankId,
              bsb_code: bsbCode,
              comments: request.payees.comments,
              currency_id: currencyId,
              due_date: request.payees.dueDate,
              gross_amount: utils.amountStringToInt(request.payees.grossAmount),
              id,
              payment_description: request.payees.comments,
              recipient_name: recipientName,
              supporting_documents: request.payees.uid
                ? requestSupportingDocs[
                    `${request.payerId}_${request.payees.uid as string}`
                  ]
                : requestSupportingDocs[request.payerId],
              uid: request.payees.uid
            }
          ],
          payer_id: request.payerId,
          requester_rates: isValidate
            ? collectedAccount.payeeData.requesterRates
            : request.requesterRate,
          to_create_or_upload_invoice: "upload"
        };
      })
    },
    service: isValidate ? "validate_payment_request" : "send_payment_request",
    showGlobalLoader: true
  });

  if (!res) {
    return false;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, INVOICE_DETAIL_FORM));
    return false;
  }

  if (isValidate) {
    yield put(
      actions.toggleModal(ModalID.COLLECTION_REVIEW_BEFORE_SEND_REQUEST, {
        currencyId
      })
    );
  } else {
    yield put(
      actions.closeModal(ModalID.COLLECTION_REVIEW_BEFORE_SEND_REQUEST)
    );
    const checkoutUrls = _get(res, "data.checkout_url_list", []);

    const currency = CurrencyUtils.convertFromId(currencyId).code;
    yield put(
      actions.toggleModal(ModalID.REQUEST_PAYMENT_SUCCESSFULLY, {
        checkoutUrls,
        currency,
        paymentAmount: toCents(amount)
      })
    );
  }

  return;
}

export function* handleFetchPaymentCollectionRequest(
  action: ActionType<typeof actions.fetchPaymentCollectionRequest>
) {
  const token = action.payload.token;
  const res = yield call(RestClient.send, {
    params: {
      token
    },
    service: "retrieve_collected_payment_request"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = _get(res, "data", {});
    yield put(
      actions.setPaymentCollectionRequest({
        acquirerId: json.acquirer_id,
        isFetching: false,
        payment: {
          companyLogoBase64: _get(json, "company_logo_base64", ""),
          companyName: json.company_name,
          currencyId: json.currency_id,
          feePayer: json.payees[0].fee_payer,
          isCancelled: json.is_canceled,
          isCharged: json.is_charged,
          isProduction: json.is_production,
          paymentCountryId: json.payment_country_id,
          purposeId: json.purpose_id,
          receiptNumber: json.receipt_number,
          requests: json.payees.map((payee: any) => ({
            amount: _get(payee, "gross_amount", 0),
            amountTotal:
              _get(payee, "gross_amount", 0) + _get(payee, "commission_fee", 0),
            commissionFee: _get(payee, "commission_fee", 0),
            currencyId: payee.currency_id,
            invoiceNumber: payee.payment_description,
            isSecondary: _get(payee, "is_secondary", false),
            recipientName: _get(payee, "recipient_name", "")
          })),
          returnUrl: _get(json, "return_url", ""),
          statementDescriptor: json.statement_descriptor,
          subTotal: json.payees.reduce((result: number, payee: any) => {
            result +=
              _get(payee, "gross_amount", 0) + _get(payee, "commission_fee", 0);
            return result;
          }, 0),
          token
        }
      })
    );

    // yield put(
    //   accountProfileActions.setAcquirerId(
    //     CardUtils.getAcquirerId(json.currency_id, json.is_production)
    //   )
    // );

    return;
  } catch (e) {
    window.Logger.error("handleFetchPaymentCollectionRequest: ", e.message);
  }
}

// export function* handleFetchPaymentCollectionFees(
//   action: ActionType<typeof actions.fetchPaymentCollectionFees>
// ) {
//   const state: RootState = yield select();

//   const formState = selectors.getControls(state, ADD_CARD_FORM);
//   const paymentToken = _get(formState, "payment_token.value");
//   const query = {
//     card_id: action.payload.cardId,
//     token: paymentToken
//   };
//   const res = yield call(RestClient.send, {
//     query,
//     service: "retrieve_collected_payment_fees",
//     showGlobalLoader: true,
//     timeout: 20000
//   });

//   if (!res) {
//     return;
//   }

//   const data = _get(res, "data", "");

//   yield put(
//     actions.setPaymentCollectionFees({
//       fee: data.fee,
//       totalAmount: data.total
//     })
//   );
// }

// card_info: {
//   bin: cko.bin,
//     card_type: cko.card_type,
//     expiry_month: cko.expiry_month,
//     expiry_year: cko.expiry_year,
//     issuer: cko.issuer,
//     issuer_country: cko.issuer_country,
//     last4: cko.last4,
//     scheme: cko.scheme,
//     type: cko.type,
// },
export function* handleChargePaymentCollection(
  action: ActionType<typeof actions.chargePaymentCollection>
) {
  const state: RootState = yield select();
  const payment = selectors.getPaymentCollectionRequest(state);

  yield put(actions.showGlobalLoader());
  let wpSessionId: string | undefined;
  const cardInfo = action.payload.cardInfo;
  const acquirerId = selectors.getPaymentCollectionAcquirerId(state);
  if (cardInfo) {
    if (CardUtil.isWorldpay(acquirerId)) {
      wpSessionId = yield call(
        getWorldpaySessionId,
        0,
        acquirerId,
        true,
        payment.token
      );
      if (!wpSessionId) {
        yield put(actions.hideGlobalLoader());
        return;
      }
    }
  }

  const res = yield call(RestClient.send, {
    body: {
      card_bin: action.payload.cardInfo?.cardBin,
      card_brand: action.payload.cardInfo?.cardBrand,
      card_expiry_month: action.payload.cardInfo?.cardExpiryMonth,
      card_expiry_year: action.payload.cardInfo?.cardExpiryYear,
      card_issuer_country: action.payload.cardInfo?.cardIssuerCountry,
      card_last4: action.payload.cardInfo?.cardLast4,
      card_token: action.payload.cardInfo?.cardToken,
      card_token_type: action.payload.cardInfo?.cardTokenType,
      card_type: action.payload.cardInfo?.cardType,
      cardholder_name: action.payload.cardholderName,
      payment_token: payment.token,
      statement_descriptor: payment.statementDescriptor,
      wp_session_id: wpSessionId,
      acquirer_id: acquirerId
    },
    service: "charge_payment_collection"
  });

  if (!res) {
    yield put(actions.hideGlobalLoader());
    return;
  }

  const errors = _get(res, "errors", undefined);
  const data = _get(res, "data", undefined);
  const requiredAction = _get(res, "action_required", undefined);

  if (requiredAction) {
    if (requiredAction.recollect_cvv) {
      yield put(actions.hideGlobalLoader());
      yield put(
        actions.toggleModal(actions.ModalID.RECOLLECT_CVV, {
          callback: (cvv: string | StripeCardCvcElement) => {
            const it = processPaymentRequiredAction(
              {
                acquirerId: requiredAction.acquirer_id,
                callbackUrl: requiredAction.callback_url,
                threedsToken: requiredAction.threeds_token,
                paymentTotal: requiredAction.payment_total,
                bin: requiredAction.bin,
                provider: requiredAction.provider,
                clientToken: requiredAction.client_token,
                mid: requiredAction.mid ? requiredAction.mid : undefined,
                recollectCVV: requiredAction.recollect_cvv
              },
              cvv
            );

            while (!it.next().done) {
              // do nothing
            }
          },
          acquirerId: requiredAction.acquirer_id,
          provider: requiredAction.provider
        })
      );
    } else {
      yield processPaymentRequiredAction({
        acquirerId: requiredAction.acquirer_id,
        callbackUrl: requiredAction.callback_url,
        threedsToken: requiredAction.threeds_token,
        paymentTotal: requiredAction.payment_total,
        bin: requiredAction.bin,
        provider: requiredAction.provider,
        clientToken: requiredAction.client_token,
        mid: requiredAction.mid ? requiredAction.mid : undefined,
        recollectCVV: requiredAction.recollect_cvv
      });
    }

    return;
  }

  yield put(actions.hideGlobalLoader());
  if (_isEmpty(errors)) {
    history.push(
      `/payment_processed/?mf_receipt_number=${data.receipt_number}`
    );
  } else {
    if (errors.form && errors.form.length > 0) {
      yield put(
        actions.toggleModal(actions.ModalID.COLLECTED_PAYMENT_FAILED, {
          errors: [T.transl(errors.form[0])]
        })
      );
    }
  }
}

export function* handleRemindPaymentRequest(
  action: ActionType<typeof actions.remindPaymentRequest>
) {
  const res = yield call(RestClient.send, {
    body: {
      payment_id: action.payload.id
    },
    service: "resend_payment_request"
  });

  if (!res) {
    return;
  }

  action.payload.cb();
}

export function* handleResendPaymentRequest(
  action: ActionType<typeof actions.resendPaymentRequest>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, RESEND_FORM);

  const newEmail = _get(formState, "new_email.value", undefined);
  const isSmsInvoice = _get(formState, "is_sms_invoice.value", undefined);

  const confirmNewEmail = _get(formState, "confirm_new_email.value", "");
  const newMobileNumber = _get(formState, "new_mobile_number.value", undefined);
  const confirmNewMobileNumber = _get(
    formState,
    "confirm_new_mobile_number.value",
    ""
  );

  const newMobileCountryCode = _get(
    formState,
    "mobile_country_code.value",
    undefined
  );

  if (isSmsInvoice === "false") {
    if (newEmail !== confirmNewEmail) {
      yield put(
        actions.parseServerErrors(
          {
            fields: {
              confirm_new_email: ["EMAIL_CONFIRM_DOES_NOT_MATCH"]
            },
            form: []
          },
          RESEND_FORM
        )
      );
      return;
    }
  } else {
    if (newMobileNumber !== confirmNewMobileNumber) {
      yield put(
        actions.parseServerErrors(
          {
            fields: {
              confirm_new_mobile_number: [
                "The mobile numbers entered do not match."
              ]
            },
            form: []
          },
          RESEND_FORM
        )
      );
      return;
    }
  }

  const res = yield call(RestClient.send, {
    body: {
      new_email: newEmail,
      new_mobile_country_code: newMobileCountryCode,
      new_mobile_number: newMobileNumber,
      payment_id: action.payload.id
    },
    service: "resend_payment_request",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, RESEND_FORM));
    return;
  }

  yield put(actions.closeModal(ModalID.RESEND_PAYMENT_REQUEST));
  yield put(paymentCollectionsActions.fetchPaymentCollectionsActivityStatus());
  action.payload.cb();
}

export function* handleCancelPaymentRequest(
  action: ActionType<typeof actions.cancelPaymentRequest>
) {
  const res = yield call(RestClient.send, {
    params: {
      payment_id: action.payload.paymentId
    },
    service: "cancel_payment_request",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  yield put(paymentCollectionsActions.fetchPaymentCollectionsActivityStatus());
  yield put(actions.fetchDashboardCollectedPayments());

  return;
}

export function* handleSubmitCollectionInvoice(
  action: ActionType<typeof actions.submitCollectionInvoice>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, CREATE_INVOICE_FORM);
  const collectedAccount = selectors.getCollectedAccount(state);
  // const { collectedAccount, hasActiveProgram } = accountProfile;
  if (!collectedAccount) {
    return;
  }
  const { otherSettings } = collectedAccount;
  const collectionRates = selectors.getCollectionRates(state);
  const invoiceItems = selectors.getInvoiceLineItems(state);

  // Basic Information
  // const defaultFeePayer = payeeData.defaultFeePayer;

  if (Object.keys(invoiceItems).length === 0) {
    yield put(
      actions.parseServerErrors(
        {
          fields: {
            [`supplier_amount_${_get(formState, "customer.value")}`]: [
              "Please add an item"
            ]
          },
          form: []
        },
        CREATE_INVOICE_FORM
      )
    );
    return;
  }

  const {
    national: nationalVisa,
    international: internationalVisa,
    feePayer: feePayerVisa,
    intFeePayer: intFeePayerVisa
  } = getFeeRateAndPayerParams(
    {
      brand_id: 2,
      international: parseInt(
        _get(formState, "requester_international_rate_edited_visa.value"),
        10
      ),
      national: parseInt(
        _get(formState, "requester_national_rate_edited_visa.value"),
        10
      )
    },
    collectionRates.filter(r => r.brand_id === 2)[0]
  );

  const {
    national: nationalUnion,
    international: internationalUnion,
    feePayer: feePayerUnion,
    intFeePayer: intFeePayerUnion
  } = getFeeRateAndPayerParams(
    {
      brand_id: 5,
      international: parseInt(
        _get(formState, "requester_international_rate_edited_union.value"),
        10
      ),
      national: parseInt(
        _get(formState, "requester_national_rate_edited_union.value"),
        10
      )
    },
    collectionRates.filter(r => r.brand_id === 5)[0]
  );

  const {
    national: nationalAmex,
    international: internationalAmex,
    feePayer: feePayerAmex,
    intFeePayer: intFeePayerAmex
  } = getFeeRateAndPayerParams(
    {
      brand_id: 4,
      international: parseInt(
        _get(formState, "requester_international_rate_edited_amex.value"),
        10
      ),
      national: parseInt(
        _get(formState, "requester_national_rate_edited_amex.value"),
        10
      )
    },
    collectionRates.filter(r => r.brand_id === 4)[0]
  );

  const termNumber = _get(formState, "terms.value");
  const totalAmount = _get(formState, "invoice_creation_total_amount.value");
  const termLabel = TermsOptions.filter(v => v.value === termNumber)[0].label;
  const payerId = _get(formState, "customer.value");

  const res: Response = yield call(RestClient.send, {
    body: {
      purpose: "collection",
      requests: [
        {
          invoice_note: _get(formState, "invoice_note.value", "null"),
          mail_cc: _get(formState, "mail_cc.value", "null"),
          mail_message: _get(formState, "mail_message.value", "null"),
          mail_reply_to: _get(formState, "mail_reply_to.value", "null"),
          mail_to: _get(formState, "mail_to.value", "null"),
          payees: [
            {
              account_number: collectedAccount.accountNumber,
              bank_bsb_id: collectedAccount.bankBSBId,
              bank_code: collectedAccount.bankCode,
              bank_id: collectedAccount.bankId,
              bsb_code: collectedAccount.bsbCode,
              comments: "",
              currency_id: collectedAccount.currencyId,
              due_date: _get(formState, "due_date.value"),
              gross_amount: 0,
              id: collectedAccount.id,
              invoice_date: _get(formState, "invoice_date.value"),
              invoice_number_digits:
                _get(otherSettings, "generateOrManualIN") === "generate"
                  ? Number(_get(otherSettings, "invoiceNumberTemplate"))
                  : null,
              items: Object.keys(invoiceItems)
                .map(uid => invoiceItems[uid])
                .map(d => ({
                  discount:
                    d.discountType && d.discount ? Number(d.discount) : 0,
                  discount_type: d.discountType,
                  item_id: d.itemId,
                  name: d.name,
                  quantity: d.quantity,
                  tax_id: d.taxId,
                  tax_name: d.taxName,
                  tax_rate: d.taxRate,
                  unit_price: d.unitPrice
                })),
              payment_description: _get(formState, "payment_description.value"),
              po_number: _get(formState, "po_number.value"),
              recipient_name: selectors.getCompany(state).name,
              supporting_documents: [],
              terms: termLabel,
              uid: null
            }
          ],
          payer_id: payerId,
          requester_rates: [
            {
              fee_payer: feePayerVisa,
              int_fee_payer: intFeePayerVisa,
              brand_id: 2,
              national: nationalVisa,
              international: internationalVisa
            },
            {
              fee_payer: feePayerUnion,
              int_fee_payer: intFeePayerUnion,
              brand_id: 5,
              national: nationalUnion,
              international: internationalUnion
            },
            {
              fee_payer: feePayerAmex,
              int_fee_payer: intFeePayerAmex,
              brand_id: 4,
              national: nationalAmex,
              international: internationalAmex
            }
          ],
          term_and_condition: _get(formState, "term_and_condition.value"),
          to_create_or_upload_invoice: "create"
        }
      ]
    },
    service: action.payload.isValidate
      ? "validate_payment_request"
      : "send_payment_request",
    showGlobalLoader: true
  });

  if (!res) {
    return false;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(
      actions.parseServerErrors(errors, CREATE_INVOICE_FORM, {
        [`due_date_${payerId}`]: "due_date",
        [`invoice_date_${payerId}`]: "invoice_date",
        [`payment_description_${payerId}`]: "payment_description",
        [`supplier_amount_${payerId}`]: "supplier_amount",
        payer_id: "customer"
      })
    );
    return false;
  }

  if (action.payload.cb) {
    action.payload.cb();
  }

  if (action.payload.isValidate) {
    history.push("/invoice-creation/preview");
  } else {
    const currencyCode = CurrencyUtils.convertFromId(
      collectedAccount.currencyId
    ).code;

    const checkoutUrls = _get(res, "data.checkout_url_list", []);

    yield put(
      actions.toggleModal(ModalID.REQUEST_PAYMENT_SUCCESSFULLY, {
        checkoutUrls,
        currencyCode,
        paymentAmount: toCents(totalAmount)
      })
    );
  }

  return;
}

export function* handleGetEstimateRate(
  action: ActionType<typeof actions.getEstimateRate>
) {
  const res: Response = yield call(RestClient.send, {
    query: {
      token: action.payload.token
    },
    service: "get_estimate_rate"
  });

  if (!res) {
    return;
  }

  const data = _get(res, "data.requester_rates", "");
  const customerFee = data.map((item: any) => {
    return {
      brandId: item.brand_id,
      nationFee: item.fee_national,
      overseaFee: item.fee_oversea
    };
  });
  yield put(
    actions.setPaymentCollectionFees({
      customerFee
    })
  );
}

export function* handlePublicCreateStripeCardIntent(
  action: ActionType<typeof actions.createPublicStripeCardIntent>
) {
  const state: RootState = yield select();
  const acquirerId = selectors.getAcquirerId(state);

  const res: { data: any } = yield call(RestClient.send, {
    service: "generate_public_stripe_card_intent",
    params: {
      token: action.payload.paymentToken,
      acquirer_id: acquirerId
    },
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    console.error(errors);
    return;
  }

  if (action.payload.cb) {
    action.payload.cb(res.data.client_secret);
  }
}
