import * as React from "react";
import { connect } from "react-redux";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // TODO: @Tien please help to move this.. You can also write your own styles if you want.
import Modal from "./Modal";
import GlobalLoader from "./GlobalLoader";
import * as queryString from "query-string";
import * as actions from "./actions";
import { GlobalUIState } from "./reducers";
import _camelCase from "lodash-es/camelCase";
import { history } from "src/ipm-shared/store";

import { LanguageContext } from "src/ipm-shared/Contexts/index";

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

type IProps = typeof mapDispatchToProps;

class GlobalUI extends React.Component<IProps> {
  public render() {
    return (
      <LanguageContext.Consumer>
        {values => {
          return (
            <div className={`global-ui global-ui--${values.locale}`}>
              <GlobalLoader />
              <ToastContainer transition={Flip} />
              <Modal />
              {this.props.children}
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }

  public componentDidMount() {
    const qs = queryString.parse(window.location.search);
    const modalId = parseInt(qs.m as string, 10);

    if (modalId && actions.ModalID[modalId] !== undefined) {
      const extraInfo: any = {};
      const settings: GlobalUIState["settings"] = {};

      Object.keys(qs).map(param => {
        if (this.isPrefix("mf_", param)) {
          const field = this.formatParam("mf_", param);
          extraInfo[field] = qs[param];
        }

        if (this.isPrefix("ms_", param)) {
          const setting = this.formatParam("ms_", param);
          if (setting === "closeDisabled" && qs[param] === "y") {
            settings.backdrop = "static";
            settings.keyboard = false;
          }
        }
      });

      const timeout = parseInt(qs.t as string, 10);

      const toggleModal = () =>
        window.requestAnimationFrame(() => {
          this.props.toggleModal(modalId, extraInfo, settings);
        });

      if (timeout === 0) {
        toggleModal();
      } else {
        setTimeout(() => {
          toggleModal();
        }, timeout || 1000);
      }

      const removeQs = !!parseInt(qs.rm as string, 10);
      if (removeQs) {
        history.replace({ search: "" });
      }
    }
  }

  private isPrefix(prefix: string, name: string) {
    return name.indexOf(prefix) === 0;
  }

  private formatParam(prefix: string, name: string) {
    return _camelCase(name.replace(prefix, ""));
  }
}

export default connect(null, mapDispatchToProps)(GlobalUI);
