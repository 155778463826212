import * as React from "react";
import T from "src/ipm-shared/Utils/Intl";

type IProps = {
  amount: string;
  size: string;
};

export const ReferButton = ({ amount, size }: IProps) => (
  <span
    className="refer-button"
    dangerouslySetInnerHTML={{
      __html: `
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${
        {
          big: 191.86,
          medium: 175.87,
          small: 154.86
        }[size]
      } 28.09">
        <defs>
          <style>
            .cls-1 {
              fill: #77f7e8;
            }

            .cls-2 {
              fill: #fff;
              stroke-miterlimit: 10;
              stroke-width: 0.78px;
            }

            .cls-10,
            .cls-2,
            .cls-7,
            .cls-8 {
              stroke: #34405b;
            }

            .cls-3 {
              font-size: 10px;
              font-family: OpenSans-Bold, Open Sans;
              font-weight: 700;
            }

            .cls-3,
            .cls-9 {
              fill: #34405b;
            }

            .cls-4,
            .cls-5 {
              font-family: OpenSans-SemiBold, Open Sans;
              font-weight: 600;
            }

            .cls-5 {
              letter-spacing: 0em;
            }

            .cls-6 {
              fill: #15e8bf;
            }

            .cls-10,
            .cls-7,
            .cls-8 {
              fill: none;
              stroke-linecap: round;
              stroke-linejoin: round;
            }

            .cls-7 {
              stroke-width: 0.82px;
            }

            .cls-8 {
              stroke-width: 0.86px;
            }

            .cls-10 {
              stroke-width: 0.91px;
            }
          </style>
        </defs>
        
        ${
          {
            big: `
              <polygon
                class="cls-1"
                points="191.77 24.76 13.96 24.66 15.85 3.31 190.93 3.23 185.82 13.28 191.77 24.76"
              />
              <polygon
                class="cls-2"
                points="187.37 21.83 17.62 21.83 17.62 3.55 187.37 3.55 182.84 13.32 187.37 21.83"
              />
            `,
            medium: `
              <polygon
                class="cls-1"
                points="175.79 24.76 16.52 24.66 18.41 3.31 174.95 3.23 169.84 13.28 175.79 24.76"
              />
              <polygon
                class="cls-2"
                points="171.39 21.83 15.3 21.83 15.3 3.55 171.39 3.55 166.86 13.32 171.39 21.83"
              />
            `,
            small: `
                <polygon class="cls-1" points="151.91 26.05 20.26 25.9 22.25 3.39 151.03 3.34 145.63 13.93 151.91 26.05" />
                <polygon class="cls-2" points="147.27 22.95 21.71 22.95 21.71 3.68 147.27 3.68 142.5 13.98 147.27 22.95" />
            `
          }[size]
        }
        
        <text class="cls-3" transform="translate(33.71 17.41)">
          ${T.transl("TOP_REFER_BUTTON", {
            tspan1: (t: string) =>
              `<tspan class="text" x="0" y="0">${t}</tspan>`,
            tspan2: (t: string) =>
              `<tspan class="text cls-4" y="0" xml:space="preserve">${t} </tspan>`,
            tspan3: (t: string) => `<tspan class="text" y="0">${t}</tspan>`
          })}
          <tspan class="text" y="0"> ${amount}
          </tspan>
        </text>
        <circle class="cls-6" cx="14.08" cy="14.04" r="14.01" transform="translate(-0.84 0.9) rotate(-3.56)" />
        <rect class="cls-7" x="7.84" y="10.95" width="12.4" height="3.03" />
        <rect class="cls-8" x="8.67" y="14.07" width="10.73" height="6.82" />
        <rect class="cls-9" x="13.01" y="10.95" width="2.06" height="9.94" />
        <path class="cls-10"
          d="M14.17,10.63s1-2.73,2.33-3.16a1.42,1.42,0,0,1,1.93,1.6c-.06.7-.61,1.48-2,1.54-1.55.06-2.3,0-2.3,0" />
        <path class="cls-10"
          d="M13.91,10.63s-1-2.73-2.34-3.16a1.42,1.42,0,0,0-1.93,1.6c.07.7.61,1.48,2,1.54,1.55.06,2.31,0,2.31,0" />
      </svg>
    `
    }}
  />
);
