import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "./actions";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState) => ({
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  outComingPayments: selectors.getOutComingPayments(state),
  sumPaymentInThisMonth: selectors.getSumPaymentInThisMonth(state)
});

const mapDispatchToProps = {
  fetchPaymentCalendar: actions.fetchPaymentCalendar
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
