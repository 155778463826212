import * as React from "react";
import Authorize, { IProps } from "./index";
import { RoleType } from "src/ipm-shared/store/model/AccountProfile/types";

const requireAuthorize = <P extends IProps>(
  Component: React.ComponentType<any>,
  roles: RoleType[] | RoleType
) => {
  class Authorizer extends React.Component<P> {
    public render() {
      return (
        <Authorize roles={roles}>
          <Component />
        </Authorize>
      );
    }
  }

  return Authorizer;
};

export default requireAuthorize;
