import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "./actions";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState) => ({
  collectedAccountCurrencyId: selectors.getCurrentBePaidCurrencyId(state), // accepted
  getCurrenciesById: selectors.getCurrenciesById(state),
  getSumPaymentInThisMonth: selectors.bePaidGetSumPaymentInThisMonth(state),
  inComingPayments: selectors.getInComingPayments(state)
});

const mapDispatchToProps = {
  fetchPaymentCalendar: actions.fetchPaymentCalendar
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
