import * as React from "react";
import { NavLink } from "react-router-dom";
import * as Images from "src/ipm-shared/components/Images";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { IProps } from "./index";
import T from "src/ipm-shared/Utils/Intl";

interface IState {
  dropdownOpen: boolean;
}

class View extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  public render() {
    return (
      <Dropdown
        className="user-profile"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle tag="span" className="user-name btn-action">
          <span className="user-profile-image">
            <img src={Images.avatar} alt="Avatar User" />
          </span>

          {this.props.accountProfile.firstName.length > 0 && ( // ok
            <span className="mr-2 fullname">
              {T.transl("NEW_GREETING_NAVBAR", {
                render: (
                  <React.Fragment>
                    {this.props.accountProfile.firstName}
                  </React.Fragment>
                )
              })}
            </span>
          )}
          <span className="arrow-down" />
        </DropdownToggle>
        {this.renderMenu()}
      </Dropdown>
    );
  }

  private toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  private renderMenu() {
    if (this.state.dropdownOpen) {
      return (
        <DropdownMenu tag="ul">
          <DropdownItem tag="li" className="business-account account-block">
            <img src={Images.avatar} alt="Profile image" />
            <div className="account-info">
              <h6>
                {this.props.accountProfile.firstName}{" "}
                {this.props.accountProfile.lastName}
              </h6>
              <p>{this.props.accountProfile.email}</p>
              {this.props.accountProfile.type === "business" && (
                <span className="text-primary">ipaymy Business Account</span>
              )}
              {this.props.accountProfile.type === "personal" && (
                <span className="text-primary">ipaymy Personal Account</span>
              )}
            </div>
          </DropdownItem>
          <DropdownItem className="profile-item mb-2 d-none" tag="li">
            <a href="">My Account</a>
          </DropdownItem>
          {this.props.secondaryAccountProfiles.map(profile => (
            <DropdownItem
              onClick={this.props.switchAccount.bind(this, profile.email)}
              key={profile.email}
              tag="li"
              className="personal-account account-block"
            >
              <img src={Images.avatar} alt="Profile image" />
              <div className="account-info">
                <h6>
                  {profile.firstName} {profile.lastName}
                </h6>
                <p>{profile.email}</p>
                {profile.type === "business" && (
                  <a href="#">ipaymy Business Account</a>
                )}
                {profile.type === "personal" && (
                  <a href="#">ipaymy Personal Account</a>
                )}
              </div>
            </DropdownItem>
          ))}
          <DropdownItem tag="li" className="actions-block">
            <NavLink
              onClick={this.props.addAccount}
              className="btn btn-sm btn-info btn-bepaid"
              to="/login"
            >
              Add Account
            </NavLink>
            <a
              onClick={this.logout}
              href="#"
              className="btn btn-sm btn-outline-info btn-bepaid"
            >
              Sign Out
            </a>
          </DropdownItem>
        </DropdownMenu>
      );
    }
    return null;
  }

  private logout = () => {
    window.location.href = window.location.origin + "/login";
  };
}

export default View;
