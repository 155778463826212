import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import { ModalID } from "../actions";
import { IProps } from "./index";
import * as classNames from "classnames";
import requireComponent from "src/ipm-shared/HOCs/ComponentLoader";
import * as Modals from "src/ipm-platform/shared/modals";

function toggle(props: IProps) {
  props.toggleModal(props.modalId);
}

function switchModal(props: IProps) {
  let ModalIns: ReturnType<typeof requireComponent> | null = null;
  switch (props.modalId) {
    case ModalID.PAYMENT_SUCCESS:
      const SuccessfulPaymentModal = requireComponent(Modals.SuccessfulPayment);
      return <SuccessfulPaymentModal />;
    case ModalID.PAYMENT_SUCCESS_SCHEDULE:
      const SuccessfulScheduledPaymentModal = requireComponent(
        Modals.SuccessfulScheduledPayment
      );
      return <SuccessfulScheduledPaymentModal />;
    case ModalID.PAYMENT_ERROR:
      const ErrorPaymentModal = requireComponent(Modals.ErrorPayment);
      return <ErrorPaymentModal />;
    case ModalID.ADD_RENT_PAYEE_FORM:
      const AddLandLordModal = requireComponent(Modals.AddLandLord);
      return <AddLandLordModal isModal={true} />;
    case ModalID.ADD_INVOICE_PAYEE_FORM:
      const AddInvoicePayeeModal = requireComponent(Modals.AddInvoicePayee);
      return <AddInvoicePayeeModal isModal={true} />;
    case ModalID.ADD_SALARY_PAYEE_FORM:
      const AddSalaryPayeeModal = requireComponent(Modals.AddSalaryPayee);
      return <AddSalaryPayeeModal isModal={true} />;
    case ModalID.ADD_PAYEE_BULK_UPLOAD_CSV:
      const PayeeUploadCSVModal = requireComponent(Modals.PayeeUploadCSV);
      return <PayeeUploadCSVModal />;
    case ModalID.ADD_PAYEE_BULK_PROCESS_CSV:
      const PayeeProcessCSVModal = requireComponent(Modals.PayeeProcessCSV);
      return <PayeeProcessCSVModal />;
    case ModalID.ADD_CARD_FORM:
      const AddCardFormModal = requireComponent(Modals.AddCardForm);
      return <AddCardFormModal />;
    case ModalID.ADD_RATE_FORM:
      const AddAccountRateModal = requireComponent(Modals.AddAccountRate);
      return <AddAccountRateModal />;
    case ModalID.ADMIN_PAYMENT_DETAIL:
      const AdminPaymentDetailModal = requireComponent(
        Modals.AdminPaymentDetail
      );
      return <AdminPaymentDetailModal />;
    case ModalID.ADMIN_VIEW_MY_INFO:
      const AdminViewMyInfoModal = requireComponent(Modals.AdminViewMyInfo);
      return <AdminViewMyInfoModal />;
    case ModalID.ADMIN_VIEW_LIST_ACCOUNT_RATE:
      const AdminViewListAccountRateModal = requireComponent(
        Modals.AdminViewListAccountRate
      );
      return <AdminViewListAccountRateModal />;
    case ModalID.ADMIN_VIEW_FETCH_CDD_QUESTIONS:
      const AdminViewFetchCDDQuestionsModal = requireComponent(
        Modals.AdminViewFetchCDDQuestions
      );
      return <AdminViewFetchCDDQuestionsModal />;

    case ModalID.ADMIN_VIEW_CDD_QUESTIONS:
      const AdminViewCDDQuestionsModal = requireComponent(
        Modals.AdminViewCDDQuestions
      );
      return <AdminViewCDDQuestionsModal />;
    case ModalID.ADMIN_VIEW_ADDITIONAL_DATA:
      const AdminViewAdditionalDataModal = requireComponent(
        Modals.AdminViewAdditionalData
      );
      return <AdminViewAdditionalDataModal />;
    case ModalID.ADMIN_PAYMENT_SUPPORT_DOCUMENT_DETAIL:
      const AdminPaymentSupportDocumentsDetailModal = requireComponent(
        Modals.AdminPaymentSupportDocumentsDetail
      );
      return <AdminPaymentSupportDocumentsDetailModal />;
    case ModalID.CHANGE_RATE_FORM:
      const ChangeAccountRateModal = requireComponent(Modals.ChangeAccountRate);
      return <ChangeAccountRateModal />;
    case ModalID.ADMIN_CHANGE_RATE_DETAIL_FORM:
      const ChangeAccountRateDetailModal = requireComponent(
        Modals.ChangeAccountRateDetail
      );
      return <ChangeAccountRateDetailModal />;
    case ModalID.PAYMENT_PAYEE_DETAIL:
      const PayeeDetailModal = requireComponent(Modals.PayeeDetail);
      return <PayeeDetailModal />;
    case ModalID.ADD_COMPANY_FORM:
      const AddCompanyModal = requireComponent(Modals.AddCompany);
      return <AddCompanyModal />;
    case ModalID.MAKE_PAYMENT_MODAL:
      const MakePaymentModal = requireComponent(Modals.MakePayment);
      return <MakePaymentModal />;
    case ModalID.CONFIRMATION_DELETE:
      const ConfirmationModal = requireComponent(Modals.Confirmation);
      return <ConfirmationModal />;
    case ModalID.PAYMENT_DETAIL:
      const PaymentDetailModal = requireComponent(Modals.PaymentDetail);
      return <PaymentDetailModal />;
    case ModalID.USER_SELECT_ACCOUNT_TYPE:
      const SelectAccountTypeModal = requireComponent(Modals.SelectAccountType);
      return <SelectAccountTypeModal />;
    case ModalID.REQUEST_LOGIN_MODAL:
      const RequestLoginModal = requireComponent(Modals.RequestLogin);
      return <RequestLoginModal />;
    case ModalID.NRIC_ID_PHOTOS_UPLOAD:
      const NricIdPhotosUploadModal = requireComponent(
        Modals.NricIdPhotosUpload
      );
      return <NricIdPhotosUploadModal />;
    case ModalID.NRIC_PHOTOS_DETAIL:
      const NricPhotosModal = requireComponent(Modals.NricPhotos);
      return <NricPhotosModal />;
    case ModalID.ID_VERIFICATION_PHOTO_DETAIL:
      const IDVerificationPhotoModal = requireComponent(
        Modals.IDVerificationPhoto
      );
      return <IDVerificationPhotoModal />;
    case ModalID.ONE_TIME_MODAL:
      const OneTimeModal = requireComponent(Modals.OneTime);
      return <OneTimeModal />;
    case ModalID.MARKETING_VIDEO_MODAL:
      const MarketingVideoModal = requireComponent(Modals.MarketingVideo);
      return <MarketingVideoModal />;
    case ModalID.REFUND_MODAL:
      const RefundModal = requireComponent(Modals.Refund);
      return <RefundModal />;
    case ModalID.FULL_REFUND_MODAL:
      const FullRefundModal = requireComponent(Modals.FullRefund);
      return <FullRefundModal />;
    case ModalID.PARTIAL_REFUND_MODAL:
      const PartialRefundModal = requireComponent(Modals.PartialRefund);
      return <PartialRefundModal />;
    case ModalID.PARTIAL_REFUND_ADDITIONAL_MODAL:
      const PartialRefundAdditional = requireComponent(
        Modals.PartialRefundAdditional
      );
      return <PartialRefundAdditional />;
    case ModalID.PARTIAL_REFUND_SUMMARY_MODAL:
      const PartialRefundSummary = requireComponent(
        Modals.PartialRefundSummary
      );
      return <PartialRefundSummary />;
    case ModalID.PRINCIPAL_REFUND_MODAL:
      const PrincipalRefundModal = requireComponent(Modals.PrincipalRefund);
      return <PrincipalRefundModal />;
    case ModalID.FEE_REFUND_MODAL:
      const FeeRefundModal = requireComponent(Modals.FeeRefund);
      return <FeeRefundModal />;
    case ModalID.PAYMENT_WORK_FLOW_INFO_MODAL:
      const PaymentWorkFlowInfoModal = requireComponent(
        Modals.PaymentWorkFlowInfo
      );
      return <PaymentWorkFlowInfoModal />;
    case ModalID.TWO_DAY_ANNOUNCEMENT_MODAL:
      const TwoDayAnnouncementModal = requireComponent(
        Modals.TwoDayAnnouncement
      );
      return <TwoDayAnnouncementModal />;
    case ModalID.EXPEDITE_MODAL:
      const ExpediteModal = requireComponent(Modals.Expedite);
      return <ExpediteModal />;
    case ModalID.REFERRAL_PROGRAM_MODAL:
      const ReferralProgramModal = requireComponent(Modals.ReferralProgram);
      return <ReferralProgramModal />;
    case ModalID.NEXT_DAY_PAYOUT_MODAL:
      const NextDayPayoutModal = requireComponent(Modals.NextDayPayout);
      return <NextDayPayoutModal />;
    case ModalID.RECURRING_PAYMENT_SUCCESS:
      const RecurringPaymentSuccessfulModal = requireComponent(
        Modals.RecurringPaymentSuccessful
      );
      return <RecurringPaymentSuccessfulModal />;
    case ModalID.POINTS_GUARANTEE_MODAL:
      const PointsGuaranteeModal = requireComponent(Modals.PointsGuarantee);
      return <PointsGuaranteeModal />;
    case ModalID.TAX_PAYMENT_PROMOTION_MODAL:
      const TaxPaymentPromotionModal = requireComponent(
        Modals.TaxPaymentPromotion
      );
      return <TaxPaymentPromotionModal />;
    case ModalID.RENT_PROMO_MODAL:
      const RentPromoModal = requireComponent(Modals.RentPromo);
      return <RentPromoModal />;
    case ModalID.PROMO_CODE_MODAL:
      const PromoCodeModal = requireComponent(Modals.PromoCode);
      return <PromoCodeModal />;
    case ModalID.CROSS_BORDER_ANNOUNCEMENT_MODAL:
      const CrossBorderAnnouncementModal = requireComponent(
        Modals.CrossBorderAnnouncement
      );
      return <CrossBorderAnnouncementModal />;
    case ModalID.ADD_CUSTOMER_FORM_MODAL:
      const AddCustomerModal = requireComponent(Modals.AddCustomer);
      return <AddCustomerModal isModal={true} />;
    case ModalID.REQUEST_PAYMENT_SUCCESSFULLY:
      const RequestPaymentSuccessfully = requireComponent(
        Modals.RequestPaymentSuccessfully
      );
      return <RequestPaymentSuccessfully />;
    case ModalID.COLLECTED_PAYMENT_FAILED:
      const CollectedPaymentFailedModal = requireComponent(
        Modals.CollectedPaymentFailed
      );
      return <CollectedPaymentFailedModal />;
    case ModalID.RESEND_PAYMENT_REQUEST:
      const ResendPaymentRequestModal = requireComponent(
        Modals.ResendPaymentRequest
      );
      return <ResendPaymentRequestModal />;
    case ModalID.COLLECTED_PAYMENT_SUCCESS:
      const CollectedPaymentSuccessfully = requireComponent(
        Modals.SuccessfullyCollectionPayment
      );
      return <CollectedPaymentSuccessfully />;
    case ModalID.SCHEDULED_PAYMENT_DETAIL:
      const ScheduledPaymentDetail = requireComponent(
        Modals.ScheduledPaymentDetail
      );
      return <ScheduledPaymentDetail />;

    case ModalID.RESTRICTED_BUSINESSES_CONFIRM:
      const RestrictedBusinessesConfirmModal = requireComponent(
        Modals.RestrictedBusinessesConfirmModal
      );
      return <RestrictedBusinessesConfirmModal />;

    case ModalID.EDIT_PAYMENT_DETAIL_MODAL:
      const EditPaymentDetailModal = requireComponent(
        Modals.EditPaymentDetailModal
      );
      return <EditPaymentDetailModal />;

    case ModalID.KYC_STATUS_MODAL:
      const KycStatusModal = requireComponent(Modals.KycStatusModal);
      return <KycStatusModal />;
    case ModalID.CHOOSE_CURRENCY_MODAL:
      const ChooseCurrencyModal = requireComponent(Modals.ChooseCurrencyModal);
      return <ChooseCurrencyModal />;

    case ModalID.ADD_SELF_TRANSFER_PAYEE_FORM:
      const AddSelfTransferPayeeFormModal = requireComponent(
        Modals.AddSelfTransferPayeeForm
      );
      return <AddSelfTransferPayeeFormModal isModal={true} />;

    case ModalID.DO_YOU_HAVE_CREDIT_CARD:
      const DoYouHaveCreditCardModal = requireComponent(
        Modals.DoYouHaveCreditCardModal
      );
      return <DoYouHaveCreditCardModal />;
    case ModalID.PROMO_POPUP:
      const PromoPopupModal = requireComponent(Modals.PromoPopupModal);
      return <PromoPopupModal />;

    case ModalID.COLLECTION_REVIEW_BEFORE_SEND_REQUEST:
      const ReviewDetailsViewModal = requireComponent(
        Modals.ReviewDetailsViewModal
      );
      return <ReviewDetailsViewModal />;

    case ModalID.BE_PAID_SETUP_INVOICE_NUMBER:
      const CollectionInvoiceNumberModal = requireComponent(
        Modals.CollectionInvoiceNumberModal
      );
      return <CollectionInvoiceNumberModal />;
    case ModalID.BE_PAID_INVOICE_EDIT_FEES:
      const CollectionInvoiceEditFeesModal = requireComponent(
        Modals.CollectionInvoiceEditFeesModal
      );
      return <CollectionInvoiceEditFeesModal />;
    case ModalID.BE_PAID_INVOICE_ADD_NEW_TAX:
      const CollectionInvoiceCreateTaxModal = requireComponent(
        Modals.CollectionInvoiceCreateTaxModal
      );
      return <CollectionInvoiceCreateTaxModal />;

    case ModalID.BE_PAID_INVOICE_ADD_NEW_ITEM:
      const CollectionInvoiceCreateItemModal = requireComponent(
        Modals.CollectionInvoiceCreateItemModal
      );
      return <CollectionInvoiceCreateItemModal />;

    case ModalID.BE_PAID_MAKE_PAYMENT:
      const BePaidMakePaymentModal = requireComponent(
        Modals.BePaidMakePaymentModal
      );
      return <BePaidMakePaymentModal />;
    case ModalID.BE_PAID_SMS_NOTIFY:
      const BePaidSmsNotifyModal = requireComponent(Modals.BePaidSmsNotify);
      return <BePaidSmsNotifyModal />;
    case ModalID.OPTIMIZE_DASHBOARD_CONFIRM:
      const OptimizeDashboardConfirmModal = requireComponent(
        Modals.OptimizeDashboardConfirmModal
      );
      return <OptimizeDashboardConfirmModal />;
    case ModalID.BE_PAID_REFUND:
      const BePaidRefundModal = requireComponent(Modals.BePaidRefund);
      return <BePaidRefundModal />;
    case ModalID.BE_PAID_MARK_PAYMENT_AS_PAID:
      const BePaidMarkPaymentAsPaidModal = requireComponent(
        Modals.BePaidMarkPaymentAsPaid
      );
      return <BePaidMarkPaymentAsPaidModal />;
    case ModalID.INTERNATIONAL_REDIRECT_KYC_MODAL:
      const InternationalRedirectKycModal = requireComponent(
        Modals.InternationalRedirectKyc
      );
      return <InternationalRedirectKycModal />;

    case ModalID.INTERNATIONAL_KYC_REMINDER_MODAL:
      const InternationalKycReminderModal = requireComponent(
        Modals.InternationalKycReminder
      );
      return <InternationalKycReminderModal />;

    case ModalID.CREDIT_PROGRAM_V1_NOTIFICATION:
      ModalIns = requireComponent(Modals.CreditProgramV1NotificationModal);
      return <ModalIns />;

    case ModalID.CREDIT_PROGRAM_CONFIRM_MODAL:
      ModalIns = requireComponent(Modals.CreditProgramConfirmModal);
      return <ModalIns />;

    case ModalID.CREDIT_PROGRAM_CONFIRMED_MODAL:
      ModalIns = requireComponent(Modals.CreditProgramConfirmedModal);
      return <ModalIns />;

    case ModalID.BE_PAID_UPDATE_FEE_SETTINGS_CONFIRMATION_MODAL:
      const BePaidUpdateFeeSettingsConfirmationModal = requireComponent(
        Modals.BePaidUpdateFeeSettingsConfirmationModal
      );
      return <BePaidUpdateFeeSettingsConfirmationModal />;

    case ModalID.MY_INFO_VERIFICATION_MODAL:
      const MyInfoVerificationModal = requireComponent(
        Modals.MyInfoVerificationModal
      );
      return <MyInfoVerificationModal isModal={true} />;
    case ModalID.MY_INFO_VERIFICATION_RETRIEVED_NRIC_MODAL:
      const MyInfoVerificationRetrievedNRICModal = requireComponent(
        Modals.MyInfoVerificationRetrievedNRICModal
      );
      return <MyInfoVerificationRetrievedNRICModal isModal={true} />;
    case ModalID.MY_INFO_VERIFICATION_ERROR_MODAL:
      const MyInfoVerificationErrorModal = requireComponent(
        Modals.MyInfoVerificationErrorModal
      );
      return <MyInfoVerificationErrorModal />;
    case ModalID.PAYMENT_INTEREST_MODAL:
      const PaymentInterestModal = requireComponent(Modals.PaymentInterest);
      return <PaymentInterestModal />;
    case ModalID.UEN_MODAL:
      const UENModal = requireComponent(Modals.UENModal);
      return <UENModal />;
    case ModalID.UPLOAD_DOCUMENT_BEPAID_MODAL:
      const UploadDocumentBepaidModal = requireComponent(
        Modals.UploadDocumentBepaidModal
      );
      return <UploadDocumentBepaidModal />;

    case ModalID.CARDSPAL_MODAL:
      const CardsPalModal = requireComponent(Modals.CardsPalModal);
      return <CardsPalModal />;

    case ModalID.BEPAID_UNAVAILABLE_MODAL:
      const BePaidUnavailableModal = requireComponent(
        Modals.BePaidUnavailableModal
      );
      return <BePaidUnavailableModal />;

    case ModalID.ADMIN_GET_RESET_LINK_MODAL:
      const AdminResetLinkModal = requireComponent(Modals.AdminResetLinkModal);
      return <AdminResetLinkModal />;

    case ModalID.ADMIN_GET_EMAIL_CONFIRM_LINK_MODAL:
      const AdminEmailConfirmLinkModal = requireComponent(
        Modals.AdminEmailConfirmLinkModal
      );
      return <AdminEmailConfirmLinkModal />;

    case ModalID.ADMIN_ESCALATION_MODAL:
      const AdminEscalationModal = requireComponent(
        Modals.AdminEscalationModal
      );
      return <AdminEscalationModal />;

    case ModalID.ADMIN_ESCALATION_COMMENTS_MODAL:
      const AdminEscalationCommentsModal = requireComponent(
        Modals.AdminEscalationCommentsModal
      );
      return <AdminEscalationCommentsModal />;

    case ModalID.INFORMATION_MODAL:
      const InformationModal = requireComponent(Modals.InformationModal);
      return <InformationModal />;

    case ModalID.ADMIN_BLOCK_USER_MODAL:
      const AdminBlockUserModal = requireComponent(Modals.AdminBlockUserModal);
      return <AdminBlockUserModal />;

    case ModalID.REQUEST_LOGIN_PAYFETCH:
      const RequestLoginPayFetch = requireComponent(
        Modals.RequestLoginPayFetch
      );
      return <RequestLoginPayFetch />;

    case ModalID.RECOLLECT_CVV:
      const RecollectCVVModal = requireComponent(Modals.RecollectCVVModal);
      return <RecollectCVVModal />;
    case ModalID.SCREENING_FLAG_VIEW_MODAL:
      const AdminFlagViewModal = requireComponent(Modals.AdminFlagViewModal);
      return <AdminFlagViewModal />;
    case ModalID.UPLOAD_SETTLEMENT_FILE_MODAL:
      const UploadSettlementFileModal = requireComponent(
        Modals.UploadSettlementFileModal
      );
      return <UploadSettlementFileModal />;
    case ModalID.ADMIN_REFERRAL_DETAIL_MODAL:
      const AdminReferralDetailModal = requireComponent(
        Modals.AdminReferralDetailModal
      );
      return <AdminReferralDetailModal />;
    case ModalID.ADMIN_UPDATE_ACCOUNT_TYPE_MODAL:
      const UpdateAccountTypeModel = requireComponent(
        Modals.UpdateAccountTypeModel
      );
      return <UpdateAccountTypeModel />;
    case ModalID.ADMIN_UPDATE_ACCOUNT_DETAILS_MODAL:
      const UpdateAccountDetailsModel = requireComponent(
        Modals.UpdateAccountDetailsModel
      );
      return <UpdateAccountDetailsModel />;
    default:
      return null;
  }
}

class GlobalModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <Modal
        {...this.props.settings}
        isOpen={this.props.isOpen}
        toggle={toggle.bind(null, this.props)}
        className={classNames(
          ModalID[this.props.modalId]
            ? ModalID[this.props.modalId].toLowerCase()
            : "",
          {
            "personal-account": !this.props.isBusiness
          }
        )}
      >
        <ModalBody>{switchModal(this.props)}</ModalBody>
      </Modal>
    );
  }
}

export default GlobalModal;
