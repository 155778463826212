import * as React from "react";
import LoaderIcon from "../LoaderIcon";
import * as RL from "react-loaders";
import { Transition } from "react-transition-group";

/**
 * If `loading` is true, will display a loader, otherwise, it will display the children.
 */
interface IProps {
  loading: boolean;
  children: React.ReactElement<any> | React.ReactNode;
  type?: RL.LoaderType;
  color?: string;
}

const enterTransitionStyles = {
  entered: { opacity: 1 },
  entering: { opacity: 0 }
};
const exitTransitionStyles = {
  exited: { opacity: 0 },
  exiting: { opacity: 1 }
};

const Component: React.SFC<IProps> = props => (
  <React.Fragment>
    <Transition in={props.loading} timeout={{ enter: 0, exit: 10000 }}>
      {state => {
        return (
          <div
            style={{
              opacity: 1,
              transition: `opacity 300ms ease-in-out`,
              ...exitTransitionStyles[state]
            }}
          >
            {props.loading && (
              <LoaderIcon
                type={props.type || "line-scale"}
                active={props.loading}
                color={props.color}
              />
            )}
          </div>
        );
      }}
    </Transition>
    <Transition in={!props.loading} timeout={300}>
      {state => {
        return (
          <div
            style={{
              opacity: 0,
              transition: `opacity 300ms ease-in-out`,
              ...enterTransitionStyles[state]
            }}
          >
            {!props.loading && props.children}
          </div>
        );
      }}
    </Transition>
  </React.Fragment>
);

export default Component;
