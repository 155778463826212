/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { Escalation, EscalationCommentType } from "./reducers";

export const setEscalations = createAction(
  "escalations/SET",
  resolve => (args: {
    isFetching?: boolean;
    escalations?: Array<Escalation>;
    total?: number;
    selectedId?: number;
  }) => resolve(args)
);

export const setEscalationDetail = createAction(
  "escalations/SET_ESCALATION_DETAIL",
  resolve => (args: { escalationId?: number; statusId?: number }) =>
    resolve(args)
);

export const submitAddEscalation = createAction(
  "escalations/SUBMIT_ADD",
  resolve => (args: { typeId: number; cb?: () => void }) => resolve(args)
);

export const updateEscalation = createAction(
  "escalations/UPDATE",
  resolve => (args: {
    escalationId?: number;
    statusId?: number;
    comments?: string;
  }) => resolve(args)
);

export const fetchEscalations = createAction(
  "escalations/FETCH",
  resolve => (offset: number = 0) => resolve({ offset })
);

export const fetchAdminEscalationComments = createAction(
  "escalations/ADMIN_FETCH_ESCALATION_COMMENTS",
  resolve => (id: number) => resolve({ id })
);

export const setSelectedEscalationComments = createAction(
  "escalations/SET_SELECTED_ESCALATION_COMMENTS",
  resolve => (args: {
    isFetching: boolean;
    selectedEscalationComments: EscalationCommentType[];
  }) => resolve(args)
);

export const selectEscalation = createAction(
  "escalations/SELECT_ESCALATION",
  resolve => (id: number) => resolve({ id })
);

export const setNewEscalationCommentsCount = createAction(
  "escalations/SET_NEW_ESCALATION_COMMENT_COUNT",
  resolve => (args: { newEscalationCommentsCount: { [id: string]: number } }) =>
    resolve(args)
);
