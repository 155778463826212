/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import { PURPOSE } from "src/ipm-shared/store/model/Payment/const";
import getPaymentCollectionsRequestReducer from "./reducers";

ReducerFactory.registerReducer({
  paymentCollectionRequest: getPaymentCollectionsRequestReducer
});

export const getPaymentCollectionRequest = (state: RootState) =>
  state.paymentCollectionRequest.payment;

export const isPartnershipPaymentCollectionRequest = (state: RootState) =>
  state.paymentCollectionRequest.payment.purposeId === PURPOSE.PARTNERSHIP;

export const getPaymentCollectionAcquirerId = (state: RootState): number =>
  state.paymentCollectionRequest.acquirerId;

export const getPaymentCollectionPaymentCountryId = (
  state: RootState
): number => state.paymentCollectionRequest.payment.paymentCountryId;
