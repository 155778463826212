/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import _get from "lodash-es/get";
import _findIndex from "lodash-es/findIndex";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as screeningActions from "./actions";
import * as commonActions from "../actions";
import { ADMIN_SCREENING_FORM } from "./const";
import restService from "src/ipm-shared/services/Rest";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import RestClient from "src/ipm-shared/services/Rest";

const actions = {
  ...screeningActions,
  ...commonActions
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchScreeningReportList, handleFetchReportScreening),
  takeLatest(
    getType(actions.updateStatusScreeningReport),
    handleUpdateStatusScreeningReport
  )
];
export default watchedSagas;

export function* handleFetchReportScreening(
  action: ActionType<typeof actions.fetchScreeningReportList>
) {
  yield put(
    actions.setScreeningReportList({
      isFetching: true,
      total: 0,
      reports: []
    })
  );

  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    ADMIN_SCREENING_FORM
  ) as any;
  const formState = formSelectors.getControls(state, ADMIN_SCREENING_FORM);
  const orderBy = _get(formState, "sort_screening.value", "").trim();
  if (orderBy) {
    const sort = orderBy.split(" ");
    query[sort[0]] = sort[1];
  }
  const res: Response = yield call(restService.send, {
    query,
    service: "admin_get_report_screening"
  });

  if (!res) {
    yield put(
      actions.setScreeningReportList({
        isFetching: false,
        total: 0,
        reports: []
      })
    );
    return;
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    const total: number = _get(json, "total", 0);

    yield put(
      actions.setScreeningReportList({
        isFetching: false,
        total,
        reports: data.map(item => ({
          id: item.id,
          createdAt: item.created_at,
          email: item.email,
          name: item.name,
          singpassName: item.singpass_name,
          dob: item.dob,
          country: item.country,
          userId: item.user_id,
          companyId: item.company_id,
          payeeId: item.payee_id,
          payerId: item.payer_id,
          searchTerm: item.search_term,
          status: item.status,
          matchStatus: item.match_status,
          metaData: item.metadata,
          partyType: item.party_type,
          onScreeningReason: item.on_screening_reason,
          expirationAt: item.expiration_at,
          resultCASearch: item.result_ca_search,
          engineSearch: item.engine_search,
          artemisType: item.artemis_type,
          myinfoType: item.myinfo_type
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchUsers: ", e.message);
  }
}

export function* handleUpdateStatusScreeningReport(
  action: ActionType<typeof actions.updateStatusScreeningReport>
) {
  if (!action.payload.searchStatus) {
    return;
  }

  if (action.payload.engineSearch == "artemis") {
    if (action.payload.searchStatus == "H") {
      const acceptStatuses = ["CLEARED", "DRAFT"];
      if (!acceptStatuses.includes(action.payload.currentStatus)) {
        yield put(actions.toast("Please select cleared or draft status!"));
        return;
      }
    } else if (action.payload.searchStatus == "R") {
      const acceptStatuses = ["ON_HOLD"];
      if (!acceptStatuses.includes(action.payload.currentStatus)) {
        yield put(actions.toast("Please select on hold status!"));
        return;
      }
    }
  }

  yield put(actions.displayLoading(true));
  const res: Response = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    body: {
      search_status: action.payload.searchStatus,
      current_status: action.payload.currentStatus,
      match_status: action.payload.matchStatus,
      engine_search: action.payload.engineSearch
    },
    service: "admin_update_report_screening_status"
  });

  if (!res) {
    return;
  }

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const errors = _get(res, "errors", undefined);
  if (errors) {
    window.Logger.error(errors);
    return;
  }

  yield put(actions.fetchScreeningReportList(action.payload.flag));
}
