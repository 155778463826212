import * as React from "react";
import * as classNames from "classnames";
import { IBaseProps, IBaseState } from "./lib/Base";
import _isEmpty from "lodash-es/isEmpty";
import _findIndex from "lodash-es/findIndex";
import BaseControl from "./lib/Base";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType, ControlType, ControlValueType } from "../types";
import withPropsChecker from "./lib/withPropsChecker";
import T from "src/ipm-shared/Utils/Intl";

export type ISelectOption = {
  value: string | number | undefined;
  label: string | React.Component;
  disableItem?: boolean;
  callbackOnSelect?: (value: string | number | undefined) => void;
};
export type ISelectProps = IBaseProps & {
  options: ISelectOption[];
  labelClassName?: string;
  itemClassName?: string;
  fixedLabel?: string;
  preventFromInitChange?: boolean;
  dropdownMenuRight?: boolean;
};

const mapStateToProps = (
  state: RootState,
  props: ISelectProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type ISelectState = IBaseState & {
  dropdownShown: boolean;
  originValue?: string;
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISelectProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl
 */
class Select extends BaseControl<IProps, ISelectState> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  public componentDidMount() {
    const { name, defaultValue, form, displayError, control } = this.props;
    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });

    this.doValidate(defaultValue, name, displayError, true);
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      options,
      control,
      errorStyle,
      labelClassName,
      fixedLabel
    } = this.props;

    const label = this.getLabel();

    if (control.notFound) {
      return null;
    }

    if (this.props.labelOnly) {
      return this.customRenderLabelOnly(control, this.props.id, errorStyle);
    }

    const disableToggle = !!this.props.disabled;

    return (
      <div className="w-100">
        <Dropdown
          className={this.props.className}
          isOpen={this.state.dropdownShown}
          toggle={this.toggleDropDown}
        >
          <div className={"d-flex align-items-center"}>
            <DropdownToggle
              disabled={disableToggle}
              tag="span"
              className={classNames("form-control", labelClassName, {
                "is-invalid text-danger":
                  control.displayError && control.errors.length > 0
              })}
              caret={true}
              tabIndex={0}
            >
              {fixedLabel ? fixedLabel : label}
            </DropdownToggle>
          </div>
          <DropdownMenu right={this.props.dropdownMenuRight} tag="ul">
            {Object.keys(options).map((key: string, idx) => {
              return this.renderDropdownItem(parseInt(key, 10), idx);
            })}
          </DropdownMenu>

          {this.renderErrorMessage(control)}
        </Dropdown>
      </div>
    );
  }

  private getLabel(): ISelectOption["label"] {
    const { options, control, placeholder } = this.props;

    if (options.length === 0) {
      return placeholder || "";
    }
    let label: ISelectOption["label"] = "";

    options.map((o: ISelectOption) => {
      if (o.value === control.value) {
        label = o.label;
      }
    });

    if (label === "") {
      label = placeholder || options[0].label;
    }

    return label;
  }

  private getValue(key: number): ISelectOption["value"] {
    const { options } = this.props;
    if (_isEmpty(options[key])) {
      return undefined;
    } else {
      return options[key].value;
    }
  }

  private renderDropdownItem = (key: number, idx: number) => {
    const option = this.props.options[key];
    return (
      <DropdownItem
        key={`${key}-${idx}`}
        tag="li"
        onClick={this.onChooseOption.bind(this, key)}
        className={classNames(this.props.itemClassName, {
          disabled: option.disableItem
        })}
      >
        <span title={`${option.label}`}>{option.label}</span>
      </DropdownItem>
    );
  };

  private toggleDropDown = () => {
    this.setState({ dropdownShown: !this.state.dropdownShown });
  };

  private onChooseOption = (key: number) => {
    const { name, form } = this.props;
    const value = this.getValue(key);
    // if (value === control.value) {
    //   return;
    // } For case Action, repeat action

    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });

    this.doValidate(value, name);
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true,
    isInitChange: boolean = false
  ) => {
    let it = this.validate(value);
    let result: any;
    const errors: ControlErrorType[] = [];

    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    it = Select.baseValidate(this.props, value);
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (isInitChange && this.props.preventFromInitChange) {
        // do nothing
      } else {
        if (this.props.onChangeCustom && this.alive) {
          this.props.onChangeCustom(value);
        }

        this.props.options.map(o => {
          if (o.value === value && o.callbackOnSelect !== undefined) {
            o.callbackOnSelect(o.value);
          }
        });
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };

  private *validate(value: ControlValueType) {
    const { required, requiredMessage, options } = this.props;

    if (required) {
      // Case: Default value does not belong to select options
      if (value && _findIndex(options, o => o.value === value) === -1) {
        yield {
          code: "REQUIRED",
          message: requiredMessage || T.transl("REQUIRED_FIELD")
        };
      }
    }
  }

  private customRenderLabelOnly = (
    control: ControlType,
    id?: string,
    errorStyle?: string
  ) => {
    let output = this.renderLabelOnly(control, id, errorStyle, false);
    if (!output) {
      output = <span title={`${this.getLabel()}`}>{this.getLabel()}</span>;
    }

    return output;
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(Select),
  ["defaultValue"]
);
