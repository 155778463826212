import { Route, Router, Switch } from "react-router-dom";
import * as React from "react";
import { Provider } from "react-redux";
import store, { history, sagaMiddleware } from "src/ipm-shared/store";
import App from "./layout";
import * as Pages from "src/ipm-platform/pages";
import requireComponent from "src/ipm-shared/HOCs/ComponentLoader";
import GlobalUI from "src/ipm-shared/components/GlobalUI";
import IntlProvider from "src/ipm-shared/components/Intl/IntlProvider";
import rootSaga from "src/ipm-shared/store/model/sagas";
import FeatureProvider from "src/ipm-shared/components/FeatureGating/FeatureProvider";
import LinkedInProvider from "src/ipm-shared/components/LinkedInProvider/LinkedInProvider";
import Logger from "src/ipm-shared/Utils/Logger";
import FacebookPixelProvider from "src/ipm-shared/components/FacebookPixelProvider";
import * as ReactRouter from "react-router";
import Is from "src/ipm-shared/Utils/Is";
import * as uuidv1 from "uuid/v1";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

IPMContext.initSession();
window.Logger = Logger;
sagaMiddleware.run(rootSaga());

try {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: "go8gqcvi"
    });
  }
} catch (e) {
  window.Logger("Intercom boot failed: " + e.toString());
}

interface IProps extends React.Props<any> {}

class Root extends React.Component<IProps, any> {
  componentDidMount() {
    if (window.location.pathname === "/") {
      history.push("/admin/payments");
    }
  }
  public render() {
    return (
      <Provider store={store}>
        <IntlProvider>
          <GlobalUI>
            <LinkedInProvider>
              <FeatureProvider>
                <Router history={history}>
                  <Switch>
                    <Route
                      // tslint:disable-next-line:jsx-no-lambda
                      render={(renderProps: ReactRouter.RouteProps) => {
                        return (
                          <>
                            {Is.live() &&
                              renderProps.location &&
                              renderProps.location.pathname.indexOf(
                                "/admin"
                              ) === -1 && (
                                <img
                                  height="1"
                                  width="1"
                                  style={{ display: "none" }}
                                  alt=""
                                  src={`https://px.ads.linkedin.com/collect/?pid=164779&fmt=gif&uuid=${uuidv1()}`}
                                  key={uuidv1()}
                                />
                              )}

                            <FacebookPixelProvider
                              pixelId={"1077166438999750"}
                              {...renderProps}
                            >
                              <Switch>
                                <Route
                                  path="/reset-password/request"
                                  component={requireComponent(
                                    Pages.RequestResetPassword
                                  )}
                                />
                                <Route
                                  path="/set-password"
                                  component={requireComponent(
                                    Pages.SetNewPassword
                                  )}
                                />
                                <Route
                                  path="/reset-password/instructions"
                                  component={requireComponent(
                                    Pages.Instructions
                                  )}
                                />
                                <Route
                                  path="/login"
                                  component={Pages.LoginPage}
                                />
                                <Route
                                  path="/register"
                                  component={requireComponent(
                                    Pages.RegisterPage
                                  )}
                                />
                                <Route
                                  path="/post-register"
                                  component={requireComponent(
                                    Pages.PostRegisterPage
                                  )}
                                />
                                <Route
                                  path="/mobile-verification"
                                  component={requireComponent(
                                    Pages.MobileVerification
                                  )}
                                />
                                <Route
                                  path="/payment_collection/checkout/:token"
                                  component={requireComponent(
                                    Pages.PaymentCollectionCheckout
                                  )}
                                />
                                <Route
                                  path="/payment_processed"
                                  component={requireComponent(
                                    Pages.PaymentCollectionConfirm
                                  )}
                                />
                                <Route
                                  path="/payment_confirmation/:receipt_number"
                                  component={requireComponent(
                                    Pages.PaymentCollectionMakeAlready
                                  )}
                                />
                                <Route
                                  path="/payment_cancelled/:company_name"
                                  component={requireComponent(
                                    Pages.PaymentCancelled
                                  )}
                                />
                                <Route path="/" component={App} />
                              </Switch>
                            </FacebookPixelProvider>
                          </>
                        );
                      }}
                    />
                  </Switch>
                </Router>
              </FeatureProvider>
            </LinkedInProvider>
          </GlobalUI>
        </IntlProvider>
      </Provider>
    );
  }
}

export default Root;
