import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import * as actions from "../actions";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType } from "../types";

const mapStateToProps = (
  state: RootState,
  props: {
    form: string;
  }
): {
  errors: ControlErrorType[];
} => ({
  errors: selectors.getFormErrors(state, props.form)
});

const mapDispatchToProps = {
  resetFormErrors: actions.resetFormErrors
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    className?: string;
    form: string;
    ignoreErrors?: string[];
  };

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show Form errors.
 */
class FormErrors extends React.Component<IProps, any> {
  public render() {
    const { className, errors, ignoreErrors } = this.props;

    return (
      <div className={className}>
        {errors
          .filter(error => {
            if (ignoreErrors) {
              return ignoreErrors.indexOf(error.code) < 0;
            }
            return true;
          })
          .map((error, idx) => (
            <span key={idx} className="form-invalid-feedback">
              {error.message}
            </span>
          ))}
      </div>
    );
  }

  public componentWillUnmount() {
    this.props.resetFormErrors(this.props.form);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormErrors);
