import * as React from "react";
import BaseControl, { IBaseProps, IBaseState } from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as classNames from "classnames";
import withPropsChecker from "./lib/withPropsChecker";

type RadioOption = {
  label: string;
  value: string;
};
export type IRadioToggleProps = IBaseProps & {
  options: {
    left: RadioOption;
    right: RadioOption;
  };
  leftActive?: boolean;
};

const mapStateToProps = (
  state: RootState,
  props: IRadioToggleProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IRadioToggleProps;

type IState = IBaseState;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a radio control under toggling style.
 *
 * @base BaseControl
 */
class RadioToggle extends BaseControl<IProps, IState> {
  public static defaultProps = { ...BaseControl.defaultProps };

  public componentDidMount() {
    let { defaultValue = "" } = this.props;
    const { name, form, options, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    if (defaultValue === undefined || defaultValue === "") {
      defaultValue = options.left.value;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const { control, options } = this.props;

    if (control.notFound) {
      return null;
    }

    return (
      <div
        className={classNames(`toggle-list ${this.props.className}`, {
          "left-active": this.props.leftActive
        })}
      >
        <span>{options.left.label}</span>
        <div
          onClick={this.toggleList.bind(this, control.value)}
          className={classNames("toggle-icon", {
            active:
              control.value ===
              (this.props.leftActive ? options.left.value : options.right.value)
          })}
        >
          <div className="line" />
          <div className="circle" />
        </div>
        <span>{options.right.label}</span>
      </div>
    );
  }

  private toggleList = (currentValue: RadioOption["value"]) => {
    const {
      options: {
        left: { value: leftValue },
        right: { value: rightValue }
      }
    } = this.props;
    const value = currentValue === rightValue ? leftValue : rightValue;

    this.props.setControl({
      name: this.props.name,
      value
    });

    if (this.props.onChangeCustom && this.alive) {
      this.props.onChangeCustom(value);
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(RadioToggle)
);
