/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { CountryType } from "./reducers";

export const fetchCountries = createAction("countries/FETCH");

export const setCountries = createAction(
  "countries/SET",
  resolve => (args: { isFetching: boolean; countries: CountryType[] }) =>
    resolve(args)
);

// export const setCurrentCountry = createAction(
//   "countries/SET_CURRENT",
//   resolve => (args: { code: string }) => resolve(args)
// );
