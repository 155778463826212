import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType, getType } from "typesafe-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import * as formActions from "src/ipm-shared/components/Form/actions";
import RestClient from "src/ipm-shared/services/Rest";
import * as commonActions from "./actions";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { ADD_FORM } from "./const";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as customerSelectors from "src/ipm-shared/store/model/Customer/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as paymentCollectionSelectors from "src/ipm-shared/store/model/PaymentCollections/selectors";
import { CONFIRM_FORM } from "src/ipm-shared/store/model/Payment/const";
import { history } from "src/ipm-shared/store";
import * as companySelectors from "src/ipm-shared/store/model/Company/selectors";
import _last from "lodash-es/last";

const actions = {
  ...commonActions,
  ...formActions
};

const selectors = {
  ...formSelectors,
  ...customerSelectors,
  ...accountProfileSelectors,
  ...companySelectors,
  ...paymentCollectionSelectors
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchCustomers, handleFetchCustomers),
  takeLatest(getType(actions.addCustomer), handleAddCustomer),
  takeLatest(getType(actions.editCustomer), handleEditCustomer),
  takeLatest(getType(actions.deleteCustomer), handleDeleteCustomer)
];
export default watchedSagas;

export function* handleFetchCustomers(
  action: ActionType<typeof actions.fetchCustomers>
) {
  if (action.payload.forceRefresh) {
    yield put(
      actions.setCustomers({
        customers: [],
        isFetching: true,
        total: 0
      })
    );
  }

  const state: RootState = yield select();

  if (selectors.getCustomers(state).length !== 0) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    query: {
      get_all: action.payload.getAll,
      offset: action.payload.offset,
      page_count: action.payload.pageCount,
      sms_customer: action.payload.smsCustomers
    },
    service: "get_customer"
  });

  if (!res) {
    yield put(
      actions.setCustomers({
        customers: [],
        isFetching: false,
        total: 0
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);

  if (data.length === 0 && !action.payload.acceptEmptyData) {
    history.push("/customer/add");
    return;
  }

  yield put(
    actions.setCustomers({
      customers: data.map(c => ({
        addressLine1: _get(c, "address_line_1", null),
        addressLine2: _get(c, "address_line_2", null),
        companyName: c.company_name,
        email: c.email,
        id: c.id,
        mobileCountryId: c.mobile_country_id,
        mobileNumber: c.mobile_number,
        name: `${c.first_name} ${c.last_name}`,
        registrationNumber: c.registration_number
      })),
      isFetching: false,
      total
    })
  );
}

export function* handleAddCustomer(
  action: ActionType<typeof actions.addCustomer>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);

  const name = _get(formState, "customer_name.value", "");
  const email = _get(formState, "email.value", "");
  const companyName = _get(formState, "company_name.value", "");
  const registrationNumber = _get(formState, "registration_number.value", "");
  const mobileCountryCode = _get(formState, "mobile_country_code.value", "");
  const mobileNumber = _get(formState, "mobile.value", "");

  const res: Response = yield call(RestClient.send, {
    body: {
      address_line_1: _get(formState, "address_line_1.value", ""),
      address_line_2: _get(formState, "address_line_2.value", ""),
      company_name: companyName,
      customer_name: name,
      email,
      mobile: mobileNumber,
      mobile_country_code: mobileCountryCode,
      registration_number: registrationNumber,
      to_create_or_upload_invoice: action.payload.toCreateOrUploadInvoice
    },
    service: "add_customer",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.ADD_CUSTOMER_FORM_MODAL));

  if (action.payload.isFetchAll) {
    yield put(actions.fetchCustomers(0, "y"));
  } else {
    yield put(
      actions.fetchCustomers(
        0,
        "n",
        false,
        true,
        true,
        action.payload.setPageCount
      )
    );
  }

  const customerId = _get(res, "data.id");
  if (customerId && action.payload.successCallback) {
    action.payload.successCallback(customerId);
  }
}

export function* handleEditCustomer(
  action: ActionType<typeof actions.editCustomer>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);

  const name = _get(formState, "customer_name.value", "");
  const email = _get(formState, "email.value", "");
  const companyName = _get(formState, "company_name.value", "");
  const registrationNumber = _get(formState, "registration_number.value", "");
  const mobileCountryCode = _get(formState, "mobile_country_code.value", "");
  const mobileNumber = _get(formState, "mobile.value", "");
  const customerId = _get(formState, "customer_id.value");

  const res: Response = yield call(RestClient.send, {
    body: {
      address_line_1: _get(formState, "address_line_1.value", ""),
      address_line_2: _get(formState, "address_line_2.value", ""),
      company_name: companyName,
      customer_name: name,
      email,
      mobile: mobileNumber,
      mobile_country_code: mobileCountryCode,
      registration_number: registrationNumber,
      to_create_or_upload_invoice: action.payload.toCreateOrUploadInvoice
    },
    params: {
      id: customerId
    },
    service: "edit_customer",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, ADD_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.ADD_CUSTOMER_FORM_MODAL));
  yield put(actions.fetchCustomers(0, "y"));
}

export function* handleDeleteCustomer(
  action: ActionType<typeof actions.deleteCustomer>
) {
  const { id } = action.payload;

  const res: Response = yield call(RestClient.send, {
    params: {
      id
    },
    service: "delete_customer"
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(actions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  // Remove this customer out of selected customers
  const state = yield select();
  const ids = state.customer.selectedCustomerIds.filter(
    (customerId: number) => customerId !== action.payload.id
  );
  yield put(actions.selectCustomers(ids));
  yield put(actions.fetchCustomers(0, "y"));
}
