/* tslint:disable */
export default [
  {
    base_url: process.env.ENDPOINT,
    // base_url: "http://localhost:8080",
    name: "IPayMy Service",

    // Admin
    admin_add_promo_code: "POST /v2/admin/coupons",
    admin_cancel_payment: "POST /v2/admin/payments/cancel",
    admin_check_payment: "GET /v2/admin/check_payment",
    admin_create_rate: "POST /v2/admin/rates",
    admin_edit_promo_code: "PUT /v2/admin/coupon/:id",
    admin_fetch_collected_account: "GET /v2/admin/collected_accounts",
    admin_fetch_cdd_payees: "GET /v2/admin/cdd/payees",
    // account rates
    admin_get_account_rates: "GET /v2/admin/account_rates",
    admin_update_account_rates: "PUT /v2/admin/account_rates",
    // new
    admin_update_account_rate: "PUT /v2/admin/account_rate",
    admin_add_account_rate: "POST /v2/admin/account_rate",
    admin_delete_account_rate: "DELETE /v2/admin/account_rate/:id",
    admin_get_promo_code: "GET /v2/admin/coupons",
    admin_get_promo_whitelist: "GET /v2/admin/coupon_whitelist/:id",
    admin_list_collected_account: "GET /v2/admin/list_collected_account/:id",
    admin_list_account_rate: "GET /v2/admin/list_account_rate/:id",
    admin_list_user_update_request:
      "GET /v2/admin/list_user_update_request/:id",
    admin_update_collected_account_status:
      "PATCH /v2/admin/collected_accounts/:id/:status/:type",
    admin_add_payment_comment: "POST /v2/admin/payment/comment",
    export_daily_payments_admin: "GET /v2/admin/daily_payments_csv_export",
    export_citibank_cbft_admin: "GET /v2/admin/citibank_cbft_export",
    export_hongleong_xls_admin: "GET /v2/admin/hongleong_xls_export",
    export_weekly_statement_admin: "GET /v2/admin/weekly_statement_export",
    payments_for_approval_admin: "POST /v2/admin/payments_for_approval",
    get_payment_detail_admin: "GET /v2/admin/payment/:id",
    get_payments_admin: "GET /v2/admin/payments",
    get_payment_comments_admin: "GET /v2/admin/payment_comments/:id",
    get_payments_csv_export: "GET /v2/admin/payments_csv_export",
    mark_bulk_payments_as_complete: "POST /v2/admin/bulk_completed_payments",
    mark_payment_as_complete: "POST /v2/admin/completed_payments",
    get_log_charge_attempt: "GET /v2/admin/log_payment/:id",
    reconcile_payment: "GET /v2/admin/reconcile_payment",
    get_reconcile_payout_payment: "GET /v2/admin/reconcile_payout_payment",
    get_reconcile_bank: "GET /v2/admin/reconcile_bank",
    add_reconcile_bank: "POST /v2/admin/reconcile_bank",
    add_reconcile_bank_comment: "POST /v2/admin/reconcile_bank/comment",
    reconcile_payout_payment: "POST /v2/admin/reconcile_payout_payment",
    admin_get_payout_requests: "GET /v2/admin/payout_requests",

    admin_search_uen: "GET /v2/admin/uens",
    admin_add_uen: "POST /v2/admin/uen",
    admin_remove_uen: "DELETE /v2/admin/uen/:regno",
    admin_update_collected_account_document:
      "PUT /v2/admin/collected_accounts/documents",

    admin_get_report_screening: "GET /v2/admin/comply_advantage_report",
    admin_update_report_screening_status:
      "PATCH /v2/admin/comply_advantage_report/:id",
    admin_get_additional_data: "GET /v2/admin/user/additional_data",

    // Company
    create_company: "POST /v2/companies",
    delete_company: "DELETE /v2/companies/:id",
    edit_company: "PUT /v2/companies/:id",
    get_companies: "GET /v2/companies",
    // admin
    admin_get_companies: "GET /v2/admin/companies",

    // Payment
    add_card: "POST /v2/cards",
    generate_user_stripe_card_intent: "POST /v2/stripe/cards/intent/user",
    generate_public_stripe_card_intent:
      "POST /v2/stripe/cards/intent/public/:token",
    apply_coupon: "POST /v2/coupon_usage",
    coupon_usage_verbose: "POST /v2/coupon_usage/verbose",
    delete_applied_coupon: "DELETE /v2/coupon_usage/:token",
    delete_card: "DELETE /v2/cards/:id",
    edit_card: "PUT /v2/cards/:id",
    fees_verbose: "POST /v2/fees/verbose",
    get_cards: "GET /v2/cards",
    get_fees: "GET /v2/fees",
    validate_payment_payee: "POST /v2/payment_requests/validate_payee",

    // Refund
    fully_refund: "POST /v2/admin/payment/refund",
    get_payments_refund: "GET /v2/admin/payment_refund/waiting_for_approval",
    get_payments_refund_requested:
      "GET /v2/admin/payment_refund/refund_requested",
    partial_refund_fee: "POST /v2/admin/payment/partial_refund/fee",
    partial_refund_principal: "POST /v2/admin/payment/partial_refund/principal",
    partial_refund_principal_fees:
      "POST /v2/admin/payment/partial_refund/principal_fees",
    process_refund: "POST /v2/admin/payment/refund/process",
    admin_edit_payment_payee: "POST /v2/admin/edit_payment_payee",

    // Expedite
    expedite_payout_date: "POST /v2/admin/payments/expedite",

    // Payout Request
    payout_requests: "PUT /v2/admin/payout_requests/:id",

    // Payee
    check_payee: "GET /v2/payees/:id/active_schedule_check",
    create_bulk_payees: "POST /v2/payees/bulk",
    create_payee: "POST /v2/payees",
    delete_payee: "DELETE /v2/payees/:id",
    get_payees: "GET /v2/payees",
    hide_payee: "DELETE /v2/payees/:id/hide",
    note_to_supplier: "POST /v2/payees/bulk_update",
    post_uen: "POST /v2/validate/uen",
    update_payee: "PUT /v2/payees/:id",
    validate_note_to_recipient: "POST /v2/validate/note_to_recipient",

    // Payment
    cancel_payments: "PUT /v2/payment_cancellations",
    cancel_schedule: "PUT /v2/schedule_cancellations/:id",
    create_payment: "POST /v2/payments", // Full fill payment
    edit_payment: "PUT /v2/payment_requests/:id",
    edit_payments: "PUT /v2/payments",
    get_payment_detail: "GET /v2/payments/:id",
    get_payment_request: "GET /v2/payment_requests/:token",
    get_payments: "GET /v2/payments",
    get_schedule_detail: "GET /v2/schedule/:id",
    get_schedules: "GET /v2/schedules",
    hold_payment: "POST /v2/admin/payment/hold",
    refund_payment: "POST /v2/payment/refund",
    request_payment: "POST /v2/payment_requests",

    // Auth
    change_password: "PUT /v2/password",
    create_account: "POST /v2/register",
    create_reset_password_request: "POST /v2/password_reset",
    exchange_token: "POST /v2/tokens",
    post_login: "POST /v2/login",
    logout: "POST /v2/logout",
    post_resend_email_verification: "POST /v2/resend_email_verification",
    reset_password: "POST /v2/password",

    assign_manager: "PUT /v2/admin/user/assign_manager",
    get_accounts_manager: "GET /v2/admin/account_manager",
    get_user_profile: "GET /v2/my_profile",
    get_users: "GET /v2/admin/users",
    update_user_details: "PUT /v2/admin/user",
    get_my_info: "GET /v2/admin/my_info/:id",
    resend_verify_email: "POST /v2/admin/resend_email_verification",
    user_reset_link: "POST /v2/admin/user_reset_link",
    user_email_confirm_link: "POST /v2/admin/user_email_confirm_link",
    block_user: "POST /v2/admin/block_user",
    unblock_user: "POST /v2/admin/unblock_user",

    resend_mobile_verification: "POST /v2/resend_mobile_verification",
    verify_mobile: "POST /v2/mobile_verification",
    // Other IPayMy services

    // Files
    upload_file: "PUT /v2/files",
    admin_upload_file: "PUT /v2/admin/files",
    admin_upload_settlement_file: "POST /v2/admin/settlement_files",

    // Common
    get_banks: "GET /v2/banks",
    get_business_industries: "GET /v2/business_industries",
    get_business_type: "GET /v2/business_types",
    get_countries: "GET /v2/countries",
    get_date_conditions: "GET /v2/date_conditions",
    get_payment_calendar: "GET /v2/payment_calendar",
    validate_amount: "POST /v2/validate/amount",

    // User
    add_nric_photo: "POST /v2/account/nric_photo",
    admin_request_login: "POST /v2/admin/request_login",
    change_theme: "PUT /v2/user_setting/theme",
    get_nric_photos: "GET /v2/admin/account/nric_photo",
    get_id_verification_photo: "GET /v2/admin/user/id_verification_photo",
    has_credit_card: "PUT /v2/user/has_credit_card",
    one_time_popup: "PUT /v2/one_time_popup/:property",
    update_account_type: "PUT /v2/admin/account_type",

    // Payment Collection
    add_customer: "POST /v2/payment_collections/payer",
    add_new_item: "POST /v2/payment_collections/invoice_item",
    add_new_tax: "POST /v2/payment_collections/collected_tax",
    cancel_payment_request: "DELETE /v2/payment_collection_request/:payment_id",
    charge_payment_collection: "POST /v2/payment_collections",
    check_has_active_program: "GET /v2/account/has_active_program",
    collection_update_other_setting:
      "PUT /v2/payment_collections/collected_account/other_setting",
    create_collected_account: "POST /v2/payment_collections/collected_account",
    create_collected_card: "POST /v2/payment_collections/cards",
    delete_customer: "DELETE /v2/payment_collections/payer/:id",
    edit_customer: "PUT /v2/payment_collections/payer/:id",
    edit_item: "PUT /v2/payment_collections/invoice_item/:item_id",
    get_collected_account: "GET /v2/payment_collections/collected_account",
    get_customer: "GET /v2/payment_collections/payers",
    get_estimate_rate: "GET /v2/payment_collection/rate/public",
    get_items: "GET /v2/payment_collections/invoice_items",
    get_next_invoice_number: "GET /v2/payment_collections/next_invoice_number",
    get_payment_collection: "GET /v2/payment_collections",
    get_payment_collections_dashboard: "GET /v2/payment_collections/dashboard",
    get_rate: "GET /v2/payment_collection/rate",
    get_taxes: "GET /v2/payment_collections/collected_tax",
    mark_payment_as_paid: "PUT /v2/payment_collections/mark_as_complete",
    resend_payment_request: "POST /v2/payment_collection_request/resend",
    retrieve_collected_payment_fees: "GET /v2/payment_collections/fees",
    retrieve_collected_payment_request:
      "GET /v2/payment_collection_request/:token",
    send_payment_request: "POST /v2/payment_collection_request",
    update_collected_account: "PUT /v2/payment_collections/collected_account",
    update_collected_account_additional_setting:
      "PUT /v2/payment_collections/collected_account/additional_setting",
    validate_payment_request: "POST /v2/payment_collection_request/validate",

    // Credit program
    enter_program: "POST /v2/account/enter_program/:program",

    // Referral program
    get_referral_link: "GET /v2/referral/link",
    join_referral: "POST /v2/referral/link",
    get_referral_report: "GET /v2/referral/report",

    // Wallex
    deposit_international_payments:
      "POST /v2/admin/deposit_international_payments",
    get_address: "GET /v2/postal/:postal_code",
    get_currency: "GET /v2/currency",
    get_employee_industry: "GET /v2/wallex/employee_industry",
    get_employee_position: "GET /v2/wallex/employee_position",
    get_employee_status: "GET /v2/wallex/employee_status",
    get_exchange_rate: "GET /v2/exchange_rate",
    get_wallex_kyc_info: "GET /v2/wallex/kyc",
    payment_calculation_basic: "GET /v2/payment_calculation/basic",
    wallex_employee_industry: "GET /v2/wallex/employee_industry",
    wallex_employee_position: "GET /v2/wallex/employee_position",
    wallex_employee_status: "GET /v2/wallex/employee_status",
    wallex_kyc: "POST /v2/wallex/kyc",

    // Slack
    notify_general_error:
      "POST /v2/log/170cba4a003839ca6b8cde4d97c3387d/v3error",
    notify_user_error:
      "POST /v2/user_log/170cba4a003839ca6b8cde4d97c3387d/v3error",

    // SG My Info
    confirm_my_info_sing_pass: "PATCH /v2/corppass/data/confirm",
    fetch_my_info_sing_pass: "GET /v2/corppass/data",
    login_my_info_sing_pass: "GET /v2/corppass/login",

    // Payments (new APIs)
    get_payments_history_dashboard: "GET /v2/payments_history/dashboard",
    get_payments_history_dashboard_collected_payment_list:
      "GET /v2/payments_history/dashboard_collected_payment_list",
    get_payments_history_dashboard_payment_list:
      "GET /v2/payments_history/dashboard_payment_list",
    get_payments_history_export: "GET /v2/payments_history/export",
    get_payments_history_list: "GET /v2/payments_history/list",

    // UEN
    fetch_search_uen: "GET /v2/search/uen",

    // WP
    wp_generate_ddc_jwt: "POST /v2/wp/generate_ddc_jwt",
    wp_generate_ddc_jwt_public: "POST /v2/wp/generate_ddc_jwt/:token/public",
    wp_create_tmp_card: "POST /v2/wp/create_tmp_card",

    // Escalation
    admin_add_escalation: "POST /v2/admin/escalations",
    admin_update_escalation: "PUT /v2/admin/escalations/:id",
    admin_get_escalations: "GET /v2/admin/escalations",
    admin_get_escalation_comments: "GET /v2/admin/escalation_comments",

    // Referral
    admin_search_referral: "GET /v2/admin/referrals",
    admin_get_referral_detail: "GET /v2/admin/referral/:id"
  }

  // Others services.
];
