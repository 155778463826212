import * as React from "react";
import { NavLink } from "react-router-dom";
import AdminNavDropdown from "../AdminNavDropdown";
import * as Images from "src/ipm-shared/components/Images";
import { IProps } from "./index";
import RadioToggle from "src/ipm-shared/components/Form/controls/RadioToggle";

interface IState {
  toggleList: boolean;
}
class AppHeaderView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      toggleList: false
    };
  }

  public render() {
    let defaultEvn = "live";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "v3.ipaymy.com"
    ) {
      defaultEvn = "test";
    }
    return (
      <header className="header-block admin-header">
        <div className="logo-block col-md-2 col-sm-2 col-xs-12">
          <NavLink to="/admin" className="logo">
            <img src={Images.admin_logo} alt="ipaymy logo" />
          </NavLink>
        </div>

        <div className="header-contain col-md-10 col-sm-10 col-xs-12">
          <div className="buttons-action-group">
            <span className="collapse-nav-btn d-none">
              <img
                width={20}
                src="data:image/svg+xml;charset=UTF-8,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.28 13.29'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23113de3;%7d%3c/style%3e%3c/defs%3e%3ctitle%3eArtwork19_040537a7-8468-4466-a6e6-6695af775a55%3c/title%3e%3crect class='cls-1' x='6.79' y='7.49' width='13.49' height='1.58' rx='0.79' ry='0.79' transform='translate(0.02 -0.03) rotate(0.14)'/%3e%3crect class='cls-1' y='2.96' width='13.47' height='1.58' rx='0.79' ry='0.79' transform='translate(0.01 -0.02) rotate(0.14)'/%3e%3cpath class='cls-1' d='M4.91,1.42l-3.65,3A.78.78,0,0,1,.16,4.2h0A.78.78,0,0,1,.33,3.1L4,.15a.79.79,0,0,1,1.1.17h0A.79.79,0,0,1,4.91,1.42Z'/%3e%3cpath class='cls-1' d='M.33,4.36,4,7.33a.79.79,0,0,0,1.1-.17h0a.79.79,0,0,0-.17-1.1l-3.64-3a.79.79,0,0,0-1.1.17h0A.78.78,0,0,0,.33,4.36Z'/%3e%3crect class='cls-1' x='6.78' y='11.7' width='13.49' height='1.58' rx='0.79' ry='0.79' transform='translate(0.03 -0.03) rotate(0.14)'/%3e%3c/svg%3e"
                alt=""
              />
            </span>
            <RadioToggle
              name="general_environment"
              options={{
                left: { label: "Test", value: "test" },
                right: { label: "Live", value: "live" }
              }}
              defaultValue={defaultEvn}
            />
          </div>
          <div className="header-contain-info">
            <a
              href="#"
              onClick={this.props.requestFullScreen}
              className="link-expand mr-0"
            >
              <img
                src="data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.64 17.08'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%2349494b%7d%3c/style%3e%3c/defs%3e%3ctitle%3eexpand-icon%3c/title%3e%3cg id='Layer_2' data-name='Layer 2'%3e%3cg id='Header'%3e%3cpath class='cls-1' d='M0 17.08A.75.75 0 0 1 0 17v-5.15a.44.44 0 0 1 .31-.46.44.44 0 0 1 .56.4v4.41h4.35a.45.45 0 0 1 .46.31.44.44 0 0 1-.35.56H0zm11.37 0h-.11a.44.44 0 0 1-.32-.48.44.44 0 0 1 .41-.38h4.4v-4.37a.44.44 0 0 1 .26-.43.44.44 0 0 1 .61.29v5.37zm5.27-11.76a1.22 1.22 0 0 1-.06.15.44.44 0 0 1-.48.22.45.45 0 0 1-.34-.42V.88H11.4a.44.44 0 0 1-.4-.6.42.42 0 0 1 .33-.28h5.31zM0 0h5.21a.45.45 0 0 1 .41.19.41.41 0 0 1 0 .44.42.42 0 0 1-.37.24H.88v4.4a.44.44 0 0 1-.88.05 1 1 0 0 1 0-.12V0z' id='_5Gpgx8.tif' data-name='5Gpgx8.tif'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
                alt="Expand Icon"
              />
            </a>
          </div>

          <div className="d-flex align-items-center">
            <AdminNavDropdown />
          </div>
        </div>
      </header>
    );
  }
}

export default AppHeaderView;
