import { createAction } from "typesafe-actions";
import { ScreeningType } from "./reducers";
export const setScreeningReportList = createAction(
  "adminScreening/setScreeningReportList",
  resolve => (args: {
    isFetching: boolean;
    reports: ScreeningType[];
    total: number;
  }) => resolve(args)
);
export const fetchScreeningReportList = createAction(
  "adminScreening/fetchScreeningReportList",
  resolve => (flags: string) =>
    resolve({
      flags
    })
);
export const updateStatusScreeningReport = createAction(
  "adminScreening/updateStatusScreeningReport",
  resolve => (
    id: number,
    searchStatus: string,
    currentStatus: string,
    matchStatus: string,
    engineSearch: string,
    flag: string
  ) =>
    resolve({
      id,
      searchStatus,
      currentStatus,
      matchStatus,
      engineSearch,
      flag
    })
);

export const displayLoading = createAction(
  "adminScreening/DISPLAY_LOADING",
  resolve => (value: boolean) => resolve({ value })
);
