// Lib
import _find from "lodash-es/find";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import UrlUtils from "src/ipm-shared/Utils/UrlHelper";

// Utils
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
import RestClient from "src/ipm-shared/services/Rest";
import DateUtils from "src/ipm-shared/Utils/Date";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import AccountProfileUtil from "src/ipm-shared/Utils/AccountProfile";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

// Store, Selectors, Actions
import { history } from "src/ipm-shared/store";
import { RootState } from "../reducers";
import { AccountProfile } from "./reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as guiSelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as accountProfileSelector from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as countrySelector from "src/ipm-shared/store/model/Country/selectors";
import * as guiActions from "src/ipm-shared/components/GlobalUI/actions";
import * as authActions from "src/ipm-shared/store/model/Auth/actions";
import * as userActions from "src/ipm-shared/store/model/Users/actions";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as actions from "./actions";
import * as commonActions from "../actions";
import settingProps from "src/ipm-shared/store/metadata/user_setting_property";

import {
  GENDER,
  RESIDENTIAL_STATUS_LIST,
  PASS_TYPE
} from "src/ipm-shared/store/model/OnBoarding/consts";
import { FORM } from "src/ipm-shared/store/model/Payee/const";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import { ADMIN_UPDATE_ACCOUNT_TYPE_FORM } from "../Users/const";
import * as formActions from "src/ipm-shared/components/Form/actions";

const watchedSagas = [
  reTryTakeLatest(actions.fetchAccountProfiles, handleFetchAccountProfiles),
  reTryTakeLatest(actions.fetchNricPhotos, handleFetchNricPhotos),
  reTryTakeLatest(actions.getReferralReport, handleGetReferralReport),
  reTryTakeLatest(actions.fetchMyInfoSingPass, handleFetchMyInfoSingPass),

  takeLatest(getType(actions.changeUserSetting), handleChangeUserSetting),
  takeLatest(getType(actions.joinReferral), handleJoinReferral),
  takeLatest(getType(actions.setAccountType), handleSetAccountType),
  takeLatest(getType(actions.changeTheme), handleChangeTheme),
  takeLatest(getType(actions.setNricPhotos), handleSetNricPhotos),
  takeLatest(getType(actions.enterProgram), handleEnterProgram),
  takeLatest(getType(actions.loginMyInfoSingPass), handleLoginMyInfoSingPass),
  takeLatest(
    getType(actions.confirmMyInfoSingPass),
    handleConfirmMyInfoSingPass
  ),
  reTryTakeLatest(
    actions.fetchIDVerificationPhoto,
    handleFetchIDVerificationPhoto
  ),
  reTryTakeLatest(actions.adminGetAdditionalData, handleGetAdditionalData)
];
export default watchedSagas;

export function* handleFetchAccountProfiles(
  action: ActionType<typeof actions.fetchAccountProfiles>
) {
  yield put(
    actions.setAccountProfiles({
      hasFetched: false,
      isFetching: true,
      profiles: []
    })
  );

  const res = yield call(RestClient.send, {
    service: "get_user_profile"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const state = yield select();
    const json = res;

    const currentProfile = {
      accountCountryCode: accountProfileSelector.getAccountCountryCode(state),
      acquirerId: accountProfileSelector.getAcquirerId(state),
      availableAcquirers: json.data.available_acquirers,
      countryCode: accountProfileSelector.getCurrentCountry(state), // Get from access token
      countryId: accountProfileSelector.getCurrentCountryId(state), // Get from access token
      createdAt: json.data.created_at,
      currencyCode: accountProfileSelector.getCurrentCurrency(state), // Get from access token
      currencyId: accountProfileSelector.getCurrentCurrencyId(state), // Get from access token
      displayedEmail: json.data.sub_email || json.data.email,
      displayedFirstName: json.data.sub_first_name || json.data.first_name,
      displayedLastName: json.data.sub_last_name || json.data.last_name,
      email: json.data.email,
      features: accountProfileSelector.getFeatures(),
      firstName: json.data.first_name,
      hasActiveProgram: json.data.has_active_program,
      hasCompany: accountProfileSelector.hasCompany(), // Get from access token
      hasCreditCard: json.data.has_credit_card,
      intercomIdentifier: json.data.hmac,
      isProduction: accountProfileSelector.getIsProduction(state), // Get from access token
      lastName: json.data.last_name,
      mobileCountryId: json.data.mobile_country_id,
      mobileNumber: json.data.mobile_number,
      paymentPaidCurrencyCode: accountProfileSelector.getCurrentPaidCurrency(),
      paymentPaidCurrencyId: accountProfileSelector.getCurrentPaidCurrencyId(),
      prefilledCompany: json.data.prefilled_company,
      roleId: accountProfileSelector.getRoleId(),
      type: AccountProfileUtil.getAccountTypeString(
        accountProfileSelector.getIsBusinessAccount()
      ),
      userSettingRequired: {
        accountType:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.account_type - 1}`,
              0
            )
          ) === 1,
        additionalInvoiceSetting:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.additional_invoice_setting -
                1}`,
              0
            )
          ) === 1,
        bePaidTncConfirmation: _get(
          json.data,
          `setting_required.values.be_paid_tnc_confirmation`,
          undefined
        ),
        cardsPalPopup:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.cards_pal_popup - 1}`,
              0
            )
          ) === 1,
        closeKycStatusBar:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.close_kyc_status_bar -
                1}`,
              0
            )
          ) === 1,
        creditProgramOfferV1Enabled: false,
        // _get(
        //   json.data,
        //   "setting_required.values.credit_program_offer_v1_enabled",
        //   undefined
        // ),
        creditProgramV1Notification: false,
        // Number(
        //   _get(
        //     json.data,
        //     `setting_required.required.${settingProps.credit_program_v1_notification -
        //       1}`,
        //     0
        //   )
        // ) === 1,
        crossBorder:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.cross_border_announce -
                1}`,
              0
            )
          ) === 1,
        crossBorderCardFlag: false,
        /*Number(_get(
            json.data,
            `setting_required.required.${settingProps.cross_border_card_flag}`,
            0
          )) === 1*/
        crossBorderNewIcon:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.cross_border_new_icon -
                1}`,
              0
            )
          ) === 1,
        dashboardTheme: _get(
          json.data,
          `setting_required.values.dashboard_theme`
        ),
        editScheduleIndicator:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.edit_schedule_indicator -
                1}`,
              0
            )
          ) === 1,
        fb199:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.fb199 - 1}`,
              0
            )
          ) === 1,
        firstMySignup:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.first_my_signup - 1}`,
              0
            )
          ) === 1,
        intPayEnabledNotification:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.int_pay_enabled_notification -
                1}`,
              0
            )
          ) === 1,
        irasPopup:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.iras_popup - 1}`,
              0
            )
          ) === 1,
        myCreditCard:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.my_credit_card - 1}`,
              0
            )
          ) === 1,
        nextDayPayout:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.next_day_payout - 1}`,
              0
            )
          ) === 1,
        nricPhoto:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.nric_photo - 1}`,
              0
            )
          ) === 1,
        paymentInterestConfirmation:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.payment_interest_confirmation -
                1}`,
              0
            )
          ) === 1,
        pointsGuarantee:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.points_guarantee - 1}`,
              0
            )
          ) === 1,
        referral:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.referral - 1}`,
              0
            )
          ) === 1,
        rentPromo:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.rentmay_promo - 1}`,
              0
            )
          ) === 1,
        sgMyInfo:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.sg_myinfo - 1}`,
              0
            )
          ) === 1,
        sgTax2019:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.sg_tax_2019 - 1}`,
              0
            )
          ) === 1,
        twoDayPayoutAnnouncement:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.two_day_payout_announce -
                1}`,
              0
            )
          ) === 1,
        oldCardTokenArchivedNotification:
          Number(
            _get(
              json.data,
              `setting_required.required.${settingProps.old_card_token_archived_notification -
                1}`,
              0
            )
          ) === 1
      },
      wallexKycStatus:
        json.data.wallex_kyc_status !== null &&
        json.data.wallex_kyc_status !== undefined
          ? Number(json.data.wallex_kyc_status)
          : 0
    };

    // if (
    //   currentProfile.email.indexOf("@ipaymy.com") < 0 &&
    //   !localStorage.getItem("PLATFORM_ARCHIVE_NOTIFICATION_JULY")
    // ) {
    //   yield put(
    //     commonActions.toggleModal(commonActions.ModalID.INFORMATION_MODAL, {
    //       content:
    //         "We'll prevent access to our old platform after July 1. But there's good news! We have a powerful new platform available at web.ipaymy.com, and we have a fantastic support team available anytime through support@ipaymy.com to help you make the transition. See you there!",
    //       image: "__INFO_ICON__"
    //     })
    //   );
    //   localStorage.setItem("PLATFORM_ARCHIVE_NOTIFICATION_JULY", "y");
    // }

    const accounts: { [email: string]: AccountProfile } = {};
    accounts[currentProfile.email] = currentProfile as AccountProfile;
    yield put(
      actions.setCurrentAccountProfile({
        email: json.data.email
      })
    );
    yield put(
      actions.setAccountProfiles({
        hasFetched: true,
        isFetching: false,
        profiles: Object.keys(accounts).map(email => accounts[email])
      })
    );

    yield call(
      [localStorage, "setItem"],
      "displayed_first_name",
      currentProfile.displayedFirstName
    );
    yield call(
      [localStorage, "setItem"],
      "displayed_last_name",
      currentProfile.displayedLastName
    );

    // Intercom
    try {
      window.Intercom("boot", {
        app_id: currentProfile.isProduction ? "go8gqcvi" : "jes2hif1",
        created_at: currentProfile.createdAt,
        email: currentProfile.displayedEmail, // Email address,
        name: `${currentProfile.displayedFirstName} ${currentProfile.displayedLastName}`,
        user_hash: currentProfile.intercomIdentifier // Full name
      });
    } catch (e) {
      window.Logger.guestError("Intercom boot failed: ", e.toString());
    }

    // Active campaign
    try {
      window.vgo("setEmail", currentProfile.email);
      window.vgo("process");
      window.vgo("update");
    } catch (e) {
      window.Logger.guestError("Active campaign boot failed: ", e.toString());
    }

    if (action.payload.cb) {
      action.payload.cb();
    }

    // ---DISPLAY MODALS---

    // CardsPalPopup required

    if (
      currentProfile.userSettingRequired.cardsPalPopup &&
      currentProfile.accountCountryCode === "SG" &&
      currentProfile.type === "personal"
    ) {
      yield put(guiActions.toggleModal(ModalID.CARDSPAL_MODAL));
      return;
    }

    // Top priority: Payment interest confirmation
    if (
      currentProfile.hasCompany &&
      currentProfile.type === "business" &&
      currentProfile.userSettingRequired.paymentInterestConfirmation
    ) {
      const hasOpenedModal = guiSelectors.hasOpenedModal(state);

      if (!hasOpenedModal) {
        yield put(guiActions.toggleModal(ModalID.PAYMENT_INTEREST_MODAL));
        return;
      }
    }

    // For MY personal user, add them to have credit card.
    if (
      (currentProfile.hasCreditCard === null ||
        currentProfile.hasCreditCard === undefined) &&
      currentProfile.userSettingRequired.myCreditCard &&
      currentProfile.type === "personal"
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.DO_YOU_HAVE_CREDIT_CARD
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(guiActions.ModalID.DO_YOU_HAVE_CREDIT_CARD, {
            guideDisplay: true
          })
        );
        return;
      }
    }

    // Legacy. To allow v1 user to choose to be business or personal account
    // if (currentProfile.userSettingRequired.accountType) {
    //   const isModalOpened = guiSelectors.isGivenModalOpened(
    //     state,
    //     guiActions.ModalID.USER_SELECT_ACCOUNT_TYPE
    //   );
    //
    //   if (!isModalOpened) {
    //     // Always display add-company modal in every pages.
    //     yield put(
    //       guiActions.toggleModal(
    //         guiActions.ModalID.USER_SELECT_ACCOUNT_TYPE,
    //         {
    //           disallowCancel: true,
    //           guideDisplay: true
    //         }
    //       )
    //     );
    //     return
    //   }
    // }

    // Some settings for business user having company added only
    if (currentProfile.hasCompany) {
      if (currentProfile.userSettingRequired.firstMySignup) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.ONE_TIME_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.ONE_TIME_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.twoDayPayoutAnnouncement) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.TWO_DAY_ANNOUNCEMENT_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(
              guiActions.ModalID.TWO_DAY_ANNOUNCEMENT_MODAL,
              {
                guideDisplay: true
              }
            )
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.nextDayPayout) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.NEXT_DAY_PAYOUT_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.NEXT_DAY_PAYOUT_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.pointsGuarantee) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.POINTS_GUARANTEE_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.POINTS_GUARANTEE_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.sgTax2019) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.TAX_PAYMENT_PROMOTION_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(
              guiActions.ModalID.TAX_PAYMENT_PROMOTION_MODAL,
              {
                guideDisplay: true
              }
            )
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.rentPromo) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.RENT_PROMO_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.RENT_PROMO_MODAL, {
              guideDisplay: true
            })
          );

          return;
        }
      }

      if (currentProfile.userSettingRequired.fb199) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.PROMO_POPUP
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.PROMO_POPUP, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.creditProgramOfferV1Enabled) {
        if (currentProfile.userSettingRequired.creditProgramV1Notification) {
          const isModalOpened = guiSelectors.isGivenModalOpened(
            state,
            guiActions.ModalID.CREDIT_PROGRAM_V1_NOTIFICATION
          );

          if (!isModalOpened) {
            yield put(
              guiActions.toggleModal(
                guiActions.ModalID.CREDIT_PROGRAM_V1_NOTIFICATION,
                {
                  countryCode: currentProfile.accountCountryCode
                }
              )
            );
            return;
          }
        }
      }
    }

    // currentProfile.type === "personal" &&
    if (
      window.location.pathname === "/" &&
      currentProfile.accountCountryCode.toUpperCase() === "SG" &&
      currentProfile.userSettingRequired.sgMyInfo
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.MY_INFO_VERIFICATION_MODAL
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(
            guiActions.ModalID.MY_INFO_VERIFICATION_MODAL,
            { forced: false }
          )
        );
        return;
      }
    }

    // Display KYC modal
    if (
      currentProfile.wallexKycStatus === 0 &&
      currentProfile.userSettingRequired.intPayEnabledNotification &&
      FeatureGateUtil.verifyFeature(currentProfile.features, "WALLEX_FLOW")
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.INTERNATIONAL_REDIRECT_KYC_MODAL
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(
            guiActions.ModalID.INTERNATIONAL_REDIRECT_KYC_MODAL,
            {
              guideDisplay: true
            }
          )
        );
        return;
      }
    }

    // ---End of DISPLAY MODALS---
  } catch (e) {
    window.Logger.error("handleFetchAccountProfiles: ", e.message);
  }
}

export function* handleSetAccountType(
  action: ActionType<typeof actions.setAccountType>
) {
  yield put(guiActions.showGlobalLoader());

  const { accountType, accountId, userId, email } = action.payload;

  const res = yield call(RestClient.send, {
    body: {
      account_type: accountType,
      account_id: accountId,
      user_id: userId,
      email
    },
    service: "update_account_type",
    showGlobalLoader: true
  });

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(
        formActions.parseServerErrors(errors, ADMIN_UPDATE_ACCOUNT_TYPE_FORM)
      );
      return;
    }

    if (!res) {
      return;
    }

    yield put(userActions.fetchUsers());
    yield put(
      commonActions.closeModal(
        commonActions.ModalID.ADMIN_UPDATE_ACCOUNT_TYPE_MODAL
      )
    );
  } catch (e) {
    window.Logger.error("handleSetAccountType: ", e.message);
  }
}

export function* handleSetNricPhotos(
  action: ActionType<typeof actions.setNricPhotos>
) {
  const { extraInfo } = action.payload;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);

  const frontNricPhotos = _get(formState, "front_nric_photo.value", "");
  const backNricPhotos = _get(formState, "back_nric_photo.value", "");
  const holdingNricPhotos = _get(formState, "holding_nric_photo.value", "");

  yield put(guiActions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      nric_back: holdingNricPhotos.split(","),
      nric_front: [frontNricPhotos, backNricPhotos]
    },
    service: "add_nric_photo",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  yield put(
    commonActions.closeModal(commonActions.ModalID.NRIC_ID_PHOTOS_UPLOAD)
  );
  yield put(actions.fetchAccountProfiles());
  yield put(
    commonActions.toggleModal(commonActions.ModalID.ADD_CARD_FORM, extraInfo)
  );
}

export function* handleFetchNricPhotos(
  action: ActionType<typeof actions.fetchNricPhotos>
) {
  const { cb, id } = action.payload;
  const res = yield call(RestClient.send, {
    query: {
      user_id: id
    },
    service: "get_nric_photos"
  });

  if (!res) {
    return;
  }

  if (cb) {
    cb(res.data);
  }
}

export function* handleFetchIDVerificationPhoto(
  action: ActionType<typeof actions.fetchIDVerificationPhoto>
) {
  const { cb, userId, payerId } = action.payload;
  const res = yield call(RestClient.send, {
    query: {
      user_id: userId,
      payer_id: payerId
    },
    service: "get_id_verification_photo"
  });

  if (!res) {
    return;
  }

  if (cb) {
    cb(res.data);
  }
}

export function* handleChangeUserSetting(
  action: ActionType<typeof actions.changeUserSetting>
) {
  const {
    enable,
    property,
    targetUrl,
    refreshDisable,
    modalID,
    value,
    disableGlobalLoader,
    fetchAccountProfile
  } = action.payload;

  const res = yield call(RestClient.send, {
    body: {
      enable,
      value
    },
    params: {
      property
    },
    service: "one_time_popup",
    showGlobalLoader: !disableGlobalLoader
  });

  if (!res) {
    return;
  }

  if (!refreshDisable) {
    if (targetUrl) {
      UrlHelper.redirect(targetUrl);
    } else {
      UrlHelper.redirect("/");
    }
  } else {
    if (modalID) {
      yield put(commonActions.closeModal(commonActions.ModalID[modalID]));
      yield put(
        actions.updateUserSetting(
          AccountProfileUtil.getUserSettingObjectPropName(property),
          value !== undefined ? value : enable
        )
      );

      if (targetUrl) {
        history.push(targetUrl);
      }
    }

    if (fetchAccountProfile) {
      yield put(actions.fetchAccountProfiles());
    }
  }
}

function* handleEnterProgram(action: ActionType<typeof actions.enterProgram>) {
  const res1: Response = yield call(RestClient.send, {
    params: {
      program: action.payload.programName
    },
    service: "enter_program",
    showGlobalLoader: true
  });

  if (!res1) {
    return;
  }

  const errors1 = _get(res1, "errors", {});

  if (!_isEmpty(errors1)) {
    return;
  }

  UrlUtils.redirect(`/?m=${ModalID.CREDIT_PROGRAM_CONFIRMED_MODAL}`);
}

function* handleJoinReferral(action: ActionType<typeof actions.joinReferral>) {
  const res: Response = yield call(RestClient.send, {
    service: "join_referral",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to get referral link");
  }

  try {
    const referralLink = _get(res, "data.referral_link", "");
    if (action.payload.cb) {
      action.payload.cb(referralLink);
    }
  } catch (e) {
    window.Logger.error("handleGetReferralLink: ", e.message);
  }
}

function* handleGetReferralReport(
  action: ActionType<typeof actions.getReferralReport>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_referral_report",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to get referral report");
  }

  try {
    const joined = _get(res, "data.joined", false);
    const numberSignUps = _get(res, "data.number_of_sign_ups", 0);
    const numberPersonalReferrals = _get(
      res,
      "data.number_of_personal_referrals",
      0
    );
    const numberBusinessReferrals = _get(
      res,
      "data.number_of_business_referrals",
      0
    );
    const referralLink = _get(res, "data.referral_link", 0);

    if (action.payload.cb) {
      action.payload.cb({
        joined,
        numberBusinessReferrals,
        numberPersonalReferrals,
        numberSignUps,
        referralLink
      });
    }
  } catch (e) {
    window.Logger.error("handleGetReferralReport: ", e.message);
  }
}

function* handleLoginMyInfoSingPass(
  action: ActionType<typeof actions.loginMyInfoSingPass>
) {
  const {
    isModal = true,
    redirectUrl = window.location.origin
  } = action.payload;

  let redirectSuccess: string;
  let redirectFail: string;

  if (isModal) {
    redirectSuccess = `${redirectUrl}?m=${ModalID.MY_INFO_VERIFICATION_RETRIEVED_NRIC_MODAL}&t=0&rm=1`;
    redirectFail = `${redirectUrl}?m=${ModalID.MY_INFO_VERIFICATION_ERROR_MODAL}&t=0&rm=1`;
  } else {
    redirectSuccess = `${redirectUrl}&valid=1`;
    redirectFail = `${redirectUrl}&valid=0`;
  }

  const res = (Response = yield call(RestClient.send, {
    query: {
      redirect_fail: encodeURIComponent(redirectFail),
      redirect_success: encodeURIComponent(redirectSuccess)
    },
    service: "login_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }
}

function* handleFetchMyInfoSingPass(
  action: ActionType<typeof actions.fetchMyInfoSingPass>
) {
  const state: RootState = yield select();

  const res = (Response = yield call(RestClient.send, {
    service: "fetch_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }

  const error = _get(res, "error", {});

  if (!_isEmpty(error)) {
    return;
  }

  if (action.payload.cb) {
    const info = _get(res, "data.info");
    let formattedInfo = null;

    if (info) {
      const nric = _get(info, "uinfin.value", "");
      const name = _get(info, "name.value", "");

      const sex = _get(
        _find(GENDER, { code: _get(info, "sex.code") }),
        "label",
        ""
      );

      const dob = _get(info, "dob.value")
        ? DateUtils.formatDate(_get(info, "dob.value"), "DD MMMM YYYY")
        : "";

      const residentialStatus = _get(
        _find(RESIDENTIAL_STATUS_LIST, {
          code: _get(info, "residentialstatus.code")
        }),
        "label",
        ""
      );

      const nationality = _get(
        _find(countrySelector.getAllCountries(state), {
          code: (_get(info, "nationality.code") || "").toUpperCase()
        }),
        "name",
        ""
      );

      const passportNumber = _get(info, "passportnumber.value", "");

      const passportExpiryDate = _get(info, "passportexpirydate.value")
        ? DateUtils.formatDate(
            _get(info, "passportexpirydate.value"),
            "DD MMMM YYYY"
          )
        : "";

      const passType = _get(
        _find(PASS_TYPE, {
          code: _get(info, "passtype.code")
        }),
        "label",
        ""
      );

      const passExpiryDate = _get(info, "passexpirydate.value")
        ? DateUtils.formatDate(
            _get(info, "passexpirydate.value"),
            "DD MMMM YYYY"
          )
        : "";

      const mobileNumber = accountProfileSelector.getMobileNumber(state);

      const postalCode = _get(info, "regadd.postal.value", "");

      const address = (() => {
        let value = "";
        const regAdd = _get(info, "regadd");
        if (regAdd) {
          const unit = _get(regAdd, "unit.value");
          if (unit) {
            value += unit;
          }
          const block = _get(regAdd, "block.value");
          if (block) {
            value += ` ${block}`;
          }
          const building = _get(regAdd, "building.value");
          if (building) {
            value += `${value ? "," : ""} ${building}`;
          }
          const street = _get(regAdd, "street.value");
          if (street) {
            value += `${value ? "," : ""} ${street}`;
          }
          const postal = _get(regAdd, "postal.value");
          if (postal) {
            value += `${value ? "," : ""} ${postal}`;
          }
        }

        return value;
      })();

      const unitNumber = _get(info, "regadd.unit.value", "");

      formattedInfo = {
        address,
        dob,
        mobileNumber,
        name,
        nationality,
        nric,
        passExpiryDate,
        passType,
        passportExpiryDate,
        passportNumber,
        postalCode,
        residentialStatus,
        sex,
        unitNumber
      };
    }

    action.payload.cb({
      confirmed: _get(res, "data.confirmed"),
      info: formattedInfo
    });
  }
}

function* handleConfirmMyInfoSingPass(
  action: ActionType<typeof actions.confirmMyInfoSingPass>
) {
  const res = (Response = yield call(RestClient.send, {
    service: "confirm_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }

  const error = _get(res, "error", {});

  if (!_isEmpty(error)) {
    return;
  }

  if (action.payload.cb) {
    const data = _get(res, "data");
    if (data === "") {
      action.payload.cb({ confirmed: true });
    }
  }
}

function* handleChangeTheme(action: ActionType<typeof actions.changeTheme>) {
  const res = (Response = yield call(RestClient.send, {
    body: {
      dashboard_theme: action.payload.themeName
    },
    service: "change_theme",
    showGlobalLoader: true
  }));

  if (!res) {
    return;
  }

  const error = _get(res, "error", {});
  const data = _get(res, "data", {});

  if (!_isEmpty(error)) {
    return;
  }

  yield call([localStorage, "setItem"], "token", data.token);
  yield put(authActions.setToken({ token: data.token }));

  if (action.payload.targetUrl) {
    UrlUtils.redirect(action.payload.targetUrl);
  } else {
    UrlUtils.redirect("/");
  }
}

export function* handleGetAdditionalData(
  action: ActionType<typeof actions.adminGetAdditionalData>
) {
  yield put(actions.displayLoadingAdditionalData(true));

  const { cb, id } = action.payload;

  const res = yield call(RestClient.send, {
    query: {
      user_id: id
    },
    service: "admin_get_additional_data"
  });

  if (!res) {
    return;
  }

  const data = _get(res, "data.additional_data", {});

  yield put(actions.displayLoadingAdditionalData(false));

  if (cb) {
    cb(data);
  }
}
