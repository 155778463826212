import * as React from "react";
import { IProps } from "./index";
import PageBase from "src/ipm-platform/pages/Base";
import RecommendedSection from "src/ipm-platform/pages/Dashboard/Pay/Recommended";
import BePaidGeneralSection from "src/ipm-platform/pages/Dashboard/BePaid/GeneralSection";
import PayGeneralSection from "src/ipm-platform/pages/Dashboard/Pay/GeneralSection";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import * as classNames from "classnames";
import CountrySelector from "src/ipm-platform/pages/Dashboard/Pay/CountrySelector";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

interface IState {
  activeTab: string;
}

class FnBDashboard extends PageBase<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  public render() {
    const { isBusiness, isPersonal } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <div
          className={classNames("fbn-dashboard-tabs", {
            "pay-active": this.state.activeTab === "2"
          })}
        >
          <Nav className="fbn-dashboard-nav" tabs={true}>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "1" })}
                onClick={this.toggle.bind(this, "1")}
              >
                BE PAID
              </NavLink>
            </NavItem>
            <FeatureGate feature="NORMAL_PAYMENT">
              <NavItem>
                <NavLink
                  className={classNames({
                    active: activeTab === "2",
                    "pay-active": activeTab === "2"
                  })}
                  onClick={this.toggle.bind(this, "2")}
                >
                  {activeTab === "2" ? <CountrySelector /> : "PAY"}
                </NavLink>
              </NavItem>
            </FeatureGate>
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {isBusiness && <BePaidGeneralSection theme="fnb" />}
          </TabPane>
          <FeatureGate feature="NORMAL_PAYMENT">
            <TabPane
              className={classNames({ "pay-active": activeTab === "2" })}
              tabId="2"
            >
              <PayGeneralSection />
              {isPersonal && <RecommendedSection />}
            </TabPane>
          </FeatureGate>
        </TabContent>
      </>
    );
  }

  private toggle = (value: string) => {
    this.setState({
      activeTab: value
    });
  };
}

export default FnBDashboard;
