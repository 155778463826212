import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import { PURPOSE } from "./const";
import _isEmpty from "lodash-es/isEmpty";
import _uniq from "lodash-es/uniq";
export type PayeeType =
  | InvoicePayee
  | RentPayee
  | SalaryPayee
  | Collection
  | SelfTransfer;
import _difference from "lodash-es/difference";
import _get from "lodash-es/get";

export type ExtraPayee = PayeeAttrs & {
  uid: string;
};

export type ExtraInfo = {
  hasActiveSchedule: boolean;
  recurring: boolean;
};

export type PayeeAttrs = {
  id: number;
  defaultAmount: number; // from db
  currentAmount?: number; // changes made by the user during this session.
  countryId?: number;
  refundedAmount: number;
  bankId: number;
  bankBSBId: number;
  bankCode: string;
  bsbCode: string;
  accountNumber: string;
  name: string;
  defaultComments?: string;
  order?: number;
  files: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;
  currencyId: number;
  editingCurrencyId?: number;
  uid?: string;
  isUnsupported: boolean;
  unsupportedMessage: string;
  extraInfo?: ExtraInfo;
  display?: boolean; // to solve selected payees in different paginated list issue
  dashboardHidden?: boolean; // to solve deleted tax supplier issue. So now, when ipaymy event supplier is deleted, its not really deleted,
  // but it will be marked as hidden then we hide that payee from dashboard but its still be grabbed for local store.
  international?: International;
  commissionFee?: number;
  commissionRate?: number;
};

export type International = {
  aba: string;
  bankAccountHolderName: string;
  bankCode: string;
  bankCountryId: number;
  bankRawName: string;
  bankRawSwiftCode: string;
  // branchCode: string;
  bsbCode: string;
  companyName?: string;
  cnaps: string;
  iban: string;
  ifscCode: string;
  isBusiness: boolean;
  sortCode: string;
  stateOrProvince: string;
  firstName: string;
  lastName: string;
  businessRegistrationNumber: string;
  city: string;
  region: string;
  relationship?: string;
};

export type InvoicePayee = PayeeAttrs & {
  recipientContactName: string;
  registrationNumber: string;
  email: string;
  recipientMobile: string;
  shouldNotifyRecipientInitiated: boolean;
  supplierOptIn: boolean;
  ipaymyEvent: string;
  editDisabled: boolean;
  hasActiveScheduled: boolean;
};

export type RentPayee = PayeeAttrs & {
  address: string;
  currencyId: number;
  postalCode: string;
  idNumber: string;
  unitNumber: string;
  hasActiveScheduled: boolean;
};

export type SalaryPayee = PayeeAttrs & {
  address: string;
  email: string;
  idType: string;
  postalCode: string;
  idNumber: string;
  currencyId: number;
  unitNumber: string;
  hasActiveScheduled: boolean;
};

export type Collection = PayeeAttrs & {
  feePayer: number;
  grossAmount: number;
  paymentDescription?: string;
};

export type SelfTransfer = PayeeAttrs;

export type PayeesState = {
  readonly isFetching: boolean;
  readonly payees: {
    invoice: { [id: number]: InvoicePayee };
    rent: { [id: number]: RentPayee };
    salary: { [id: number]: SalaryPayee };
    self_transfer: { [id: number]: SelfTransfer };
  };
  readonly selectedPayeeIds: number[];
  readonly editedId: number;
  readonly selectedPurpose?: PURPOSE;
  readonly extraPayees: {
    [payeeId: number]: {
      [uid: string]: ExtraPayee;
    };
  };
  readonly total: number;
};

const defaultState: PayeesState = {
  editedId: -1,
  extraPayees: {},
  isFetching: false,
  payees: {
    invoice: {},
    rent: {},
    salary: {},
    self_transfer: {}
  },
  selectedPayeeIds: [], // Rent Payee can only select 1.
  total: 0
};

export default (state: PayeesState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPayees):
      let payees = state.payees[action.payload.purpose];
      Object.keys(state.payees[action.payload.purpose]).map(payeeId => {
        state.payees[action.payload.purpose][payeeId].display = false;
      });

      if (action.payload.payees) {
        payees = action.payload.payees.reduce((r: any, payee) => {
          r[payee.id] = {
            ...payee,
            currentAmount: payee.defaultAmount,
            display: true
          };

          return r;
        }, state.payees[action.payload.purpose]);
      }
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payees: {
          ...state.payees,
          [action.payload.purpose]: payees
        },
        total: action.payload.total
      };

    case getType(actions.selectPayees):
      if (action.payload.removedIds === "all") {
        return {
          ...state,
          selectedPayeeIds: action.payload.ids,
          selectedPurpose: action.payload.purpose
        };
      } else {
        return {
          ...state,
          selectedPayeeIds: _difference(
            _uniq([...state.selectedPayeeIds, ...action.payload.ids]),
            action.payload.removedIds
          ),
          selectedPurpose: action.payload.purpose
        };
      }

    case getType(actions.resetSelectedPayees):
      return {
        ...state,
        selectedPayeeIds: [],
        selectedPurpose: action.payload.purpose
      };

    case getType(actions.editPayee):
      return {
        ...state,
        editedId: action.payload
      };

    case getType(actions.updateExtraPayee):
      return {
        ...state,
        extraPayees: {
          ...state.extraPayees,
          [action.payload.payeeId]: {
            ...(state.extraPayees[action.payload.payeeId] || {}),
            [action.payload.uid]: action.payload.payee
          }
        }
      };

    case getType(actions.updateExtraPayees):
      return {
        ...state,
        extraPayees: action.payload.payees
      };

    case getType(actions.deleteExtraPayeesByPayeeId):
      delete state.extraPayees[action.payload.id];
      return {
        ...state,
        extraPayees: {
          ...state.extraPayees,
          [action.payload.id]: {
            ...state.extraPayees[action.payload.id]
          }
        }
      };

    case getType(actions.deleteExtraPayee):
      delete state.extraPayees[action.payload.payeeId][action.payload.uid];
      return {
        ...state,
        extraPayees: {
          ...state.extraPayees,
          [action.payload.payeeId]: {
            ...state.extraPayees[action.payload.payeeId]
          }
        }
      };

    case getType(actions.setExtraInfo):
      return {
        ...state,
        payees: {
          ...state.payees,
          [action.payload.purpose]: {
            ...state.payees[action.payload.purpose],
            [action.payload.payeeId]: {
              ...state.payees[action.payload.purpose][action.payload.payeeId],
              extraInfo: action.payload.extraInfo
            }
          }
        }
      };

    case getType(actions.updatePayeeCurrencyId):
      const selectedPayeesUpdated = state.selectedPayeeIds
        .map(id => state.payees[action.payload.purpose][id])
        .reduce((r: any, payee) => {
          r[payee.id] = {
            ...payee,
            currencyId: action.payload.currencyId
          };

          return r;
        }, state.payees[action.payload.purpose]);

      return {
        ...state,
        payees: {
          ...state.payees,
          [action.payload.purpose]: selectedPayeesUpdated
        }
      };

    case getType(actions.updateEditingPayeeCurrencyId):
      const selectedEditingPayeesUpdated = state.selectedPayeeIds
        .map(id => state.payees[action.payload.purpose][id])
        .reduce((r: any, payee) => {
          r[payee.id] = {
            ...payee,
            editingCurrencyId: action.payload.currencyId
          };

          return r;
        }, state.payees[action.payload.purpose]);

      return {
        ...state,
        payees: {
          ...state.payees,
          [action.payload.purpose]: selectedEditingPayeesUpdated
        }
      };
  }
  return state;
};
