import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import countryList from "src/ipm-shared/store/metadata/country";
import normalize from "src/ipm-shared/Utils/NormalizeObject";

export type CountryType = {
  id: number;
  code: string;
  codeNumber: number;
  currency: string;
  name: string;
};

export type CountriesState = {
  readonly byId: { [id: string]: CountryType };
};

const defaultState: CountriesState = {
  byId: normalize(
    countryList.map(c => ({
      code: c.code.toUpperCase(),
      codeNumber: c.calling_code,
      currency: c.currency_code === null ? "" : c.currency_code,
      id: c.id,
      name: c.name
    }))
  )
};

export default (state: CountriesState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setCountries):
      return {
        ...state,
        byId: normalize(action.payload.countries)
      };
    // case getType(actions.setCurrentCountry):
    //   return {
    //     ...state,
    //     currentCountry: action.payload.code
    //   };
  }
  return state;
};
