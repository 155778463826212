import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType, getType } from "typesafe-actions";
import { eventChannel, delay } from "redux-saga";
import { select, call, put, takeLatest, race, take } from "redux-saga/effects";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import { FILTER_EXPORT_FORM_PAYMENTS } from "src/ipm-shared/components/FilterExportProvider";
import {
  PAYMENT_DETAIL_FORM,
  PAYMENT_PAYEE_EDIT_FORM,
  PAYOUT_REQUEST_SEARCH_FORM
} from "src/ipm-shared/store/model/Payment/const";
import _uniq from "lodash-es/uniq";
import * as paymentActions from "./actions";
import {
  PAYMENT_STATUS,
  SCHEDULE_EDIT_COUPON_FORM,
  SCHEDULE_EDIT_FORM
} from "./const";

import {
  CONFIRM_FORM,
  EXPEDITE_FORM,
  PAGE_COUNT,
  ADMIN_PAGE_COUNT,
  REFUND_FORM,
  SEARCH_FORM,
  PAYMENT_CHECK_SEARCH_FORM,
  SCHEDULED_PAGE_COUNT
} from "./const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import RestClient from "src/ipm-shared/services/Rest";
import * as commonActions from "../actions";
import * as commonSelectors from "./selectors";
import * as payeeSelectors from "src/ipm-shared/store/model/Payee/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as cardSelectors from "src/ipm-shared/store/model/Card/selectors";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { PayeeType } from "../Payee/reducers";
import { format } from "date-fns";
import { RootState } from "../reducers";
import { ModalID } from "src/ipm-shared/components/Form/actions";
import T from "src/ipm-shared/Utils/Intl";
import utils from "src/ipm-shared/Utils/Number";
import { PURPOSE } from "./const";
import { PaymentType } from "./reducers";
import { COUNTRY_OPTION_FORM } from "src/ipm-shared/components/SwitchCountryControl/const";
import {
  getWorldpaySessionId,
  processPaymentRequiredAction
} from "../PaymentRequest/sagas";
import { StripeCardCvcElement } from "@stripe/stripe-js";
import CardUtil from "src/ipm-shared/Utils/Card";

const actions = {
  ...paymentActions,
  ...formActions,
  ...commonActions
};

const selectors = {
  ...commonSelectors,
  ...payeeSelectors,
  ...accountProfileSelectors,
  ...cardSelectors
};

const watchedSagas = [
  reTryTakeLatest(actions.adminCheckPayment, handleAdminCheckPayment),
  reTryTakeLatest(actions.fetchAdminPayments, handleFetchAdminPayments),
  // reTryTakeLatest(actions.fetchPayments, handleFetchPayments),
  reTryTakeLatest(actions.fetchDashboardPayments, handleFetchDashboardPayments),
  reTryTakeLatest(actions.fetchSchedules, handleFetchSchedules),
  takeLatest(
    getType(actions.markPaymentAsCompleted),
    handleMarkPaymentAsCompleted
  ),
  takeLatest(
    getType(actions.markPaymentAsCompletedWithDownloadCSV),
    handleMarkPaymentAsCompletedWithDownloadCSV
  ),
  takeLatest(
    getType(actions.markBulkPaymentsAsCompleted),
    handleMarkBulkPaymentsAsCompleted
  ),
  takeLatest(
    getType(actions.markBulkPaymentsAsCompletedWithDownloadCSV),
    handleMarkBulkPaymentsAsCompletedWithDownloadCSV
  ),
  takeLatest(getType(actions.adminExportPayment), handleAdminExportPayment),
  takeLatest(
    getType(actions.adminExportDailyPayment),
    handleAdminExportDailyPayment
  ),
  takeLatest(
    getType(actions.adminExportCitibankCBFT),
    handleAdminExportCitibankCBFT
  ),
  takeLatest(
    getType(actions.adminExportHongleongXLS),
    handleAdminExportHongleongXLS
  ),
  takeLatest(
    getType(actions.adminExportWeeklyStatement),
    handleAdminExportWeeklyStatement
  ),
  takeLatest(
    getType(actions.adminPaymentsForApproval),
    handleAdminPaymentsForApproval
  ),
  reTryTakeLatest(actions.selectPayment, handleFetchPaymentDetail),
  reTryTakeLatest(actions.adminSelectPayment, handleFetchAdminPaymentDetail),
  reTryTakeLatest(
    actions.adminSelectRefundRequestPaymentDetail,
    handleFetchAdminRefundRequestPaymentDetail
  ),
  reTryTakeLatest(actions.adminEditPaymentPayee, adminEditPaymentPayee),
  reTryTakeLatest(actions.selectNextPayment, handleFetchNextPaymentDetail),
  reTryTakeLatest(
    actions.adminSelectNextRefundRequest,
    handleAdminSelectNextRefundRequest
  ),
  reTryTakeLatest(
    actions.adminSelectNextPayment,
    handFetchAdminNextPaymentDetail
  ),
  takeLatest(getType(actions.cancelSchedule), handleCancelSchedule),
  takeLatest(getType(actions.fullyRefund), handleFullyRefund),
  takeLatest(
    getType(actions.partialRefundPrincipal),
    handlePartialRefundPrincipal
  ),
  takeLatest(getType(actions.partialRefundFee), handlePartialRefundFee),
  takeLatest(
    getType(actions.partialRefundPrincipalFees),
    handlePartialRefundPrincipalFees
  ),
  reTryTakeLatest(
    actions.fetchPendingRefundRequests,
    handleFetchPendingRefundRequests
  ),
  reTryTakeLatest(
    actions.fetchPendingRefundRequesteds,
    handleFetchPendingRefundRequesteds
  ),
  takeLatest(getType(actions.processRefund), handleProcessRefund),
  takeLatest(getType(actions.expeditePayoutDate), handleExpeditePayoutDate),
  takeLatest(getType(actions.holdPayment), handleHoldPayment),
  takeLatest(getType(actions.adminCancelPayment), handleAdminCancelPayment),
  reTryTakeLatest(actions.selectSchedule, handleFetchScheduleDetail),
  takeLatest(getType(actions.selectNextSchedule), handleSelectNextSchedule),
  takeLatest(getType(actions.cancelPayments), handleCancelPayments),
  takeLatest(getType(actions.checkFeeVerbose), handleCheckFeeVerbose),
  takeLatest(getType(actions.couponUsageVerbose), handleCouponUsageVerbose),
  takeLatest(getType(actions.editPayments), handleEditPayments),
  takeLatest(getType(actions.validatePayee), handleValidatePayee),
  takeLatest(
    getType(actions.depositInternationalPayments),
    handleDepositInternationalPayments
  ),
  reTryTakeLatest(
    actions.fetchPaymentsHistoryList,
    handleFetchPaymentsHistoryList
  ),
  reTryTakeLatest(
    actions.fetchPaymentsHistoryExport,
    handleFetchPaymentsHistoryExport
  ),
  takeLatest(actions.fetchLogChargeAttempt, handleFetchLogChargeAttempt),
  reTryTakeLatest(
    actions.fetchPayoutPaymentsRequests,
    handleFetchPayoutPaymentsRequests
  ),
  takeLatest(
    getType(actions.processPayoutPaymentsRequest),
    handleProcessPayoutPaymentsRequest
  ),
  reTryTakeLatest(
    actions.fetchAdminPaymentComments,
    handleFetchAdminPaymentComments
  ),
  takeLatest(
    getType(actions.adminAddPaymentComment),
    handleAdminAddPaymentComment
  )
];
export default watchedSagas;

export function* handleAdminExportPayment(
  action: ActionType<typeof actions.adminExportPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  const payoutDate = query.payout_date_lower
    ? format(query.payout_date_lower, "DD_MMM_YYYY")
    : "";

  const res = yield race({
    res1: call(RestClient.download, {
      fileName: `dbs_fast_payment_csv_GPP_${payoutDate}.csv`,
      query: {
        ...query,
        template: "GPP",
        type: action.payload.type
      },
      service: "get_payments_csv_export"
    })

    // res2: call(RestClient.download, {
    //   fileName: `dbs_fast_payment_csv_LVT_${format(
    //     startDate,
    //     "DD_MMM_YYYY"
    //   )}.csv`,
    //   query: {
    //     end_date: endDate,
    //     environment: query.environment,
    //     reference_no: query.reference_no,
    //     start_date: startDate,
    //     template: "LVT",
    //     type: action.payload.type
    //   },
    //   service: "get_payments_csv_export"
    // })
  });

  const res1 = res.res1;
  const errors = _get(res1, "errors");

  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }
}

export function* handleAdminExportDailyPayment(
  action: ActionType<typeof actions.adminExportDailyPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;
  const startDate = query.payout_date_lower
    ? format(query.payout_date_lower, "DD_MMM_YYYY")
    : "";

  yield call(RestClient.download, {
    fileName: `Payment Operations_(${startDate}).csv`,
    query,
    service: "export_daily_payments_admin"
  });
}

export function* handleAdminExportCitibankCBFT(
  action: ActionType<typeof actions.adminExportCitibankCBFT>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;

  yield call(RestClient.download, {
    fileName: `upload_file.txt`,
    query,
    service: "export_citibank_cbft_admin"
  });
}

export function* handleAdminExportHongleongXLS(
  action: ActionType<typeof actions.adminExportHongleongXLS>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;

  const payoutDate = query.payout_date_lower
    ? format(query.payout_date_lower, "DD_MMM_YYYY")
    : "";

  yield call(RestClient.download, {
    fileName: `hongleong_payment_xls_${payoutDate}.xlsx`,
    query,
    service: "export_hongleong_xls_admin"
  });
}

export function* handleAdminExportWeeklyStatement(
  action: ActionType<typeof actions.adminExportWeeklyStatement>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;

  const chargeDateLower = query.charge_date_lower
    ? format(query.charge_date_lower, "DD MMM")
    : "";

  const chargeDateUpper = query.charge_date_upper
    ? format(query.charge_date_upper, "DD MMM YYYY")
    : "";

  if (chargeDateLower == "" || chargeDateUpper == "") {
    yield put(actions.toast("Please select charge date range!"));
    return;
  }

  yield call(RestClient.download, {
    fileName: `Weekly Statement F&M ${chargeDateLower} - ${chargeDateUpper}.xlsx`,
    query,
    service: "export_weekly_statement_admin"
  });
}

export function* handleAdminPaymentsForApproval(
  action: ActionType<typeof actions.adminPaymentsForApproval>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;

  const chargeDateLower = query.charge_date_lower
    ? format(query.charge_date_lower, "DD/MM/YYYY")
    : "";

  const chargeDateUpper = query.charge_date_upper
    ? format(query.charge_date_upper, "DD/MM/YYYY")
    : "";

  if (chargeDateLower == "" || chargeDateUpper == "") {
    yield put(actions.toast("Please select charge date range!"));
    return;
  }

  const res = yield call(RestClient.send, {
    query,
    service: "payments_for_approval_admin"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  } else {
    yield put(actions.toast("Send email successfully!"));
  }

  return;
}

export function* handleFetchAdminPayments(
  action: ActionType<typeof actions.fetchAdminPayments>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: [],
      sumPaymentAmount: 0,
      sumPaymentTotal: 0,
      total: 0,
      totalBankPayout: 0,
      totalBatch: 0,
      totalStripeTransactions: 0
    })
  );

  const { offset } = action.payload;
  const state = yield select();
  const sortPayments = formSelectors.getControl(state, "sort_payments")
    .value as string;

  let paymentStatusId = formSelectors.getControl(state, "payment_status_id")
    .value;
  if (paymentStatusId) {
    paymentStatusId = (paymentStatusId as string).replace(/-/g, ",");
  }

  let paymentMethodId = formSelectors.getControl(state, "payment_method_id")
    .value;
  if (paymentMethodId) {
    paymentMethodId = (paymentMethodId as string).replace(/-/g, ",");
  }

  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }

  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);

  query.country_id = countryId;
  query.currency_id = currencyId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  if (sortPayments) {
    const sort = sortPayments.split(" ");
    query[sort[0]] = sort[1];
  }

  query.offset = offset;
  if (query.email_prefix) {
    query.email_prefix = query.email_prefix.toLowerCase();
  }
  query.page_count = ADMIN_PAGE_COUNT;
  query.payment_status_id = paymentStatusId;
  query.payment_method_id = paymentMethodId;
  query.purpose_id = purposeId;
  const res = yield call(RestClient.send, {
    query,
    service: "get_payments_admin"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }
  const { data: payments, ...metadata } = res;

  yield put(
    actions.setPayments({
      isFetching: false,
      payments: payments?.map((payment: any) => ({
        accountId: payment.account_id,
        cardCharge: payment.card_charges?.map((cc: any) => ({
          amount: cc.amount,
          chargeDate: cc.charge_date,
          id: cc.id,
          receiptNumber: cc.receipt_number,
          status: cc.status
        })),
        cards: payment.cards?.map((card: any) => ({
          amount: card.amount,
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor,
          token: card.token
        })),
        commissionAmount: _get(payment, "comission_amount"),
        company: payment.company_name,
        createdAt: payment.created_at,
        creatorEmail: payment.creator_email,
        currencyId: payment.currency_id,
        id: payment.id,
        isBusiness: payment.is_business,
        isInternationalPayment: payment.is_international_payment,
        isCryptoPayment: payment.is_crypto_payment,
        payees: payment.payees?.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankBSBId: payee.bank_bsb_id,
          bankId: payee.bank_id,
          commissionFee: _get(payee, "commission_fee"),
          commissionRate: _get(payee, "commission_rate"),
          countryId:
            payee.bank_country_id === 0
              ? payee.currency_id
              : payee.bank_country_id,
          currencyId: payee.currency_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          feePayer: payee.fee_payer,
          grossAmount: payee.gross_amount,
          id: payee.id,
          international: {
            bankRawName: payee.bank_raw_name,
            bankAccountHolderName: payee.bank_account_holder_name
          },
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid
        })),
        paymentAmount: payment.payment_amount,
        paymentFees: payment.payment_fees,
        paymentGSTFees: payment.payment_gst_fees,
        paymentFlashPayFees: payment.payment_flash_pay_fees,
        paymentInstantPayFees: payment.payment_instant_pay_fees,
        paymentMethodId: payment.payment_method_id,
        paymentStatusId: payment.payment_status_id,
        paymentTotal: payment.payment_total,
        payoutDate: payment.payout_date,
        purposeId: payment.purpose_id,
        receiptNumber: payment.receipt_number,
        referenceNumber: payment.reference_number,
        scheduleEndDate: payment.schedule_end_date,
        scheduleFrequency: payment.schedule_frequency,
        scheduleId: payment.schedule_id,
        scheduleStartDate: payment.schedule_start_date,
        scheduledChargeDate: payment.scheduled_charge_date,
        settlementTime: payment.settlement_time,
        supportingDocument: [],
        updatedAt: payment.updated_at,
        wallexReference: payment.wallex_reference
      })),
      sumPaymentAmount: metadata.sum_payment_amount,
      sumPaymentTotal: metadata.sum_payment_total,
      total: metadata.total,
      totalBankPayout: metadata.total_bank_payout,
      totalBatch: metadata.batch_total,
      totalStripeTransactions: metadata.total_stripe_transactions
    })
  );
}

export function* handleAdminCheckPayment(
  action: ActionType<typeof actions.adminCheckPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    PAYMENT_CHECK_SEARCH_FORM
  ) as any;
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);
  query.country_id = countryId;
  if (query.payment_id === "" || query.card_id === "") {
    return;
  }

  const res = yield call(RestClient.send, {
    query,
    service: "admin_check_payment"
  });

  action.payload.cb(res);
}

export function* handleMarkPaymentAsCompleted(
  action: ActionType<typeof actions.markPaymentAsCompleted>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      account_id: action.payload.accountId,
      payment_id: action.payload.paymentId
    },
    service: "mark_payment_as_complete"
  });

  yield put(actions.hideGlobalLoader());
  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleMarkPaymentAsCompletedWithDownloadCSV(
  action: ActionType<typeof actions.markPaymentAsCompletedWithDownloadCSV>
) {
  // If Country is MY or HK then call api export csv first then call complete payment action.
  const { accountId, paymentId } = action.payload;
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  yield call(RestClient.send, {
    query: {
      ...query,
      template: "GPP",
      type: "dbs"
    },
    service: "get_payments_csv_export"
  });

  yield put(actions.markPaymentAsCompleted({ accountId, paymentId }));
}

export function* handleMarkBulkPaymentsAsCompleted(
  action: ActionType<typeof actions.markBulkPaymentsAsCompleted>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.parseJSONAsStream, {
    body: {
      payments: action.payload
    },
    service: "mark_bulk_payments_as_complete",
    timeout: -1,
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const channel = eventChannel(emitter => {
    let count = 0;
    const total = action.payload.length;
    res
      .node("finished_id", function(id: number) {
        count++;
        emitter({
          done: false,
          message: `Processed ${count}/${total} payments`
        });
      })
      .node("errors.form", function(reason: string[]) {
        if (reason[0]) {
          emitter({
            done: true,
            success: false,
            message: T.transl(reason[0])
          });
        } else {
          emitter({
            done: true,
            success: false,
            message: T.transl(
              "Internally technical issue. Please contact engineers."
            )
          });
        }
      })
      .node("done", function() {
        emitter({
          done: true,
          success: true
        });
      });

    return () => {
      // Perform any clean up here
    };
  });

  while (true) {
    const { done, success, message } = yield take(channel);

    if (!done) {
      yield put(
        actions.updateLoaderMessage(
          `${message}. Refresh the page to cancel request`
        )
      );
    } else {
      if (success) {
        yield put(actions.updateLoaderMessage(`Success. Close in 6s`));
        yield put(actions.fetchAdminPayments());
      } else {
        yield put(
          actions.updateLoaderMessage(
            `Action failed and be rollbacled. Reason: ${message}. Close in 6s`
          )
        );
      }
      yield call(delay, 6000);
      yield put(actions.hideGlobalLoader());

      break;
    }
  }
}

export function* handleMarkBulkPaymentsAsCompletedWithDownloadCSV(
  action: ActionType<typeof actions.markBulkPaymentsAsCompletedWithDownloadCSV>
) {
  // If Country is MY or HK then call api export csv first then call complete payment action.
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  let purposeId = formSelectors.getControl(state, "purpose_id").value;
  if (purposeId) {
    purposeId = (purposeId as string).replace(/-/g, ",");
  }
  query.country_id = countryId;
  query.currency_id = currencyId;
  query.purpose_id = purposeId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  yield call(RestClient.send, {
    query: {
      ...query,
      template: "GPP",
      type: "dbs"
    },
    service: "get_payments_csv_export"
  });

  yield put(actions.markBulkPaymentsAsCompleted(action.payload));
}

export function* handleFetchDashboardPayments(
  action: ActionType<typeof actions.fetchDashboardPayments>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_payments_history_dashboard_payment_list"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data.payments", []);
  yield put(
    actions.setPayments({
      isFetching: false,
      payments: data.map(payment => ({
        cards: payment.cards.map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        id: payment.id,
        order: payment.order,
        payees: payment.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          countryId:
            payee.bank_country_id === 0
              ? payee.currency_id
              : payee.bank_country_id,
          currencyId: payee.currency_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid
        })),
        paymentAmount: payment.payment_amount,
        paymentFees: payment.payment_fees,
        paymentStatusId: payment.payment_status_id,
        paymentTotal: payment.payment_total,
        payoutDate: payment.payout_date,
        purposeId: payment.purpose_id,
        receiptNumber: payment.receipt_number,
        scheduleEndDate: payment.schedule_end_date,
        scheduleFrequency: payment.schedule_frequency,
        scheduleId: payment.schedule_id,
        scheduleStartDate: payment.schedule_start_date,
        scheduledChargeDate: payment.scheduled_charge_date,
        supportingDocument: [],
        updatedAt: payment.updated_at,
        wallexReference: payment.wallex_reference
      }))
    })
  );
}

export function* handleFetchSchedules(
  action: ActionType<typeof actions.fetchSchedules>
) {
  yield put(
    actions.setSchedules({
      isFetching: true,
      schedules: []
    })
  );

  const { offset, isActive } = action.payload;
  const query = {
    is_active: isActive,
    offset,
    page_count: SCHEDULED_PAGE_COUNT
  };

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_schedules"
  });

  if (!res) {
    yield put(
      actions.setSchedules({
        isFetching: false,
        schedules: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);
  if (!data) {
    yield put(
      actions.setSchedules({
        isFetching: false,
        schedules: []
      })
    );
    return;
  }

  yield put(
    actions.setSchedules({
      isFetching: false,
      schedules: data.map(schedule => ({
        cards: schedule.cards.map((card: any) => ({
          id: card.id,
          statementDescriptor: card.statement_descriptor
        })),
        hasSpecialRate: schedule.has_special_rate,
        isCancelled: schedule.is_cancelled,
        payees: schedule.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          recipientName: payee.recipient_name,
          refundedAmount: payee.refunded_amount
        })),
        paymentAmount: schedule.payment_amount,
        paymentFees: schedule.payment_fees,
        paymentStatusId: schedule.payment_status_id,
        paymentTotal: schedule.payment_total,
        purposeId: schedule.purpose_id,
        scheduleEndDate: schedule.end_date,
        scheduleFrequency: schedule.frequency,
        scheduleId: schedule.id,
        scheduleStartDate: schedule.start_date,
        scheduleStatus: schedule.schedule_status,
        wallexReference: schedule.wallex_reference
      })),
      total
    })
  );

  if (action.payload.cb) {
    yield action.payload.cb();
  }
}

export function* handleFetchPaymentDetail(
  action: ActionType<typeof actions.selectPayment>
) {
  const paymentId = action.payload.id;
  yield put(actions.updatePaymentDetail(paymentId));

  if (paymentId < 0) {
    return;
  }

  yield put(
    actions.setControl({
      form: "default",
      name: "selected_payment",
      value: paymentId
    })
  );

  const res: Response = yield call(RestClient.send, {
    params: {
      id: paymentId
    },
    service: "get_payment_detail",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const payment: any = _get(res, "data");

  yield put(
    actions.updatePaymentDetail(paymentId, {
      accountId: payment.account_id,
      amountOff: payment.amount_off,
      cardCharge: payment.card_charges.map((cc: any) => ({
        id: cc.id,
        refundedAmount: cc.refunded_amount,
        refundedFee: cc.refunded_fee,
        refundedReason: cc.refuse_reason,
        refuseReason: cc.refuse_reason,
        refundedInstantPayFee: cc.refunded_instant_pay_fee,
        refundedFlashPayFee: cc.refunded_flash_pay_fee,
        refundedBankFee: cc.refunded_bank_fee,
        refundedGSTFee: cc.refunded_gst_fee,
        refundedBankPayoutFee: cc.refunded_bank_payout_fee,
        refundedMinimumTransactionFee: cc.refunded_minimum_transaction_fee
      })),
      cards: payment.cards.map((card: any) => ({
        cardBrand: card.card_brand,
        id: card.id,
        last4: card.last4,
        statementDescriptor: card.statement_descriptor
      })),
      channelFees: payment.channel_fees,
      couponCode: payment.coupon_code,
      createdAt: payment.created_at,
      currencyId: payment.currency_id,
      exchangeRate: payment.exchange_rate,
      feeRate: payment.rate,
      id: payment.id,
      isCryptoPayment: payment.is_crypto_payment,
      payees: payment.payees.map((payee: any) => ({
        accountNumber: payee.account_number,
        bankBSBId: payee.bank_bsb_id,
        bankId: payee.bank_id,
        countryId:
          payee.bank_country_id === 0
            ? payee.currency_id
            : payee.bank_country_id,
        currencyId: payee.currency_id,
        currentAmount: payee.amount,
        defaultAmount: payee.amount,
        defaultComments: payee.comments,
        files: [],
        id: payee.id,
        international: {
          bankAccountHolderName: payee.bank_account_holder_name,
          bankRawName: payee.bank_raw_name
        },
        name: payee.recipient_name,
        refundedAmount: payee.refunded_amount,
        uid: payee.uid,
        ...extractPayeeData(payee.data)
      })),
      paymentAmount: payment.payment_amount,
      paymentFees: payment.payment_fees,
      paymentMethodId: payment.payment_method_id,
      paymentStatusId: payment.payment_status_id,
      paymentTotal: payment.payment_total,
      payoutDate: payment.payout_date,
      purposeId: payment.purpose_id,
      receiptNumber: payment.receipt_number,
      referenceNumber: payment.reference_number,
      scheduleEndDate: payment.schedule_end_date,
      scheduleFrequency: payment.schedule_frequency,
      scheduleId: payment.schedule_id,
      scheduleStartDate: payment.schedule_start_date,
      scheduledChargeDate: payment.scheduled_charge_date,
      supportingDocument: [],
      updatedAt: payment.updated_at,
      wallexReference: payment.wallex_reference
    })
  );
}

export function* handleFetchAdminPaymentDetail(
  action: ActionType<typeof actions.adminSelectPayment>
) {
  const paymentId = action.payload.id;
  yield put(actions.updatePaymentDetail(paymentId));

  if (paymentId < 0) {
    return;
  }

  yield put(
    actions.setControl({
      form: "default",
      name: "selected_payment",
      value: paymentId
    })
  );

  const res: Response = yield call(RestClient.send, {
    params: {
      id: paymentId
    },
    service: "get_payment_detail_admin"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const payment: any = _get(res, "data.0");
  yield put(
    actions.updatePaymentDetail(paymentId, {
      accountId: payment.account_id,
      amountOff: payment.amount_off,
      bankTransferFees: payment.bank_transfer_fees,
      merchantFees: payment.merchant_fees,
      schemeFees: payment.scheme_fees,
      interchangeFees: payment.interchange_fees,
      settlementFile: payment.settlement_file,
      cardCharge: (payment.card_charges || []).map((cc: any) => ({
        chargeDate: cc.charge_date,
        id: cc.id,
        receiptNumber: cc.receipt_number,
        refundedAmount: cc.refunded_amount,
        refundedFee: cc.refunded_fee,
        refundedReason: cc.refuse_reason,
        refuseReason: cc.refuse_reason,
        refundedInstantPayFee: cc.refunded_instant_pay_fee,
        refundedFlashPayFee: cc.refunded_flash_pay_fee,
        refundedGSTFee: cc.refunded_gst_fee,
        refundedBankPayoutFee: cc.refunded_bank_payout_fee,
        refundedMinimumTransactionFee: cc.refunded_minimum_transaction_fee,
        refundedBankFee: cc.refunded_bank_fee,
        processedAccountID: cc.processed_account_id,
        standardMCC: cc.standard_mcc
      })),
      cards: (payment.cards || []).map((card: any) => ({
        cardBrand: card.card_brand,
        id: card.id,
        last4: card.last_4,
        statementDescriptor: card.statement_descriptor,
        cardHolderName: card.card_holder_name
      })),
      channelFees: payment.channel_fees,
      company: payment.company_name,
      couponCode: payment.coupon_code,
      createdAt: payment.created_at,
      creatorEmail: payment.creator_email,
      currencyId: payment.currency_id,
      customerEmail: payment.customer_email,
      customerName: payment.customer_name,
      exchangeRate: payment.exchange_rate,
      displayCryptoCurrencyId: payment.display_crypto_currency_id,
      feeRate: payment.rate,
      id: payment.id,
      isCryptoPayment: payment.is_crypto_payment,
      isInternationalPayment: payment.is_international_payment,
      isDeductedRate: payment.is_deducted_rate,
      originalAmount: payment.original_amount,
      origPayoutDate: payment.orig_payout_date.Valid
        ? payment.orig_payout_date.Time
        : undefined,
      payees: (payment.payees || []).map((payee: any) => ({
        accountNumber: payee.account_number,
        bankBSBId: payee.bank_bsb_id,
        bankId: payee.bank_id,
        commissionFee: _get(payee, "commission_fee"),
        commissionRate: _get(payee, "commission_rate"),
        countryId:
          payee.bank_country_id === 0
            ? payee.currency_id
            : payee.bank_country_id,
        currencyId: payee.currency_id,
        currentAmount: payee.amount,
        defaultAmount: payment.is_deducted_rate
          ? payee.original_amount
          : payee.amount,
        originalAmount: payee.original_amount,
        defaultComments: payee.comments,
        feePayer: payee.fee_payer,
        files: payee.payee_data.supporting_documents_data,
        grossAmount: payee.gross_amount,
        id: payee.id,
        idNumber: payee.payee_data.id_number,
        international: {
          bankAccountHolderName: payee.bank_account_holder_name,
          bankRawName: payee.bank_raw_name
        },
        name: payee.recipient_name,
        recipientContactName: payee.payee_data.recipient_contact_name,
        refundedAmount: payee.refunded_amount,
        registrationNumber:
          payee.payee_data.registration_number || payee.payee_data.id_number,
        ...extractPayeeData(payee.payee_data),
        paymentDescription: payee.payment_description,
        uid: payee.uid
      })),
      paymentAmount: payment.payment_amount,
      paymentFees: payment.payment_fees,
      paymentInstantPayFees: payment.payment_instant_pay_fees,
      paymentFlashPayFees: payment.payment_flash_pay_fees,
      paymentGSTFees: payment.payment_gst_fees,
      paymentBankPayoutFees: payment.payment_bank_payout_fees,
      paymentMinimumTransactionFees: payment.payment_minimum_transaction_fees,
      paymentStatusId: payment.payment_status_id,
      paymentMethodId: payment.payment_method_id,
      paymentTotal: payment.payment_total,
      payoutDate: payment.payout_date,
      purposeId: payment.purpose_id,
      receiptNumber: payment.receipt_number,
      referenceNumber: payment.reference_number,
      scheduleEndDate: payment.schedule_end_date,
      scheduleFrequency: payment.schedule_frequency,
      scheduleId: payment.schedule_id,
      scheduleStartDate: payment.schedule_start_date,
      scheduledChargeDate: payment.scheduled_charge_date,
      settlementTime: payment.settlement_time,
      supportingDocument: payment.supporting_documents || [],
      updatedAt: payment.updated_at,
      wallexReference: payment.wallex_reference,
      refundActionType: payment.refund_action_type,
      refundMetadata: {
        newPaymentAmount: payment.refund_metadata?.new_payment_amount,
        newPaymentBankPayoutFees:
          payment.refund_metadata?.new_payment_bank_payout_fees,
        newPaymentFees: payment.refund_metadata?.new_payment_fees,
        newPaymentInstantPayFees:
          payment.refund_metadata?.new_payment_instant_pay_fees,
        newPaymentFlashPayFees:
          payment.refund_metadata?.new_payment_flash_pay_fees,
        newPaymentGstFees: payment.refund_metadata?.new_payment_gst_fees,
        newPaymentMinimumTransactionFees:
          payment.refund_metadata?.new_payment_minimum_transaction_fees,
        newPaymentTotal: payment.refund_metadata?.new_payment_total,
        newRate: payment.refund_metadata?.new_rate,
        otherRefusalReason: payment.refund_metadata?.other_refusal_reason,
        payees: (payment.refund_metadata?.payees || [])?.map((payee: any) => ({
          refundedAmount: payee.refunded_amount
        })),
        paymentRefNo: payment.refund_metadata?.payment_ref_no,
        refundedAmount: payment.refund_metadata?.refunded_amount,
        refundedBankPayoutFee:
          payment.refund_metadata?.refunded_bank_payout_fee,
        refundedDate: payment.refund_metadata?.refunded_date,
        refundedFee: payment.refund_metadata?.refunded_fee,
        refundedBankFee: payment.refund_metadata?.refunded_bank_fee,
        refundedInstantPayFee:
          payment.refund_metadata?.refunded_instant_pay_fee,
        refundedFlashPayFee: payment.refund_metadata?.refunded_flash_pay_fee,
        refundedGstFee: payment.refund_metadata?.refunded_gst_fee,
        refundedMinimumTransactionFee:
          payment.refund_metadata?.refunded_minimum_transaction_fee,
        refundedTotal: payment.refund_metadata?.refunded_total,
        refusalReason: payment.refund_metadata?.refusal_reason,
        refusalReasonCode: payment.refund_metadata?.refusal_reason_code
      }
    })
  );
}

export function* handleFetchAdminRefundRequestPaymentDetail(
  action: ActionType<typeof actions.adminSelectRefundRequestPaymentDetail>
) {
  const paymentId = action.payload.id;

  if (paymentId < 0) {
    return;
  }

  yield put(
    actions.setControl({
      form: "default",
      name: "selected_refund_request_payment_detail",
      value: paymentId
    })
  );

  yield put(actions.adminSelectPayment(paymentId));
  yield put(actions.fetchAdminPaymentComments(paymentId));
}

export function* handleFetchNextPaymentDetail(
  action: ActionType<typeof actions.selectPayment>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_payment"
  ).value;

  const args = (nextSelectedPayment as string).split(",");
  nextSelectedPayment = args[0];

  nextSelectedPayment = parseInt(nextSelectedPayment, 10);

  yield put(actions.selectPayment(nextSelectedPayment));
}

export function* handFetchAdminNextPaymentDetail(
  action: ActionType<typeof actions.adminSelectPayment>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_payment"
  ).value;

  nextSelectedPayment = parseInt(nextSelectedPayment as string, 10);
  yield put(actions.adminSelectPayment(nextSelectedPayment));
  yield put(actions.fetchAdminPaymentComments(nextSelectedPayment));
}

export function* handleAdminSelectNextRefundRequest(
  action: ActionType<typeof actions.adminSelectNextRefundRequest>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_refund_request"
  ).value;
  nextSelectedPayment = parseInt(nextSelectedPayment as string, 10);
  yield put(actions.adminSelectRefundRequestPaymentDetail(nextSelectedPayment));
}

export function* adminEditPaymentPayee(
  action: ActionType<typeof actions.adminEditPaymentPayee>
) {
  const state = yield select();
  const { isRequireAccountId, accountId } = action.payload;
  const selectedPayment = selectors.getSelectedPaymentDetail(state);
  const payees = selectedPayment.payees;

  const documentTags = formSelectors.getControlsPattern(
    state,
    /^document_tag_/
  );

  const paymentSupportingDocs: {
    [payeeId: number]: string[];
  } = {};
  documentTags.map(control => {
    const fileRef = control.name.replace("document_tag_", "");
    const supportingDocument = formSelectors.getControl(state, fileRef)
      .value as string;

    if (supportingDocument) {
      if (isRequireAccountId && accountId) {
        paymentSupportingDocs[accountId] =
          paymentSupportingDocs[accountId] || [];
        paymentSupportingDocs[accountId].push(supportingDocument);
        paymentSupportingDocs[accountId] = _uniq(
          paymentSupportingDocs[accountId]
        );
      } else {
        switch (control.value) {
          case "all":
            for (const payee of payees) {
              paymentSupportingDocs[payee.id] =
                paymentSupportingDocs[payee.id] || [];
              paymentSupportingDocs[payee.id].push(supportingDocument);
              paymentSupportingDocs[payee.id] = _uniq(
                paymentSupportingDocs[payee.id]
              );
            }
            break;
          default:
            const payeeId = parseInt(control.value as string, 10);
            paymentSupportingDocs[payeeId] =
              paymentSupportingDocs[payeeId] || [];
            paymentSupportingDocs[payeeId].push(supportingDocument);
            paymentSupportingDocs[payeeId] = _uniq(
              paymentSupportingDocs[payeeId]
            );
        }
      }
    }
  });

  const newPayees = payees.map((payee: any) => {
    const recipient_name = formSelectors.getControl(
      state,
      `payee_name_${payee.id}`
    ).value;
    const accountNumber = formSelectors.getControl(
      state,
      `account_no_${payee.id}`
    ).value;
    const comments = formSelectors.getControl(
      state,
      `comments_${payee.id}${payee.uid ? `_${payee.uid}` : ""}`
    ).value;
    const bankId =
      formSelectors.getControl(state, `bank_id_${payee.id}`).value ||
      payee.bankId;
    const bankRawName = formSelectors.getControl(
      state,
      `bank_raw_name_${payee.id}`
    ).value;
    const requestdPayees = {
      account_number: accountNumber,
      bank_id: bankId,
      bank_raw_name: bankRawName,
      comments,
      id: payee.id,
      recipient_name: recipient_name,
      uid: payee.uid || ""
    };
    return requestdPayees;
  });
  const paymentId = selectedPayment.id;

  const payload = {
    payees: newPayees,
    payment_id: paymentId,
    purpose_id: selectedPayment.purposeId,
    supporting_documents: paymentSupportingDocs
  };

  const res: Response = yield call(RestClient.send, {
    body: payload,
    service: "admin_edit_payment_payee",
    showGlobalLoader: true
  });

  const errors = _get(res, "errors", undefined);
  if (!res || errors) {
    action.payload.isSuccess(false);
    yield put(
      formActions.parseServerErrors(errors, PAYMENT_PAYEE_EDIT_FORM, undefined)
    );
    return;
  }

  selectedPayment.paymentStatusId = PAYMENT_STATUS.ON_HOLD;
  action.payload.isSuccess(true);
  yield put(formActions.toast(T.transl("ADMIN_EDIT_PAYEE_SUCCESS")));
  yield put(actions.updatePaymentDetail(selectedPayment.id, selectedPayment));
}

function extractPayeeData(data: any): Partial<PayeeType> {
  // Currently we only have rental payee.
  return {
    address: data.address
  };
}

export function* handleCancelSchedule(
  action: ActionType<typeof actions.cancelSchedule>
) {
  const scheduleId = action.payload.id;

  const res: Response = yield call(RestClient.send, {
    params: {
      id: scheduleId
    },
    service: "cancel_schedule",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }
  yield put(actions.fetchSchedules());
}

export function* handleFullyRefund(
  action: ActionType<typeof actions.fullyRefund>
) {
  yield put(actions.showGlobalLoader());
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REFUND_FORM);

  const paymentId = action.payload.id;
  const widthFee = _get(formState, "refund_with.value", "") === "WITH_FEE";
  let refundReason = _get(formState, "reason_refund.value", "");
  const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
  const otherRefundReason = _get(formState, "other_reason.value", "");

  const res: Response = yield call(RestClient.send, {
    body: {
      other_refusal_reason: otherRefundReason,
      payment_id: paymentId,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode,
      with_fee: widthFee
    },
    service: "fully_refund"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.FULL_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handlePartialRefundPrincipal(
  action: ActionType<typeof actions.partialRefundPrincipal>
) {
  yield put(actions.showGlobalLoader());

  const payees = action.payload.payees;
  const paymentId = action.payload.id;
  const currentFee = Math.round(action.payload.currentFee * 100);

  const res: Response = yield call(RestClient.send, {
    body: {
      current_rate: currentFee,
      new_rate: currentFee,
      payees: Object.keys(payees).map((key: any) => ({
        refunded_amount: payees[key]
      })),
      payment_id: paymentId,
      refusal_reason: "",
      refusal_reason_code: ""
    },
    service: "partial_refund_principal"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.PRINCIPAL_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handlePartialRefundFee(
  action: ActionType<typeof actions.partialRefundFee>
) {
  yield put(actions.showGlobalLoader());

  const paymentId = action.payload.id;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REFUND_FORM);
  let refundReason = _get(formState, "reason_refund.value", "");
  const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
  const otherRefundReason = _get(formState, "other_reason.value", "");
  const currentRate = Math.trunc(
    Math.round(parseFloat(_get(formState, "current_fee.value", 0)) * 100)
  );

  const newRate = Math.trunc(
    Math.round(parseFloat(_get(formState, "new_fee.value", 0)) * 100)
  );

  if (refundReason === "other") {
    refundReason = otherRefundReason;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      current_rate: currentRate,
      new_rate: newRate,
      payment_id: paymentId,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode
    },
    service: "partial_refund_fee"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.FEE_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handlePartialRefundPrincipalFees(
  action: ActionType<typeof actions.partialRefundPrincipalFees>
) {
  yield put(actions.showGlobalLoader());

  const {
    payees,
    id: paymentId,
    currentFee,
    newRate,
    refundedFeeAmount
  } = action.payload;
  const currentRate = Math.round(currentFee * 100);
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REFUND_FORM);
  let refundReason = _get(formState, "reason_refund.value", "");
  const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
  const otherRefundReason = _get(formState, "other_reason.value", "");
  const paymentRefundType = _get(
    formState,
    "payment_refund_type.value",
    ""
  ).split(",");
  const instantPayFeeRefund = paymentRefundType?.includes("instant_pay");
  const flashPayFeeRefund = paymentRefundType?.includes("flash_pay");
  const bankFeeRefund = paymentRefundType?.includes("bank_fee");
  const minimumTransactionFeeRefund = paymentRefundType?.includes(
    "minimum_transaction_fee"
  );
  const fundSafeguardingRefund = paymentRefundType?.includes(
    "fund_safeguarding"
  );

  const fundSafeguardingRefundAmount = fundSafeguardingRefund
    ? parseFloat(_get(formState, "fund_safeguarding_option.value", 0)) * 100 ||
      0
    : 0;

  if (refundReason === "Other" || refundReason === "Compliance") {
    refundReason = otherRefundReason;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      current_rate: currentRate,
      new_rate: !!newRate ? Math.round(newRate * 100) : null,
      refunded_fee_amount: refundedFeeAmount,
      payees: payees.map((payee: any, idx: number) => ({
        refunded_amount: Math.round(
          parseFloat(_get(formState, `refunded_amount_${idx}.value`, 0)) *
            100 || 0
        )
      })),
      payment_id: paymentId,
      instant_pay: instantPayFeeRefund,
      flash_pay: flashPayFeeRefund,
      bank_fee: bankFeeRefund,
      fund_safe_guarding: fundSafeguardingRefundAmount,
      minimum_transaction_fee: minimumTransactionFeeRefund,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode
    },
    service: "partial_refund_principal_fees"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.PARTIAL_REFUND_SUMMARY_MODAL));
  yield put(actions.removeForm(REFUND_FORM));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handleFetchPendingRefundRequests(
  action: ActionType<typeof actions.fetchPendingRefundRequests>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: []
    })
  );

  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.currency_id = currencyId;
  query.country_id = countryId;

  if (currencyId == null) {
    query.currency_id = 0;
  }
  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_refund"
  });

  if (!res) {
    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const refundData = data.map(request => {
    const refund: any = {
      actionType: request.action_type,
      chargeDate: request.charge_date,
      createdAt: request.created_at,
      createdBy: {
        email: request.created_by.email,
        firstName: request.created_by.first_name,
        lastName: request.created_by.last_name,
        userId: request.created_by.user_id
      },
      email: request.email,
      paymentAmount: request.payment_amount,
      paymentFees: request.payment_fees,
      paymentMethodId: request.payment_method_id,
      paymentId: request.payment_id,
      paymentTotal: request.payment_total,
      payoutDate: request.payout_date,
      receiptNumber: request.receipt_number,
      referenceNumber: request.reference_number,
      requestId: request.request_id,
      scheduledChargeDate: request.scheduled_charge_date,
      isInternationalPayment: request.is_international_payment
    };
    if (refund.actionType === "edit_payment_payee") {
      refund.metaData = {
        supportingDocuments: request.metadata?.supporting_documents,
        payees: request.metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };

      refund.oldMetaData = {
        supportingDocuments: request.old_metadata?.supporting_documents,
        payees: request.old_metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };
    } else {
      refund.metaData = {
        newPaymentFees: request.metadata.new_payment_fees,
        newPaymentTotal: request.metadata.new_payment_total,
        newPayoutDate: request.metadata.new_payout_date,
        newRate: request.metadata.new_rate,
        otherRefusalReason: request.metadata.other_refusal_reason,
        paymentRefNo: request.receipt_number,
        refundedAmount: request.metadata.refunded_amount,
        refundedFee: request.metadata.refunded_fee,
        refundedTotal: request.metadata.refunded_total,
        refusalReason: request.metadata.refusal_reason,
        refusalReasonCode: request.metadata.refusal_reason_code,
        refundedBankPayoutFee: request.metadata.refunded_bank_payout_fee,
        refundedInstantPayFee: request.metadata.refunded_instant_pay_fee,
        refundedFlashPayFee: request.metadata.refunded_flash_pay_fee,
        refundedGstFee: request.metadata.refunded_gst_fee,
        refundedMinimumTransactionFee:
          request.metadata.refunded_minimum_transaction_fee,
        refundedBankFee: request.metadata.refunded_bank_fee
      };

      refund.oldMetaData = {
        paymentAmount: request.old_metadata.payment_amount,
        paymentBankPayoutFees: request.old_metadata.payment_bank_payout_fees,
        paymentFees: request.old_metadata.payment_fees,
        paymentInstantPayFees: request.old_metadata.payment_instant_pay_fees,
        paymentFlashPayFees: request.old_metadata.payment_flash_pay_fees,
        paymentGstFees: request.old_metadata.payment_gst_fees,
        paymentMinimumTransactionFees:
          request.old_metadata.payment_minimum_transaction_fees,
        paymentTotal: request.old_metadata.payment_total,
        paymentBankFee: request.old_metadata.payment_bank_fee
      };
    }
    return refund;
  });

  yield put(
    actions.setRefundRequests({
      isFetching: false,
      refundRequests: refundData
    })
  );
}

export function* handleFetchPendingRefundRequesteds(
  action: ActionType<typeof actions.fetchPendingRefundRequesteds>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: []
    })
  );

  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  if (currencyId == null) {
    query.currency_id = 0;
  }
  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_refund_requested"
  });

  if (!res) {
    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const refundData = data.map(request => {
    const refund: any = {
      actionType: request.action_type,
      chargeDate: request.charge_date,
      createdAt: request.created_at,
      createdBy: {
        email: request.created_by.email,
        firstName: request.created_by.first_name,
        lastName: request.created_by.last_name,
        userId: request.created_by.user_id
      },
      email: request.email,
      paymentAmount: request.payment_amount,
      paymentFees: request.payment_fees,
      paymentMethodId: request.payment_method_id,
      paymentId: request.payment_id,
      paymentTotal: request.payment_total,
      payoutDate: request.payout_date,
      receiptNumber: request.receipt_number,
      referenceNumber: request.reference_number,
      requestId: request.request_id,
      scheduledChargeDate: request.scheduled_charge_date
    };
    if (refund.actionType === "edit_payment_payee") {
      refund.metaData = {
        supportingDocuments: request.metadata?.supporting_documents,
        payees: request.metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };

      refund.oldMetaData = {
        supportingDocuments: request.old_metadata?.supporting_documents,
        payees: request.old_metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };
    } else {
      refund.metaData = {
        newPaymentFees: request.metadata.new_payment_fees,
        newPaymentTotal: request.metadata.new_payment_total,
        newPayoutDate: request.metadata.new_payout_date,
        newRate: request.metadata.new_rate,
        otherRefusalReason: request.metadata.other_refusal_reason,
        paymentRefNo: request.receipt_number,
        refundedAmount: request.metadata.refunded_amount,
        refundedFee: request.metadata.refunded_fee,
        refundedTotal: request.metadata.refunded_total,
        refusalReason: request.metadata.refusal_reason,
        refusalReasonCode: request.metadata.refusal_reason_code
      };
    }
    return refund;
  });

  yield put(
    actions.setRefundRequesteds({
      isFetching: false,
      refundRequesteds: refundData
    })
  );
}

export function* handleProcessRefund(
  action: ActionType<typeof actions.processRefund>
) {
  yield put(actions.showGlobalLoader());
  const paymentId = action.payload.paymentId;
  const requestId = action.payload.requestId;
  const processStatus = action.payload.status;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      request_id: requestId,
      status: processStatus
    },
    service: "process_refund"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }
    return;
  }

  yield put(actions.fetchPendingRefundRequests());
}

export function* handleExpeditePayoutDate(
  action: ActionType<typeof actions.expeditePayoutDate>
) {
  yield put(actions.showGlobalLoader());
  const paymentId = action.payload.paymentId;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, EXPEDITE_FORM);
  const expeditedPayoutDate = _get(
    formState,
    "expedited_payout_date.value",
    ""
  );

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      payout_date: expeditedPayoutDate
    },
    service: "expedite_payout_date"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, EXPEDITE_FORM));
    return;
  }

  yield put(actions.closeModal(ModalID.EXPEDITE_MODAL));
  yield put(actions.fetchAdminPayments());
}

export function* handleHoldPayment(
  action: ActionType<typeof actions.holdPayment>
) {
  const paymentId = action.payload.paymentId;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId
    },
    service: "hold_payment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }
  yield put(actions.fetchAdminPayments());
}

export function* handleFetchScheduleDetail(
  action: ActionType<typeof actions.selectSchedule>
) {
  const { id } = action.payload;
  yield put(
    actions.setControl({
      form: "default",
      name: "selected_schedule",
      value: id
    })
  );

  const res: Response = yield call(RestClient.send, {
    params: {
      id
    },
    service: "get_schedule_detail",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const upcomingPayments: any[] = _get(data, "payments", []);
  const schedule = _get(data, "schedule", {});
  const paymentSetting = _get(data, "schedule.payment_setting", {});

  yield put(
    actions.setScheduleDetail({
      lastPayout: schedule.last_payout,
      paymentSetting: {
        cards: _get(paymentSetting, "cards", []).map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        couponCode: paymentSetting.coupon,
        createdAt: _get(paymentSetting, "created_at", ""),
        currencyId: paymentSetting.currency_id,
        id: _get(paymentSetting, "id", 0),
        payees: paymentSetting.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          bankBSBId: payee.bank_bsb_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid,
          ...extractPayeeData(payee.data)
        })),
        paymentAmount: paymentSetting.payment_amount,
        paymentFees: paymentSetting.payment_fees,
        paymentStatusId: paymentSetting.payment_status_id || 0,
        paymentTotal: paymentSetting.payment_total,
        payoutDate: paymentSetting.payout_date || "",
        purposeId: paymentSetting.purpose_id,
        receiptNumber: paymentSetting.receipt_number || "",
        scheduleEndDate: schedule.end_date,
        scheduleFrequency: schedule.frequency,
        scheduleId: schedule.id,
        scheduleStartDate: schedule.start_date,
        scheduledChargeDate: schedule.scheduled_charge_date || "",
        supportingDocument: [],
        updatedAt: paymentSetting.updated_at || "",
        wallexReference: paymentSetting.wallex_reference || ""
      } as PaymentType,
      payments: upcomingPayments.map(p => ({
        cards: _get(p, "cards", []).map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        chargeDate: p.scheduled_charge_date,
        defaultComments: p.default_comments,
        editable: p.editable,
        id: p.id,
        paymentAmount: p.payment_amount,
        paymentFee: p.payment_fee,
        paymentTotal: p.payment_total,
        payoutDate: p.payout_date
      })),
      scheduleId: schedule.id
    })
  );
}

export function* handleSelectNextSchedule(
  action: ActionType<typeof actions.selectNextSchedule>
) {
  const state = yield select();
  let nextSelectedSchedule = formSelectors.getControl(
    state,
    "next_selected_schedule"
  ).value;

  const args = (nextSelectedSchedule as string).split(",");
  nextSelectedSchedule = args[0];

  nextSelectedSchedule = parseInt(nextSelectedSchedule, 10);

  yield put(actions.selectSchedule(nextSelectedSchedule));
}

export function* handleCancelPayments(
  action: ActionType<typeof actions.cancelPayments>
) {
  // Cancel payments in schedule
  const state = yield select();
  const selectedPaymentIds = commonSelectors.getSelectedPaymentIds(state);

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_ids: selectedPaymentIds
    },
    service: "cancel_payments",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  yield put(actions.fetchSchedules());
}

export function* handleCheckFeeVerbose(
  action: ActionType<typeof actions.checkFeeVerbose>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const paymentSelected = selectors.getSelectedPaymentDetail(state);
  const scheduleSelected = selectors.getScheduleDetail(state);
  const chargeDate = _get(
    formState,
    `charge_date_${scheduleSelected.scheduleId}.value`,
    scheduleSelected.scheduleStartDate // TODO: Should check here! Temple solution
  );

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];
  try {
    paymentSelected.payees.map(payee => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}.value`,
            ""
          ),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`,
            ""
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleCheckFeeVerbose: ", e.message);
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      card_id: action.payload.cardId,
      coupon_code: action.payload.couponCode,
      currency_id: paymentSelected.currencyId,
      end_date: scheduleSelected.scheduleEndDate,
      frequency:
        scheduleSelected.scheduleFrequency.toLowerCase() === "one-time"
          ? "once"
          : scheduleSelected.scheduleFrequency.toLowerCase(),
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose_id: paymentSelected.purposeId,
      start_date: chargeDate
    },
    service: "fees_verbose"
    // showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const data = _get(res, "data", {});

  yield put(
    actions.setFeesVerbose({
      [paymentSelected.id]: {
        coupon: data.coupon,
        fee: data.fee,
        rate: data.rate,
        rateBeforeCoupon: data.rateBeforeCoupon,
        savings: data.savings,
        total: data.total
      }
    })
  );

  if (action.payload.cb) {
    action.payload.cb(
      _get(res, "data.coupon", undefined),
      _get(res, "data.fee", 0)
    );
  }
}

export function* handleCouponUsageVerbose(
  action: ActionType<typeof actions.couponUsageVerbose>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const fromStateCode = formSelectors.getControls(
    state,
    SCHEDULE_EDIT_COUPON_FORM
  );
  const paymentSelected = selectors.getSelectedPaymentDetail(state);
  const scheduleSelected = selectors.getScheduleDetail(state);
  const couponCode = _get(fromStateCode, "code.value", "");

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];
  try {
    paymentSelected.payees.map(payee => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(formState, `account_number_${payee.id}.value`),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleCouponUsageVerbose: ", e.message);
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      card_id: action.payload.cardId,
      coupon_code: couponCode,
      currency_id: paymentSelected.currencyId,
      end_date: scheduleSelected.scheduleEndDate,
      frequency:
        scheduleSelected.scheduleFrequency.toLowerCase() === "one-time"
          ? "once"
          : scheduleSelected.scheduleFrequency.toLowerCase(),
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose_id: paymentSelected.purposeId,
      start_date: _get(
        formState,
        `charge_date_${scheduleSelected.scheduleId}.value`
      )
    },
    service: "coupon_usage_verbose"
    // showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_COUPON_FORM));
    return;
  }

  yield put(actions.checkFeeVerbose(action.payload.cardId, couponCode));

  if (action.payload.cb) {
    action.payload.cb(couponCode);
  }
}

export function* handleEditPayments(
  action: ActionType<typeof actions.editPayments>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const paymentSelected = selectors.getSchedulePaymentSetting(
    state
  ) as PaymentType;
  const scheduleSelected = selectors.getScheduleDetail(state);

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    bankBsbId: number;
    bankCode: string;
    bsbCode: string;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
    recipient_name: string;
  }> = [];
  try {
    paymentSelected.payees.map(payee => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: payee.accountNumber,
          bankBsbId: payee.bankBSBId,
          bankCode: payee.bankCode,
          bankId: payee.bankId,
          bsbCode: payee.bsbCode,
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name,
          recipient_name: payee.name
        });
      } else {
        payees.push({
          accountNumber: payee.accountNumber,
          bankBsbId: payee.bankBSBId,
          bankCode: payee.bankCode,
          bankId: payee.bankId,
          bsbCode: payee.bsbCode,
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          recipient_name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleEditPayments: ", e.message);
    return;
  }

  yield put(actions.showGlobalLoader());
  let wpSessionId: string | undefined;
  const cardId = _get(
    formState,
    `card_id_${scheduleSelected.scheduleId}.value`
  );
  if (_get(paymentSelected, "cards[0].id") !== cardId) {
    const card = selectors.cardsById(state)[cardId];
    if (CardUtil.isWorldpay(card.acquirerId)) {
      const wpResult = yield call(
        getWorldpaySessionId,
        cardId,
        card.acquirerId
      );
      wpSessionId = wpResult;
      window.Logger.error(JSON.stringify(wpResult));
      if (!wpSessionId) {
        yield put(actions.hideGlobalLoader());
        return;
      }
    }
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      card_id: _get(formState, `card_id_${scheduleSelected.scheduleId}.value`),
      code: _get(formState, `code.value`),
      currency_id: paymentSelected.currencyId,
      end_date:
        _get(formState, `end_date_${scheduleSelected.scheduleId}.value`) ||
        null,
      frequency:
        scheduleSelected.scheduleFrequency.toLowerCase() === "one-time"
          ? "once"
          : scheduleSelected.scheduleFrequency.toLowerCase(),
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_bsb_id: payee.bankBsbId,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      payment_ids: action.payload.ids,
      payout_date:
        _get(formState, `payout_date_${scheduleSelected.scheduleId}.value`) ||
        null,
      purpose_id: paymentSelected.purposeId,
      schedule_id: scheduleSelected.scheduleId,
      wp_session_id: wpSessionId
    },
    service: "edit_payments"
  });

  if (!res) {
    yield put(actions.hideGlobalLoader());
    return;
  }

  const errors = _get(res, "errors", {});
  const requiredAction = _get(res, "action_required", undefined);
  if (requiredAction) {
    if (requiredAction.recollect_cvv) {
      yield put(actions.hideGlobalLoader());
      yield put(
        actions.toggleModal(actions.ModalID.RECOLLECT_CVV, {
          callback: (cvv: string | StripeCardCvcElement) => {
            const it = processPaymentRequiredAction(
              {
                acquirerId: requiredAction.acquirer_id,
                callbackUrl: requiredAction.callback_url,
                threedsToken: requiredAction.threeds_token,
                paymentTotal: requiredAction.payment_total,
                bin: requiredAction.bin,
                provider: requiredAction.provider,
                clientToken: requiredAction.client_token,
                mid: requiredAction.mid ? requiredAction.mid : undefined,
                recollectCVV: requiredAction.recollect_cvv
              },
              cvv
            );

            while (!it.next().done) {
              // do nothing
            }
          },
          acquirerId: requiredAction.acquirer_id,
          provider: requiredAction.provider
        })
      );
    } else {
      yield processPaymentRequiredAction({
        acquirerId: requiredAction.acquirer_id,
        callbackUrl: requiredAction.callback_url,
        threedsToken: requiredAction.threeds_token,
        paymentTotal: requiredAction.payment_total,
        bin: requiredAction.bin,
        provider: requiredAction.provider,
        clientToken: requiredAction.client_token,
        mid: requiredAction.mid ? requiredAction.mid : undefined,
        recollectCVV: requiredAction.recollect_cvv
      });
    }
    return;
  }

  yield put(actions.hideGlobalLoader());

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_FORM));
    yield put(
      actions.setUpdatedPaymentStatus({
        failed: true,
        success: false
      })
    );
    return;
  }

  yield put(actions.closeModal(ModalID.EDIT_PAYMENT_DETAIL_MODAL));
  yield put(
    actions.fetchSchedules(
      "y",
      0,
      reOpenScheduleDetailModal.bind({}, scheduleSelected.scheduleId)
    )
  );
}

function* reOpenScheduleDetailModal(scheduleId: number) {
  yield put(actions.selectSchedule(scheduleId));
  yield put(
    actions.toggleModal(ModalID.SCHEDULED_PAYMENT_DETAIL, {
      key: "schedule_detail"
    })
  );
  yield put(
    actions.setUpdatedPaymentStatus({
      failed: false,
      success: true
    })
  );
}

export function* handleValidatePayee(
  action: ActionType<typeof actions.validatePayee>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const paymentSelected = selectors.getSelectedPaymentDetail(state);

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];

  try {
    paymentSelected.payees.map(payee => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(formState, `account_number_${payee.id}.value`),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleValidatePayee: ", e.message);
    return;
  }

  let purpose = "";

  switch (paymentSelected.purposeId) {
    case PURPOSE.RENTAL:
      purpose = "rent";
      break;
    case PURPOSE.SALARY:
      purpose = "salary";
      break;
    case PURPOSE.INVOICE:
      purpose = "invoice";
      break;
    default:
      purpose = "";
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose
    },
    service: "validate_payment_payee"
  });

  if (!res) {
    return false;
  }

  yield put(actions.setSubmitButtonState(false));

  const json = res;
  const errors = _get(json, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_FORM));
    return false;
  }
  return;
}

export function* handleDepositInternationalPayments(
  action: ActionType<typeof actions.depositInternationalPayments>
) {
  const res = yield call(RestClient.send, {
    body: {
      payment_ids: action.payload.paymentId
    },
    service: "deposit_international_payments",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleFetchPaymentsHistoryList(
  action: ActionType<typeof actions.fetchPaymentsHistoryList>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: [],
      sumPaymentAmount: 0,
      sumPaymentTotal: 0,
      total: 0,
      totalBankPayout: 0,
      totalStripeTransactions: 0
    })
  );

  const { offset } = action.payload;

  const defaultStatusIds = [
    PAYMENT_STATUS.IN_PROGRESS_2,
    PAYMENT_STATUS.ON_HOLD,
    PAYMENT_STATUS.INT_PENDING,
    PAYMENT_STATUS.UNDER_REVIEW,
    PAYMENT_STATUS.COMPLETED,
    PAYMENT_STATUS.PAID,
    PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
    PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
    PAYMENT_STATUS.DECLINED
  ].toString();
  // new default: "3,4,5,9,10,11,15,16,17"
  // old default: "3,4,5,6,7,9,10,11,15"

  let query = {
    offset,
    page_count: PAGE_COUNT,
    purposes: "1,2,3,5",
    statuses: defaultStatusIds
  } as any;

  const state = yield select();

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENTS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENTS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_history_list",
    timeout: 30000
  });

  if (!res) {
    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);

  yield put(
    actions.setPayments({
      isFetching: false,
      payments: data.map(payment => ({
        cards: payment.cards.map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        id: payment.id,
        order: payment.order,
        payees: payment.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          countryId:
            payee.bank_country_id === 0
              ? payee.currency_id
              : payee.bank_country_id,
          currencyId: payee.currency_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid
        })),
        paymentAmount: payment.payment_amount,
        paymentFees: payment.payment_fees,
        paymentStatusId: payment.payment_status_id,
        paymentTotal: payment.payment_total,
        payoutDate: payment.payout_date,
        purposeId: payment.purpose_id,
        receiptNumber: payment.receipt_number,
        scheduleEndDate: payment.schedule_end_date,
        scheduleFrequency: payment.schedule_frequency,
        scheduleId: payment.schedule_id,
        scheduleStartDate: payment.schedule_start_date,
        scheduledChargeDate: payment.scheduled_charge_date,
        supportingDocument: [],
        updatedAt: payment.updated_at,
        wallexReference: payment.wallex_reference
      })),
      total
    })
  );
}

export function* handleFetchPaymentsHistoryExport(
  action: ActionType<typeof actions.fetchPaymentsHistoryExport>
) {
  const defaultStatusIds = [
    PAYMENT_STATUS.IN_PROGRESS_2,
    PAYMENT_STATUS.ON_HOLD,
    PAYMENT_STATUS.INT_PENDING,
    PAYMENT_STATUS.UNDER_REVIEW,
    PAYMENT_STATUS.COMPLETED,
    PAYMENT_STATUS.PAID,
    PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
    PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
    PAYMENT_STATUS.DECLINED
  ].toString();
  // new default: "3,4,5,9,10,11,15,16,17"
  // old default: "3,4,11,15"

  let query = {
    offset: 0,
    page_count: 1000, // PAGE_COUNT,
    purposes: "1,2,3,5",
    statuses: defaultStatusIds,
    template: "normal"
  } as any;

  const state = yield select();

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENTS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENTS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  const startDate = query.charge_date_lower
    ? format(query.charge_date_lower, "DD_MMM_YYYY")
    : "x";
  const endDate = query.charge_date_upper
    ? format(query.charge_date_upper, "DD_MMM_YYYY")
    : "y";

  const res = yield call(RestClient.download, {
    fileName: `my_payments_csv_${startDate}-${endDate}.csv`,
    query,
    service: "get_payments_history_export",
    timeout: 30000
  });

  const errors = _get(res, "errors");

  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }
}

export function* handleAdminCancelPayment(
  action: ActionType<typeof actions.adminCancelPayment>
) {
  // Cancel payments in schedule
  const paymentId = action.payload.paymentId;
  const userEmail = action.payload.userEmail;
  const statusId = action.payload.statusId;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      payment_status_id: statusId,
      user_email: userEmail
    },
    service: "admin_cancel_payment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }
    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleFetchLogChargeAttempt(
  action: ActionType<typeof actions.fetchLogChargeAttempt>
) {
  const res: Response = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    service: "get_log_charge_attempt",
    showGlobalLoader: false
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any = _get(res, "data");

  yield put(
    actions.setLogChargeAttempt(
      data.map((item: any) => ({
        id: item.payment_id,
        success: item.success,
        metadata: item.metadata,
        errors: item.errors,
        actionType: item.action_type,
        last4: item.last4,
        cardBrandId: item.card_brand_id,
        name: item.name,
        cardType: item.card_type,
        bankIssuer: item.bank_issuer,
        cardFunding: item.card_funding
      }))
    )
  );
}

export function* handleFetchPayoutPaymentsRequests(
  action: ActionType<typeof actions.fetchPayoutPaymentsRequests>
) {
  yield put(
    actions.setPayoutPaymentsRequests({
      isFetching: true,
      payoutPaymentsRequests: []
    })
  );
  const state = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    PAYOUT_REQUEST_SEARCH_FORM
  ) as any;

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;

  const sortPayments = formSelectors.getControl(state, "sort_payout_requests")
    .value as string;

  if (sortPayments) {
    const sort = sortPayments.split(" ");
    query[sort[0]] = sort[1];
  }
  const controlValue = _get(queryCountry, "switch_control_value", "");
  query.payment_type = controlValue;

  const res: Response = yield call(RestClient.send, {
    query,
    service: "admin_get_payout_requests"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);

  yield put(
    actions.setPayoutPaymentsRequests({
      isFetching: false,
      payoutPaymentsRequests: (data ?? []).map(request => ({
        id: request.id,
        countryId: request.country_id,
        createdAt: request.created_at,
        currencyCode: request.currency_code,
        currencyId: request.currency_id,
        numberOfPayment: request.number_of_payment,
        payoutDate: request.payout_date,
        status: request.status,
        totalAmount: request.total_amount,
        payments: (request.payments ?? []).map((payment: any) => ({
          id: payment.payment_id,
          accountNumber: payment.account_number,
          amount: payment.amount,
          bank: payment.bank,
          bsbCode: payment.bsb_code,
          countryId: payment.country_id,
          currencyId: payment.currency_id,
          email: payment.email,
          payee: payment.payee,
          purpose: payment.purpose,
          receiptNumber: payment.receipt_number,
          payees: (payment.payees ?? []).map((payee: any) => ({
            id: payee.id,
            accountNumber: payee.account_number,
            recipientName: payee.recipient_name,
            payeeData: {
              supportingDocuments: payee.payee_data.supporting_documents
            },
            countryId: payee.country_id,
            createdAt: payee.created_at,
            email: payee.email,
            accountId: payee.account_id,
            amount: payee.amount,
            bsbCode: payee.bsb_code,
            firstName: payee.first_name,
            lastName: payee.last_name,
            bankBsbId: payee.bank_bsb_id,
            currencyId: payee.currency_id,
            comments: payee.comments
          }))
        }))
      }))
    })
  );
}

export function* handleProcessPayoutPaymentsRequest(
  action: ActionType<typeof actions.processPayoutPaymentsRequest>
) {
  yield put(actions.showGlobalLoader());
  const { requestId, status: processStatus } = action.payload;

  const state = yield select();
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const controlValue = _get(queryCountry, "switch_control_value", "");

  const res: Response = yield call(RestClient.send, {
    params: {
      id: requestId
    },
    body: {
      status: processStatus,
      payment_type: controlValue
    },
    service: "payout_requests"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  yield put(actions.fetchPayoutPaymentsRequests());
}

export function* handleFetchAdminPaymentComments(
  action: ActionType<typeof actions.fetchAdminPaymentComments>
) {
  const paymentId = action.payload.id;

  if (paymentId < 0) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    params: {
      id: paymentId
    },
    service: "get_payment_comments_admin",
    showGlobalLoader: false
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any = _get(res, "data");

  yield put(
    actions.setSelectedPaymentComments({
      isFetching: false,
      selectedPaymentComments: (data ?? []).map((item: any) => ({
        id: item.id,
        createdAt: item.created_at,
        updateAt: item.update_at,
        createdBy: item.created_by,
        paymentId: item.payment_id,
        comment: item.comment,
        commentedBy: item.commented_by
      }))
    })
  );
}

export function* handleAdminAddPaymentComment(
  action: ActionType<typeof actions.adminAddPaymentComment>
) {
  const paymentId = action.payload.id;
  const state = yield select();
  const formState = formSelectors.getControls(state, PAYMENT_DETAIL_FORM);
  const comment = _get(formState, "payment_detail_comment.value", "");

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      comment: comment
    },
    service: "admin_add_payment_comment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, PAYMENT_DETAIL_FORM));
    return;
  }
  yield put(actions.fetchAdminPaymentComments(paymentId));
}
