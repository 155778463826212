/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import _get from "lodash-es/get";
import _findIndex from "lodash-es/findIndex";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as actions from "./actions";
import * as commonActions from "../actions";
import {
  REQUEST_LOGIN_FORM,
  SEARCH_FORM,
  PAGE_COUNT,
  RESET_LINK_REQUEST,
  EMAIL_CONFIRM_LINK_REQUEST,
  VERIFY_FORM,
  ADMIN_UPDATE_ACCOUNT_DETAILS_FORM
} from "./const";
import restService from "src/ipm-shared/services/Rest";
import * as authActions from "src/ipm-shared/store/model/Auth/actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

const watchedSagas = [
  reTryTakeLatest(actions.fetchUsers, handleFetchUsers),
  reTryTakeLatest(
    actions.adminListUserUpdateRequest,
    handleAdminListUserUpdateRequest
  ),
  takeLatest(getType(actions.adminRequestLogin), handleAdminRequestLogin),
  takeEvery(getType(actions.resendVerifyEmail), handleResendVerifyEmail),
  takeEvery(getType(actions.adminSelectMyInfo), handleGetMyInfo),
  takeEvery(getType(actions.adminGetResetLink), handleGetResetLink),
  takeEvery(
    getType(actions.adminGetEmailConfirmLink),
    handleGetEmailConfirmLink
  ),
  takeLatest(getType(actions.adminBlockUser), handleBlockUser),
  takeLatest(getType(actions.adminUnBlockUser), handleUnBlockUser),
  takeLatest(getType(actions.adminUpdateUserDetails), handleUpdateUserDetails)
];
export default watchedSagas;

export function* handleFetchUsers(
  action: ActionType<typeof actions.fetchUsers>
) {
  yield put(
    actions.setUsers({
      isFetching: true,
      total: 0,
      users: []
    })
  );

  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;

  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;

  const countryId = _get(queryCountry, "value", 1);

  query.country_id = countryId;
  query.offset = action.payload.offset;
  if (query.email) {
    query.email = query.email.toLowerCase();
  }
  query.page_count = PAGE_COUNT;

  try {
    let inactiveDays = _get(query, "inactive_days", "-");
    inactiveDays = inactiveDays.split("-");
    query.lower_inactive_days = inactiveDays[0];
    query.upper_inactive_days = inactiveDays[1];
  } catch (e) {
    window.Logger.error("handleFetchUsers: ", e.message);
  }

  delete query.inactive_days;

  const res: Response = yield call(restService.send, {
    query,
    service: "get_users"
  });

  if (!res) {
    yield put(actions.setUsers({ isFetching: false, total: 0 }));
    return;
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    const total: number = _get(json, "total", 0);

    yield put(
      actions.setUsers({
        isFetching: false,
        total,
        users: data.map(user => ({
          amRequired: user.am_required,
          smRequired: user.sm_required,

          accountId: user.account_id,
          accountManager: user.account_manager,
          accountType: user.account_type,
          companyCountryId: user.company_country_id,
          companyName: user.company_name,
          confirmedEmailAt: user.confirmed_email_at,
          confirmedMobileAt: user.confirmed_mobile_at,
          createdAt: user.created_at,
          email: user.email,
          firstName: user.first_name,
          firstPayment: user.first_payment,
          hasAdvancedAM: user.has_advanced_am,
          id: user.id,
          inactiveDays: user.inactive_days,
          isBusiness: user.is_business,
          isProduction: user.is_production,
          lastName: user.last_name,
          mobileCountryId: user.mobile_country_id,
          mobileNumber: user.mobile_number,
          regNumber: user.reg_number,
          sumMonthlyPayment: user.sum_monthly_payment,
          updatedAt: user.updated_at,
          deletedAt: user.deleted_at,
          countryId: user.country_id
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchUsers: ", e.message);
  }
}

export function* handleAdminRequestLogin(
  action: ActionType<typeof actions.adminRequestLogin>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REQUEST_LOGIN_FORM);
  const { appId, cb } = action.payload;
  const res = yield call(restService.send, {
    body: {
      email: _get(formState, "email.value"),
      pass_code: _get(formState, "pass_code.value"),
      app_id: appId
    },
    service: "admin_request_login"
  });

  if (!res) {
    return;
  }

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(formActions.parseServerErrors(errors, REQUEST_LOGIN_FORM));
      return;
    }

    if (!res.data.token) {
      return;
    }

    if (cb) {
      cb({
        token: res.data.token
      });
    }

    if (action.payload.getTokenOnly) {
      return;
    }

    yield call([localStorage, "setItem"], "token", res.data.token);
    yield put(authActions.setToken({ token: res.data.token }));

    window.location.href = "/";
  } catch (e) {
    window.Logger.error("handleAdminRequestLogin: ", e.message);
  }
}

export function* handleResendVerifyEmail(
  action: ActionType<typeof actions.resendVerifyEmail>
) {
  const userEmail = action.payload.email;
  const res = yield call(restService.send, {
    body: {
      email: userEmail
    },
    service: "resend_verify_email"
  });

  if (!res) {
    return;
  }

  action.payload.afterFetchCb();
}

export function* handleGetMyInfo(
  action: ActionType<typeof actions.adminSelectMyInfo>
) {
  const res = yield call(restService.send, {
    params: {
      id: action.payload.userId
    },
    service: "get_my_info"
  });

  yield put(actions.setMyInfo(res?.data || { confirmed: null, info: null }));
}

export function* handleGetResetLink(
  action: ActionType<typeof actions.adminGetResetLink>
) {
  const res = yield call(restService.send, {
    body: {
      email: action.payload.email
    },
    service: "user_reset_link"
  });

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(formActions.parseServerErrors(errors, RESET_LINK_REQUEST));
    }

    yield put(actions.setResetLink(res.data || null));
  } catch (e) {
    window.Logger.error("handleGetResetLink: ", e.message);
  }
}

export function* handleGetEmailConfirmLink(
  action: ActionType<typeof actions.adminGetEmailConfirmLink>
) {
  const res = yield call(restService.send, {
    body: {
      email: action.payload.email
    },
    service: "user_email_confirm_link"
  });

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(
        formActions.parseServerErrors(errors, EMAIL_CONFIRM_LINK_REQUEST)
      );
    }

    yield put(actions.setEmailConfirmLink(res.data || null));
  } catch (e) {
    window.Logger.error("handleGetEmailConfirmLink: ", e.message);
  }
}

export function* handleUnBlockUser(
  action: ActionType<typeof actions.adminUnBlockUser>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, VERIFY_FORM);

  const res: Response = yield call(restService.send, {
    body: {
      id: action.payload.userId,
      pass_code: _get(formState, "pass_code.value")
    },
    service: "unblock_user",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(formActions.parseServerErrors(errors, VERIFY_FORM));
      return;
    }

    const index = _findIndex(state.user.users, { id: action.payload.userId });
    const { users } = state.user;

    if (index >= 0 && users[index]) {
      users[index].deletedAt = null;
      const email = users[index].email;
      if (email) {
        const suffix = email.substr(email.length - 4, 4);
        if (suffix === "-old") {
          users[index].email = email.replace("-old", "");
        }
      }
      yield put(actions.updateUsers([...users]));
    }

    action.payload.blockSuccessCb();
  } catch (e) {
    window.Logger.error("handleUnBlockUser: ", e.message);
  }
}

export function* handleBlockUser(
  action: ActionType<typeof actions.adminBlockUser>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, VERIFY_FORM);
  const is_rename_email =
    _get(formState, "is_rename_email.value") === "checked";

  const res: Response = yield call(restService.send, {
    body: {
      id: action.payload.userId,
      pass_code: _get(formState, "pass_code.value"),
      is_rename_email
    },
    service: "block_user",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(formActions.parseServerErrors(errors, VERIFY_FORM));
      return;
    }

    const index = _findIndex(state.user.users, { id: action.payload.userId });
    const { users } = state.user;

    if (index >= 0 && users[index]) {
      users[index].deletedAt = new Date().toDateString();
      if (is_rename_email) {
        users[index].email = `${users[index].email}-old`;
      }
      yield put(actions.updateUsers([...users]));
    }

    action.payload.blockSuccessCb();
  } catch (e) {
    window.Logger.error("handleBlockUser: ", e.message);
  }
}

export function* handleUpdateUserDetails(
  action: ActionType<typeof actions.adminUpdateUserDetails>
) {
  const { userId, email, phone } = action.payload;

  const res: Response = yield call(restService.send, {
    body: {
      user_id: userId,
      email,
      phone
    },
    service: "update_user_details",
    showGlobalLoader: true
  });

  try {
    const errors = _get(res, "errors", undefined);

    if (errors) {
      yield put(
        formActions.parseServerErrors(errors, ADMIN_UPDATE_ACCOUNT_DETAILS_FORM)
      );
      return;
    }
    if (!res) {
      return;
    }
    yield put(actions.fetchUsers());
    yield put(
      commonActions.closeModal(
        commonActions.ModalID.ADMIN_UPDATE_ACCOUNT_DETAILS_MODAL
      )
    );
  } catch (e) {
    window.Logger.error("handleUpdateUserDetails: ", e.message);
  }
}

export function* handleAdminListUserUpdateRequest(
  action: ActionType<typeof actions.adminListUserUpdateRequest>
) {
  yield put(
    actions.adminSetUserUpdateRequest({
      isFetchingUserUpdateRequest: true
    })
  );

  const query = {
    action_type: action.payload.actionType
  };

  const res: Response = yield call(restService.send, {
    query,
    params: {
      id: action.payload.userId
    },
    service: "admin_list_user_update_request"
  });

  if (!res) {
    yield put(
      actions.adminSetUserUpdateRequest({
        userUpdateRequests: [],
        isFetchingUserUpdateRequest: false
      })
    );
    return;
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data", []);

    yield put(
      actions.adminSetUserUpdateRequest({
        userUpdateRequests: data.map(row => ({
          id: row.id,
          userId: row.user_id,
          createdAt: row.created_at,
          createdBy: row.created_by,
          createdName: row.created_name,
          actionType: row.action_type,
          data: row.data,
          oldData: row.old_data
        })),
        isFetchingUserUpdateRequest: false
      })
    );
  } catch (e) {
    window.Logger.error("handleAdminListUserUpdateRequest: ", e.message);
  }
}
