import * as React from "react";
import ImageUtil from "src/ipm-shared/Utils/Images";

type IProps = {
  brandId: number;
};

const CardTypeIcon: React.SFC<IProps> = props => {
  const cardTypeIcon = ImageUtil.getCardTypeIcon(props.brandId);
  if (cardTypeIcon) {
    return <img className="card-icon" src={cardTypeIcon} alt="Card Icon" />;
  } else {
    return null;
  }
};

export default CardTypeIcon;
