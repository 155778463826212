import * as React from "react";
import { IBaseProps, IBaseState } from "./lib/Base";
import BaseControl from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType } from "../types";
import * as classNames from "classnames";

export type ICheckboxProps = IBaseProps & {
  defaultChecked?: boolean;
  id: string;
  presentedValue: string;
  singleSelect?: boolean;
  hidden?: boolean;
};
type ISelectState = IBaseState;

const mapStateToProps = (
  state: RootState,
  props: ICheckboxProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  appendControlValue: actions.appendControlValue,
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl,
  spliceControlValue: actions.spliceControlValue
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ICheckboxProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a checkbox.
 *
 * @base BaseControl.tsx
 */
class Checkbox extends BaseControl<IProps, ISelectState> {
  public static defaultProps = { ...BaseControl.defaultProps };

  public componentDidMount() {
    const { defaultChecked = false, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.setValue(defaultChecked, false);
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const { control, label, id } = this.props;

    if (control.notFound) {
      return null;
    }

    if (this.props.hidden) {
      return null;
    }

    if (this.props.labelOnly) {
      return <span>{label}</span>;
    }

    const isChecked: boolean = this.isSelected();

    return (
      <div
        className={classNames(
          "custom-control custom-checkbox ipm-custom-checkbox  ipm-custom-checkbox blue-checked",
          this.props.className
        )}
      >
        <input
          onChange={this.onChange}
          checked={isChecked}
          type="checkbox"
          className="custom-control-input"
          id={id}
          onClick={this.onChoose}
          disabled={this.props.disabled}
          required={this.props.required}
        />

        <label className="custom-control-label mb-0" htmlFor={id}>
          {label}
        </label>

        {this.renderErrorMessage(control)}

        <div className={"mb-2"} />
      </div>
    );
  }

  public toggle() {
    if (!this.props.disabled) {
      this.setValue(!this.isSelected());
    }
  }

  private onChoose = (e: React.ChangeEvent<any>) => {
    this.setValue(e.target.checked);
  };

  private doValidate = (
    values: string[],
    name: string,
    displayError: boolean = true
  ) => {
    const value = values.join(",");
    let it: any;
    let result: any;
    const errors: ControlErrorType[] = [];

    it = Checkbox.baseValidate(this.props, value);
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (this.props.onChangeCustom && this.alive) {
        this.props.onChangeCustom(values);
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };

  private setValue(checked: boolean, displayError = true) {
    const { name, form, group, presentedValue, singleSelect } = this.props;
    this.props.setControl({
      errors: [],
      form,
      group,
      name
    });

    if (checked) {
      if (singleSelect) {
        this.props.setControl({
          form,
          name,
          value: presentedValue
        });
        this.doValidate([presentedValue], name, displayError);
      } else {
        this.props.appendControlValue(
          name,
          presentedValue,
          (values: string[]) => {
            setTimeout(() => {
              this.doValidate(values, name, displayError);
            }, 0); // Set timeout to wait to dispatching finished completely
          }
        );
      }
    } else {
      if (singleSelect) {
        this.props.setControl({
          form,
          name,
          value: ""
        });
        this.doValidate([], name, displayError);
      } else {
        this.props.spliceControlValue(
          name,
          presentedValue,
          (values: string[]) => {
            setTimeout(() => {
              this.doValidate(values, name, displayError);
            }, 0); // Set timeout to wait to dispatching finished completely
          }
        );
      }
    }
  }

  private isSelected = (): boolean => {
    const { control, presentedValue } = this.props;

    if (control.value) {
      return (
        (control.value as string)
          .split(",")
          .indexOf(presentedValue.toString()) >= 0
      );
    }

    return false;
  };

  private onChange = () => {
    // Do nothing
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(Checkbox);
