import * as React from "react";
import { IProps } from "./index";
import ImageUtils from "src/ipm-shared/Utils/Images";

class GeneralShortCardInfo extends React.Component<IProps> {
  public render() {
    const { cardBrandId, last4 } = this.props;
    if (!cardBrandId) {
      return `Ending ${last4}`;
    }

    const icon = ImageUtils.getCardTypeInlineIcon(cardBrandId);

    return (
      <span className="d-flex">
        {icon && (
          <React.Fragment>
            <span className={`bank-icon icon-${cardBrandId}`}>
              <img src={icon} />
            </span>
            <span className="mx-1">- </span>
          </React.Fragment>
        )}
        <span>{last4}</span>
      </span>
    );
  }
}

export default GeneralShortCardInfo;
