import { format, isEqual, isWeekend } from "date-fns";

const PaymentUtil = {
  convertFrequencyString: (frequency: string) => {
    let result = "";
    switch (frequency) {
      case "7 days":
        result = "Weekly";
        break;
      case "1 mon":
        result = "Monthly";
        break;
      default:
        result = "Once";
    }
    return result;
  },

  payoutTimeBetween: (
    dateLeft: string,
    dateRight: string,
    exclusionDates: Date[]
  ) => {
    const from = new Date(format(new Date(dateLeft), "YYYY-MM-DD"));
    const to = new Date(format(new Date(dateRight), "YYYY-MM-DD"));
    let finalRangeDays: any[];

    const rangeDays = [];
    const d1 = from;
    while (d1 < to) {
      d1.setDate(d1.getDate() + 1);
      rangeDays.push(new Date(d1));
    }

    finalRangeDays = rangeDays
      .filter(
        d =>
          exclusionDates.filter(ex =>
            isEqual(
              format(new Date(ex), "YYYY-MM-DD"),
              format(new Date(d), "YYYY-MM-DD")
            )
          ).length === 0
      )
      .filter(d => !isWeekend(d));

    return finalRangeDays;
  },

  convertToSGD: (amount: number, rate: number): number => {
    return Math.floor(amount * rate);
  },

  isUSDPaymentInHK: (
    accountCountryId: number,
    currencyId: number,
    countryId?: number
  ) => {
    return (
      accountCountryId === 3 &&
      currencyId === 73 &&
      countryId !== undefined &&
      countryId === 3
    );
  }
};

export default PaymentUtil;
