import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";

const mapStateToProps = (state: RootState) => ({
  token: selectors.getToken(state)
});

const mapDispatchToProps = {
  fetchPaymentCollectionsActivityStatus:
    actions.fetchPaymentCollectionsActivityStatus
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
