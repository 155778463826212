import _isUndefined from "lodash-es/isUndefined";
import _includes from "lodash-es/includes";

export default {
  ratify(percentage: number): string {
    return (percentage / 100).toFixed(2);
  },

  amountStringToInt(amount: string | number): number {
    amount = amount.toString();
    const parts = amount.split(".");
    if (parts.length > 2) {
      throw new Error("Invalid amount.");
    }

    const [significant, maybeCents] = parts;
    let cents = maybeCents;
    if (_isUndefined(cents)) {
      cents = "00";
    }
    if (cents.length === 1) {
      cents = cents + "0";
    }
    if (cents.length > 2) {
      throw new Error("Cents is at most 2 dp.");
    }

    try {
      const n = Number(significant.split(",").join("") + cents);
      if (isNaN(n)) {
        throw new Error(`That's not a valid amount.`);
      }
      return n;
    } catch (e) {
      throw new Error(`That's not a valid amount.`);
    }
  },

  isValidAmount(amount: string): boolean {
    if (amount.length === 0) {
      return true; // Empty string is valid.
    }
    // if (amount.length < 2) {
    //   return false;
    // }

    const parts = amount.split(".");
    if (parts.length > 2) {
      return false; // Should only have 2 parts at most.
    }
    if (parts.length === 1) {
      // Significant digits only is ok, but check if comma usage is ok.
      return this.isValidCommaSeparation(parts[0]);
    }
    const cents = parts[1];
    if (cents.length > 2 || cents.length === 0) {
      return false;
    }
    const sig = parts[0];
    // Test if it's purely integer or has commas
    if (!sig.includes(",")) {
      return true; // Purely integers (significant portion) is always valid.
    }

    return this.isValidCommaSeparation(sig);
  },

  /**
   * A string without commas is always valid.
   */
  isValidCommaSeparation(significant: string): boolean {
    if (!_includes(significant, ",")) {
      return true;
    }
    if (significant[0] === ",") {
      return false;
    }
    // Else if commas are used, then test properly.
    let count = 1;
    const lastIdx = significant.length - 1;
    for (let i = lastIdx; i >= 0; i--) {
      if (i < lastIdx && count % 4 === 0 && significant[i] !== ",") {
        return false;
      }
      count++;
    }
    return true;
  },

  numberWithCommas(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatRate: (rate: number): string => {
    if (rate % 10 > 0) {
      return (rate / 100).toFixed(2);
    } else {
      return (rate / 100).toFixed(1);
    }
  },

  numberRoundDecimal(value: number, decimal: number): number {
    return Math.round(value * (decimal * 10) + Number.EPSILON) / (decimal * 10);
  },

  numberRoundAtMostTwoDecimal(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  },
  mobileMaxLengthByCountry(code: string): number {
    return {
      HK: 8,
      MY: 10,
      SG: 8
    }[code];
  }
};
