/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
// import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { default as escalationsReducer /*Card*/ } from "./reducers";
// import store from "src/ipm-shared/store";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
ReducerFactory.registerReducer({ escalations: escalationsReducer });

// const selectors = {};

// Use this to get available cards in wallet
export const getEscalations = (state: RootState) => {
  return state.escalations.escalations;
};

export const getEscalationsTotal = (state: RootState) =>
  state.escalations.total;

export const getSelectedId = (state: RootState) => state.escalations.selectedId;

export const getSelectedEscalationComments = (state: RootState) =>
  state.escalations.selectedEscalationComments;

export const getNewEscalationsCommentsCount = (state: RootState) =>
  state.escalations.newEscalationsCommentsCount;

export const shouldDisplayLoadingEscalations = (state: RootState) =>
  state.escalations.isFetching;

// export const getSelectedCard = (state: RootState) => state.cards.selectedId;

// export const shouldDisplayLoadingCards = (state: RootState) =>
//   state.cards.isFetching;

// export const cardsById = (state: RootState) => state.cards.byId;
