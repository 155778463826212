const t = {
  AMEX_ALLOW_PAYMENT: 15,
  CROSS_BORDER_PAYMENT: 7,
  EDIT_SCHEDULE: 8,
  EXPORT_CITIBANK_CBFT: 16,
  EXPORT_DAILY_PAYMENT: 13,
  EXPORT_DBS_PAYMENT: 12,
  EXPORT_HONGLEONG_XLS: 17,
  EXPORT_WEEKLY_STATEMENT: 19,
  FLASH_PAYMENT: 18,
  INSTANT_PAYMENT: 23,
  INVOICE_WORK_FLOW: 3,
  NORMAL_PAYMENT: 11,
  PAYEE_BULK_ADD: 1,
  PAYMENTS_FOR_APPROVAL: 21,
  RECONCILE_PAYMENT: 14,
  REFUND: 5,
  RENT_WORK_FLOW: 6,
  SALARY_BUSINESS_PAYMENT: 20,
  SALARY_WORK_FLOW: 2,
  SELF_TRANSFER_WORK_FLOW: 9,
  STATEMENT_DESCRIPTOR: 4,
  TESTER: 0,
  UPLOAD_SETTLEMENT_FILE: 22,
  WALLEX_FLOW: 10
};
export default t;
