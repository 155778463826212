export const AddLandLord = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeAdd/WizardRentAdd" /* webpackChunkName: "Modal_WizardRentAdd" */
  );
export const AddInvoicePayee = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeAdd/WizardInvoiceAdd" /* webpackChunkName: "Modal_WizardInvoiceAdd" */
  );
export const AddSalaryPayee = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeAdd/WizardSalaryAdd" /* webpackChunkName: "Modal_WizardSalaryAdd" */
  );
export const PayeeUploadCSV = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeBulkAdd/UploadCSV" /* webpackChunkName: "Modal_UploadCSV" */
  );
export const RequestLogin = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/RequestLoginModal" /* webpackChunkName: "Modal_RequestLoginModal" */
  );
export const RequestLoginPayFetch = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/RequestLoginPayFetchModal" /* webpackChunkName: "Modal_RequestLoginPayFetchModal" */
  );
export const PaymentWorkFlowInfo = () =>
  import(
    "src/ipm-platform/pages/Dashboard/Pay/MakePayment/InformModal" /* webpackChunkName: "Modal_InformModal" */
  );
export const PayeeDetail = () =>
  import(
    "src/ipm-platform/pages/AdminPayment/PayeeDetailModal" /* webpackChunkName: "Modal_PayeeDetailModal" */
  );
export const AdminPaymentDetail = () =>
  import(
    "./AdminPaymentDetail" /* webpackChunkName: "Modal_AdminPaymentDetail" */
  );
export const AdminPaymentSupportDocumentsDetail = () =>
  import(
    "./AdminPaymentDetail/SupportDocuments" /* webpackChunkName: "Modal_AdminPaymentDetail" */
  );
export const PayeeProcessCSV = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeBulkAdd/ProcessCSV" /* webpackChunkName: "Modal_ProcessCSV" */
  );
export const SuccessfulPayment = () =>
  import(
    "./SuccessFulPaymentModal" /* webpackChunkName: "Modal_SuccessFulPaymentModal" */
  );
export const SuccessfulScheduledPayment = () =>
  import(
    "./SuccessFulPaymentScheduleModal" /* webpackChunkName: "Modal_SuccessFulPaymentScheduleModal" */
  );
export const ErrorPayment = () =>
  import(
    "./ErrorPaymentModal" /* webpackChunkName: "Modal_ErrorPaymentModal" */
  );
export const AddCardForm = () =>
  import("./AddCardForm" /* webpackChunkName: "Modal_AddCardForm" */);
export const AddAccountRate = () =>
  import(
    "./AdminAddAccountRatesModal" /* webpackChunkName: "Modal_AdminAddAccountRatesModal" */
  );
export const ChangeAccountRate = () =>
  import(
    "./AdminChangeRatesModal" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );

export const ChangeAccountRateDetail = () =>
  import(
    "./AdminChangeRateDetailModal" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AdminViewMyInfo = () =>
  import(
    "./AdminViewMyInfo" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AdminViewListAccountRate = () =>
  import(
    "./AdminViewListAccountRate" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AdminViewFetchCDDQuestions = () =>
  import(
    "./AdminViewFetchCDDQuestions" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AdminViewCDDQuestions = () =>
  import(
    "./AdminViewCDDQuestions" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AdminViewAdditionalData = () =>
  import(
    "./AdminViewAdditionalData" /* webpackChunkName: "Modal_AdminChangeRatesModal" */
  );
export const AddCompany = () =>
  import("./AddCompanyModal" /* webpackChunkName: "Modal_AddCompanyModal" */);
export const MakePayment = () =>
  import("./MakePaymentModal" /* webpackChunkName: "Modal_MakePaymentModal" */);
export const Confirmation = () =>
  import(
    "./ConfirmationModal" /* webpackChunkName: "Modal_ConfirmationModal" */
  );
export const PaymentDetail = () =>
  import("./PaymentDetail" /* webpackChunkName: "Modal_PaymentDetail" */);
export const SelectAccountType = () =>
  import(
    "./SelectAccountTypeModal" /* webpackChunkName: "Modal_SelectAccountTypeModal" */
  );
export const NricIdPhotosUpload = () =>
  import(
    "./NricIdPhotosUploadModal" /* webpackChunkName: "Modal_NricIdPhotosUploadModal" */
  );
export const NricPhotos = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/NricPhotos" /* webpackChunkName: "Modal_NricPhotos" */
  );
export const IDVerificationPhoto = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/IDVerificationPhoto" /* webpackChunkName: "Modal_NricPhotos" */
  );
export const OneTime = () =>
  import("./OneTimeModal" /* webpackChunkName: "Modal_OneTimeModal" */);
export const MarketingVideo = () =>
  import(
    "./MarketingVideoModal" /* webpackChunkName: "Modal_MarketingVideoModal" */
  );
export const Refund = () =>
  import("./AdminRefund" /* webpackChunkName: "Modal_AdminRefund" */);
export const FullRefund = () =>
  import("./AdminRefund/FullRefund" /* webpackChunkName: "Modal_FullRefund" */);
export const PartialRefund = () =>
  import(
    "./AdminRefund/PartialRefund" /* webpackChunkName: "Modal_PartialRefund" */
  );
export const PartialRefundAdditional = () =>
  import(
    "./AdminRefund/PartialRefundAdditional" /* webpackChunkName: "Modal_PartialRefundAdditional" */
  );
export const PartialRefundSummary = () =>
  import(
    "./AdminRefund/PartialRefundSummary" /* webpackChunkName: "PartialRefundSummary" */
  );
export const PrincipalRefund = () =>
  import(
    "./AdminRefund/PrincipalRefund" /* webpackChunkName: "Modal_PrincipalRefund" */
  );
export const FeeRefund = () =>
  import("./AdminRefund/FeeRefund" /* webpackChunkName: "Modal_FeeRefund" */);
export const TwoDayAnnouncement = () =>
  import(
    "./TwoDayAnnouncementModal" /* webpackChunkName: "Modal_TwoDayAnnouncementModal" */
  );
export const Expedite = () =>
  import("./AdminExpedite" /* webpackChunkName: "Modal_AdminExpedite" */);
export const ReferralProgram = () =>
  import(
    "./ReferralProgramModal" /* webpackChunkName: "Modal_ReferralProgramModal" */
  );
export const NextDayPayout = () =>
  import(
    "./NextDayPayoutModal" /* webpackChunkName: "Modal_NextDayPayoutModal" */
  );
export const RecurringPaymentSuccessful = () =>
  import(
    "./RecurringPaymentSuccessful" /* webpackChunkName: "Modal_RecurringPaymentSuccessful" */
  );
export const PointsGuarantee = () =>
  import(
    "./PointsGuaranteeModal" /* webpackChunkName: "Modal_PointsGuaranteeModal" */
  );
export const TaxPaymentPromotion = () =>
  import(
    "./TaxPaymentPromotionModal" /* webpackChunkName: "Modal_TaxPaymentPromotionModal" */
  );
export const RentPromo = () =>
  import("./RentPromoModal" /* webpackChunkName: "Modal_RentPromoModal" */);
export const PromoCode = () =>
  import(
    "./AddPromoCodeModal" /* webpackChunkName: "Modal_AddPromoCodeModal" */
  );
export const CrossBorderAnnouncement = () =>
  import(
    "./CrossBorderAnnouncementModal" /* webpackChunkName: "Modal_CrossBorderAnnouncementModal" */
  );
export const AddCustomer = () =>
  import(
    "src/ipm-platform/pages/Customers/AddCustomer/Form" /* webpackChunkName: "Modal_AddCustomerForm" */
  );
export const RequestPaymentSuccessfully = () =>
  import(
    "./RequestPaymentSuccessfully" /* webpackChunkName: "Modal_OnBoarding" */
  );
export const CollectedPaymentFailed = () =>
  import(
    "./CollectedPaymentFailed" /* webpackChunkName: "Modal_CollectedPaymentFailed" */
  );
export const ResendPaymentRequest = () =>
  import(
    "./ResendPaymentRequest" /* webpackChunkName: "Modal_ResendPaymentRequest" */
  );
export const SuccessfullyCollectionPayment = () =>
  import(
    "./SuccessFulPaymentCollectionModal" /* webpackChunkName: "Modal_SuccessFulPaymentCollectionModal" */
  );
export const ScheduledPaymentDetail = () =>
  import(
    "src/ipm-platform/pages/MySchedules/ScheduledPaymentDetail" /* webpackChunkName: "Modal_ScheduledPaymentDetail" */
  );
export const RestrictedBusinessesConfirmModal = () =>
  import(
    "./RestrictedBusinessesConfirm" /* webpackChunkName: "Modal_RestrictedBusinessesConfirm" */
  );
export const EditPaymentDetailModal = () =>
  import(
    "src/ipm-platform/pages/MySchedules/EditPaymentDetail" /* webpackChunkName: "Modal_EditPaymentDetail" */
  );
export const KycStatusModal = () =>
  import("./KycStatusModal" /* webpackChunkName: "Modal_KycStatusModal" */);
export const ChooseCurrencyModal = () =>
  import(
    "./ChooseCurrencyModal" /* webpackChunkName: "Modal_ChooseCurrencyModal" */
  );
export const AddSelfTransferPayeeForm = () =>
  import(
    "src/ipm-platform/pages/WizardPayeeAdd/WizardTransferAdd" /* webpackChunkName: "Modal_WizardTransferAdd" */
  );
export const DoYouHaveCreditCardModal = () =>
  import(
    "./DoYouHaveCreditCard" /* webpackChunkName: "Modal_DoYouHaveCreditCard" */
  );
export const PromoPopupModal = () =>
  import("./PromoPopupModal" /* webpackChunkName: "Modal_PromoPopupModal" */);
export const ReviewDetailsViewModal = () =>
  import(
    "src/ipm-platform/pages/CustomerInvoiceDetails/ReviewDetailsModal" /* webpackChunkName: "Modal_ReviewDetailsModal" */
  );

export const OptimizeDashboardConfirmModal = () =>
  import("src/ipm-platform/shared/modals//OptimizeDashboardConfirmModal");

// PAYMENT COLLECTION
export const CollectionInvoiceNumberModal = () =>
  import("src/ipm-platform/pages/BePaid/InvoiceCreation/InvoiceNumber");
export const CollectionInvoiceEditFeesModal = () =>
  import("src/ipm-platform/pages/BePaid/InvoiceCreation/EditFees");
export const CollectionInvoiceCreateTaxModal = () =>
  import("src/ipm-platform/pages/BePaid/InvoiceCreation/AddTax");
export const CollectionInvoiceCreateItemModal = () =>
  import("src/ipm-platform/pages/BePaid/InvoiceCreation/AddItem");
export const BePaidMakePaymentModal = () =>
  import("src/ipm-platform/shared/modals//BePaidMakePaymentModal");
export const BePaidSmsNotify = () =>
  import("src/ipm-platform/shared/modals//BePaidSmsNotify");
export const BePaidRefund = () =>
  import("src/ipm-platform/shared/modals//BePaidRefund");
export const BePaidMarkPaymentAsPaid = () =>
  import("src/ipm-platform/shared/modals//BePaidMarkPaymentAsPaid");
export const InternationalRedirectKyc = () =>
  import("src/ipm-platform/shared/modals//InternationalRedirectKycModal");
export const InternationalKycReminder = () =>
  import("src/ipm-platform/shared/modals//InternationalKycReminderModal");
export const CreditProgramConfirmedModal = () =>
  import("src/ipm-platform/shared/modals//CreditProgramConfirmedModal");
export const CreditProgramConfirmModal = () =>
  import("src/ipm-platform/shared/modals//CreditProgramConfirmModal");
export const CreditProgramV1NotificationModal = () =>
  import("src/ipm-platform/shared/modals//CreditProgramV1NotificationModal");

// BE PAID SETTINGS
export const BePaidUpdateFeeSettingsConfirmationModal = () =>
  import(
    "src/ipm-platform/shared/modals//BePaidUpdateFeeSettingsConfirmationModal"
  );

// MY INFO VERIFICATION
export const MyInfoVerificationModal = () =>
  import("src/ipm-platform/pages/MyInfoVerification");
export const MyInfoVerificationRetrievedNRICModal = () =>
  import(
    "src/ipm-platform/pages/MyInfoVerification/MyInfoVerificationRetrievedNRIC"
  );
export const MyInfoVerificationErrorModal = () =>
  import("src/ipm-platform/pages/MyInfoVerification/MyInfoVerificationError");

// PAYMENT INTEREST
export const PaymentInterest = () =>
  import(
    "./PaymentInterestModal" /* webpackChunkName: "Modal_PaymentInterestModal" */
  );

// UEN
export const UENModal = () =>
  import("./AddUenModal" /* webpackChunkName: "Modal_AddUenModal" */);

// Upload Document Bepaid
export const UploadDocumentBepaidModal = () =>
  import(
    "./UploadDocumentBePaid" /* webpackChunkName: "Modal_UploadDocumentBePaid" */
  );

export const CardsPalModal = () => import("./CardsPalModal");

// Bepaid Unavailable
export const BePaidUnavailableModal = () =>
  import(
    "./BePaidUnavailableModal" /* webpackChunkName: "Modal_BePaidUnavailableModal" */
  );

// Admin Get Reset Link
export const AdminResetLinkModal = () =>
  import(
    "./AdminResetLinkModal" /* webpackChunkName: "Modal_AdminResetLinkModal" */
  );

// Admin get email confirm link
export const AdminEmailConfirmLinkModal = () =>
  import(
    "./AdminEmailConfirmLinkModal" /* webpackChunkName: "Modal_AdminEmailConfirmLinkModal" */
  );

// Admin update account type
export const UpdateAccountTypeModel = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/UpdateAccountTypeModel" /* webpackChunkName: "Modal_UpdateAccountTypeModal" */
  );

// Admin update account detais
export const UpdateAccountDetailsModel = () =>
  import(
    "src/ipm-platform/pages/AdminUsers/UpdateAccountDetailsModel" /* webpackChunkName: "Modal_UpdateAccountDetailsModel" */
  );

// Admin escalation
export const AdminEscalationModal = () =>
  import(
    "./AdminEscalationModal" /* webpackChunkName: "Modal_AdminEscalationModal" */
  );

// Admin escalation comments
export const AdminEscalationCommentsModal = () =>
  import(
    "./AdminEscalationCommentsModal" /* webpackChunkName: "Modal_AdminEscalationCommentsModal" */
  );

// Information
export const InformationModal = () =>
  import("./InformationModal" /* webpackChunkName: "Modal_InformationModal" */);

// Block User
export const AdminBlockUserModal = () =>
  import(
    "./AdminBlockUserModal" /* webpackChunkName: "Modal_AdminBlockUserModal" */
  );

// RecollectCVVModal
export const RecollectCVVModal = () =>
  import(
    "./RecollectCVVModal" /* webpackChunkName: "Modal_RecollectCVVModal" */
  );

export const AdminFlagViewModal = () =>
  import(
    "src/ipm-platform/pages/AdminScreening/FlagViewModal" /* webpackChunkName: "Modal_AdminFlagViewModal" */
  );

export const UploadSettlementFileModal = () =>
  import(
    "src/ipm-platform/pages/AdminPayment/UploadSettlementFileModal" /* webpackChunkName: "Modal_AdminFlagViewModal" */
  );

// Referral
export const AdminReferralDetailModal = () =>
  import(
    "./AdminReferralDetailModal" /* webpackChunkName: "Modal_AdminReferralDetailModal" */
  );
