import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "../../selectors";
import * as actions from "../../actions";

const mapStateToProps = (state: RootState) => ({
  countries: selectors.getAllCountries(state),
  currentCountry: selectors.getCurrentCountry(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  isCrossBorder: selectors.isCrossBorder(state),
  isDisplayCrossBorderNewIcon: selectors.isDisplayCrossBorderNewIcon(state),
  wallexKycStatus: selectors.getWallexKycStatus(state)
});

const mapDispatchToProps = {
  changeUserSetting: actions.changeUserSetting,
  exchangeToken: actions.exchangeToken,
  setControl: actions.setControl,
  setCurrentCountry: actions.setCurrentCountry,
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
