import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type ExtraCustomer = CustomerType & {
  uid: string;
};

export type CustomerType = {
  addressLine1?: string;
  addressLine2?: string;
  id: number;
  name: string;
  email?: string;
  invoiceNumber?: string;
  files?: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;
  dueDate?: string;
  amountDue?: number;
  registrationNumber: string;
  mobileCountryId?: string;
  companyName?: string;
  mobileNumber?: string;
};

export type CustomerState = {
  readonly editedId: number;
  readonly customers: CustomerType[];
  readonly isFetching: boolean;
  readonly selectedCustomerIds: number[];
  readonly extraCustomers: {
    [customerId: number]: {
      [uid: string]: ExtraCustomer;
    };
  };
  readonly total: number;
};

const defaultState: CustomerState = {
  customers: [],
  editedId: -1,
  extraCustomers: {},
  isFetching: false,
  selectedCustomerIds: [],
  total: 0
};

export default (state: CustomerState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setCustomers):
      let customers = state.customers;
      if (action.payload.customers) {
        customers = action.payload.customers.reduce((r: any, customer) => {
          r[customer.id] = {
            ...customer
          };
          return r;
        }, {});
      }

      return {
        ...state,
        customers,
        isFetching: action.payload.isFetching,
        total: action.payload.total
      };
    case getType(actions.selectCustomers):
      return {
        ...state,
        selectedCustomerIds: action.payload
      };

    case getType(actions.editCustomerId):
      return {
        ...state,
        editedId: action.payload.id
      };
    case getType(actions.updateExtraCustomer):
      return {
        ...state,
        extraCustomers: {
          ...state.extraCustomers,
          [action.payload.customerId]: {
            ...(state.extraCustomers[action.payload.customerId] || {}),
            [action.payload.uid]: action.payload.customer
          }
        }
      };

    case getType(actions.deleteExtraCustomersByCustomerId):
      delete state.extraCustomers[action.payload.id];
      return {
        ...state,
        extraPayees: {
          ...state.extraCustomers,
          [action.payload.id]: {
            ...state.extraCustomers[action.payload.id]
          }
        }
      };

    case getType(actions.deleteExtraCustomer):
      delete state.extraCustomers[action.payload.customerId][
        action.payload.uid
      ];
      return {
        ...state,
        extraCustomers: {
          ...state.extraCustomers,
          [action.payload.customerId]: {
            ...state.extraCustomers[action.payload.customerId]
          }
        }
      };
  }
  return state;
};
