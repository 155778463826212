import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _isIncludes from "lodash-es/includes";
import _groupBy from "lodash-es/groupBy";
import { ActionType, getType } from "typesafe-actions";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import { select, call, put, takeLatest } from "redux-saga/effects";
import { default as RestClient } from "src/ipm-shared/services/Rest";
import * as Images from "src/ipm-shared/components/Images";

import * as companyActions from "./actions";
import * as countryActions from "../Country/actions";
import { RootState } from "../reducers";
import * as commonActions from "../actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as authActions from "../Auth/actions";
import * as companySelectors from "./selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as guiSelectors from "src/ipm-shared/components/GlobalUI/selectors";
import { ADD_FORM } from "./const";
import { FORM } from "../Payee/const";

const selectors = {
  ...companySelectors,
  ...accountProfileSelectors,
  ...guiSelectors
};

const actions = {
  ...authActions,
  ...companyActions,
  ...commonActions,
  ...countryActions
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchCompanies, handleFetchCompanies),
  takeLatest(actions.fetchUEN, handleFetchUEN),
  takeLatest(actions.fetchCompaniesByUserId, handleFetchCompaniesByUserId),
  takeLatest(getType(actions.addCompanySubmit), handleAddCompany),
  takeLatest(getType(actions.editCompanySubmit), handleEditCompany),
  takeLatest(getType(actions.deleteCompany), handleDeleteCompany),
  takeLatest(getType(actions.hasCreditCard), handleHasCreditCard),
  reTryTakeLatest(actions.fetchSearchCompanyName, handleSearchCompanyName)
];
export default watchedSagas;

export function* handleFetchCompanies(
  action: ActionType<typeof actions.fetchCompanies>
) {
  const res = yield call(RestClient.send, {
    query: {
      detailed: action.payload.viewDetail ? "1" : undefined
    },
    service: "get_companies"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    const formErrors: string = _get(json, "errors.form") || [];

    if (_isIncludes(formErrors, "WRONG_ACCOUNT_TYPE_ERROR")) {
      yield put(
        actions.setCompanies({
          companies: [],
          isFetching: false
        })
      );
      return;
    }

    if (data.length === 0) {
      const state = yield select();
      const isModalOpened = selectors.isGivenModalOpened(
        state,
        actions.ModalID.ADD_COMPANY_FORM
      );

      if (!isModalOpened) {
        // Always display add-company modal in every pages.
        yield put(
          actions.toggleModal(
            actions.ModalID.ADD_COMPANY_FORM,
            {
              disallowCancel: true,
              guideDisplay: true
            },
            {
              backdrop: "static",
              keyboard: false
            }
          )
        );
      }

      return;
    }

    // no company has isCurrent = true => exchange token needed
    if (data.every(c => !c.is_current)) {
      yield put(
        actions.exchangeToken({
          companyId: _get(data, "0.id"),
          purpose: "switch_to_first_company"
        })
      );
      return;
    }

    const currentCompany = data.filter(c => c.is_current)[0];
    if (currentCompany) {
      yield call(
        [localStorage, "setItem"],
        "current_company",
        JSON.stringify({
          accountId: currentCompany.account_id,
          allowDelete: currentCompany.allow_delete,
          countryCode: currentCompany.country_code,
          hasCollectedAccount: currentCompany.has_collected_account,
          id: currentCompany.id,
          isCurrent: currentCompany.is_current,
          name: currentCompany.name,
          profileUrl: currentCompany.url || Images.logo,
          registrationNumber: currentCompany.registration_number
        })
      );
    }

    yield put(
      actions.setCompanies({
        companies: data.map(company => ({
          accountId: company.account_id,
          allowDelete: company.allow_delete,
          countryCode: company.country_code,
          hasCollectedAccount: company.has_collected_account,
          id: company.id,
          isCurrent: company.is_current,
          name: company.name,
          profileUrl: company.url || Images.logo,
          registrationNumber: company.registration_number
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchCompanies: ", e.message);
  }
}

export function* handleFetchUEN(action: ActionType<typeof actions.fetchUEN>) {
  const {
    uen,
    callback,
    registrationFieldName,
    checker,
    purposeId,
    formError,
    noError
  } = action.payload;

  const state: RootState = yield select();
  const countryId = selectors.getCurrentCountryId(state);
  const currencyId = selectors.getCurrentCurrencyId(state);
  const paymentPaidCurrencyId = selectors.getCurrentPaidCurrencyId(state);

  const res = yield call(RestClient.send, {
    query: {
      checker,
      country_id: countryId,
      currency_id: currencyId,
      id: action.payload.id,
      paid_currency_id: paymentPaidCurrencyId,
      purpose_id: purposeId || 0,
      uen
    },
    service: "post_uen"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const companyName = _get(res, "data.name", "");
  const predefinedUen = _get(res, "data.predefined_uen", []);
  const editable = _get(res, "data.editable", "");

  const predefinedUenGroup = _groupBy(predefinedUen, "account_number");

  yield put(
    actions.setPredefinedUen({
      predefinedUen: Object.values(predefinedUenGroup).map(key => ({
        accountNumber: key[0].account_number,
        bankName: key[0].bank_name,
        companyName: key.map(k => k.company_name)
      }))
    })
  );

  if (callback) {
    callback({ companyName, editable });
  }
  const errors = _get(res, "errors", {});

  if (noError === true) {
    // Do nothing
    return;
  }

  if (!_isEmpty(errors)) {
    if (formError) {
      // Want to display error to another form name
      yield put(
        formActions.parseServerErrors(errors, formError, {
          uen: registrationFieldName
        })
      );
    } else {
      yield put(
        formActions.parseServerErrors(errors, FORM, {
          uen: registrationFieldName
        })
      );
    }
  }
}

export function* handleAddCompany(
  action: ActionType<typeof actions.addCompanySubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);
  const name = _get(formState, "company_name.value");
  const regno = _get(formState, "registration_number.value");
  const companies = state.company.companies;

  const found = companies.find(company => company.name === name);
  if (found) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            company_name: ["COMPANY_EXISTED"]
          },
          form: []
        },
        ADD_FORM
      )
    );
    return;
  }

  if (regno.trim().length > 0) {
    yield put(actions.showGlobalLoader());

    const res = yield call(RestClient.send, {
      body: {
        country_code: _get(formState, "country_code.value"),
        name,
        registration_number: regno
      },
      service: "create_company"
    });

    yield put(actions.hideGlobalLoader());

    if (!res) {
      return;
    }

    const errors = _get(res, "errors", {});

    if (!_isEmpty(errors)) {
      yield put(
        formActions.parseServerErrors(errors, ADD_FORM, {
          name: "company_name"
        })
      );
      return;
    }

    const companyId = _get(res, "data.company_id", "");

    yield put(actions.closeModal(actions.ModalID.ADD_COMPANY_FORM));
    if (selectors.hasCompany(state)) {
      yield put(actions.fetchCompanies());
    } else {
      // First company was created. Then fetch token attached to this such company.
      yield put(
        actions.exchangeToken({
          companyId,
          preventDefault: true,
          purpose: "switch_to_new_created_company"
        })
      );

      // Payment interest confirmation
      yield put(actions.toggleModal(actions.ModalID.PAYMENT_INTEREST_MODAL));
    }
  } else {
    return;
  }
}

export function* handleEditCompany(
  action: ActionType<typeof actions.editCompanySubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);
  const companies = state.company.companies;
  const name = _get(formState, "company_name.value");
  const regno = _get(formState, "registration_number.value");
  const found = companies.find(company => company.name === name);
  if (found) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            company_name: ["COMPANY_EXISTED"]
          },
          form: []
        },
        ADD_FORM
      )
    );
    return;
  }
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      country_code: _get(formState, "country_code.value"),
      name,
      registration_number: regno
    },
    params: {
      id: _get(formState, "id.value")
    },
    service: "edit_company"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(
      formActions.parseServerErrors(errors, ADD_FORM, {
        name: "company_name"
      })
    );
    return;
  }

  yield put(actions.fetchCompanies());
  yield put(actions.closeModal(actions.ModalID.ADD_COMPANY_FORM));
}

export function* handleDeleteCompany(
  action: ActionType<typeof actions.deleteCompany>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    service: "delete_company"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  const token = _get(res, "data.token");

  if (!_isEmpty(errors)) {
    return;
  }

  if (token) {
    yield call([localStorage, "setItem"], "token", token);
    yield put(actions.setToken({ token }));

    window.location.reload();
  } else {
    yield put(actions.fetchCompanies());
  }
}

export function* handleHasCreditCard(
  action: ActionType<typeof actions.hasCreditCard>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);

  let value = action.payload.value;
  if (value === undefined) {
    value = _get(formState, "has_credit_card.value", "") === "yes";
  }

  const res = yield call(RestClient.send, {
    body: {
      has_credit_card: value
    },
    service: "has_credit_card"
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }
}

export function* handleSearchCompanyName(
  action: ActionType<typeof actions.fetchSearchCompanyName>
) {
  const res = yield call(RestClient.send, {
    query: {
      country_id: action.payload.arg.countryId,
      q: action.payload.arg.value,
      offset: action.payload.arg.offset,
      page_count: action.payload.arg.pageCount
    },
    service: "fetch_search_uen"
  });
  if (!res) {
    return;
  }
  const data: any[] = _get(res, "data", []);
  if (data.length === 0) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            uen_search_company: ["SEARCH_COMPANY_NO_RESULTS"]
          },
          form: []
        },
        ADD_FORM
      )
    );
  }
  if (action.payload.callback) {
    action.payload.callback(data);
  }
  return;
}

export function* handleFetchCompaniesByUserId(
  action: ActionType<typeof actions.fetchCompaniesByUserId>
) {
  const res = yield call(RestClient.send, {
    query: {
      user_id: action.payload.userId
    },
    service: "admin_get_companies"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    yield put(
      actions.setCompanies({
        companies: data
          .filter(el => el?.name)
          .map(company => ({
            accountId: company.account_id,
            id: company.id,
            name: company.name
          })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchCompaniesByUserId: ", e.message);
  }
}
