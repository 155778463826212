import * as React from "react";
import { IProps } from "./index";

interface IState {
  isTogglePayDetail: boolean;
  isResentRequest: boolean;
  collapse: boolean;
}

class GlobalNotificationView extends React.Component<IProps, IState> {
  private ws: WebSocket | null;
  private retry = 0;
  private intervalTimer: number;

  public componentDidMount() {
    // this.initWs();
    this.intervalTimer = window.setInterval(() => {
      if (window.location.pathname == "/") {
        this.props.fetchPaymentCollectionsActivityStatus();
      }
    }, 30000);
  }

  public componentWillUnmount() {
    if (this.intervalTimer) {
      window.clearInterval(this.intervalTimer);
    }
    if (this.ws) {
      this.ws.close();
    }
  }

  public render() {
    return null;
  }

  private initWs = () => {
    if (process.env.WS_ENDPOINT === "") {
      return;
    }
    const token = this.props.token;

    if (this.ws != null) {
      return;
    }
    this.ws = new WebSocket(`${process.env.WS_ENDPOINT}/ws/v1/notifier/`);

    this.ws.onopen = evt => {
      this.ws?.send(
        JSON.stringify({
          action: "auth",
          data: token
        })
      );
      console.log("WS open");
    };

    this.ws.onclose = evt => {
      this.ws = null;
      if (evt.code === 1006) {
        // unexpected close
        setTimeout(() => {
          this.retry++;
          if (this.retry < 3) {
            this.initWs();
          }
        }, 2000);
      }
    };
    this.ws.onmessage = evt => {
      if (evt.data === "ASKED_TO_FETCH_NOTIFICATION") {
        this.props.fetchPaymentCollectionsActivityStatus();
      }
    };
    this.ws.onerror = evt => {
      console.error(evt);
      setTimeout(() => {
        this.ws = null;
        this.retry++;
        if (this.retry < 3) {
          this.initWs();
        }
      }, 2000);
    };
  };
}

export default GlobalNotificationView;
