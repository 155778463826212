/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import {
  MapCountryPayments,
  MapReconcileSummaryType,
  MapReconcileSummaryWPType,
  ReconcilePaymentType,
  MapCountryPayoutPayments,
  MapReconcilePayoutSummaryType,
  ReconcileBankType
} from "./reducer";

export const reconcileRequest = createAction(
  "AdminReconciliations/reconcileRequest"
);

export const reconcileSuccess = createAction(
  "AdminReconciliations/reconcileSuccess",
  resolve => (
    isReconcilePayments: boolean,
    isReconcileWPPayments: boolean,
    reconcilePayments: MapCountryPayments,
    summaryRegionPayments: MapReconcileSummaryType,
    summaryWPRegionPayments: MapReconcileSummaryWPType,
    dataFromPaymentServiceProvider: ReconcilePaymentType[],
    dataIPAYMY: ReconcilePaymentType[]
  ) =>
    resolve({
      isReconcilePayments,
      isReconcileWPPayments,
      reconcilePayments,
      summaryRegionPayments,
      summaryWPRegionPayments,
      dataFromPaymentServiceProvider,
      dataIPAYMY
    })
);

export const reconcilePayoutRequest = createAction(
  "AdminReconciliations/reconcilePayoutRequest"
);

export const getReconcilePayoutRequest = createAction(
  "AdminReconciliations/getReconcilePayoutRequest"
);

export const reconcilePayoutSuccess = createAction(
  "AdminReconciliations/reconcilePayoutSuccess",
  resolve => (
    isReconcilePayoutPayments: boolean,
    reconcilePayoutPayments: MapCountryPayoutPayments,
    summaryRegionPayoutPayments: MapReconcilePayoutSummaryType
  ) =>
    resolve({
      isReconcilePayoutPayments,
      reconcilePayoutPayments,
      summaryRegionPayoutPayments
    })
);

export const reconcileBankRequest = createAction(
  "AdminReconciliations/reconcileBankRequest"
);

export const reconcileBankSuccess = createAction(
  "AdminReconciliations/reconcileBankSuccess",
  resolve => (reconcileBank: ReconcileBankType) =>
    resolve({
      reconcileBank
    })
);

export const addReconcileBankRequest = createAction(
  "AdminReconciliations/addReconcileBankRequest"
);

export const addReconcileBankCommentRequest = createAction(
  "AdminReconciliations/addReconcileBankCommentRequest"
);
