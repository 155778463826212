import React, { useContext, useState } from "react";
import * as Images from "../Images";
import { connect } from "react-redux";
import Tooltip from "reactstrap/lib/Tooltip";

import { LanguageContext } from "src/ipm-shared/Contexts";

import { ModalID } from "../GlobalUI/actions";
import * as actions from "src/ipm-platform/pages/Login/actions";
import T from "src/ipm-shared/Utils/Intl";

interface IGuestFooter {
  toggleModal: (name: number) => any;
}

function GuestFooter(props: IGuestFooter) {
  const [isOpenLanguageSwitcher, setShowLanguageSwitcher] = useState(false);

  const {
    changeLanguage: handleChangeLanguage,
    currentLanguageValue,
    languageOptions
  } = useContext(LanguageContext);

  const triggerVideo = () => {
    props.toggleModal(ModalID.MARKETING_VIDEO_MODAL);
  };

  const toggleShowLanguageSwitcher = () => {
    setShowLanguageSwitcher(!isOpenLanguageSwitcher);
  };

  const changeLanguage = (lang: string) => () => {
    handleChangeLanguage(lang);
  };

  return (
    <>
      <div className="learn-more-ipaymy">
        <a
          href="javascript:void(0)"
          className="learn-more-ipaymy-link mr-5 pr-3"
          id="LanguageSwitcher"
        >
          <img
            src={Images.earth}
            alt="Arrow circle"
            style={{
              height: 38
            }}
          />
          <span>{currentLanguageValue.label}</span>

          <Tooltip
            isOpen={isOpenLanguageSwitcher}
            autohide={false}
            target="LanguageSwitcher"
            toggle={toggleShowLanguageSwitcher}
            className={"nav-properties-tooltip switcher-language-tooltip"}
          >
            <div className={"tooltip-dropdown"}>
              {languageOptions.map(languageOption => (
                <div key={languageOption.value}>
                  <button onClick={changeLanguage(languageOption.value)}>
                    {languageOption.label}
                  </button>
                </div>
              ))}
            </div>
          </Tooltip>
        </a>

        <a
          href="javascript:void(0)"
          onClick={triggerVideo}
          className="learn-more-ipaymy-link"
        >
          <img src={Images.arrow_circle} alt="Arrow circle" />
          <span>{T.transl("IPAYMY_LEARN_MORE_LINK")}</span>
        </a>
        <img className="pci-logo" src={Images.pci} alt="pci logo" />
      </div>
    </>
  );
}

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export default connect(null, mapDispatchToProps)(GuestFooter);
