import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _uniq from "lodash-es/uniq";
import { ActionType, getType } from "typesafe-actions";
import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import * as payeeActions from "./actions";
import * as commonActions from "src/ipm-shared/store/model/actions";
import RestClient from "src/ipm-shared/services/Rest";
import {
  BULK_ADD_FORM,
  FORM,
  INTERNATIONAL_FORM,
  NOTE_TO_SUPPLIER_FORM,
  PAYEES_PAGE_COUNT
} from "./const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import utils from "src/ipm-shared/Utils/Number";
import { RootState } from "../reducers";
import * as payeeSelectors from "./selectors";
import * as bankActions from "src/ipm-shared/store/model/Bank/actions";
import * as countrySelectors from "../Country/selectors";
import * as currencySelectors from "../Currency/selectors";
import { getBanksMap, mapIdToSwiftCode } from "../Bank/selectors";
import * as legacyFormSelectors from "../LegacyForms/selectors";
import * as commonSelectors from "../selectors";
import { getCurrentAccountProfile } from "../selectors";
import * as accountProfileSelectors from "../AccountProfile/selectors";
import { InvoicePayee, RentPayee, SalaryPayee } from "./reducers";
import { history } from "src/ipm-shared/store";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import * as queryString from "query-string";

const selectors = {
  ...payeeSelectors,
  ...countrySelectors,
  ...legacyFormSelectors,
  ...commonSelectors,
  ...accountProfileSelectors,
  ...currencySelectors
};

const actions = {
  ...commonActions,
  ...payeeActions,
  ...formActions,
  ...bankActions
};

const watchedSagas = [
  takeLatest(getType(actions.bulkAddPayeeSubmit), handleBulkAddPayee),
  takeLatest(getType(actions.addLandlordSubmit), handleAddLandlord),
  takeLatest(
    getType(actions.addSelfTransferSubmit),
    handleAddSelfTransferSubmit
  ),
  takeLatest(
    getType(actions.editSelfTransferSubmit),
    handleEditSelfTransferSubmit
  ),
  takeLatest(getType(actions.editLandlordSubmit), handleEditLandlordPayee),
  takeLatest(getType(actions.addSupplierSubmit), handleAddSupplier),
  takeLatest(getType(actions.editSupplierSubmit), handleEditSupplier),
  takeLatest(getType(actions.addEmployeeSubmit), handleAddEmployee),
  takeLatest(getType(actions.editEmployeeSubmit), handleEditEmployee),
  takeLatest(getType(actions.noteToSupplier), handleNoteToSupplier),
  takeLatest(getType(actions.checkPayee), handleCheckPayee),

  // Common use.
  reTryTakeLatest(actions.fetchPayees, handleFetchPayees),
  takeLatest(getType(actions.deletePayee), handleDeletePayee),
  takeLatest(getType(actions.hidePayee), handleHidePayee),
  takeEvery(
    getType(actions.checkValidNoteToRecipient),
    handleCheckValidNoteToRecipient
  )
];
export default watchedSagas;

export function* handleFetchPayees(
  action: ActionType<typeof actions.fetchPayees>
) {
  const qs = queryString.parse(window.location.search);
  let navigateOnEmptyPayee = true;

  // If user is on property page, they will not be navigated to add-page if no payee found
  // So use QueryString to ensure every other action (like delete) will not affect to this
  if (qs.navout === "n") {
    navigateOnEmptyPayee = false;
  }

  const state: RootState = yield select();
  let currencyCode: string | undefined = selectors.getCurrentCurrency(state);
  let countryCode: string | undefined = selectors.getCurrentCountry(state);
  const purpose = action.payload.type;

  if (purpose !== state.payees.selectedPurpose) {
    yield put(actions.resetSelectedPayees(purpose));
  }

  yield put(
    actions.setPayees({ isFetching: true, purpose, total: state.payees.total })
  );

  if (action.payload.getAllPayees || selectors.isHongKongAccount(state)) {
    currencyCode = undefined;
    countryCode = undefined;
  }

  const res: Response = yield call(RestClient.send, {
    query: {
      bank_country_code: purpose === "self_transfer" ? countryCode : undefined,
      country_code: purpose === "self_transfer" ? "SG" : countryCode,
      currency_code: currencyCode,
      offset: action.payload.offset,
      page_count: PAYEES_PAGE_COUNT,
      purpose,
      o_recipient_name: "asc"
    },
    service: "get_payees",
    showGlobalLoader: !!action.payload.showGlobalLoader
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data", []);
    const total: number = _get(json, "total", 0);

    switch (purpose) {
      case "rent":
        if (data.length === 0 && navigateOnEmptyPayee) {
          history.push("/wizard/add/rent");
          return;
        }

        yield put(
          actions.setPayees({
            isFetching: false,
            payees: data.map(payee => ({
              accountNumber: payee.account_number.toString().trim(),
              address: payee.data.address,
              bankBSBId: payee.data.bank_bsb_id,
              bankCode: payee.bank_code,
              bankId: payee.bank,
              bsbCode: payee.bsb_code,
              countryId:
                payee.country_id === null || payee.country_id === 0
                  ? payee.currency_id
                  : payee.country_id,
              currencyId: payee.currency_id,
              dashboardHidden: payee.dashboard_hidden,
              defaultAmount: payee.amount,
              defaultComments: payee.comments,
              files: payee.data.files,
              hasActiveScheduled: payee.has_active_schedule,
              id: payee.id,
              idNumber: payee.data.id_number,
              international: {
                aba: payee.data.aba,
                bankAccountHolderName: payee.data.bank_account_holder_name,
                bankCode: payee.data.bank_code,
                bankCountryId: payee.data.bank_country_id,
                bankRawName: payee.data.bank_raw_name,
                bankRawSwiftCode: payee.data.bank_raw_swift_code,
                // branchCode: payee.data.branch_code,
                bsbCode: payee.data.bsb_code,
                businessRegistrationNumber: payee.data.business_registration,
                city: payee.data.city,
                cnaps: payee.data.cnaps,
                companyName: payee.data.company_name,
                firstName: payee.data.first_name,
                iban: payee.data.iban,
                ifscCode: payee.data.ifsc_code,
                isBusiness: payee.data.is_business,
                lastName: payee.data.last_name,
                nationalIdentificationNumber:
                  payee.data.national_identification_number,
                region: payee.data.region,
                relationship: payee.data.relationship,
                sortCode: payee.data.sort_code,
                stateOrProvince: payee.data.state_or_province
              },
              ipaymyEvent: payee.ipaymy_event,
              isUnsupported: payee.is_unsupported,
              name: payee.name,
              order: payee.order,
              postalCode: payee.data.postal_code,
              refundedAmount: payee.refunded_amount,
              unitNumber: payee.data.unit_number,
              unsupportedMessage: payee.unsupported_message
            })) as RentPayee[],
            purpose,
            total
          })
        );
        break;

      case "salary":
        if (data.length === 0 && navigateOnEmptyPayee) {
          history.push("/wizard/add/salary");
          return;
        }

        yield put(
          actions.setPayees({
            isFetching: false,
            payees: data.map(payee => ({
              accountNumber: payee.account_number.toString().trim(),
              address: payee.data.address,
              bankBSBId: payee.data.bank_bsb_id,
              bankCode: payee.data.bank_code,
              bankId: payee.bank,
              bsbCode: payee.data.bsb_code,
              companyName: payee.data.company_name,
              countryId:
                payee.country_id === null || payee.country_id === 0
                  ? payee.currency_id
                  : payee.country_id,
              currencyId: payee.currency_id,
              dashboardHidden: payee.dashboard_hidden,
              defaultAmount: payee.amount,
              defaultComments: payee.comments,
              email: payee.data.recipient_email,
              files: payee.data.files,
              hasActiveScheduled: payee.has_active_schedule,
              id: payee.id,
              idNumber: payee.data.id_number,
              idType: payee.data.id_type,
              international: {
                aba: payee.data.aba,
                bankAccountHolderName: payee.data.bank_account_holder_name,
                bankCode: payee.data.bank_code,
                bankCountryId: payee.data.bank_country_id,
                bankRawName: payee.data.bank_raw_name,
                bankRawSwiftCode: payee.data.bank_raw_swift_code,
                // branchCode: payee.data.branch_code,
                bsbCode: payee.data.bsb_code,
                businessRegistrationNumber: payee.data.business_registration,
                city: payee.data.city,
                cnaps: payee.data.cnaps,
                companyName: payee.data.company_name,
                firstName: payee.data.first_name,
                iban: payee.data.iban,
                ifscCode: payee.data.ifsc_code,
                isBusiness: payee.data.is_business,
                lastName: payee.data.last_name,
                nationalIdentificationNumber:
                  payee.data.national_identification_number,
                region: payee.data.region,
                relationship: payee.data.relationship,
                sortCode: payee.data.sort_code,
                stateOrProvince: payee.data.state_or_province
              },
              ipaymyEvent: payee.ipaymy_event,
              isUnsupported: payee.is_unsupported,
              name: payee.name,
              order: payee.order,
              postalCode: payee.data.postal_code,
              refundedAmount: payee.refunded_amount,
              unitNumber: payee.data.unit_number,
              unsupportedMessage: payee.unsupported_message
            })) as SalaryPayee[],
            purpose,
            total
          })
        );
        break;

      case "invoice":
        if (data.length === 0 && navigateOnEmptyPayee) {
          history.push("/wizard/add/invoice");
          return;
        }

        yield put(
          actions.setPayees({
            isFetching: false,
            payees: data.map(payee => ({
              accountNumber: payee.account_number.toString().trim(),
              address: payee.data.address,
              bankBSBId: payee.data.bank_bsb_id,
              bankCode: payee.data.bank_code,
              bankId: payee.bank,
              bsbCode: payee.data.bsb_code,
              countryId:
                payee.country_id === null || payee.country_id === 0
                  ? payee.currency_id
                  : payee.country_id,
              currencyId: payee.currency_id,
              dashboardHidden: payee.dashboard_hidden,
              defaultAmount: payee.amount,
              defaultComments: payee.comments,
              editDisabled: payee.edit_disabled,
              email: payee.data.recipient_email,
              files: payee.data.files,
              hasActiveScheduled: payee.has_active_schedule,
              id: payee.id,
              international: {
                aba: payee.data.aba,
                bankAccountHolderName: payee.data.bank_account_holder_name,
                bankCode: payee.data.bank_code,
                bankCountryId: payee.data.bank_country_id,
                bankRawName: payee.data.bank_raw_name,
                bankRawSwiftCode: payee.data.bank_raw_swift_code,
                // branchCode: payee.data.branch_code,
                bsbCode: payee.data.bsb_code,
                businessRegistrationNumber: payee.data.registration_number,
                city: payee.data.city,
                cnaps: payee.data.cnaps,
                companyName: payee.data.company_name,
                firstName: payee.data.first_name,
                iban: payee.data.iban,
                ifscCode: payee.data.ifsc_code,
                isBusiness: payee.data.is_business,
                lastName: payee.data.last_name,
                nationalIdentificationNumber:
                  payee.data.national_identification_number,
                postalCode: payee.data.postal_code,
                region: payee.data.region,
                relationship: payee.data.relationship,
                sortCode: payee.data.sort_code,
                stateOrProvince: payee.data.state_or_province
              },
              ipaymyEvent: payee.ipaymy_event,
              isUnsupported: payee.is_unsupported,
              name: payee.name,
              order: payee.order,
              recipientContactName: payee.data.recipient_contact_name,
              recipientMobile: payee.data.recipient_mobile,
              refundedAmount: payee.refunded_amount,
              registrationNumber: payee.data.registration_number,
              shouldNotifyRecipientInitiated:
                payee.data.should_notify_recipient_initiated,
              supplierOptIn: payee.data.supplier_opt_in,
              unsupportedMessage: payee.unsupported_message
            })) as InvoicePayee[],
            purpose,
            total
          })
        );
        break;

      case "self_transfer":
        if (data.length === 0 && navigateOnEmptyPayee) {
          history.push("/wizard/add/self_transfer");
          return;
        }

        yield put(
          actions.setPayees({
            isFetching: false,
            payees: data.map(payee => ({
              accountNumber: payee.account_number.toString().trim(),
              bankBSBId: payee.bankBSBId,
              bankCode: payee.bankCode,
              bankId: payee.bank,
              bsbCode: payee.bsbCode,
              countryId:
                payee.country_id === null || payee.country_id === 0
                  ? payee.currency_id
                  : payee.country_id,
              currencyId: payee.currency_id,
              dashboardHidden: payee.dashboard_hidden,
              defaultAmount: payee.amount,
              defaultComments: payee.comments,
              editDisabled: payee.edit_disabled,
              email: payee.data.recipient_email,
              files: payee.data.files,
              hasActiveScheduled: payee.has_active_schedule,
              id: payee.id,
              international: {
                aba: payee.data.aba,
                bankAccountHolderName: payee.data.bank_account_holder_name,
                bankCode: payee.data.bank_code,
                bankCountryId: payee.data.bank_country_id,
                bankRawName: payee.data.bank_raw_name,
                bankRawSwiftCode: payee.data.bank_raw_swift_code,
                // branchCode: payee.data.branch_code,
                bsbCode: payee.data.bsb_code,
                businessRegistrationNumber: payee.data.registration_number,
                city: payee.data.city,
                cnaps: payee.data.cnaps,
                firstName: payee.data.first_name,
                iban: payee.data.iban,
                ifscCode: payee.data.ifsc_code,
                isBusiness: payee.data.is_business,
                lastName: payee.data.last_name,
                nationalIdentificationNumber:
                  payee.data.national_identification_number,
                region: payee.data.region,
                sortCode: payee.data.sort_code,
                stateOrProvince: payee.data.state_or_province
              },
              ipaymyEvent: payee.ipaymy_event,
              isUnsupported: payee.is_unsupported,
              name: payee.name,
              order: payee.order,
              recipientContactName: payee.data.recipient_contact_name,
              recipientMobile: payee.data.recipient_mobile,
              refundedAmount: payee.refunded_amount,
              registrationNumber:
                payee.data.registration_number || payee.data.id_number,
              shouldNotifyRecipientInitiated:
                payee.data.should_notify_recipient_initiated,
              supplierOptIn: payee.data.supplier_opt_in,
              unsupportedMessage: payee.unsupported_message
            })) as InvoicePayee[],
            purpose,
            total
          })
        );
        break;
    }

    if (action.payload.cb) {
      action.payload.cb(data);
    }
  } catch (e) {
    window.Logger.error("handleFetchPayees: ", e.message);
  }
}

export function* handleDeletePayee(
  action: ActionType<typeof actions.deletePayee>
) {
  const { id, purpose } = action.payload;
  const res = yield call(RestClient.send, {
    params: {
      id
    },
    service: "delete_payee"
  });

  const errors = _get(res, "errors", undefined);

  if (errors) {
    return;
  }

  // Remove this payee out of selected payees
  yield put(
    actions.selectPayees({ purpose, ids: [], removedIds: [action.payload.id] })
  );

  // Re-fetch payees
  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: purpose
    })
  );
}

export function* handleHidePayee(action: ActionType<typeof actions.hidePayee>) {
  const { id, purpose } = action.payload;
  const res = yield call(RestClient.send, {
    params: {
      id
    },
    service: "hide_payee"
  });

  const errors = _get(res, "errors", undefined);

  if (errors) {
    return;
  }

  // Remove this payee out of selected payees
  yield put(
    actions.selectPayees({
      ids: [],
      purpose,
      removedIds: [action.payload.id]
    })
  );

  // Re-fetch payees
  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: purpose
    })
  );
}

export function* handleAddLandlord(
  action: ActionType<typeof actions.addLandlordSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);
  const countryCode = selectors.getCurrentCountry(state);
  const isWallexPayment = selectors.isWallexPayment(state);
  const currentCurrency = selectors.getCurrentCurrency(state);
  const accountProfile = selectors.getCurrentAccountProfile(state);
  const { countryId } = accountProfile;

  const HKMultipleCurrencyId = _get(
    formState,
    "hk_payee_multiple_currency.value",
    undefined
  );
  let { currencyId, paymentPaidCurrencyId } = accountProfile;
  if (countryId === 3 && HKMultipleCurrencyId !== undefined) {
    currencyId = Number(HKMultipleCurrencyId);
    paymentPaidCurrencyId = Number(HKMultipleCurrencyId);
  }

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );

  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  const isBusiness = _get(formState, "is_business.value", "false") as string;
  const firstName = _get(formState, "first_name.value");
  const lastName = _get(formState, "last_name.value");

  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");

  const isUseBankId = isWallexPayment
    ? countryCode === "MY" || countryCode === "HK" || countryCode === "SG"
    : true;

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  let recipientName: string = _get(
    formState,
    "recipient_name.value",
    ""
  ) as string;

  if (isWallexPayment) {
    if (isBusiness === "true") {
      recipientName = _get(formState, "company_name.value", "") as string;
    } else {
      recipientName = firstName + " " + lastName;
    }
  } else {
    if (bankId > 0) {
      bankSwiftCode = bankById[bankId].swiftCode; // For local payment case.
    }
  }

  let amount;
  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        formActions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] },
          form: []
        },
        FORM
      )
    );

    return;
  }

  const relationship = _get(formState, "relationship.value");

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value")
        .toString()
        .toLocaleUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_code: _get(formState, "bank_code.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: countryCode,
      country_id: countryId,
      currency_code: currentCurrency,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: isBusiness === "true", // International
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "rent",
      recipient_name: recipientName,
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      relationship, // International
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      unit_number: _get(formState, "unit_number.value")
    },
    service: "create_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors) && errors !== 0) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }
  yield put(actions.removeForm(FORM));
  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "rent"
    })
  );

  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_RENT_PAYEE_FORM)
  );
}

export function* handleEditLandlordPayee(
  action: ActionType<typeof actions.editLandlordSubmit>
) {
  const state: RootState = yield select();
  const getCountriesById = selectors.getCountriesById(state);
  const getCurrenciesById = selectors.getCurrenciesById(state);
  const editingPayee = selectors.getEditedPayee(state, "rent");
  const { countryId, accountCountryCode } = selectors.getCurrentAccountProfile(
    state
  );
  const formState = formSelectors.getControls(state, FORM);
  const isWallexPayment =
    accountCountryCode === "SG" &&
    editingPayee.currencyId !== editingPayee.countryId;

  let amount;
  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        actions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      actions.parseServerErrors(
        { form: [], fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] } },
        FORM
      )
    );
    return;
  }

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );

  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  const isBusiness = _get(formState, "is_business.value", "false") as string;
  const firstName = _get(formState, "first_name.value");
  const lastName = _get(formState, "last_name.value");

  let recipientName: string = _get(
    formState,
    "recipient_name.value",
    ""
  ) as string;

  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");

  const isUseBankId = isWallexPayment
    ? editingPayee.countryId === 1 ||
      editingPayee.countryId === 2 ||
      editingPayee.countryId === 3
    : true;

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  if (isWallexPayment) {
    if (isBusiness === "true") {
      recipientName = _get(formState, "company_name.value", "") as string;
    } else {
      recipientName = firstName + " " + lastName;
    }
  } else {
    bankSwiftCode = bankById[bankId].swiftCode;
  }

  const HKMultipleCurrencyId = _get(
    formState,
    "hk_payee_multiple_currency.value",
    undefined
  );

  let paymentPaidCurrencyId = selectors.getCurrentPaidCurrencyId(state);
  let currencyId = editingPayee.currencyId;

  if (countryId === 3 && HKMultipleCurrencyId !== undefined) {
    currencyId = Number(HKMultipleCurrencyId);
    paymentPaidCurrencyId = Number(HKMultipleCurrencyId);
  }

  const relationship = _get(formState, "relationship.value");

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value")
        .toString()
        .toUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_code: _get(formState, "bank_code.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName, // International
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: getCountriesById[editingPayee.countryId].code.toUpperCase(),
      country_id: editingPayee.countryId,
      currency_code: getCurrenciesById[currencyId].code,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: isBusiness === "true", // International
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "rent",
      recipient_name: recipientName,
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      relationship, // International
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      unit_number: _get(formState, "unit_number.value")
    },
    params: {
      id: _get(formState, "payee_id.value")
    },
    service: "update_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }
  yield put(actions.removeForm(FORM));

  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "rent"
    })
  );
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_RENT_PAYEE_FORM)
  );
}

export function* handleAddSupplier(
  action: ActionType<typeof actions.addSupplierSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);
  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  const defaultAmount = 0;

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );
  const countryCode = selectors.getCurrentCountry(state);
  const isWallexPayment = selectors.isWallexPayment(state);

  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  const supplierOptIn = _get(formState, "supplier_opt_in.value", false);

  let bankRawName = _get(formState, "bank_raw_name.value", "");
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");

  const isUseBankId =
    countryCode === "MY" || countryCode === "HK" || countryCode === "SG";

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  if (!isWallexPayment) {
    if (bankId > 0) {
      bankSwiftCode = bankById[bankId].swiftCode;
    }
  }

  const accountProfile = selectors.getCurrentAccountProfile(state);
  const { countryId, currencyId, paymentPaidCurrencyId } = accountProfile;

  // Relationship (only PH)
  const relationship = _get(formState, "relationship.value");

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value")
        .toString()
        .toUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_code: _get(formState, "bank_code.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName, // International
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: countryCode,
      country_id: countryId,
      currency_id: currencyId,
      default_amount: defaultAmount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: true,
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "invoice",
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      unit_number: _get(formState, "unit_number.value"),

      currency_code: selectors.getCurrentCurrency(state),
      recipient_contact_name: _get(formState, "recipient_contact_name.value"),
      recipient_email: _get(formState, "recipient_email.value"),
      recipient_mobile: _get(formState, "recipient_mobile.value"),
      recipient_name: isWallexPayment
        ? _get(formState, "company_name.value")
        : _get(formState, "recipient_name.value"), // Supplier Company name
      relationship, // International
      should_notify_recipient_initiated:
        _get(formState, "should_notify_recipient_initiated.value") ===
        "invoice_notify",
      supplier_opt_in: supplierOptIn === "checked"
    },
    service: "create_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }

  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "invoice"
    })
  );
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_INVOICE_PAYEE_FORM)
  );
}

export function* handleEditSupplier(
  action: ActionType<typeof actions.editSupplierSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);
  const getCountriesById = selectors.getCountriesById(state);
  const getCurrenciesById = selectors.getCurrenciesById(state);
  const editingPayee = selectors.getEditedPayee(state, "invoice");
  const isWallexPayment = editingPayee.currencyId !== editingPayee.countryId;

  const amount = 0;
  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );
  const bankById = getBanksMap(state);

  const bankId = _get(formState, "bank_id.value", 0);
  const supplierOptIn = _get(formState, "supplier_opt_in.value", false);
  let bankRawName = _get(formState, "bank_raw_name.value", "undefined"); // International
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");
  const currencyId = editingPayee.currencyId;
  const paymentPaidCurrencyId = selectors.getCurrentPaidCurrencyId(state);

  const isUseBankId =
    editingPayee.countryId === 1 ||
    editingPayee.countryId === 2 ||
    editingPayee.countryId === 3; // SG - MY - HK

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  if (!isWallexPayment) {
    if (bankId > 0) {
      bankSwiftCode = bankById[bankId].swiftCode;
    }
  }

  // Relationship (only PH)
  const relationship = _get(formState, "relationship.value");

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value")
        .toString()
        .toUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_code: _get(formState, "bank_code.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: getCountriesById[editingPayee.countryId].code.toUpperCase(),
      country_id: editingPayee.countryId,
      currency_code: getCurrenciesById[currencyId].code,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: true,
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "invoice",
      recipient_contact_name: _get(formState, "recipient_contact_name.value"),
      recipient_email: _get(formState, "recipient_email.value"),
      recipient_mobile: _get(formState, "recipient_mobile.value"),
      recipient_name: isWallexPayment
        ? _get(formState, "company_name.value")
        : _get(formState, "recipient_name.value"), // Supplier Company name
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      relationship, // International
      should_notify_recipient_initiated:
        _get(formState, "should_notify_recipient_initiated.value") ===
        "invoice_notify",
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supplier_opt_in: supplierOptIn === "checked",
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      unit_number: _get(formState, "unit_number.value")
    },
    params: {
      id: _get(formState, "payee_id.value")
    },
    service: "update_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const json = res;
  const errors = _get(json, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }

  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "invoice"
    })
  );
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_INVOICE_PAYEE_FORM)
  );
}

export function* handleAddEmployee(
  action: ActionType<typeof actions.addEmployeeSubmit>
) {
  const state: RootState = yield select();
  const bankById = getBanksMap(state);
  const formState = formSelectors.getControls(state, FORM);
  const isWallexPayment = selectors.isWallexPayment(state);
  const accountProfile = selectors.getCurrentAccountProfile(state);
  const countryCode = selectors.getCurrentCountry(state);

  const bankId = _get(formState, "bank_id.value", 0);
  const { countryId } = accountProfile;
  let { currencyId, paymentPaidCurrencyId } = accountProfile;
  const currentCurrency = selectors.getCurrentCurrency(state);
  const firstName = _get(formState, "first_name.value");
  const lastName = _get(formState, "last_name.value");
  const HKMultipleCurrencyId = _get(
    formState,
    "hk_payee_multiple_currency.value",
    undefined
  );
  const isUseBankId = isWallexPayment
    ? countryCode === "MY" || countryCode === "HK" || countryCode === "SG"
    : true;
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");
  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  let recipientName: string = _get(
    formState,
    "recipient_name.value",
    ""
  ) as string;
  let identificationType = "nricfin"; // country: sg

  if (countryId === 3 && HKMultipleCurrencyId !== undefined) {
    // Is HongKong account
    currencyId = Number(HKMultipleCurrencyId);
    paymentPaidCurrencyId = Number(HKMultipleCurrencyId);
  }

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  if (isWallexPayment) {
    recipientName = firstName + " " + lastName;
  } else {
    if (bankId > 0) {
      bankSwiftCode = bankById[bankId].swiftCode; // For local payment case.
    }
  }

  if (accountProfile.countryCode !== "SG") {
    identificationType = "mykad";
  }

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );

  let amount;

  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        formActions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] },
          form: []
        },
        FORM
      )
    );
    return;
  }

  const relationship = _get(formState, "relationship.value");

  const res: Response = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value", "")
        .toString()
        .toLocaleUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: countryCode,
      country_id: countryId,
      currency_code: currentCurrency,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      identification_number: _get(formState, "identification_number.value"),
      identification_type: identificationType,
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: false,
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "salary",
      recipient_name: recipientName,
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      relationship, // International
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],

      unit_number: _get(formState, "unit_number.value")
    },
    service: "create_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const json = res;
  const errors = _get(json, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }
  yield put(actions.removeForm(FORM));

  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "salary"
    })
  );
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_SALARY_PAYEE_FORM)
  );
}

export function* handleEditEmployee(
  action: ActionType<typeof actions.editEmployeeSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);
  const getCountriesById = selectors.getCountriesById(state);
  const getCurrenciesById = selectors.getCurrenciesById(state);
  const editingPayee = selectors.getEditedPayee(state, "salary");
  const countryId = selectors.getCurrentCountryId(state);
  const accountCountryCode = selectors.getAccountCountryCode(state);
  let paymentPaidCurrencyId = selectors.getCurrentPaidCurrencyId();
  const isWallexPayment =
    accountCountryCode === "SG" &&
    editingPayee.currencyId !== editingPayee.countryId;

  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  const firstName = _get(formState, "first_name.value");
  const lastName = _get(formState, "last_name.value");

  const isUseBankId = isWallexPayment
    ? editingPayee.countryId === 1 ||
      editingPayee.countryId === 2 ||
      editingPayee.countryId === 3
    : true;

  const HKMultipleCurrencyId = _get(
    formState,
    "hk_payee_multiple_currency.value",
    undefined
  );
  let bankSwiftCode = _get(formState, "bank_swift_code.value", "");
  let recipientName: string = _get(
    formState,
    "recipient_name.value",
    ""
  ) as string;
  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  if (isWallexPayment) {
    recipientName = firstName + " " + lastName;
  } else {
    if (bankId > 0) {
      bankSwiftCode = bankById[bankId].swiftCode; // For local payment case.
    }
  }
  let currencyId = editingPayee.currencyId;
  if (countryId === 3 && HKMultipleCurrencyId !== undefined) {
    // Is HongKong account
    currencyId = Number(HKMultipleCurrencyId);
    paymentPaidCurrencyId = Number(HKMultipleCurrencyId);
  }

  let amount;
  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        formActions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      formActions.parseServerErrors(
        { form: [], fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] } },
        FORM
      )
    );
    return;
  }

  let identificationType = "nricfin"; // country: sg

  if (editingPayee.countryCode !== "SG") {
    identificationType = "mykad";
  }

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );

  // Relationship (only PH)
  const relationship = _get(formState, "relationship.value");

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined), // International
      account_number: _get(formState, "account_number.value", "")
        .toString()
        .toLocaleUpperCase(),
      address: _get(formState, "address.value"),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        undefined
      ), // International
      bank_bsb_id: _get(formState, "bank_bsb_id.value", undefined),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined), // International
      bsb_code: _get(formState, "bsb_code.value", undefined), // International
      city: _get(formState, "city.value", undefined), // International
      cnaps: _get(formState, "cnaps.value", undefined), // International
      company_name: _get(formState, "company_name.value", undefined), // International,
      country_code: getCountriesById[editingPayee.countryId].code.toUpperCase(),
      country_id: editingPayee.countryId,
      currency_code: getCurrenciesById[currencyId].code,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value"),
      email: _get(getCurrentAccountProfile(state), "email"),
      first_name: _get(formState, "first_name.value", undefined), // International - Personal required
      iban: _get(formState, "iban.value", undefined), // International
      identification_number: _get(formState, "identification_number.value"),
      identification_type: identificationType,
      ifsc_code: _get(formState, "ifsc_code.value", undefined), // International
      is_business: false,
      last_name: _get(formState, "last_name.value", undefined), // International - Personal required
      national_identification_number: _get(
        formState,
        "national_identification_number.value",
        undefined
      ), // International
      paid_currency_id: paymentPaidCurrencyId,
      postal_code: _get(formState, "postal_code.value"),
      purpose: "salary",
      recipient_name: recipientName,
      region: _get(formState, "region.value", undefined), // International
      registration_number: _get(
        formState,
        "registration_number.value",
        undefined
      ), // International,
      relationship, // International
      sort_code: _get(formState, "sort_code.value", undefined), // International
      state_or_province: _get(formState, "state_or_province.value", undefined), // International
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],

      unit_number: _get(formState, "unit_number.value")
    },
    params: {
      id: _get(formState, "payee_id.value")
    },
    service: "update_payee",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const json = res;
  const errors = _get(json, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }

  yield put(actions.removeForm(FORM));

  yield put(
    actions.fetchPayees({
      getAllPayees: action.payload.getAllPayees,
      type: "salary"
    })
  );
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_SALARY_PAYEE_FORM)
  );
}

export function* handleBulkAddPayee(
  action: ActionType<typeof actions.bulkAddPayeeSubmit>
) {
  const state: RootState = yield select();
  const countryCode = selectors.getCurrentCountry(state);
  const { purpose, ids } = action.payload;
  let payees: object = {};

  let identificationType = "nricfin"; // country: sg
  if (countryCode !== "SG") {
    identificationType = "mykad";
  }

  try {
    ids.map(id => {
      let payee: any;

      payee = {
        account_number: formSelectors.getControl(state, `account_number_${id}`)
          .value,
        bank_id: formSelectors.getControl(state, `bank_id_${id}`).value,
        bank_swift_code: mapIdToSwiftCode(
          state,
          formSelectors.getControl(state, `bank_id_${id}`).value as string
        ),
        country_id: accountProfileSelectors.getCurrentCountryId(state),
        currency_id: accountProfileSelectors.getCurrentCurrencyId(),
        paid_currency_id: accountProfileSelectors.getCurrentPaidCurrencyId(),
        purpose,
        recipient_name: formSelectors.getControl(state, `recipient_name_${id}`)
          .value
      };

      switch (purpose) {
        case "salary":
          payee.identification_number = formSelectors.getControl(
            state,
            `identification_number_${id}`
          ).value;
          payee.identification_type = identificationType;
          payee.default_amount = utils.amountStringToInt(
            formSelectors.getControl(state, `default_amount_${id}`)
              .value as string
          );
          payee.default_comments = formSelectors.getControl(
            state,
            `default_comments_${id}`
          ).value;
          break;
        case "invoice":
          payee.registration_number = formSelectors.getControl(
            state,
            `registration_number_${id}`
          ).value;
          break;
      }

      payees[id] = payee;
    });

    switch (purpose) {
      case "salary":
        payees = yield composeSalaryPayeeData(payees, ids);
        break;
    }
  } catch (e) {
    window.Logger.error("handleBulkAddPayee: ", e.message);
    return;
  }

  yield put(formActions.resetErrors(BULK_ADD_FORM));

  const res: Response = yield call(RestClient.send, {
    body: {
      payees: Object.keys(payees).map(id => payees[id])
    },
    service: "create_bulk_payees",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    const mapErrors = {
      fields: {},
      form: errors.form
    };
    for (const fieldName of Object.keys(errors.fields)) {
      const fieldIdx = fieldName.split("_").pop();
      mapErrors.fields[
        fieldName.replace(
          `_${fieldIdx}`,
          `_${ids[parseInt(fieldIdx as string, 10)]}`
        )
      ] = errors.fields[fieldName];
    }

    yield put(formActions.parseServerErrors(mapErrors, BULK_ADD_FORM));
    return;
  }

  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_PAYEE_BULK_PROCESS_CSV)
  );
  history.push(`/wizard/select/${purpose}?action=add`);
}

export function* handleNoteToSupplier(
  action: ActionType<typeof actions.noteToSupplier>
) {
  const { purpose } = action.payload;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, NOTE_TO_SUPPLIER_FORM);
  const note = _get(formState, "note_to_supplier.value", "").toString();
  const currentCurrency = selectors.getCurrentCurrency(state);
  const countryCode = selectors.getCurrentCountry(state);
  const bankById = getBanksMap(state);
  const payees = (payeeSelectors.getPayees(
    state,
    "invoice"
  ) as InvoicePayee[]).map(payee => ({
    account_number: payee.accountNumber,
    bank_id: payee.bankId,
    bank_swift_code: bankById[payee.bankId].swiftCode,
    country_code: countryCode,
    currency_code: currentCurrency,
    default_amount: payee.defaultAmount,
    default_comments: note,
    id: payee.id.toString(),
    purpose,
    recipient_contact_name: payee.recipientContactName,
    recipient_email: payee.email,
    recipient_mobile: payee.recipientMobile,
    recipient_name: payee.name,
    registration_number: payee.registrationNumber,
    should_notify_recipient_initiated: payee.shouldNotifyRecipientInitiated,
    supporting_documents: payee.files.map(file => file.key)
  }));

  const res = yield call(RestClient.send, {
    body: {
      payees
    },
    service: "note_to_supplier",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const json = res;
  const errors = _get(json, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, FORM));
    return;
  }

  yield put(actions.fetchPayees({ type: "invoice" }));
}

/**
 * Add more props: document_tag
 */
function* composeSalaryPayeeData(payees: object, ids: string[]) {
  const state: RootState = yield select();

  const documentTags = formSelectors.getControlsPattern(
    state,
    /^document_tag_/
  );

  documentTags.map(control => {
    const fileRef = control.name.replace("document_tag_", "");
    const supportingDocument = formSelectors.getControl(state, fileRef);

    switch (control.value) {
      case "all":
        ids.map(payeeId => {
          payees[payeeId].supporting_documents =
            payees[payeeId].supporting_documents || [];
          payees[payeeId].supporting_documents.push(supportingDocument.value);
          payees[payeeId].supporting_documents = _uniq(
            payees[payeeId].supporting_documents
          );
        });
        break;
      default:
        const id = parseInt(control.value as string, 10);
        payees[id].supporting_documents = payees[id].supporting_documents || [];
        payees[id].supporting_documents.push(supportingDocument.value);
        payees[id].supporting_documents = _uniq(
          payees[id].supporting_documents
        );
    }
  });

  return payees;
}

export function* handleCheckValidNoteToRecipient(
  action: ActionType<typeof actions.checkValidNoteToRecipient>
) {
  const { value, defaultCommentsFieldName, cb } = action.payload;
  const state: RootState = yield select();
  const countryCode = selectors.getCurrentCountry(state);

  const res = yield call(RestClient.send, {
    body: {
      country_code: countryCode,
      default_comments: value,
      purpose_id: 0
    },
    service: "validate_note_to_recipient"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const json = res;
  const errors = _get(json, "errors", {});

  cb();
  if (!_isEmpty(errors)) {
    yield put(
      formActions.parseServerErrors(errors, FORM, {
        default_comments: defaultCommentsFieldName
      })
    );
    return false;
  }

  return true;
}

export function* handleCheckPayee(
  action: ActionType<typeof actions.checkPayee>
) {
  const id = action.payload.payeeId;

  const res: Response = yield call(RestClient.send, {
    params: {
      id
    },
    service: "check_payee",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data = _get(res, "data", {});
    yield put(
      actions.setExtraInfo({
        extraInfo: {
          hasActiveSchedule: data.has_active_schedule,
          recurring: data.recurring
        },
        payeeId: action.payload.payeeId,
        purpose: action.payload.purpose
      })
    );

    if (action.payload.cb) {
      action.payload.cb({
        hasActiveSchedule: data.has_active_schedule,
        recurring: data.recurring
      });
    }
  } catch (e) {
    window.Logger.error("handleCheckPayee: ", e.message);
  }
}

// Self Transfer (International payment)
export function* handleAddSelfTransferSubmit(
  action: ActionType<typeof actions.addSelfTransferSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, INTERNATIONAL_FORM);

  const profile = selectors.getCurrentAccountProfile(state);
  const countryCode = selectors.getCurrentCountry(state);

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );
  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  const bankSwiftCode = _get(formState, "bank_swift_code.value", "");

  const isUseBankId =
    countryCode === "MY" || countryCode === "HK" || countryCode === "SG";

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
  }

  const accountProfile = selectors.getCurrentAccountProfile(state);
  const { countryId, currencyId, paymentPaidCurrencyId } = accountProfile;

  let amount;
  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        formActions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      formActions.parseServerErrors(
        { form: [], fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] } },
        FORM
      )
    );
    return;
  }

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined),
      account_number: _get(formState, "account_number.value", "")
        .toString()
        .toUpperCase(),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        ""
      ),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined),
      bsb_code: _get(formState, "bsb_code.value", undefined),
      clabe: _get(formState, "clabe.value", undefined),
      cnaps: _get(formState, "cnaps.value", undefined),
      country_id: countryId,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value", ""),
      email: _get(accountProfile, "email", ""),
      first_name: _get(accountProfile, "firstName", ""),
      iban: _get(formState, "iban.value", undefined),
      ifsc_code: _get(formState, "ifsc_code.value", undefined),
      is_business: false,
      last_name: _get(accountProfile, "lastName", ""),
      paid_currency_id: paymentPaidCurrencyId,
      purpose: "self_transfer",
      recipient_name:
        _get(profile, "firstName", "") + " " + _get(profile, "lastName", ""),
      sort_code: _get(formState, "sort_code.value", undefined),
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      relationship: _get(formState, "relationship.value")
    },
    service: "create_payee",
    showGlobalLoader: true
  });

  yield put(commonActions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors) && errors !== 0) {
    yield put(formActions.parseServerErrors(errors, INTERNATIONAL_FORM));
    return;
  }

  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_SELF_TRANSFER_PAYEE_FORM)
  );
  history.push("/wizard/select/self_transfer?action=add");
}

export function* handleEditSelfTransferSubmit(
  action: ActionType<typeof actions.editSelfTransferSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, INTERNATIONAL_FORM);

  const token = commonSelectors.getToken(state);
  if (!token) {
    return;
  }

  const profile = selectors.getCurrentAccountProfile(state);
  const countryCode = selectors.getCurrentCountry(state);

  const supportingDocuments = _get(
    formState,
    "supporting_documents.value",
    undefined
  );
  const bankById = getBanksMap(state);
  const bankId = _get(formState, "bank_id.value", 0);
  let bankRawName = _get(formState, "bank_raw_name.value", ""); // International
  const bankSwiftCode = _get(formState, "bank_swift_code.value", "");

  const isUseBankId =
    countryCode === "MY" || countryCode === "HK" || countryCode === "SG";

  if (bankId !== 0) {
    bankRawName = bankById[bankId].name;
    // bankSwiftCode = bankById[bankId].swiftCode;
  }

  const currentAccountProfile = selectors.getCurrentAccountProfile(state);
  const {
    countryId,
    currencyId,
    paymentPaidCurrencyId
  } = currentAccountProfile;

  let amount;
  try {
    amount = _get(formState, "default_amount.value", "0").toString();
    if (!utils.isValidAmount(amount)) {
      yield put(
        formActions.parseServerErrors(
          { form: [], fields: { default_amount: ["INVALID_AMOUNT"] } },
          FORM
        )
      );
      return;
    }
    amount = utils.amountStringToInt(amount);
  } catch (e) {
    yield put(
      formActions.parseServerErrors(
        { form: [], fields: { default_amount: ["INVALID_AMOUNT_CONVERSION"] } },
        FORM
      )
    );
    return;
  }

  const res = yield call(RestClient.send, {
    body: {
      aba: _get(formState, "aba.value", undefined),
      account_number: _get(formState, "account_number.value", "")
        .toString()
        .toUpperCase(),
      bank_account_holder_name: _get(
        formState,
        "bank_account_holder_name.value",
        ""
      ),
      bank_id: isUseBankId ? bankId : undefined,
      bank_raw_name: bankRawName,
      bank_swift_code: bankSwiftCode,
      // branch_code: _get(formState, "branch_code.value", undefined),
      bsb_code: _get(formState, "bsb_code.value", undefined),
      clabe: _get(formState, "clabe.value", undefined),
      cnaps: _get(formState, "cnaps.value", undefined),
      country_id: countryId,
      currency_id: currencyId,
      default_amount: amount,
      default_comments: _get(formState, "default_comments.value", ""),
      email: _get(currentAccountProfile, "email", ""),
      first_name: _get(currentAccountProfile, "firstName", ""),
      iban: _get(formState, "iban.value", undefined),
      ifsc_code: _get(formState, "ifsc_code.value", undefined),
      is_business: false,
      last_name: _get(currentAccountProfile, "lastName", ""),
      paid_currency_id: paymentPaidCurrencyId,
      purpose: "self_transfer",
      recipient_name:
        _get(profile, "firstName", "") + " " + _get(profile, "lastName", ""),
      sort_code: _get(formState, "sort_code.value", undefined),
      supporting_documents: supportingDocuments
        ? supportingDocuments.split(",")
        : [],
      relationship: _get(formState, "relationship.value")
    },
    params: {
      id: _get(formState, "payee_id.value")
    },
    service: "update_payee",
    showGlobalLoader: true
  });

  yield put(commonActions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const json = res;
  const errors = _get(json, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, INTERNATIONAL_FORM));
    return;
  }
  yield put(actions.fetchPayees({ type: "self_transfer" }));
  yield put(
    commonActions.closeModal(commonActions.ModalID.ADD_SELF_TRANSFER_PAYEE_FORM)
  );
}
