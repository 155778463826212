import { format } from "date-fns";

export default {
  formatDate: (date: string, formatDate?: string): string => {
    if (date !== "") {
      return format(new Date(date), formatDate || "DD/MM/YYYY");
    } else {
      return "-";
    }
  },
  formatISODate: (date: string): string => {
    if (date !== "") {
      return new Date(date).toISOString();
    } else {
      return "-";
    }
  },
  getCurrentYear: (): number => {
    return new Date().getFullYear();
  }
};
