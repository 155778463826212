import Services from "./List";
import _get from "lodash-es/get";
import _upperFirst from "lodash-es/upperFirst";
import _camelCase from "lodash-es/camelCase";
import fetchToCurl from "fetch-to-curl";
import uuid from "uuid";
import _isUndefined from "lodash-es/isUndefined";
import _isNull from "lodash-es/isNull";

export type SendParams = {
  token?: string;
  body?:
    | FormData
    | {
        [key: string]: any;
      };
  timeout?: number; // Ms. E.g: 10000,
} & ServiceParams;

type Method = "GET" | "POST" | "PUT" | "DELETE";
type ServiceInfo = {
  method: Method;
  url: string;
};

export type RestResponse = Response & {
  service?: ServiceInfo;
};

const pendingRequests: {
  [urlAndMethod: string]: AbortController;
} = {};

type StaticUrl = {
  url: string;
  method: string;
};
type DefinedService = {
  params?: object;
  /** E.g: get_users. See in List.ts */
  service: string;
};

type ServiceParams = (StaticUrl | DefinedService) & {
  nocache?: boolean;
  query?: object;
};
class BaseRestService {
  public static getServiceInfo(args: ServiceParams): ServiceInfo {
    let urlStr: string = ""; // temporary
    let method: string = "";
    if (args.hasOwnProperty("url")) {
      args = args as StaticUrl;
      urlStr = args.url;
      method = args.method;
    } else if (args.hasOwnProperty("service")) {
      args = args as DefinedService;
      for (const service of Services) {
        if (service.hasOwnProperty(args.service)) {
          const parts = service[args.service].split(" ");
          method = parts[0];
          const uri = parts[1];
          urlStr = `${service.base_url}${uri}`;
          if (args.params) {
            Object.keys(args.params).map(key => {
              urlStr = urlStr.replace(
                `:${key}`,
                _get((args as DefinedService).params, key)
              );
            });
          }
        }
      }
    } else {
      throw new Error("Invalid send params");
    }

    try {
      const url = new URL(urlStr);
      if (args.query) {
        Object.keys(args.query).map(q => {
          if (
            args.query &&
            !_isUndefined(args.query[q]) &&
            !_isNull(args.query[q])
          ) {
            url.searchParams.set(q, args.query[q]);
          }
        });
      }
      if (args.nocache) {
        url.searchParams.set("burst", uuid.v4());
      }

      return {
        method: method.toUpperCase() as Method,
        url: url.toString()
      };
    } catch (e) {
      throw new Error("Invalid url: " + urlStr);
    }
  }

  protected handleSend(args: SendParams): Promise<any> {
    const service = BaseRestService.getServiceInfo(args);
    const { url, method } = service;

    return new Promise((resolve, reject) => {
      const controller = new AbortController();

      let pendingRequestsKey = url + method;
      if (method.toUpperCase() === "GET") {
        if (pendingRequestsKey.indexOf("/v2/admin/") > -1) {
          pendingRequestsKey = url.split("?")[0] + method;
        }
        if (pendingRequests.hasOwnProperty(pendingRequestsKey)) {
          pendingRequests[pendingRequestsKey].abort();
          delete pendingRequests[pendingRequestsKey];
        }
        pendingRequests[pendingRequestsKey] = controller;
      }

      if (args.timeout !== -1) {
        if (process.env.REACT_APP_DISABLE_REQUEST_TIMEOUT !== "y") {
          const timeoutNumber: number = args.timeout
            ? args.timeout
            : parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || "11000", 10);

          setTimeout(() => {
            controller.abort();
            reject(new Error("Abort connection because of timeout"));
          }, timeoutNumber);
        }
      }

      if (process.env.REACT_APP_CURL_LOG === "y") {
        console.info(`
        #curl-name: ${_upperFirst(_camelCase((args as any).service ?? ""))}
        ${fetchToCurl(url, {
          body: args.body
            ? args.body instanceof FormData
              ? args.body
              : JSON.stringify(args.body)
            : undefined,
          method
        })}`);
      }

      const headers = {
        authorization: `Bearer ${args.token}`
      };

      let sessionId: string | null = "";
      try {
        sessionId = window.sessionStorage.getItem("id");
      } catch (e) {
        console.error("Client does not support sessionStorage", e);
      }

      if (sessionId) {
        headers["x-ipm-session-id"] = sessionId;
      }

      headers["x-ipm-client-version"] = process.env.REACT_APP_BUILD_VERSION; // build version is pointed out when building

      const appId = process.env.REACT_APP_FETCH_APP_ID || "";

      if (appId) {
        headers["x-ipm-app-id"] = appId;
      }

      fetch(url, {
        body: args.body
          ? args.body instanceof FormData
            ? args.body
            : JSON.stringify(args.body)
          : undefined,
        credentials: "include",
        headers,
        method,
        mode: "cors",
        signal: controller.signal
      })
        .then((response: Response) => {
          // Attach service into response body for later use.
          const clonedResponse: RestResponse = response.clone();
          clonedResponse.service = service;
          resolve(clonedResponse);
        })
        .catch(reason => {
          if (reason.name === "AbortError") {
            return;
          }

          reject(reason);
        });
    });
  }
}

export default BaseRestService;
