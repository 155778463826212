import * as React from "react";
import { IProps } from "./index";
import { ROLES } from "src/ipm-shared/store/model/AccountProfile/const";
import { RoleType } from "src/ipm-shared/store/model/AccountProfile/types";

class AuthorizeComponent extends React.Component<IProps> {
  public componentDidMount() {
    // TODO: implement a pretty scrollTo.
    window.scrollTo(0, 0);
  }

  public render() {
    const hasPermission = this.hasPermission();
    return hasPermission && this.props.children;
  }

  private hasPermission(): boolean {
    let { roles } = this.props;
    const { userRole, isBusinessAccount } = this.props;

    if (typeof roles === "string") {
      roles = [roles];
    }

    let hasPermission = true;
    (roles as RoleType[]).map((role: RoleType) => {
      switch (role) {
        case "__ADMIN__":
          if (
            ROLES.INTERNAL_ADMIN_1 !== userRole &&
            ROLES.INTERNAL_ADMIN_2 !== userRole &&
            ROLES.AM_ADMIN !== userRole
          ) {
            hasPermission = false;
          }
          break;

        case "__BUSINESS_ACCOUNT__":
          if (!isBusinessAccount) {
            hasPermission = false;
          }

          break;
      }

      return null;
    });

    return hasPermission;
  }
}

export default AuthorizeComponent;
