/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { AccountRateType, RateType } from "./reducer";

export const fetchRates = createAction("adminRate/FETCH", resolve => () =>
  resolve()
);

export const setRates = createAction(
  "adminRate/SET_RATES",
  resolve => (args: { isFetching: boolean; rates: RateType[] }) => resolve(args)
);

export const add = createAction("adminRate/ADD_RATE", resolve => () =>
  resolve()
);
export const change = createAction("adminRate/CHANGE_RATE", resolve => () =>
  resolve()
);

export const adminAddAccountRate = createAction(
  "adminRate/ADMIN_ADD_RATE",
  resolve => (cb?: () => void) => resolve({ cb })
);

export const adminUpdateAccountRate = createAction(
  "adminRate/ADMIN_UPDATE_RATE",
  resolve => (cb?: () => void) => resolve({ cb })
);

export const adminDeleteAccountRate = createAction(
  "adminUser/DELETE_RATE",
  resolve => (accountRateId: number) => resolve({ accountRateId })
);

export const selectRate = createAction(
  "adminRate/SELECT_RATE",
  resolve => (index: number) => resolve(index)
);

export const adminListAccountRate = createAction(
  "adminUser/LIST_ACCOUNT_RATE",
  resolve => (userId: number) => resolve({ userId })
);

export const setListAccountRate = createAction(
  "adminUser/SET_LIST_ACCOUNT_RATE",
  resolve => (listAccountRate: AccountRateType[]) =>
    resolve({ listAccountRate })
);
