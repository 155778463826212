import * as React from "react";
import { IProps } from "./index";
import PaymentTable from "./PaymentTable";
import PaymentRequestTable from "./PaymentRequestTable";
import Loader from "src/ipm-shared/components/Loader";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import _get from "lodash-es/get";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as classNames from "classnames";
import { Collapse } from "reactstrap";

import T from "src/ipm-shared/Utils/Intl";

interface IState {
  isTogglePayDetail: boolean;
  isResentRequest: boolean;
  isSmsInvoice: boolean;
  collapse: boolean;
}

class BePaidPaymentPanels extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      collapse: false,
      isResentRequest: false,
      isSmsInvoice: false,
      isTogglePayDetail: props.dashboardTheme === "fnb"
    };
  }

  public render() {
    const {
      activityStatus,
      paymentRequestFailed,
      getCurrenciesById,
      collectedAccountCurrencyId
    } = this.props;

    const currencyId = collectedAccountCurrencyId;

    return (
      <div className="main-content">
        <div
          className={classNames("bar-notify-successfully", {
            "is-active": this.state.isResentRequest
          })}
        >
          {this.state.isSmsInvoice
            ? T.transl("REQUEST_RESENT_MOBILE")
            : T.transl("REQUEST_RESENT_EMAIL")}
        </div>

        <div className={"activity-block pay-in-activity-block"}>
          <h6>{T.transl("ACTIVITY_TITLE")}</h6>
          <div className={"payment-activity-status"}>
            <div className="row">
              <div className="col-xl-3 col-sm-6">
                <div className={"payment-status"}>
                  <div className={"text"}>
                    <span>{T.transl("ACTIVITY_PAYMENTS_REQUEST")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.395"
                      height="28.395"
                      viewBox="0 0 28.395 28.395"
                    >
                      <rect
                        id="Rectangle_89"
                        data-name="Rectangle 89"
                        width="28.395"
                        height="28.395"
                        rx="3.074"
                        fill="#1bd2d6"
                        opacity="0.3"
                      />
                      <g
                        id="Group_133"
                        data-name="Group 133"
                        transform="translate(-3469.275 -745.188)"
                      >
                        <line
                          id="Line_32"
                          data-name="Line 32"
                          y1="10.313"
                          x2="10.89"
                          transform="translate(3477.906 755.266)"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_296"
                          data-name="Path 296"
                          d="M3489.083,761.52l.162-6.549-6.229-.337"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className={"currency"}>
                    {currencyId && getCurrenciesById[currencyId].symbol}
                    <b>
                      <ShortCurrency
                        value={_get(activityStatus, "request", 0)}
                      />
                    </b>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6">
                <div className={"payment-status"}>
                  <div className={"text"}>
                    <span>{T.transl("ACTIVITY_PAYMENTS_COLLECTED")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.395"
                      height="28.395"
                      viewBox="0 0 28.395 28.395"
                    >
                      <g
                        id="Group_135"
                        data-name="Group 135"
                        transform="translate(-3756.524 -743.821)"
                        opacity="0.3"
                      >
                        <rect
                          id="Rectangle_91"
                          data-name="Rectangle 91"
                          width="28.395"
                          height="28.395"
                          rx="3.074"
                          transform="translate(3756.524 743.821)"
                          fill="#1bd2d6"
                        />
                      </g>
                      <g
                        id="Group_136"
                        data-name="Group 136"
                        transform="translate(-3756.524 -743.821)"
                      >
                        <line
                          id="Line_34"
                          data-name="Line 34"
                          y1="0.099"
                          x2="14.998"
                          transform="translate(3763.006 758.413)"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_298"
                          data-name="Path 298"
                          d="M3773.942,763.178l4.59-4.674-4.321-4.5"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className={"currency"}>
                    {currencyId && getCurrenciesById[currencyId].symbol}
                    <b>
                      <ShortCurrency
                        value={_get(activityStatus, "inprogress", 0)}
                      />
                    </b>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6">
                <div className={"payment-status"}>
                  <div className={"text"}>
                    <span>{T.transl("ACTIVITY_PAYMENTS_DEPOSITED")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.395"
                      height="28.395"
                      viewBox="0 0 28.395 28.395"
                    >
                      <rect
                        id="Rectangle_90"
                        data-name="Rectangle 90"
                        width="28.395"
                        height="28.395"
                        rx="3.074"
                        fill="#1bd2d6"
                        opacity="0.3"
                      />
                      <g
                        id="Group_134"
                        data-name="Group 134"
                        transform="translate(-4042.601 -743.821)"
                      >
                        <line
                          id="Line_33"
                          data-name="Line 33"
                          x1="12.069"
                          y2="11.65"
                          transform="translate(4051.468 752.066)"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_297"
                          data-name="Path 297"
                          d="M4051.081,756.725l-.111,7.326,6.97.31"
                          fill="none"
                          stroke="#4940a0"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className={"currency"}>
                    {currencyId && getCurrenciesById[currencyId].symbol}
                    <b>
                      <ShortCurrency
                        value={_get(activityStatus, "completed", 0)}
                      />
                    </b>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6">
                <div className={"buttons-block"}>
                  <button
                    onClick={this.openBePaidMakePayment}
                    className={"btn btn-sm btn-info btn-bepaid btn-round mb-3"}
                  >
                    {T.transl("REQUEST_PAYMENT_BUTTON")}
                  </button>
                  <button
                    onClick={this.handleTogglePayDetail}
                    className={
                      "btn btn-sm btn-outline-info btn-bepaid btn-round"
                    }
                  >
                    {this.state.isTogglePayDetail
                      ? T.transl("HIDE_DETAILS_BUTTON")
                      : T.transl("VIEW_DETAILS_BUTTON")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-request-notify">
            <ul className={"mb-0"}>
              {paymentRequestFailed.length > 0 && (
                <li className="text-danger mb-2">
                  {T.transl("PAYMENT_REQUEST_FAILED", {
                    render1: paymentRequestFailed[0].invoiceNo,
                    render2: paymentRequestFailed[0].isSmsInvoice
                      ? T.transl("DETAILS_PLEASE_CHECK_MOBILE")
                      : T.transl("DETAILS_PLEASE_CHECK_EMAIL"),
                    span1: (text: any) => (
                      <span className={"fw-600"}>{text}</span>
                    ),
                    span2: (text: any) => (
                      <span
                        className={
                          "fw-600 cursor-pointer text-underline-danger"
                        }
                        onClick={this.openResendModal.bind(this, {
                          customerEmail: paymentRequestFailed[0].customer.email,
                          id: paymentRequestFailed[0].id,
                          isSmsInvoice: paymentRequestFailed[0].isSmsInvoice,
                          mobileCountryId:
                            paymentRequestFailed[0].customer.mobileCountryId,
                          mobileNumber:
                            paymentRequestFailed[0].customer.mobileNumber
                        })}
                      >
                        {text}
                      </span>
                    )
                  })}
                  {paymentRequestFailed.length > 1 && (
                    <>
                      {" "}
                      <span className={"fw-600"}>
                        {paymentRequestFailed.length - 1}
                      </span>{" "}
                      <span>
                        more notification.
                        <span className={"btn-show-more"} onClick={this.toggle}>
                          {this.state.collapse ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.279"
                              height="16.279"
                              viewBox="0 0 16.279 16.279"
                            >
                              <path
                                id="Path_581"
                                data-name="Path 581"
                                d="M5854.28,858.173l-3.244-3.244-3.244,3.244"
                                transform="translate(-5842.899 -848.356)"
                                fill="none"
                                stroke="#ae005a"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0.581"
                              />
                              <circle
                                id="Ellipse_59"
                                data-name="Ellipse 59"
                                cx="7.849"
                                cy="7.849"
                                r="7.849"
                                transform="translate(0.291 0.291)"
                                strokeWidth="0.581"
                                stroke="#ae005a"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="none"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.279"
                              height="16.279"
                              viewBox="0 0 16.279 16.279"
                            >
                              <path
                                id="Path_314"
                                data-name="Path 314"
                                d="M4307.825,854.939l3.244,3.244,3.244-3.244"
                                transform="translate(-4303.163 -848.138)"
                                fill="none"
                                stroke="#ae005a"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0.581"
                              />
                              <circle
                                id="Ellipse_33"
                                data-name="Ellipse 33"
                                cx="7.849"
                                cy="7.849"
                                r="7.849"
                                transform="translate(0.291 0.291)"
                                strokeWidth="0.581"
                                stroke="#ae005a"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill="none"
                              />
                            </svg>
                          )}
                        </span>
                      </span>
                    </>
                  )}
                </li>
              )}

              <Collapse isOpen={this.state.collapse}>
                {paymentRequestFailed.map((p, idx) => (
                  <li
                    key={idx}
                    className={classNames("text-danger mb-2", {
                      "d-none": idx === 0
                    })}
                  >
                    <span className={"fw-600"}>
                      {T.transl("DETAILS_NOTIFICATION")}:
                    </span>
                    {T.transl("DETAILS_REQUEST_FAILED", {
                      span: () => (
                        <span className={"fw-600"}>{p.invoiceNo}</span>
                      )
                    })}
                    {T.transl("DETAILS_PLEASE_CHECK", {
                      render: p.isSmsInvoice
                        ? T.transl("DETAILS_PLEASE_CHECK_MOBILE")
                        : T.transl("DETAILS_PLEASE_CHECK_EMAIL")
                    })}
                    <span
                      className={"fw-600 cursor-pointer text-underline-danger"}
                      onClick={this.openResendModal.bind(this, {
                        customerEmail: p.customer.email,
                        id: p.id,
                        isSmsInvoice: p.isSmsInvoice,
                        mobileCountryId: p.customer.mobileCountryId,
                        mobileNumber: p.customer.mobileNumber
                      })}
                    >
                      here.
                    </span>
                  </li>
                ))}
              </Collapse>
            </ul>
          </div>
        </div>

        {this.state.isTogglePayDetail && (
          <div className="payment-status-section payment-request">
            <Loader color="#00A9BE" loading={false}>
              <div className={"payment-status-block lasted-payments"}>
                <h5>{T.transl("DETAILS_PAYMENTS_REQUESTED")}</h5>
                <PaymentRequestTable
                  payments={this.props.paymentRequested}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("LABEL_DASHBOARD_PAYMENT_EMPTY")}
                      <span
                        className="clickable-link"
                        onClick={this.openBePaidMakePayment}
                      >
                        {T.transl("DETAILS_REQUEST_PAYMENT_NOW")}
                      </span>
                    </p>
                  }
                />
              </div>

              <div className={"payment-status-block upcoming-payments"}>
                <h5>{T.transl("DETAILS_PAYMENTS_COLLECTED")}</h5>
                <PaymentTable
                  payments={this.props.paymentInProgress}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("LABEL_DASHBOARD_PAYMENT_EMPTY")}
                      <span
                        className="clickable-link"
                        onClick={this.openBePaidMakePayment}
                      >
                        {T.transl("DETAILS_REQUEST_PAYMENT_NOW")}
                      </span>
                    </p>
                  }
                />
              </div>

              <div className={"payment-status-block completed-payments"}>
                <h5>{T.transl("DETAILS_PAYMENTS_DEPOSITED")}</h5>
                <PaymentTable
                  status={"received"}
                  payments={this.props.paymentReceived}
                  nullText={
                    <p className="no-payment-yet">
                      {T.transl("LABEL_DASHBOARD_PAYMENT_EMPTY")}
                      <span
                        className="clickable-link"
                        onClick={this.openBePaidMakePayment}
                      >
                        {T.transl("DETAILS_REQUEST_PAYMENT_NOW")}
                      </span>
                    </p>
                  }
                />
              </div>
            </Loader>
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    if (this.state.isTogglePayDetail) {
      this.props.fetchDashboardCollectedPayments();
    }
  }

  private openBePaidMakePayment = () => {
    this.props.toggleModal(ModalID.BE_PAID_MAKE_PAYMENT);
  };

  private toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  private openResendModal = (args: {
    id: number;
    customerEmail: string;
    isSmsInvoice: boolean;
    mobileNumber: string;
    mobileCountryId: number;
  }) => {
    this.props.editCustomerId(args.id);
    this.props.toggleModal(ModalID.RESEND_PAYMENT_REQUEST, {
      callback: () => {
        this.setState({
          isResentRequest: true,
          isSmsInvoice: args.isSmsInvoice
        });
        setTimeout(() => {
          this.setState({ isResentRequest: false });
        }, 5000);
      },
      customerEmail: args.customerEmail,
      isSmsInvoice: args.isSmsInvoice,
      mobileCountryId: args.mobileCountryId,
      mobileNumber: args.mobileNumber,
      paymentId: args.id
    });
  };

  private handleTogglePayDetail = () => {
    const willOpen = !this.state.isTogglePayDetail;
    this.setState({ isTogglePayDetail: willOpen });

    if (willOpen) {
      this.props.fetchDashboardCollectedPayments();
    }
  };
}

export default BePaidPaymentPanels;
