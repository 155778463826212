export enum PURPOSE {
  RENTAL = 1,
  SALARY,
  INVOICE,
  COLLECTION,
  SELF_TRANSFER,
  PARTNERSHIP,
  SALARY_BUSINESS,
  INSURANCE
}

export const ADD_FORM = "add_payment_form";
export const SEARCH_FORM = "admin_payment_search_form";
export const SEARCH_CDD_FORM = "admin_cdd_search_form";
export const REFUND_FORM = "refund_form";
export const EXPEDITE_FORM = "expedite_form";
export const CONFIRM_FORM = "confirm_form";
export const PAYMENT_CHECK_SEARCH_FORM = "payment_check_search_form";
export const PAYMENT_PAYEE_EDIT_FORM = "payment_payee_edit_form";
export const PAYOUT_REQUEST_SEARCH_FORM = "admin_payout_request_search_form";
export const CREATE_PAYOUT_FORM = "admin_create_payout_form";
export const PAYMENT_DETAIL_FORM = "admin_payment_detail_form";

// Scheduled
export const SCHEDULE_EDIT_FORM = "schedule_edit_form";
export const SCHEDULE_EDIT_COUPON_FORM = "schedule_edit_coupon_form";

export enum PAYMENT_STATUS {
  DRAFT = 1,
  IN_PROGRESS, // Is schedule
  IN_PROGRESS_2,
  COMPLETED,
  DECLINED,
  CANCELLED_DUE_TO_DELETED_CARD,
  CANCELLED_DUE_TO_DELETED_SCHEDULE,
  CANCELLED_DUE_TO_DELETED_PROPERTY,
  FULLY_REFUNDED_WITH_FEE,
  FULLY_REFUNDED_WITHOUT_FEE,
  ON_HOLD,
  REQUEST,
  INT_PENDING = 15,
  PAID,
  UNDER_REVIEW = 17,
  ON_SCREENING_FOR_COMPLY_ADVANTAGE = 19,
  HOLD_FOR_COMPLY_ADVANTAGE = 21,
  SHORT,
  CRYPTO_HOLD,
  CRYPTO_PAYMENT_REFUND_REQUESTED,
  BLOCKED = 26,
  REFUND_PENDING = 27,
  ESCALATED = 28
}

export const REFUND_TYPE = {
  PARTIAL_REFUND_FEE: "partial_refund_fee",
  PARTIAL_REFUND_PRINCIPAL: "partial_refund_principal",
  REFUND_WITHOUT_FEE: "refund_without_fee",
  REFUND_WITH_FEE: "refund_with_fee",
  FULL_REFUND: "full_refund",
  PARTIAL_REFUND_PRINCIPAL_WITH_FEES: "partial_refund_principal_with_fees"
};

export const PAGE_COUNT = 50;
export const ADMIN_PAGE_COUNT = 200;
export const SCHEDULED_PAGE_COUNT = 50;
