import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type PaymentCollectionRequestType = {
  currencyId: number;
  subTotal: number;
  totalAmount?: number;
  nationFee?: number;
  overseaFee?: number;
  feePayer: number;
  isProduction: boolean;
  isCharged?: boolean;
  isCancelled?: boolean;
  requests: RequestType[];
  receiptNumber?: string;
  companyName?: string;
  token: string;
  cardCode?: string;
  statementDescriptor: string;
  paymentCountryId: number;
  purposeId?: number;
  companyLogoBase64?: string;
  returnUrl?: string;
  customerFee?: CustomerFee[];
};

export type RequestType = {
  invoiceNumber: string;
  amount: number;
  currencyId: number;
  amountTotal?: number;
  commissionFee?: number;
  isSecondary?: boolean;
  recipientName?: string;
};

export type CustomerFee = {
  brandId: number;
  currency: number;
  nationFee: number;
  overseaFee: number;
  feePayer: number;
  intFeePayer: number;
};
export type PaymentCollectionRequestState = {
  readonly isFetching: boolean;
  readonly payment: PaymentCollectionRequestType;
  readonly acquirerId: number;
};

const defaultState: PaymentCollectionRequestState = {
  acquirerId: 0,
  isFetching: false,
  payment: {
    cardCode: "",
    currencyId: 0,
    customerFee: [],
    feePayer: 0,
    isCharged: false,
    isProduction: false,
    nationFee: 0,
    overseaFee: 0,
    paymentCountryId: 0,
    requests: [],
    statementDescriptor: "",
    subTotal: 0,
    token: "",
    totalAmount: 0
  }
};

export default (state: PaymentCollectionRequestState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPaymentCollectionRequest):
      const updatedPayment = action.payload.payment;
      return {
        ...state,
        acquirerId: action.payload.acquirerId,
        isFetching: action.payload.isFetching,
        payment: {
          ...state.payment,
          ...updatedPayment
        }
      };
    case getType(actions.setPaymentCollectionFees):
      return {
        ...state,
        payment: {
          ...state.payment,
          customerFee: action.payload.customerFee
        }
      };
    case getType(actions.setCardCode):
      return {
        ...state,
        payment: {
          ...state.payment,
          cardCode: action.payload.cardCode
        }
      };
  }
  return state;
};
