import * as redux from "redux";
import createSagaMiddleware from "redux-saga";
import { RootState } from "./model/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
declare module "redux" {
  export type GenericStoreEnhancer = redux.StoreEnhancer;
}
let composeEnhancers: redux.GenericStoreEnhancer;
import { createBrowserHistory } from "history";
import rootReducer from 'src/ipm-shared/store/model/reducers';
export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

const composers = redux.applyMiddleware(sagaMiddleware);
if (process.env.NODE_ENV === "development") {
  composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  })(composers);
} else {
  composeEnhancers = composers;
}

export function configureStore(initialState: redux.DeepPartial<RootState>) {
  // @ts-ignore
  return redux.createStore(rootReducer, initialState, composeEnhancers);
}

function rehydrate(): redux.DeepPartial<RootState> {
  // Do some localstrage/cookie retrieval here.
  // TODO: use proper persisting
  const token = localStorage.getItem("token") || "";
  const smsOtpToken = localStorage.getItem("sms_otp_token") || "";
  return {
    accounts: {
      currentEmail: ""
    },
    auth: {
      smsOtpToken,
      token
    }
  } as redux.DeepPartial<RootState>; // Bypass tscheck.
}

export default configureStore(rehydrate());
