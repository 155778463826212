import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./../selectors";
import * as actions from "./../actions";

const mapStateToProps = (state: RootState) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  countries: selectors.getAllCountries(state),
  countryCode: selectors.getCurrentCountry(state),
  currentCurrency: selectors.getCurrentCurrency(state),
  currentPaidCurrency: selectors.getCurrentPaidCurrency(),
  hasCompany: selectors.hasCompany(),
  isBusiness: selectors.getIsBusinessAccount(state),
  isDisplayIrasPopup: selectors.isDisplayIrasPopup(state),
  isPersonal: selectors.getIsPersonalAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state)
});

const mapDispatchToProps = {
  changeUserSetting: actions.changeUserSetting,
  closeModal: actions.closeModal.bind(
    actions,
    actions.ModalID.MAKE_PAYMENT_MODAL
  ),
  exchangeToken: actions.exchangeToken,
  fetchPayees: actions.fetchPayees,
  removeForm: actions.removeForm,
  selectPayees: actions.selectPayees,
  toggleModal: actions.toggleModal
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
